import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AlloyPerformanceCpp, AlloyPerformanceCppServiceResponses, AlloyPerformanceUpwardFeedbackParams, AlloyPerformanceUpwardFeedbackServiceResponses, GetDashboardFinalOverviewForAdminResponseType, KrukOpenAPI } from "atfcore-commonclasses";
import * as moment from 'moment';
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { CompetenceGraph, ProgressGraph } from "src/app/admin/admin-dashboard/admin-dashboard.component";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { CountUsersForImpersonateResponse, ListUsersForImpersonateResponse, SenecaResponse } from "../../../commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { MonitoringFilter } from "../components/pce-monitoring-filters/pce-monitoring-filters.component";

@Injectable()
export class AdminService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  countPerformanceProcesses() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-performance-assessments-for-admin/')
  }


  countFeedback360Rounds() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-feedback-360-rounds/')
  }

  // Ritorna la list di round
  listFeedback360Rounds(roundStatus?: string, roundIds?: Array<string>, fromRecord?: number, numRecords?: number) {
    let httpParams = new HttpParams();

    if (roundStatus) {
      httpParams = httpParams.append('roundStatus', roundStatus);
    }
    if (roundIds && roundIds.length) {
      for (let i = 0; i < roundIds.length; i++) {
        httpParams = httpParams.append('roundIds', roundIds[i]);
      }
    }
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord)
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords)
    }

    return this.http.get<SenecaResponse<KrukOpenAPI.PerfAlloyFeedback360Round[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-feedback-360-rounds', {
      params: httpParams
    })
  }

  //Modifico il round
  updateFeedback360Round(roundId: string, roundStatus: KrukOpenAPI.PerfAlloyFeedback360RoundStatus, startDate: Date, endDate: Date) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-feedback-360-round', {
      roundId: roundId,
      roundStatus: roundStatus,
      startDate: startDate,
      endDate: endDate
    });
  }

  //Creo un nuovo round
  createFeedback360Round(round: KrukOpenAPI.PerfAlloyFeedback360Round) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-feedback-360-round', {
      round: round
    });
  }

  listPerformanceProcesses(fromRecord: number, numRecords: number, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-performance-assessments-for-admin/', {
      params: httpParams
    });
  }

  processStructureDetail(perfYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/monitoring-structures-for-admin/', {
      params: httpParams
    });
  }

  exportStructureReport(perfYear: string, preSharedKey?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-monitoring-structures-for-admin-report/', {
      params: httpParams
    });
  }

  countUserImpersonate(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    return this.http.get<SenecaResponse<CountUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-users-for-impersonate/', {
      params: httpParams
    })
  }


  listUserImpersonate(fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    return this.http.get<SenecaResponse<ListUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-users-for-impersonate/', {
      params: httpParams
    });

  }

  impersonateUserForAdmin(userIdToImpersonate: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userIdToImpersonate', userIdToImpersonate);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/impersonate-user-for-admin/', {
      params: httpParams
    });
  }

  // contatori utenti calibration
  calibrationUsersCounter(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-calibration-users-count-for-admin', {
      params: httpParams
    });
  }

  processUserCounter(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-process-participants-stats-for-admin', {
      params: httpParams
    });
  }

  countProcessUsers(perfYear: number, perfYearCode: string, searchedText: string, filters?: MonitoringFilter) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (searchedText) httpParams = httpParams.append('searchedText', searchedText);

    if (filters) {
      for (let i = 0; i < filters.evaluationStatus?.length; i++) {
        httpParams = httpParams.append("appraisalStatusFilter", filters.evaluationStatus[i] as string);
      }
      for (let i = 0; i < filters.selfEvaluationStatus?.length; i++) {
        httpParams = httpParams.append("selfAssessmentStatusFilter", filters.selfEvaluationStatus[i] as string);
      }
      for (let i = 0; i < filters.goalSettingStatus?.length; i++) {
        httpParams = httpParams.append("goalSettingStatusFilter", filters.goalSettingStatus[i] as string);
      }

      for (let i = 0; i < filters.performanceManagerName?.length; i++) {
        httpParams = httpParams.append("performanceManagerName", (filters.performanceManagerName[i] as any).userId);
      }

      for (let i = 0; i < filters.position?.length; i++) {
        httpParams = httpParams.append("position", filters.position[i] as string);
      }
      for (let i = 0; i < filters.department?.length; i++) {
        httpParams = httpParams.append("department", filters.department[i] as string);
      }
    }

    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-process-participants-for-admin', {
      params: httpParams
    });
  }

  // Lista utenti calibration
  listProcessUsers(perfYear: number, perfYearCode: string, searchedText: string, fromRecord: number, numRecords: number, filters?: MonitoringFilter) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (searchedText) httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      for (let i = 0; i < filters?.evaluationStatus?.length; i++) {
        httpParams = httpParams.append("appraisalStatusFilter", filters?.evaluationStatus[i] as string);
      }
      for (let i = 0; i < filters?.selfEvaluationStatus?.length; i++) {
        httpParams = httpParams.append("selfAssessmentStatusFilter", filters?.selfEvaluationStatus[i] as string);
      }
      for (let i = 0; i < filters?.goalSettingStatus?.length; i++) {
        httpParams = httpParams.append("goalSettingStatusFilter", filters?.goalSettingStatus[i] as string);
      }

      for (let i = 0; i < filters?.performanceManagerName?.length; i++) {
        httpParams = httpParams.append("performanceManagerName", (filters?.performanceManagerName[i] as any).userId);
      }

      for (let i = 0; i < filters?.position?.length; i++) {
        httpParams = httpParams.append("position", filters?.position[i] as string);
      }
      for (let i = 0; i < filters?.department?.length; i++) {
        httpParams = httpParams.append("department", filters?.department[i] as string);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-process-participants-for-admin', {
      params: httpParams
    });
  }

  // Ritorna range payout
  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-payout-range-by-final-evaluation', {});
  }

  // recap di tutti i dati necessari per definire la calibration
  getUserCalibrationData(perfYear: number | string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-calibration-data-for-admin', {
      params: httpParams
    });
  }

  setCalibration(perfYear: number, perfCareUserCalibrationToUpdate: any) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-calibration-for-admin', {
      perfYear: perfYear,
      perfCareUserCalibrationToUpdate: perfCareUserCalibrationToUpdate
    });
  }

  massiveConfirmCalibration(perfYear: number, userIdsToConfirmCalibration: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/massive-confirm-calibration-for-admin', {
      perfYear: perfYear,
      userIdsToConfirmCalibration: userIdsToConfirmCalibration
    });
  }

  getFinalEvaluationFormDataForAdmin(perfYear: number, perfYearCode: string, targetUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('targetUserId', targetUserId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-final-evaluation-form-data-for-admin', {
      params: httpParams
    });
  }

  // Lista usenti paginata e ricerca per appunti privati
  countFilterUsers(perfYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-company-users-for-admin/', {
      params: httpParams
    });
  }

  downloadProcessReport(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());

    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-report-for-admin', {
      params: httpParams
    })
  }
  downloadFeedbackReport(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());

    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-report-feedback-for-admin', {
      params: httpParams
    })
  }

  listFilterUsers(fromRecord: number, numRecords: number, perfYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-company-users-for-admin/', {
      params: httpParams
    });
  }

  downloadProcessParticipantsReportForAdmin(perfYear: number, perfYearCode: string, allData?: boolean, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('allData', allData ? 'true' : 'false');

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-process-participants-report-for-admin', {
      params: httpParams
    });
  }

  exportEvaluationCardPdfForAdmin(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-evaluation-card-pdf-for-admin', {
      params: httpParams
    });
  }


  downloadMatrixReport(perfYear: number, perfYearCode: string, userIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-monitoring-matrix-users-for-admin', {
      params: httpParams
    });
  }

  savePostCalibrationFormForAdmin(targetUserIds: string[], comment: string | null, shareForm: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/save-post-calibration-form-for-admin', {
      comment: comment,
      targetUserIds: targetUserIds,
      shareForm: shareForm
    });
  }


  getMonitoringMatrixUsersForAdmin(perfYear: number, perfYearCode: string, userIds?: string[], teamsOfUserIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    } else if (teamsOfUserIds && teamsOfUserIds.length) {
      for (let i = 0; i < teamsOfUserIds.length; i++) {
        httpParams = httpParams.append('teamsOfUserIds', teamsOfUserIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-monitoring-matrix-users-for-admin', {
      params: httpParams
    });
  }

  downloadSimulatedMatrixReportForAdmin(perfYear: number, perfYearCode: string, simulatedData: { userId: string; pasProfile: string; }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-simulated-matrix-report-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      simulatedData: simulatedData
    });
  }


  countBehavior(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-behavior-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listBehavior(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-behavior-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  listMacroBehaviorForAssessment(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-macro-behavior-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagToCreate: { title: string, macroBehaviorTagId: string, description: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagToCreate: behaviorTagToCreate,
    });
  }

  updateBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagToUpdate: { tagId?: string, title: string, macroBehaviorTagId: string, description: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagToUpdate: behaviorTagToUpdate,
    });
  }

  deleteBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagIdToDelete: behaviorTagIdToDelete,
    });
  }


  importBehaviorInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-behaviors-in-process-by-year', formData, options);
    return this.http.request(req);

  }

  importCompetenceInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-competences-in-process-by-year', formData, options);
    return this.http.request(req);

  }

  countCompetences(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-competence-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listCompetences(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-competence-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagToCreate: { title: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagToCreate: competenceTagToCreate,
    });
  }

  updateCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagToUpdate: { tagId?: string, title: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagToUpdate: competenceTagToUpdate,
    });
  }

  deleteCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagIdToDelete: competenceTagIdToDelete,
    });
  }

  getDashboardSelfAssessment(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-self-assessment-for-admin/', {
      params: httpParams
    });
  }

  getDashboardPeopleAppraisal(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-people-appraisal-for-admin/', {
      params: httpParams
    });
  }

  getDashboardCalibration(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-calibration-for-admin/', {
      params: httpParams
    });
  }

  getDashboardPas(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-restituzione-pas-for-admin/', {
      params: httpParams
    });
  }

  getDashboardBehaviorCompetence(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-behaviour-and-competences-for-admin/', {
      params: httpParams
    });
  }

  getDashboardOverview(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }
    return this.http.get<SenecaResponse<GetDashboardFinalOverviewForAdminResponseType>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-final-overview-for-admin/', {
      params: httpParams
    });
  }

  getDashboardCompetencesForAdmin(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }
    return this.http.get<SenecaResponse<{ competences: Array<CompetenceGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-competences-for-admin/', {
      params: httpParams
    });
  }
  getDashboardProgress(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }
    return this.http.get<SenecaResponse<{ competences: Array<ProgressGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-progress-for-admin/', {
      params: httpParams
    });
  }
  getFeedback(perfYear: number, perfYearCode: string, filters: Array<{ id: string, value: string }>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-feedback-for-admin/', {
      params: httpParams
    });
  }

  countRoles(perfYear: number, perfYearCode: string, retrieveBehaviorRole: boolean, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (retrieveBehaviorRole) {
      httpParams = httpParams.append('retrieveBehaviorRole', 'true');
    } else {
      httpParams = httpParams.append('retrieveCompetenceRole', 'true');
    }
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-roles-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listRoles(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, retrieveBehaviorRole: boolean, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (retrieveBehaviorRole) {
      httpParams = httpParams.append('retrieveBehaviorRole', 'true');
    } else {
      httpParams = httpParams.append('retrieveCompetenceRole', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-roles-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  getRole(perfYear: number, perfYearCode: string, roleId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('tagId', roleId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-role-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createRole(perfYear: number, perfYearCode: string, roleTagToCreate: { title: string, evaluateSpecialisticGoal?: boolean; competenceTags?: { tagId: string }[], behaviorTags?: { tagId: string, evaluationScaleTagId: string }[] }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagToCreate: roleTagToCreate
    });
  }

  updateRole(perfYear: number, perfYearCode: string, roleTagToUpdate: { tagId?: string, title: string, evaluateSpecialisticGoal?: boolean; competenceTags?: { tagId: string }[], behaviorTags?: { tagId: string, evaluationScaleTagId: string }[] }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagToUpdate: roleTagToUpdate
    });
  }

  deleteRole(perfYear: number, perfYearCode: string, roleTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagIdToDelete: roleTagIdToDelete
    });
  }

  countDictionaries(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-evaluation-scales-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listDictionaries(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[], allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-evaluation-scales-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  getDictionary(perfYear: number, perfYearCode: string, tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('tagId', tagId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-evaluation-scale-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createDictionary(perfYear: number, perfYearCode: string, evaluationScaleToCreate: { title: string, description: string, evaluation: { evaluationPoint: number, evaluationDescription: string }[]; }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToCreate: evaluationScaleToCreate
    });
  }

  deleteDictionary(perfYear: number, perfYearCode: string, evaluationScaleToDelete: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToDelete: evaluationScaleToDelete
    });
  }

  updateDictionary(perfYear: number, perfYearCode: string, evaluationScaleToUpdate: { scaleTagId: string, title: string, description: string, evaluation: { evaluationPoint: number, evaluationDescription: string }[]; }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToUpdate: evaluationScaleToUpdate
    });
  }

  downloadCompetencesReport(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-competences-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  // riapre scheda self assessment
  reopenSelfAssessmentSchedule(userId: string, perfYear: number, perfYearCode: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/reopen-self-assessment-final-evaluation-form-for-admin', {
      userId: userId,
      perfYear:perfYear,
      perfYearCode:perfYearCode
    });
  }

  // riapre scheda appraisal
  reopenPeopleAppraisalSchedule(userId: string, perfYear:number, perfYearCode: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/reopen-people-appraisal-final-evaluation-form-for-admin', {
      userId: userId,
      perfYear:perfYear,
      perfYearCode:perfYearCode
    });
  }

  // riapre scheda goal setting
  reopenGoalSettingSchedule(userId: string, perfYear:number, perfYearCode: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/reopen-goal-setting-form-for-admin', {
      userId: userId,
      perfYear:perfYear,
      perfYearCode:perfYearCode
    });
  }

  // Import dei dizionari
  importDictionaryInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-behavior-scale-in-process-by-year', formData, options);
    return this.http.request(req);
  }

  // Import dei dizionari
  importRolesInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-roles-in-process-by-year', formData, options);
    return this.http.request(req);
  }

  getProcess(assessmentId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-for-admin', {
      assessmentId: assessmentId
    });
  }


  createProcess(perfYear: number, perfYearCode: string, processTitle: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-performance-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      processTitle: processTitle
    });
  }

  // aggiorna solo le date delle fasi
  updateProcessPhase(perfYear: number, perfYearCode: string, phaseKey?: string, startDate?: Date, endDate?: Date, deadlineDate?: Date) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-performance-assessment-phase-dates-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      phaseKey: phaseKey,
      startDate: startDate,
      endDate: endDate,
      deadlineDate: deadlineDate,
    });
  }

  listAnagUser(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromRecord', fromRecord.toString());

    if (filters.length) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].id && filters[i].value) {
          httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
        }
      }
    }

    // if (searchedText) {
    //   httpParams = httpParams.append('searchedText', searchedText.toString());
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-data-in-process-for-admin/', {
      params: httpParams
    });
  }

  includeUsersInProcessForAdmin(perfYear: number, perfYearCode: string, userIds: Array<string>) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/include-users-in-process-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userIds: userIds
    });
  }

  excludeUsersFromProcessForAdmin(perfYear: number, perfYearCode: string, userIds: Array<string>) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/exclude-users-from-process-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userIds: userIds
    });
  }

  getAnagCountersData(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-analytics-count-for-admin/', {
      params: httpParams
    });
  }

  getAnagUser(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id && filters[i].value) {
        httpParams = httpParams.append(filters[i]?.id.toString(), filters[i].value);
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-count-in-process-for-admin/', {
      params: httpParams
    });
  }


  getUserAnagraphicById(perfYear: number, perfYearCode: string,userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-user-data-by-id-for-admin/', {
      params: httpParams
    });
  }


  /** ----------- WIP MARCO WEBRANKING */
  setPersonAsSync(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-person-as-sync', {
      userId: userId
    });
  }

  setPersonAsAsync(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-person-as-async', {
      userId: userId
    });
  }


  countValidUsersForSetManager(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (filters) {
      if (filters.searchedText) {
        httpParams = httpParams.append('searchedText', filters.searchedText);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-valid-users-for-set-as-manager-for-admin/', {
      params: httpParams
    });
  }

  // Aggiorna il processo
  updateProcess(perfYear: number, perfYearCode: string, processStatus: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      processStatus: processStatus
    });
  }


  countPeopleAnag(searchedText: string) {
    return of({ "error": null, "response": 2 });
  }

  listPeopleAnag(fromRecord: number, numRecords: number, searchedText: string) {
    return of({
      "error": null,
      "response": [
        {
          forename: "Claudia",
          surname: "Adani",
          codFisc: "DDDDDDDDDDDDDDDD",
          email: "chiara.adani@webranking.it",
          position: "Nome Posizione",
          status: "EXCLUDED"
        },
        {
          forename: "Mario",
          surname: "Rossi",
          codFisc: "DDDDDDDDDDDDDDDD",
          email: "mario.fake@webranking.it",
          position: "Nome Posizione",
          status: "INCLUDED"
        }
      ]
    });
    //return this.http.get<SenecaResponse<any>>( ¡${this.applicationData.applicationContext} + 'rest-api/alloyperformance-mediator/')
  }

  DetailPeopleAnag() {
    return of({
      "error": null,
      "response": {
        surname: "Adani",
        forename: "Chiara",
        codFisc: "DNACHR82B68F257F",
        email: "chiara.adani@webranking.it",
        selfAssessment: true,
        descrizioneRuoloOperativo: "No",
        mansioneOrganizzativa: "HR",
        performanceManagerName: "Cecilia Magnani",
        performanceManagerCodFisc: "MGNCCL79A71B819I",
        defTpDip: "HR Generalist",
        chief: "Risorse Umane",
        team: null,
        ramo: "HR",
        sedeLavoro: "Correggio"
      }
    });
    //return this.http.get<SenecaResponse<any>>( ¡${this.applicationData.applicationContext} + 'rest-api/alloyperformance-mediator/')
  }

  getPhases() {
    return of({
      error: null,
      response: [
        {
          id: '1',
          label: 'Valutazione',
          startDate: new Date(),
          endDate: new Date(),
          closeDate: new Date(),
          status: 'INACTIVE'
        },
        {
          id: '2',
          label: 'AutoValutazione',
          startDate: new Date(),
          endDate: new Date(),
          closeDate: new Date(),
          status: 'ACTIVE'
        },
        {
          id: '3',
          label: 'Aggiornamento degli obiettivi',
          startDate: new Date(),
          endDate: new Date(),
          closeDate: new Date(),
          status: 'INACTIVE'
        }
      ]
    })
  }
  // get-performance-assessment-phases-for-admin
  getPerformanceAssessmentPhasesForAdmin(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-phases-for-admin/', {
      params: httpParams
    });
  }

  listProcessUserAnagraphics(perfYear: number, perfYearCode: string, searchedText: string, fromRecord: number, numRecords: number, userIds?: string[], filters?: any) {
    return of({
      error: null,
      response: [{
        "userId": "d6abf53b-570a-4c09-a42d-6a66ec5d869c",
        "cid": "2633354",
        "codFisc": "BTALCU95B19L259Q",
        "forename": "Luca",
        "surname": "Abate",
        "chiaveSesso": "M",
        "email": "luca.abate@facile.it",
        "dataAssunzione": "2020/12/10T00:00:00+01:00",
        "socDistacco": "Facile.it s.p.a.",
        "ramo": "MARKETING",
        "active": true,
        "userOptions": {
          "avatarImage": null,
          "langCode": "it",
          "timezone": "Europe/Rome"
        },
        "userTenant": {
          "tenant": "FACILE_GRP"
        },
        "isInProcess": true
      },
      {
        "userId": "f77230cb-95db-47e0-bc97-74e47d2ddec8",
        "cid": "11928396",
        "codFisc": "BBTSRA97A45B354F",
        "forename": "Sara",
        "surname": "Abbate",
        "chiaveSesso": "F",
        "email": "sara.abbate@facile.it",
        "dataAssunzione": "2022-10-18T22:00:00.000Z",
        "socDistacco": "Facile.it Contact s.r.l.",
        "ramo": "OPERATIONS",
        "active": true,
        "userOptions": {
          "avatarImage": null,
          "langCode": "it",
          "timezone": "Europe/Rome"
        },
        "userTenant": {
          "tenant": "FACILE_GRP"
        },
        "isInProcess": false
      }]
    }) as any;

    // let httpParams = new HttpParams();
    // httpParams = httpParams.append('perfYear', perfYear.toString());
    // httpParams = httpParams.append('perfYearCode', perfYearCode);
    // httpParams = httpParams.append('searchedText', searchedText);
    // httpParams = httpParams.append('fromRecord', fromRecord.toString());
    // httpParams = httpParams.append('numRecords', numRecords.toString());

    // if (userIds && userIds.length) {
    //   for (let i = 0; i < userIds.length; i++) {
    //     httpParams = httpParams.append('userIds', userIds[i]);
    //   }
    // }

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   } 0
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    // }

    // return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-data-in-process-for-admin', {
    //   params: httpParams
    // });
  }

  setUserManagerByUserId(userId: string, managerUserId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-performance-manager-for-admin', {
      userId: userId,
      managerUserId: managerUserId
    });
  }

  getValidUsersForSetManager(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.searchedText) {
        httpParams = httpParams.append('searchedText', filters.searchedText);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-valid-users-for-set-as-manager-for-admin/', {
      params: httpParams
    });
  }

  // includeUserInProcess(perfYear: number, perfYearCode: string, userIds: string[]) {
  //   return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/include-user-in-process-for-admin', {
  //     perfYear: perfYear,
  //     perfYearCode: perfYearCode,
  //     userIds: userIds,
  //   });
  // }

  includeUserInProcess(perfYear: number, perfYearCode: string, userIds?: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/include-user-in-process-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userIds: userIds
    });
  }

  importIncludeUserInProcess(perfYear: number, perfYearCode: string, file?: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/include-user-in-process-for-admin', formData, options);
    return this.http.request(req);
  }

  excludeUserFromProcess(perfYear: number, perfYearCode: string, userIds?: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/exclude-user-from-process-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userIds: userIds
    });
  }


  importExcludeUserFromProcess(perfYear: number, perfYearCode: string, file?: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);

    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/exclude-user-from-process-for-admin', formData, options);
    return this.http.request(req);
  }

  downloadAllUsersForAdmin(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-all-users-for-admin/', {
      params: httpParams
    });
  }

  createCppProcess(name: string, startDate: Date, endDate: Date) {
    return this.http.post<SenecaResponse<AlloyPerformanceCpp.CppRound>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-cpp-round-for-admin', {
      roundName: name.toString(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    });
  }

  updateCppProcess(roundId: string, name: string, startDate: Date, endDate: Date, status: string) {
    return this.http.post<SenecaResponse<AlloyPerformanceCpp.CppRound>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-cpp-round-for-admin', {
      roundId: roundId.toString(),
      roundName: name.toString(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      status: status.toString()
    });
  }

  countCppRounds() {

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-cpp-rounds-for-admin/');
  }

  getCppRounds(fromRecord?: string, numRecords?: string, roundIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord || '0');
    httpParams = httpParams.append('numRecords', numRecords || '0');
    if (roundIds?.length) {
      for (let i = 0; i < roundIds.length; i++) {
        httpParams = httpParams.append('roundIds', roundIds[i]);
      }
    }
    return this.http.get<SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppRoundsForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-rounds-for-admin/', {
      params: httpParams
    });
  }

  downloadCppReport(roundId: string, userIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    if (userIds?.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-cpp-survey-for-admin/', {
      params: httpParams
    });
  }

  // Count cpp round
  countCppRoundUsers(roundId: string, searchedText: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('searchedText', searchedText);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('ruolo', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-users-in-cpp-round-for-admin', {
      params: httpParams
    });
  }


  // Lista utenti cpp round
  getCppRoundUsers(roundId: string, searchedText: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('ruolo', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<AlloyPerformanceCppServiceResponses.GetUsersInCppRoundForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-in-cpp-round-for-admin', {
      params: httpParams
    });
  }

  getCppUserFilters(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<AlloyPerformanceCpp.CppUsersFilters>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-users-distinct-field-filter-for-admin/', {
      params: httpParams
    });
  }

  includeCppUsers(roundId: string, userIds: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/include-users-in-cpp-round', {
      roundId: roundId,
      userIds: userIds
    });
  }

  excludeCppUsers(roundId: string, userIds: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/exclude-users-from-cpp-round', {
      roundId: roundId,
      userIds: userIds
    });
  }

  downloadCppTemplate(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-template-for-admin/', {
      params: httpParams
    });
  }

  setCppTemplate(roundId: string, file: any) {
    let formData = new FormData();
    formData.append('roundId', roundId);
    formData.append('file', file);

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-cpp-survey-template-for-admin', formData, options);
    return this.http.request(req);
  }

  getAvailableCppRoundCppDate(roundId: string) {
    let httpParams = new HttpParams();
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-available-cpp-round-dates-for-admin/', {
      params: httpParams
    });
  }

  // Ritorna il count dei round di Upward Feedback
  countUpwardFeedbackRounds() {
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-upwardfeedback-rounds-for-admin/')
  }

  // Ritorna la lista dei round disponibili
  getUpwardFeedbackRounds(fromRecord: number, numRecords: number, roundIds?: string[], allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    if (roundIds && roundIds.length) {
      httpParams = httpParams.append('roundIds', roundIds.toString());
    }
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackRoundsForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-rounds-for-admin/', {
      params: httpParams
    });
  }

  // Crea un nuovo round per l'Upward Feedback
  createUpwardFeedbackRound(roundName: string, startDate: Date, endDate: Date) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-upwardfeedback-round-for-admin', {
      roundName: roundName,
      startDate: startDate,
      endDate: endDate
    });
  }

  // Aggiorna un round di l'Upward Feedback
  updateUpwardFeedbackRound(roundId: string, roundName: string, startDate: Date, endDate: Date, status: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-upwardfeedback-round-for-admin', {
      roundId: roundId,
      roundName: roundName,
      startDate: startDate,
      endDate: endDate,
      status: status
    });
  }

  // Recupera il tempalte attuale di un round
  getUpwardFeedbackTemplate(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-template-for-admin/', {
      params: httpParams
    });
  }

  // Aggiorna un round di l'Upward Feedback
  updateUpwardFeedbackSurveyTemplate(roundId: string, uploadObj: any,) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('roundId', roundId);
    let params = new HttpParams();
    // params = params.append('roundId', roundId);
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-upwardfeedback-survey-template-for-admin', formData, options);
    return this.http.request(req);
  }

  //CountManagersInUpwFeedRoundForAdmin
  // Ritorna il count dei manager
  countManagersInUpwardFeedbackRound(roundId: string, searchedText: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('searchedText', searchedText);
    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.upwardEvaluationStatus && filters.upwardEvaluationStatus.value) {
        httpParams = httpParams.append('evaluationStatus', filters.upwardEvaluationStatus.value);
      }
    }

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-managers-in-upwardfeedback-round-for-admin/', {
      params: httpParams
    });
  }

  getManagersInUpwardFeedbackRound(roundId: string, searchedText: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.upwardEvaluationStatus && filters.upwardEvaluationStatus.value) {
        httpParams = httpParams.append('evaluationStatus', filters.upwardEvaluationStatus.value);
      }
    }

    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetManagersInUpwFeedRoundForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-managers-in-upwardfeedback-round-for-admin/', {
      params: httpParams
    });
  }

  getUpwardFeedbackEvaluatorsOfManager(params: AlloyPerformanceUpwardFeedbackParams.GetUpwardFeedbackEvaluatorsForAdmin) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', params.roundId);
    httpParams = httpParams.append('userId', params.userId);

    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackEvaluatorsForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-evaluators-of-manager-for-admin/', {
      params: httpParams
    });
  }

  // Ritorna i filtri di ricerca per l'admin
  getUpwardFeedbackFilters(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<AlloyPerformanceCpp.CppUsersFilters>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-managers-distinct-field-filter-for-admin/', {
      params: httpParams
    });
  }

  // Recupera i risultati di un manger
  getUpwardFeedbackManagerResults(params: AlloyPerformanceUpwardFeedbackParams.GetUpwardFeedbackResultsForAdmin) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', params.roundId);
    httpParams = httpParams.append('managerId', params.managerId);

    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForAdmin>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-admin/', {
      params: httpParams
    });
  }

  // Dalla pagina di dettaglio di  un manager manda i reminder ai valutatori che non hanno ancora compilato la scheda
  sendReminderToRemainingEvaluators(params: AlloyPerformanceUpwardFeedbackParams.SendUpwardFeedbackReminderToUsersForAdmin) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-upwardfeedback-reminder-to-users-for-admin', {
      roundId: params.roundId,
      managerId: params.managerId,
    });
  }

  // Condividi i risultati con il manager
  shareUpwardFeedbackResultsWithManager(params: AlloyPerformanceUpwardFeedbackParams.ShareUpwardFeedbackResultsWithManager) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-upwardfeedback-results-with-manager-for-admin', {
      roundId: params.roundId,
      userIds: params.userIds,
    });
  }

  // Cambia status del manager
  changeUpwardFeedbackManagerStatus(params: AlloyPerformanceUpwardFeedbackParams.ChangeUpwardFeedbackManagerStatusForAdmin) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/change-upwardfeedback-status-of-manager-for-admin', {
      roundId: params.roundId,
      userId: params.userId,
      status: params.status
    });
  }

  downloadUpwardFeedbackReport(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-upwardfeedback-data-for-admin', {
      params: httpParams
    });
  }

  // Recupera il file delle competenze
  getExcportCompetencesReport(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-competences-report-for-admin', {
      params: httpParams
    });
  }

  // Recupera il file dei ruoli
  getExcportRolesReport(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-roles-report-for-admin', {
      params: httpParams
    });
  }

  // Recupera il file dizionario
  getExcportCompetenceScalesReport(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-competence-scales-report-for-admin', {
      params: httpParams
    });
  }
  importCompetences(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-competences-for-admin', formData, options);
    return this.http.request(req);
  }

  importRoles(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-roles-for-admin', formData, options);
    return this.http.request(req);
  }

  importCompetenceScales(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-competence-scales-for-admin', formData, options);
    return this.http.request(req);
  }
}