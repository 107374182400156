<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap.gt-xs="16px">

  <banner-home [isAdminBanner]="true"></banner-home>
  <!-- [ngClass]="isCppAdmin && !isAdmin? 'only-cpp-box': ''" -->
  <div class="box-collection-container">
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
      fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
      <!-- Setup processi  -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/settings.svg"
        backgroundColor="#E0DAFF" title="{{'adminHome.cards.PROCESS_SETUP' | translate}}"
        [disabledModalMessage]="boxDisabledModal" description="adminHome.cards.PROCESS_SETUP_DESCR" [isNew]="false"
        (onButtonClicked)="goToProcessSetup()">
      </box-home>
      <!-- Setup delle competenze -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/settings.svg"
        backgroundColor="#E0DAFF" title="{{'adminHome.cards.PROCESS_COMPETENCES' | translate}}"
        description="adminHome.cards.PROCESS_COMPETENCES_DESCR" [isNew]="false"
        [disabledModalMessage]="boxDisabledModal" (onButtonClicked)="goToSetupCompetences()">
      </box-home>
      <!-- Anagrafica delle persone  -->
      <!-- Rimosso la tile anagrafica perchè attualmente non serve -->
      <box-home *ngIf="false" [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
        iconSrc="../../../../assets/img/icons/building.svg" backgroundColor="#FFD1D3"
        title="{{'adminHome.cards.ANAG' | translate}}" [isDisabled]="true" [disabledModalMessage]="boxDisabledModal"
        description="adminHome.cards.ANAG_DESCR" [isNew]="false" (onButtonClicked)="goToPeopleAnag()"></box-home>
      <!-- Impersonifica [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToImpersonate()"
        iconSrc="../../../../assets/img/icons/user.svg" backgroundColor="#FFECCB"
        title="{{'adminHome.cards.IMPERSONATE' | translate}}" description="adminHome.cards.IMPERSONATE_DESCR"
        [isNew]="false"></box-home>
      <!-- Dashboard [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/pie-chart.svg"
        backgroundColor="#DDE0FF" title="{{'adminHome.cards.DASHBOARD' | translate}}"
        description="adminHome.cards.DASHBOARD_DESCR" [isNew]="false" (onButtonClicked)="goToDashboard()"></box-home>
      <!-- Monitoraggio -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/balance.svg"
        backgroundColor="#E1F1FD" title="{{'adminHome.cards.CALIBRATION' | translate}}"
        (onButtonClicked)="goToMonitoring()" description="adminHome.cards.CALIBRATION_DESCR" [isNew]="false">
      </box-home>
      <!-- Feedback 360 -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
        iconSrc="../../../../assets/img/icons/messages-square.svg" backgroundColor="#E1F1FD"
        title="{{'adminHome.cards.FEEDBACK_360' | translate}}" (onButtonClicked)="goToFeedback360()"
        description="adminHome.cards.FEEDBACK_360_DESCR" [isNew]="false">
      </box-home>

      <!-- Monitoraggio (vecchia calibration) -->
      <!--ng-container *ngIf="canAccessCalibration">
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/balance.svg"
          backgroundColor="#E1F1FD" title="{{'adminHome.cards.CALIBRATION' | translate}}"
          (onButtonClicked)="goToCalibration()" description="adminHome.cards.CALIBRATION_DESCR" [isNew]="false">
        </box-home>
      </ng-container-->
    </div>
  </div>
</div>