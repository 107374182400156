<div class="page-content-wrapper">

  <header-dossier [user]="loggedUser" [disabled]="true" [hasTabs]="false" [yearItems]="yearList"
    [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)" (onNewInterview)="downloadReport()"
    [isDashboard]="true" [hideAction]="true">
  </header-dossier>

  <div class="main-content-wrapper">
    <div class="accordion-list">
      <!-- Overview finale -->
      <div class="item-container">
        <ng-container *ngIf="!isLoadingOverviewData">
          <div class="title-container">
            <p translate="dashboard.accordion.FINAL_OVERVIEW"></p>
            <svg-icon src="../../../assets/img/icons/info.svg" (click)="openFinalOverviewModal()"></svg-icon>
          </div>
          <dx-chart id="finalChart" [dataSource]="finalOverviewData">
            <dxo-common-series-settings argumentField="status" [barPadding]="0.5">
            </dxo-common-series-settings>
            <dxi-series type="bar" valueField="value" [name]="finalOverviewData[0]?.structure" color="#006EC7">
            </dxi-series>
            <dxi-series *ngIf="finalOverviewData[0]?.cumulativePercent" type="spline" valueField="cumulativePercent"
              [name]="'dashboard.ENTIRE_COMPANY' | translate" color="#003282"></dxi-series>

            <dxo-title [text]="'dashboard.accordion.FINAL_OVERVIEW' | translate">
              <dxo-font color="#333333" family="'Lato', sans-serif" weight="600" size="20px">
              </dxo-font>
              <dxo-subtitle [text]="'dashboard.FINAL_OVERVIEW_DESCR' | translate">
                <dxo-font color="#767676" weight="300" size="12px">
                </dxo-font>
              </dxo-subtitle>
            </dxo-title>

            <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
            </dxo-legend>
            <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20" [valueMarginsEnabled]="false">
              <dxo-label [customizeText]="customizePercentageText"></dxo-label>
            </dxi-value-axis>
            <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
          </dx-chart>
        </ng-container>

        <!-- Loader -->
        <ng-container *ngIf="isLoadingOverviewData">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </div>

      <!-- Avanzamento processo -->
      <percentage-accordion [title]="'dashboard.accordion.ADVANCEMENT_PROCESS' | translate" [isAccordionOpened]="true"
        [isLoading]="isLoadingPeopleAppraisalData" (onModalMessageOpen)="openAdvancementProcessModal()"
        (onAccordionOpen)="redrawGraphs('people-appraisal')" [isLoading]="isLoadingProgressData"
        [cardsCount]="{all:progressData.totalEligible, shared: progressData.overallSent}"
        [percentage]="progressData.percentage">
        <ng-container *ngIf="!isLoadingProgressData && progressData">
          <div class="circularProgress-container">
            <div class="cluster-wrapper" *ngFor="let gauge of progressData.list; let i = index">
              <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                <div class="circularProgress" fxLayoutAlign="center center" [ngClass]="gauge.class">
                  <div tabindex="0" class="circularProgress-overlay">{{gauge.value || 0}}</div>
                </div>
                <div fxLayout="column" class="full-width" fxLayoutAlign="center start">
                  <p tabindex="0" class="cluster-indicator-label full-width">
                    {{gauge.title}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Loader -->
        <ng-container *ngIf="isLoadingProgressData">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </percentage-accordion>

      <!-- Grafici spider -->
      <percentage-accordion [title]="'recap.COMPETENCE' | translate" [hidePercentage]="true" [isAccordionOpened]="true"
        [isLoading]="isLoadingPeopleAppraisalData" (onModalMessageOpen)="openSpiderModal()"
        (onAccordionOpen)="redrawGraphs('people-appraisal')">
        <div class="graphs-main" *ngIf="!isLoadingCompetencesData && managerialOverallGraph.length">
          <!-- Manageriali -->
          <div class="graphs-container">
            <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="managerialOverallGraph">
              <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
              </dxo-legend>
              <dxo-title text="Competenze manageriali">
                <dxo-font color="#232323" size="16px" weight="400">
                </dxo-font>
              </dxo-title>
              <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
              <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
              <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
              <dxo-common-series-settings type="line"></dxo-common-series-settings>
              <dxo-tooltip [enabled]="true"></dxo-tooltip>
            </dx-polar-chart>
          </div>
          <!-- Legenda -->
          <div class="graphs-container gray">
            <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
            <ng-container *ngFor="let competence of managerialOverallGraph">
              <div class="competence-container">
                <p class="indicator">{{competence.legend.title}}</p>
                <p class="explanation">{{competence.legend.desc}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="graphs-main" *ngIf="!isLoadingCompetencesData && genericOverallGraph.length">
          <!-- Generali -->
          <div class="graphs-container">
            <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="genericOverallGraph">
              <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
              </dxo-legend>
              <dxo-title text="Competenze generali">
                <dxo-font color="#232323" size="16px" weight="400">
                </dxo-font>
              </dxo-title>
              <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
              <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
              <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
              <dxo-common-series-settings type="line"></dxo-common-series-settings>
              <dxo-tooltip [enabled]="true"></dxo-tooltip>
            </dx-polar-chart>
          </div>
          <!-- Legenda -->
          <div class="graphs-container gray">
            <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
            <ng-container *ngFor="let competence of genericOverallGraph">
              <div class="competence-container">
                <p class="indicator">{{competence.legend.title}}</p>
                <p class="explanation">{{competence.legend.desc}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Loader -->
        <ng-container *ngIf="isLoadingCompetencesData">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </percentage-accordion>

      <!-- Competenze-->
      <percentage-accordion [title]="'dashboard.accordion.GENERAL_COMPETENCE' | translate" [hidePercentage]="true"
        [isAccordionOpened]="true" [isLoading]="isLoadingCompetencesData" (onModalMessageOpen)="openCompetenceModal()"
        (onAccordionOpen)="redrawGraphs('people-appraisal')">
        <bar-chart *ngIf="!isLoadingCompetencesData" [valueList]="competenceGraphData"></bar-chart>

        <!-- Loader -->
        <ng-container *ngIf="isLoadingCompetencesData">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </percentage-accordion>


      <!-- Feedback -->
      <percentage-accordion [title]="'dashboard.accordion.FEEDBACK' | translate" [isAccordionOpened]="true"
        [hidePercentage]="true" (onModalMessageOpen)="openFeedbackModal()"
        (onAccordionOpen)="redrawGraphs('people-appraisal')">
        <ng-container *ngIf="!isLoadingFeedback && feedbackData">
          <div class="circularProgress-container">
            <div class="cluster-wrapper" *ngFor="let gauge of feedbackData; let i = index">
              <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                <div class="circularProgress" fxLayoutAlign="center center" [ngClass]="gauge.class">
                  <div tabindex="0" class="circularProgress-overlay">{{gauge.value || 0}}</div>
                </div>
                <div fxLayout="column" class="full-width" fxLayoutAlign="center start">
                  <p tabindex="0" class="cluster-indicator-label full-width">
                    {{gauge.title}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Loader -->
        <ng-container *ngIf="isLoadingFeedback">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </percentage-accordion>
    </div>
  </div>
</div>


<!-- Filtri -->
<modal id="dashboard-tooltip" (onClose)="closeDashboardTooltip()">
  <modal-text-content modalId="dash002" [title]="'dashboard.modals.DASH_TOOL_TITLE' | translate"
    [text]="'dashboard.modals.DASH_TOOL_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale OverView finale -->
<modal id="finalOverview" (onClose)="closeFinalOverviewModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.FINAL_OVERVIEW_TITLE' | translate"
    [text]="'dashboard.modals.FINAL_OVERVIEW_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale avanzamento processo -->
<modal id="advancementProcess" (onClose)="closeAdvancementProcessModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.ADVANCEMENT_PROCESS_TITLE' | translate"
    [text]="'dashboard.modals.ADVANCEMENT_PROCESS_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale grafico spider -->
<modal id="spiderGraph" (onClose)="closeSpiderModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.COMPETENCE_TITLE' | translate"
    [text]="'dashboard.modals.COMPETENCE_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale competenze -->
<modal id="competence" (onClose)="closeCompetenceModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.COMPETENCE_TITLE' | translate"
    [text]="'dashboard.modals.COMPETENCE_TEXT_2' | translate"></modal-text-content>
</modal>

<!-- Modale feedback -->
<modal id="feedback" (onClose)="closeFeedbackModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.FEEDBACK_TITLE' | translate"
    [text]="'dashboard.modals.FEEDBACK_TEXT' | translate"></modal-text-content>
</modal>