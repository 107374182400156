<ng-container [ngSwitch]="level">
  
  <!-- Non applicabile -->
  <div *ngSwitchCase="-1"
    class="rating-item-na"
    [ngClass]="{
      'active': active,
      'basic': !active,
      'read-only': readOnly
    }"
    (click)="getLevel(-1)">
    N/A
  </div>
 
  <!-- 0 -->
  <div *ngSwitchCase="0"
    class="rating-item"
    [ngClass]="{ 'active-zero': active, 'basic': !active, 'read-only': readOnly, 'sm': size == 'sm', 'lg': size == 'lg', 'md': size == 'md' }"
    (click)="getLevel(level)">
    <div class="zero" 
      [ngClass]="{
        'active': active,
        'basic': !active,
        'read-only': readOnly,
      }">
    </div>
  </div>
 
  <!-- Tutti gli altri -->
  <div *ngSwitchDefault
    class="rating-item"
    [ngClass]="{ 'active': active, 'basic': !active, 'read-only': readOnly, 'sm': size == 'sm', 'lg': size == 'lg', 'md': size == 'md' }"
    style="--level: '{{level}}'"
    (click)="getLevel(level)">
  </div>
</ng-container>

