import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { SenecaResponse } from 'src/commonclasses';
import * as CoreActions from "../../core/ngrx/core.actions";
import { AlloyPerformanceCppServiceResponses } from 'atfcore-commonclasses';

@Component({
  selector: 'app-technicalAssessment',
  templateUrl: './technicalAssessment.component.html',
  styleUrls: ['./technicalAssessment.component.scss']
})
export class TechnicalAssessmentComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  isImpersonate: boolean = false;
  loggedUser: any;
  combinedSelected$: any;

  isFetchingCppSurvey: boolean = false;
  currentModule: any;
  selectedAnswers: any[] = [];
  originalSelectedAnswers: any[] = [];
  roundId: string = '';

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Self Assessment - Crea o modifica appunto privato");
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const runningYear$ = this.store.select(fromApp.getRunningYear);
    this.combinedSelected$ = combineLatest([loggedUser$, runningYear$])
      .subscribe(([loggedUser, runningYear]) => {
        this.loggedUser = loggedUser && loggedUser.user;
        this.runningYear = runningYear;
        if (this.runningYear && this.runningYear.year) {
          this.route.params
            .subscribe(
              (params: Params) => {
                this.roundId = params.roundId;
                this.getSurveyInfoCPP();
              }
            );
        }
      });
  }

  ngOnInit() {
  }

  // Recupero dati cpp
  getSurveyInfoCPP(moduleId?: string) {
    this.isFetchingCppSurvey = true;

    if(moduleId) {
    this.closePrevModuleModal();
    }

    this.selfAssessmentService.getCppSurvey(this.roundId, moduleId)
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppSurveyForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else if (data.response) {
          this.currentModule = data.response;
          this.currentModule.totalPercentage = Math.floor(this.currentModule.totalPercentage);
          if (this.currentModule.questions && this.currentModule.questions.length) {
            this.selectedAnswers = [];
            this.originalSelectedAnswers = [];
            for (let i = 0; i < this.currentModule.questions.length; i++) {
              let question = this.currentModule.questions[i];
              let header = question.text.substring(0, question.text.indexOf('\n'));
              let footer = question.text.substring(question.text.lastIndexOf('\n'), question.text.length);
              footer = footer.replaceAll('\n', '');
              let list = question.text.substring(question.text.indexOf('\n\n'), question.text.lastIndexOf('\n'));
              list = list.substring(1);
              list = list.replaceAll('\n', '<br>');

              question.text = list?.length ?  '<b>' + header + '</b><br>' + list + '<b><br>' + footer + '*</b>' :
              '<b>' + header + '</b><br><br>' + '<b>' + footer + '*</b>';

              let answersRadioList: any[] = [];
              question.answers.forEach((x: any, index: number) => {
                answersRadioList.push({
                  id: index,
                  value: x
                })
              })
              question.answersRadioList = answersRadioList;
              if (question.selectedAnswerIndex != null && question.selectedAnswerIndex != undefined) {
                this.selectedAnswers.push(
                  {
                    questionId: question.questionId,
                    selectedAnswerIndex: question.selectedAnswerIndex
                  }
                );
                question.selectedAnswer = question.answersRadioList.filter((x: any) => {
                  return x.id == question.selectedAnswerIndex;
                })[0];
              }
            }
            this.originalSelectedAnswers = JSON.parse(JSON.stringify(this.selectedAnswers));
          }
        }
        this.isFetchingCppSurvey = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
          this.isFetchingCppSurvey = false;
        })
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  openInstructionsModal() {
    this.modalService.open("cpp-instructions-modal");
  }

  closeInstructionsModal() {
    this.modalService.close("cpp-instructions-modal");
  }

  goPrecModule() {
    if (this.isSelectedAnswersNotChanged()) {
      this.getSurveyInfoCPP(this.currentModule.prevModuleId);
    } else {
      this.openPrevModuleModal();
    }
  }

  saveCurrentModule(saveLastModule?: boolean) {
    if (this.selectedAnswers?.length != this.currentModule?.questions?.length) {
      this.openNotContinueAssessmentModal();
    }
    else if (!saveLastModule && (this.currentModule.index + 1) == this.currentModule.totalModules) {
      this.openConfirmTechnicalAssessmentModal();
    } else {
      this.isFetchingCppSurvey = true;

      if (saveLastModule) {
        this.closeConfirmTechnicalAssessmentModal();
      }

      this.selfAssessmentService.updateCppSurvey(this.roundId, this.currentModule.id, this.selectedAnswers)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "ta001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          } else if (data.response?.id) {
            this.currentModule = data.response;
            this.currentModule.totalPercentage = Math.floor(this.currentModule.totalPercentage);
            this.selectedAnswers = [];
            this.originalSelectedAnswers = [];
            this.currentModule?.questions?.forEach((x: any) => {

              let header = x.text.substring(0, x.text.indexOf('\n'));
              let footer = x.text.substring(x.text.lastIndexOf('\n'), x.text.length);
              footer = footer.replaceAll('\n', '');
              let list = x.text.substring(x.text.indexOf('\n\n'), x.text.lastIndexOf('\n'));
              list = list.substring(1);
              list = list.replaceAll('\n', '<br>');

              x.text = list?.length ?  '<b>' + header + '</b><br>' + list + '<b><br>' + footer + '*</b>' :
              '<b>' + header + '</b><br><br>' + '<b>' + footer + '*</b>';
              let answersRadioList: any[] = [];
              x.answers.forEach((y: any, index: number) => {
                answersRadioList.push({
                  id: index,
                  value: y
                })
              })
              x.answersRadioList = answersRadioList;
              if (x.selectedAnswerIndex != null && x.selectedAnswerIndex != undefined) {
                this.selectedAnswers.push(
                  {
                    questionId: x.questionId,
                    selectedAnswerIndex: x.selectedAnswerIndex
                  }
                );
                x.selectedAnswer = x.answersRadioList.filter((y: any) => {
                  return y.id == x.selectedAnswerIndex;
                })[0];
              }
            })
            this.originalSelectedAnswers = JSON.parse(JSON.stringify(this.selectedAnswers));
          } else if (data.response) {
            this.goBackBrowser();
          }
          this.isFetchingCppSurvey = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "ta002",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
            this.isFetchingCppSurvey = false;
          })
    }
  }

  onRadioSelect(event: any, questionId: string) {
    let indexToDelete = this.selectedAnswers.findIndex(object => {
      return object.questionId === questionId;
    });

    if (indexToDelete != -1 && indexToDelete != null && indexToDelete != undefined) {
      this.selectedAnswers.splice(indexToDelete, 1);
    }

    this.selectedAnswers.push({
      questionId: questionId,
      selectedAnswerIndex: event.id
    })
  }

  openConfirmTechnicalAssessmentModal() {
    this.modalService.open("confirm-technical-assessment-modal");
  }

  closeConfirmTechnicalAssessmentModal(saveLastModule?: boolean) {
    if (saveLastModule) {
      this.saveCurrentModule(true);
    } else {
      this.modalService.close("confirm-technical-assessment-modal");
    }
  }

  openPrevModuleModal() {
    this.modalService.open("open-prev-module-modal");
  }

  closePrevModuleModal(goToPrevModule?: boolean) {
    if (goToPrevModule) {
      this.getSurveyInfoCPP(this.currentModule.prevModuleId);
    } else {
      this.modalService.close("open-prev-module-modal");
    }
  }

  openNotContinueAssessmentModal() {
    this.modalService.open("not-continue-assessment-modal");
  }

  closeNotContinueAssessmentModal() {
    this.modalService.close("not-continue-assessment-modal");
  }

  openGoBackModal() {
    this.modalService.open("open-go-back-modal");
  }

  closeGoBackModal(goBack?: boolean) {
    if (goBack) {
      this.modalService.close("open-go-back-modal");
      this.goBackBrowser();
    } else {
      this.modalService.close("open-go-back-modal");
    }
  }

  isSelectedAnswersNotChanged() {
    return JSON.stringify(this.selectedAnswers) == JSON.stringify(this.originalSelectedAnswers);
  }

  goBack() {
    if (this.isSelectedAnswersNotChanged()) {
      this.goBackBrowser();
    } else {
      this.openGoBackModal();
    }
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}