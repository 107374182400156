import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AdminComponent } from './admin.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { AdminHomeComponent } from './home/home.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { MonitoringUserListComponent } from './monitoring-user-list/monitoring-user-list.component';
import { MonitoringMatrixComponent } from './monitoring-matrix/monitoring-matrix.component';
import { SetupCompetencesComponent } from './setupCompetences/setupCompetences.component';
import { CreateUpdateCompetenceComponent } from './setupCompetences/createUpdateCompetence/createUpdateCompetence.component';
import { VerifySendScheduleComponent } from './verifySendSchedule/verifySendSchedule.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { ManageRolesComponent } from './setupCompetences/manageRoles/manageRoles.component';
import { ManageScaleComponent } from './setupCompetences/manageScale/manageScale.component';
import { ProcessDetailsComponent } from './processDetails/processDetails.component';
import { EditPhaseDateComponent } from './editPhaseDate/editPhaseDate.component';
import { CppComponent } from './cpp/cpp.component';
import { CreateUpdateCppComponent } from './createUpdateCPP/createUpdateCPP.component';
import { CppDetailsComponent } from './cpp-details/cpp-details.component';
import { CppAdminGuard } from '../shared/services/cpp-admin.guard';
import { ManageUpwardFeedbackComponent } from './manageUpwardFeedback/manageUpwardFeedback.component';
import { CreateUpdateUpwardFeedbackComponent } from './manageUpwardFeedback/createUpdateUpwardFeedbackRound/createUpdateUpwardFeedbackRound.component';
import { UpwardFeedbackUserListComponent } from './manageUpwardFeedback/upwardFeedbackUserList/upwardFeedbackUserList.component';
import { VerifyUpwardFeedbackComponent } from './manageUpwardFeedback/verifyUpwardFeedback/verifyUpwardFeedback.component';
import { AdminUpwardFeedbackGuard } from '../shared/services/admin-upward-feedback-guard.service';
import { Feedback360RoundAdminComponent } from './feedback360-round-admin/feedback360-round-admin.component';
import { Feedback360RoundUpdateComponent } from './feedback360-round-update/feedback360-round-update.component';
import { Feedback360RoundCreateComponent } from './feedback360-round-create/feedback360-round-create.component';
// import { Feedback360RoundCreateUpdateComponent } from './feedback360-round-create-update/feedback360-round-create-update.component';

const adminRoutes: Routes = [
  {
    path: '', component: AdminComponent, children: [
      { path: 'home', component: AdminHomeComponent, canActivate: [AdminGuard], data: { alsoAllowCppAdmin: true } },
      { path: 'processSetup', component: ProcessSetupComponent, canActivate: [AdminGuard] },
      { path: 'cpp', component: CppComponent, canActivate: [CppAdminGuard] },
      { path: 'cpp/:roundId', component: CppDetailsComponent, canActivate: [CppAdminGuard] },
      { path: 'createUpdateCpp', component: CreateUpdateCppComponent, canActivate: [CppAdminGuard] },
      { path: 'createUpdateCppWithActiveRound', component: CreateUpdateCppComponent, canActivate: [CppAdminGuard] },
      { path: 'createUpdateCpp/:roundId', component: CreateUpdateCppComponent, canActivate: [CppAdminGuard] },
      { path: 'processDetails/:processId/:processYear/:processYearCode/anagraphics', component: ProcessDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processDetails/:processId/:processYear/:processYearCode/phases', component: ProcessDetailsComponent, canActivate: [AdminGuard] },

      { path: 'createUpdateProcess', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess/:processId/:processYear/:processYearCode', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },

      { path: 'editDates/assessment/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'editDates/self-assessment/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'editDates/update-goal/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess/:processId', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },

      { path: 'editDates/self/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'editDates/people/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'editDates/goal/:processId/:processYear/:processYearCode', component: EditPhaseDateComponent, canActivate: [AdminGuard] },

      // { path: 'peopleAnagraphics', component: PeopleAnagComponent, canActivate: [AdminGuard] },

      { path: 'structureAnag', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'structureAnag/:processYear', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'impersonate', component: ImpersonateComponent, canActivate: [AdminGuard] },
      // Dashboard
      { path: 'dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: 'dashboard/:processYear/:processYearCode', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      // Monitoraggio / valutazione scheda utente
      { path: 'monitoring', component: MonitoringComponent, canActivate: [AdminGuard] },
      { path: 'monitoringUserList/:processYear/:processYearCode', component: MonitoringUserListComponent, canActivate: [AdminGuard] },
      { path: 'monitoringMatrix/:processYear/:processYearCode', component: MonitoringMatrixComponent, canActivate: [AdminGuard] },
      { path: 'verifySendSchedule/:processYear/:processYearCode/:userId', component: VerifySendScheduleComponent, canActivate: [AdminGuard] },
      // Pagina gestione crud competenze
      { path: 'setupCompetences', component: SetupCompetencesComponent, canActivate: [AdminGuard] },
      { path: 'setupCompetences/:processYear/:processYearCode/all', component: SetupCompetencesComponent, canActivate: [AdminGuard] },
      { path: 'setupCompetences/:processYear/:processYearCode/rolesAndCompetences', component: SetupCompetencesComponent, canActivate: [AdminGuard] },
      { path: 'setupCompetences/:processYear/:processYearCode/dictionaries', component: SetupCompetencesComponent, canActivate: [AdminGuard] },
      // Gestione Competenze Soft
      { path: 'createUpdateCompetence/softSkills/:processYear/:processYearCode', component: CreateUpdateCompetenceComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateCompetence/softSkills/:processYear/:processYearCode/:competenceId', component: CreateUpdateCompetenceComponent, canActivate: [AdminGuard] },
      // Gestione Competenze Terch
      { path: 'createUpdateCompetence/techSkills/:processYear/:processYearCode', component: CreateUpdateCompetenceComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateCompetence/techSkills/:processYear/:processYearCode/:competenceId', component: CreateUpdateCompetenceComponent, canActivate: [AdminGuard] },

      // Gestione ruoli competenze e comportamenti
      { path: 'manageRoles/softSkills/:processYear/:processYearCode', component: ManageRolesComponent, canActivate: [AdminGuard] },
      { path: 'manageRoles/softSkills/:processYear/:processYearCode/:roleId', component: ManageRolesComponent, canActivate: [AdminGuard] },
      { path: 'manageRoles/techSkills/:processYear/:processYearCode', component: ManageRolesComponent, canActivate: [AdminGuard] },
      { path: 'manageRoles/techSkills/:processYear/:processYearCode/:roleId', component: ManageRolesComponent, canActivate: [AdminGuard] },
      // Gestione dizionari 
      { path: 'manageScale/:processYear/:processYearCode', component: ManageScaleComponent, canActivate: [AdminGuard] },
      { path: 'manageScale/:processYear/:processYearCode/:dictionaryId', component: ManageScaleComponent, canActivate: [AdminGuard] },
      // UpwardFeedback
      { path: 'manageUpwardFeedback', component: ManageUpwardFeedbackComponent, canActivate: [AdminUpwardFeedbackGuard] },
      { path: 'manageUpwardFeedback/createUpdate', component: CreateUpdateUpwardFeedbackComponent, canActivate: [AdminUpwardFeedbackGuard] },
      { path: 'manageUpwardFeedback/createUpdate/:roundId', component: CreateUpdateUpwardFeedbackComponent, canActivate: [AdminUpwardFeedbackGuard] },
      { path: 'manageUpwardFeedback/:roundId', component: UpwardFeedbackUserListComponent, canActivate: [AdminUpwardFeedbackGuard] },
      { path: 'manageUpwardFeedback/verify/:roundId/:userId', component: VerifyUpwardFeedbackComponent, canActivate: [AdminUpwardFeedbackGuard] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AdminGuard] },

      { path: 'feedback360Round', component: Feedback360RoundAdminComponent, canActivate: [AdminGuard] },
      { path: 'feedback360Round/edit/:id', component: Feedback360RoundUpdateComponent, canActivate: [AdminGuard] },
      { path: 'feedback360Round/create', component: Feedback360RoundCreateComponent, canActivate: [AdminGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
      
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
