<div class="page-content-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [hasTabs]="false" [adminPageTitle]="'cppAdmin.MANAGE_CPP' | translate"
        [adminPageSubtitle]="'cppAdmin.ROUND_LIST' | translate" [adminBack]="true"
        [adminActionLabel]="'cppAdmin.CREATE_NEW_ROUND_CPP' | translate" (onAdminAction)="goToCreateCppRound()"
        adminActionLabelType="success" [adminActionDisabled]="isLoadingCppRoundsData">
    </header-dossier>

    <!-- Home lista cpp round -->
    <div class="main-content-wrapper admin">
        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingCppRoundsData">
            <div class="admin-table-container">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th translate="{{ ('cppAdmin.CPP_ROUND' | translate)}}"></th>
                                <th translate="{{ ('cppAdmin.OPEN_DATE_CPP' | translate)}}"></th>
                                <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                                <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let process of cppRoundData.list | 
                                slice:0:cppRoundData.numRecords | 
                                paginate: { id: 'roundList',
                                            itemsPerPage: cppRoundData.numRecords,
                                            currentPage: cppRoundData.page,
                                            totalItems: cppRoundData.counter }">
                                <td>{{ process.name }}</td>
                                <td>{{ process.startDate | date: 'dd/MM/YYYY' }}</td>
                                <td>{{ process.totalParticipants || 0 }}</td>
                                <td>
                                    <item-list-team-status [type]="process.statusType"
                                        [message]="process.statusMessage"></item-list-team-status>
                                </td>
                                <td class="action">
                                    <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(process)"
                                        (clickOutside)="closeMenu(process)"
                                        src="/assets/img/icons/more-horizontal.svg">
                                    </svg-icon>
                                    <ng-container *ngIf="process.isMenuOpen && process.roundStatus != 'CLOSED'">
                                        <div class="dropdown-menu larger">
                                            <ng-container *ngFor="let option of menuOptions">
                                                <alloy-dropdown-item (click)="onMenuOptionsClicked(option, process)"
                                                    class="full-width" [item]="option">
                                                </alloy-dropdown-item>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="process.isMenuOpen && process.roundStatus == 'CLOSED'">
                                        <div class="dropdown-menu larger">
                                            <ng-container *ngFor="let option of menuOptionsWithStatusClosed">
                                                <alloy-dropdown-item (click)="onMenuOptionsClicked(option, process)"
                                                    class="full-width" [item]="option">
                                                </alloy-dropdown-item>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="roundList" (pageChanged)="roundPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </alloy-select>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- loader -->
        <ng-container *ngIf="isLoadingCppRoundsData">
            <div class="loader-container">
                <div class="loader spinner-big"></div>
            </div>
        </ng-container>
    </div>
</div>

<modal id="cant-create-cpp-round" (onClose)="closeCantCreateRound()" [confirmLabel]="'generic.CLOSE' | translate"
    (onConfirm)="closeCantCreateRound()"
    (onCancel)="closeCantCreateRound()">
    <modal-text-content [title]="'cppAdmin.create.CANT_CREATE_ROUND' | translate"
        [text]="'cppAdmin.create.CANT_CREATE_ROUND_DESC' | translate"></modal-text-content>
</modal>