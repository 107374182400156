<div class="accordion-container"
  [ngClass]="{'disabled': isDisabled, 'challenge': isChallengeAccordion, 'gray-background': grayBackground, 'upward-feedback': isAdminUpwardFeedback}">
  <div class="accordion-header">
    <div class="accordion" [ngClass]="{'restore-margin': !isAccordionOpened, 'black': black}">
      <div class="title-accordion">
        <p class="title-info" [ngClass]="{'smaller-title': smallerTitle}">
          {{ title }}
          <!-- status a xs -->
          <ng-container *ngIf="isMobile">
            <div class="status-wrapper" fxHide fxShow.xs>
              <item-list-team-status class="status-position" *ngIf="!isDisabled && statusData" styleItem="vuoto"
                [message]="statusData.statusMessage" [type]="statusData.status">
              </item-list-team-status>
              <item-list-team-status class="status-position" *ngIf="isDisabled" styleItem="vuoto"
                message="itemListTeamStatus.disabled" type="disabled">
              </item-list-team-status>
            </div>
          </ng-container>
        </p>
        <ng-container *ngIf="(badgeNumber > 0 || infoIcon) && !noInfo">
          <svg-icon *ngIf="infoIcon" src="./assets/img/icons/info.svg" class="info-wrapper medium-size"
            (click)="openModalMessage()" [applyClass]="true" (click)="openModalMessage()"></svg-icon>
          <badge-notifications *ngIf="badgeNumber > 0" [badgeNumber]="badgeNumber" type="active">
          </badge-notifications>
        </ng-container>
      </div>
      <!-- Status fino a xs -->
      <ng-container *ngIf="!isMobile">
        <item-list-team-status *ngIf="!isDisabled && statusData" styleItem="border" [message]="statusData.statusMessage"
          [type]="statusData.status">
        </item-list-team-status>
        <item-list-team-status *ngIf="isDisabled" styleItem="border" message="itemListTeamStatus.disabled"
          type="disabled">
        </item-list-team-status>
      </ng-container>
      <!-- nuovo -->
      <div *ngIf="isNew" class="new">
        <p translate="generic.NEWS"></p>
      </div>
      <!-- Icona accordion -->
      <svg-icon *ngIf="!withOutRightIcon" (click)="openAccordion()" src="./assets/img/icons/chevron-down.svg"
        class="medium-accordion-size-transition blue-primary"
        [ngClass]="{'gray-gray2': isDisabled, 'opened': isAccordionOpened, 'black': black}" [applyClass]="true">
      </svg-icon>
    </div>
    <ng-container *ngIf="isChallengeAccordion && isAccordionOpened">
      <div class="border-divider"></div>
    </ng-container>
  </div>
  <div [@showIf] *ngIf="isAccordionOpened" [ngClass]="{'is-objective-table': isObjectiveTable}" class="full-width">
    <ng-content></ng-content>
  </div>
</div>