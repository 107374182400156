import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from '../../shared/services/shared.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { Filter } from 'src/app/shared/components/anag-filters/anag-filters.component';
import { Production } from '../personDetails/personDetails.component';
import { CompetenceGraph } from 'src/app/admin/admin-dashboard/admin-dashboard.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  loggedUser: any;
  // dashboard
  dashboardType: string = 'MY_TEAM';
  dashboardTypeStructure: boolean = false;

  // selfAssessment
  selfAssessmentPercentage: number = 0;
  selfAssessmentOpen: boolean = false;
  selfAssessmentProgress: any;
  getSelfAssessmentData$: Subscription = new Subscription();
  isLoadingSelfAssessmentData: boolean = false;
  selfAssessmentNoData: boolean = false;

  // People appraisal
  peopleAppraisalPercentage: number = 0;
  peopleAppraisalOpen: boolean = false;
  peopleAppraisalData: any;
  getPeopleAppraisalData$: Subscription = new Subscription();
  isLoadingPeopleAppraisalData: boolean = false;
  peopleAppraisalNoData: boolean = false;

  // Calibration
  calibrationPercentage: number = 0;
  calibrationOpen: boolean = false;
  calibrationData: any;
  getCalibrationData$: Subscription = new Subscription();
  isLoadingCalibrationData: boolean = false;
  calibrationNoData: boolean = false;

  // Pas
  pasPercentage: number = 0;
  pasOpen: boolean = false;
  pasData: any;
  getPasData$: Subscription = new Subscription();
  isLoadingPasData: boolean = false;

  // Competences
  isLoadingCompetencesData: boolean = false;
  getompetencesData$: Subscription = new Subscription;
  competencesData: Array<CompetenceGraph> = [];
  managerialOverallGraph: Array<Production> = [];
  genericOverallGraph: Array<Production> = [];

  // Progress
  isLoadingProgressData: boolean = false;
  getProgressData$: Subscription = new Subscription();
  progressData: {
    overallSent: number,
    totalEligible: number,
    percentage: number,
    list: Array<{ value: string, class: string, title: string }>
  } = { overallSent: 0, totalEligible: 0, percentage: 0, list: [] };

  // Comportamenti e competenze
  behaviorCompetenceOpen: boolean = false;
  isLoadingBehaviorCompetenceData: boolean = false;
  behaviorCompetenceData: any;
  getBehaviorCompetenceData$: Subscription = new Subscription();

  //  Overview
  isLoadingOverviewData: boolean = false;
  getOverviewData$: Subscription = new Subscription();
  isDownloadingReport: boolean = false;

  careColors: string[] = ["#C6EBB3", "#58BC5D", "#378C0C"];

  actionColor: string[] = ["#E4002B", "#F5B336", "#25BB48"];
  midTermColor: string[] = ["#E4002B", "#25BB48", "#DBDBDB"];

  /** Overview finale */
  /** Overview finale */
  finalOverviewData: Array<{
    status: string,
    structure: string,
    value: number,
    tooltip: number
    tooltipAllUser: number
    cumulativePercent?: number,
    allUsers?: number,
    totalInCompany?: number
    avgAllUsers?: number
  }> = [];
  finalPalette: string[] = ['#006EC7', '#003282']
  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];
  competenceGraphData: Array<{ title: string, first: number, second: number, third: number }> = [{ title: '', first: 0, second: 0, third: 0 }];
  processYear!: number;

  downloadReport$: Subscription = new Subscription();
  processYearCode: any;
  selectedFiltersNumber: any;
  translations: any;
  selectedFilters: any;
  isLoadingFilters: boolean = false;


  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;
  showOverviewGraph: boolean = true;

  // Feedback
  feedbackData: Array<{ value: string, class: string, title: string }> = [];
  isLoadingFeedback: boolean = false;
  feedbackData$: Subscription = new Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService,
    private modalService: ModalService,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,
    public redirectService: RedirectService) {
    this.store.select(fromApp.getLoggedUser)
      .subscribe((loggedUser: any) => {
        this.loggedUser = loggedUser && loggedUser.user;
        this.route.params
          .subscribe((params: Params) => {
            if (params.processYear) {
              this.processYear = params.processYear;
            }
            if (params.processYearCode) {
              this.processYearCode = params.processYearCode;
            }
            if (this.processYear && this.processYearCode) {
              this.selectedYear = {
                id: this.processYear,
                code: this.processYearCode,
                name: this.processYearCode + ' ' + this.processYear
              }
            } else {
              this.selectedYear = {
                id: 2021,
                code: 'H1',
                name: 'H1 ' + 2021
              }
            }
            // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
            //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Dashboard - Admin");
            this.getPerformanceAssessmentYears();
            this.getGraphData();
          });
      });
  }

  ngOnInit(): void {
    this.translate.get([
      'dashboard.feedbackTypes.SENT_SMART_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK',
      'dashboard.feedbackTypes.SENT_PEER_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK',
      'dashboard.feedbackTypes.EVIDENCES',
      'dashboard.feedbackTypes.INTERVIEWS',
      'dashboard.FILTERED',
      'dashboard.TO_START',
      'dashboard.STARTED',
      'dashboard.SENT_HR',
      'dashboard.accordion.SELF_ASSESSMENT',
      'dashboard.accordion.EVALUATION',
      'dashboard.POST',
      'dashboard.pas.ELIGIBLE',
      'dashboard.pas.EVALUATION',
      'dashboard.pas.SELF_ASSESSMENT',
      'dashboard.pas.OVERALL',
      'dashboard.pas.SMART_ENVOYS',
      'dashboard.pas.PEER_FEEDBACK_SENT_OR_REQUESTED',
      'dashboard.pas.FEEDBACK360_SENT_OR_REQUESTED',
      'dashboard.pas.PROPOSED_FEEDBACK360',
      'dashboard.ENTIRE_COMPANY',
      'dashboard.PAS_EVALUATED',
      'recap.DELEGATION',
      'recap.EXTERNAL_RELATIONS',
      'recap.GUIDING_PEOPLE_THROUGH_CHANGE',
      'recap.ORIENTATION_AND_RESULTS_MANAGEMENT',
      'recap.PEOPLE_DEVELOPMENT'
    ]).subscribe((translations: any) => {
      this.translations = translations;

    })
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.peopleAppraisalService.getPerformanceAssessmentYears(this.loggedUser.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          for (let i = (data.response.length - 1); i >= 0; i--) {
            this.yearList.push({
              id: data.response[i].assessmentYear,
              code: data.response[i].assessmentYearCode,
              name: data.response[i].assessmentYearCode + ' ' + data.response[i].assessmentYear,
              disabled: !data.response[i].isInProcess
            });
          }
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.router.navigate([url, this.selectedYear.id, this.selectedYear.code])
  }

  openDashboardTooltip() {
    this.modalService.open('dashboard-tooltip');
  }

  closeDashboardTooltip() {
    this.modalService.close('dashboard-tooltip');
  }

  // Personalizza le label delle tabelle
  customizePercentageText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeSeries(valueFromNameField: string) {
    return valueFromNameField === 'Azienda intera'
      ? { type: 'spline', label: { visible: true }, color: '#378C0C' } : {};
  }


  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  redrawGraphs(graphSection: any) {
    let graphToRedraw: string[] = [];
    switch (graphSection) {
      case 'self-assessment': {
        graphToRedraw = ["selfAssessmentGauge", "selfAssessmentChart"];
        break;
      }
      case 'people-appraisal': {
        graphToRedraw = ["peopleAppraisalGauge", "peopleAppraisalChart"];
        break;
      }
      case 'calibration': {
        graphToRedraw = ["calibrationGauge", "calibrationChart"];
        break;
      }
      default:
        graphToRedraw = Object.keys(this.chartInstances);
        break;
    }

    if (graphToRedraw && graphToRedraw.length) {
      setTimeout(() => {
        //let keys = Object.keys(this.chartInstances);
        for (let i = 0; i < graphToRedraw.length; i++) {
          if (this.chartInstances[graphToRedraw[i]]) {
            this.chartInstances[graphToRedraw[i]].render();
          }
        }
      }, 100);
    }
  }


  // inizializza i dati dei grafici
  getGraphData(event?: any) {
    this.selfAssessmentNoData = false;
    this.peopleAppraisalNoData = false;
    this.calibrationNoData = false;
    if (this.selectedFiltersNumber > 0) {
      sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
      sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    }
    // Recupera overview
    this.getOverviewData();
    // Recupera Competenze
    this.getCompetencesData();
    // Recupera i progressi
    this.getProgressData();
    // Recupera i feedback
    this.getFeedbackData();
  }

  getSelfAssessmentData() {
    this.isLoadingSelfAssessmentData = true;
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }

    this.getSelfAssessmentData$ = this.peopleAppraisalService.getDashboardSelfAssessment(this.processYear, this.processYearCode, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        } else {
          this.selfAssessmentProgress = {};
          this.selfAssessmentPercentage = Math.round(data.response.progress * 100) || 0;
          this.selfAssessmentProgress.completedPercentage = this.selfAssessmentPercentage;
          if (data.response.noUsersFound) {
            this.selfAssessmentNoData = true;
          }
          this.selfAssessmentProgress.total = data.response.total;
          this.selfAssessmentProgress.sent = data.response.totalSent;
          this.selfAssessmentProgress.chartData = [
            {
              attribute: this.translations['dashboard.SENT_HR'],
              number: data.response.totalSent,
              color: '#C6EBB3',
            },
            {
              attribute: this.translations['dashboard.STARTED'],
              number: data.response.totalStarted,
              color: '#58BC5D',
            },
            {
              attribute: this.translations['dashboard.TO_START'],
              number: data.response.totalToStart,
              color: '#378C0C',
            }
          ]

          this.isLoadingSelfAssessmentData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        });
  }

  getCompetencesData() {
    // Avvvio il loader
    this.isLoadingCompetencesData = true;

    if (this.getompetencesData$) {
      this.getompetencesData$.unsubscribe()
    }

    this.getompetencesData$ = this.peopleAppraisalService.getDashboardCompetencesForManager(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<{ competences: Array<CompetenceGraph> }>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetencesData = false;
        } else {
          this.competencesData = [];
          this.competencesData = data.response.competences;

          this.managerialOverallGraph = [];
          this.genericOverallGraph = [];
          this.competenceGraphData = [];

          //Ordino i dati in modo da avere prima le competenze manageriali e poi le competenze generali
          let managerial = this.competencesData.filter(x => x.isManagerial);
          let generic = this.competencesData.filter(x => !x.isManagerial);
          const arrayCopytidy = managerial.concat(generic);


          for (let i = 0; i < arrayCopytidy.length; i++) {
            const competence = arrayCopytidy[i];
            this.competenceGraphData.push({
              title: competence.macroCompetence,
              first: competence.averageSubordinateEvaluation,
              second: competence.averageManagerEvaluation,
              third: competence.averageFeedbackEvaluation
            })
          }

          let k = 0;
          let j = 0
          for (let i = 0; i < this.competencesData.length; i++) {
            const competence = this.competencesData[i];
            if (competence.isManagerial) {
              this.managerialOverallGraph.push({
                arg: String.fromCharCode(65 + j),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageSubordinateEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + j), desc: competence.macroCompetence }
              })
              j++
            } else {
              this.genericOverallGraph.push({
                arg: String.fromCharCode(65 + k),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageSubordinateEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + k), desc: competence.macroCompetence }
              })
              k++;
            }
          }

          this.isLoadingCompetencesData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetencesData = false;
        });
  }

  getFeedbackData() {
    //Avvio il loader
    this.isLoadingFeedback = true;

    if (this.feedbackData$) {
      this.feedbackData$.unsubscribe;
    }

    this.feedbackData$ = this.peopleAppraisalService.getFeedback(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.feedbackData = [
            {
              title: this.translations['dashboard.pas.SMART_ENVOYS'],
              class: "percentage slim",
              value: data.response.smartFeedbackItemsCount
            },
            {
              title: this.translations['dashboard.pas.PEER_FEEDBACK_SENT_OR_REQUESTED'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.peerFeedbackSent / data.response.peerFeedbackRequested) * 100)) + ' primary',
              value: data.response.peerFeedbackRequested ? data.response.peerFeedbackSent + '/' + data.response.peerFeedbackRequested : '--'
            },
            {
              title: this.translations['dashboard.pas.FEEDBACK360_SENT_OR_REQUESTED'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.feedback360Sent / data.response.feedback360Requested) * 100)) + ' primary',
              value: data.response.feedback360Requested ? data.response.feedback360Sent + '/' + data.response.feedback360Requested : '--'
            },
            {
              title: this.translations['dashboard.pas.PROPOSED_FEEDBACK360'],
              class: "percentage slim",
              value: data.response.feedback360Suggestions

            }]
          this.isLoadingFeedback = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });

  }

  getProgressData() {
    // Avvvio il loader
    this.isLoadingProgressData = true;

    if (this.getProgressData$) {
      this.getProgressData$.unsubscribe()
    }

    this.getProgressData$ = this.peopleAppraisalService.getDashboardProgress(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.progressData = {
            totalEligible: data.response.totalEligible,
            overallSent: data.response.overallSent,
            percentage: data.response.progress,
            list: [
              {
                title: this.translations['dashboard.pas.ELIGIBLE'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.totalEligible / data.response.totalUsers) * 100)) + ' secondary',
                value: data.response.totalEligible
              },
              {
                title: this.translations['dashboard.pas.EVALUATION'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.appraisalSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.appraisalSent
              },
              {
                title: this.translations['dashboard.pas.SELF_ASSESSMENT'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.selfAssessmentSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.selfAssessmentSent
              },
              {
                title: this.translations['dashboard.pas.OVERALL'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.overallSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.overallSent
              }
            ]
          }
          this.isLoadingProgressData = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingProgressData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }


  getBehaviorCompetenceData() {
    this.isLoadingBehaviorCompetenceData = true;
    if (this.getBehaviorCompetenceData$) {
      this.getBehaviorCompetenceData$.unsubscribe()
    }

    this.getBehaviorCompetenceData$ = this.peopleAppraisalService.getDashboardBehaviorCompetence(this.processYear, this.processYearCode, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingBehaviorCompetenceData = false;
        } else {
          this.behaviorCompetenceData = {};
          this.behaviorCompetenceData.behaviors = data.response.behaviours;
          this.behaviorCompetenceData.competences = data.response.competences;

          this.isLoadingBehaviorCompetenceData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingBehaviorCompetenceData = false;
        });
  }

  getOverviewData() {
    // Avvvio il loader
    this.isLoadingOverviewData = true;

    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }

    this.getOverviewData$ = this.peopleAppraisalService.getDashboardOverview(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        } else {
          this.finalOverviewData = [];
          this.showOverviewGraph = !data.response.hideChart;
          let evaluations = [
            "UNSATISFACTONARY_PERFORMER",
            "MARGINAL_PERFORMER",
            "STANDARD_PERFORMER",
            "STRONG_PERFORMER",
            "TOP_PERFORMER",
          ]

          let filteredTotal = data.response.totalFiltered;
          let compiledFiltered = data.response.totalFilteredEvaluated;
          for (let i = 0; i < evaluations.length; i++) {
            let response = data.response[evaluations[i]];
            if (response) {
              this.finalOverviewData.push({
                status: (evaluations[i].charAt(0).toUpperCase() + evaluations[i].slice(1).toLowerCase()).split('_')[0],
                structure: this.translations['dashboard.PAS_EVALUATED'] + data.response.totalFilteredEvaluated + '/' + filteredTotal,
                value: Math.round((response.filtered / filteredTotal) * 100),
                tooltip: response.filtered,
                tooltipAllUser: compiledFiltered,
                cumulativePercent: data.response.hideChart ? undefined : Math.round((response.allUsers / data.response.totalInCompany) * 100),
                allUsers: response.allUsers,
                totalInCompany: data.response.totalInCompany,
                avgAllUsers: Math.round((response.allUsers / data.response.totalInCompany) * 100),
              })
            }
          }
          this.isLoadingOverviewData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.peopleAppraisalService.downloadDashboardReport(this.processYear, this.processYearCode, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }


  customizeTooltip = (info: any) => (
    {
      html: `<div><div class='tooltip-header'>${this.translate.instant('dashboard.NUM_PAS')}</div>`
        + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
        + `<span class=\'top-series-name\'>${info.points[0].point.data.value + '% - ' + info.points[0].point.data.tooltip + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[0].point.data.tooltipAllUser + ' ' + this.translate.instant('dashboard.TOTAL')}</span><br>`
        + `${info.points[0].point.data.cumulativePercent > 0 ? `<span class=\'top-series-name\'>${info.points[1].point.data.avgAllUsers + '% - ' + info.points[1].point.data.allUsers + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[1].point.data.totalInCompany + ' ' + this.translate.instant('dashboard.TOTAL_FILTERED')}</span>` : ``}`
        + `</div></div></div>`
    }
  );

  // customizeTooltip(item: any) {
  //   console.log(item)
  // }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }


  switchDashboardType() {
    if (this.dashboardType == 'MY_TEAM') {
      this.dashboardType = 'MY_STRUCTURE';
      this.dashboardTypeStructure = true;
    } else {
      this.dashboardType = 'MY_TEAM';
      this.dashboardTypeStructure = false;
    }
    this.getGraphData();
  }


  isLoading() {
    return this.isLoadingSelfAssessmentData || this.isLoadingPeopleAppraisalData || this.isLoadingCalibrationData || this.isLoadingPasData || this.isLoadingBehaviorCompetenceData || this.isLoadingOverviewData;
  }
  openFinalOverviewModal() {
    this.modalService.open('finalOverview')
  }
  closeFinalOverviewModal() {
    this.modalService.close('finalOverview')
  }

  openAdvancementProcessModal() {
    this.modalService.open('advancementProcess')
  }

  closeAdvancementProcessModal() {
    this.modalService.close('advancementProcess')
  }

  openSpiderModal() {
    this.modalService.open('spiderGraph')
  }
  closeSpiderModal() {
    this.modalService.close('spiderGraph')
  }

  openCompetenceModal() {
    this.modalService.open('competence')
  }
  closeCompetenceModal() {
    this.modalService.close('competence')
  }

  openFeedbackModal() {
    this.modalService.open('feedback')
  }
  closeFeedbackModal() {
    this.modalService.close('feedback')
  }

  ngOnDestroy(): void {
    // if (this.combinedSelected$) {
    //   this.combinedSelected$.unsubscribe();
    // }
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }
    if (this.getPeopleAppraisalData$) {
      this.getPeopleAppraisalData$.unsubscribe()
    }
    if (this.getCalibrationData$) {
      this.getCalibrationData$.unsubscribe()
    }
    if (this.getPasData$) {
      this.getPasData$.unsubscribe()
    }
    if (this.getBehaviorCompetenceData$) {
      this.getBehaviorCompetenceData$.unsubscribe()
    }
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }
  }

}
