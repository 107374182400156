<div class="page-content-wrapper">

  <header-dossier [user]="loggedUser" [disabled]="true" [isAdminHeader]="true" [adminBack]="true"
    [adminPageTitle]="'dashboard.TITLE' | translate" [hasTabs]="false"
    [adminPageSubtitle]="processYear ? ('monitoring.PERFORMANCE_MANAGEMENT' | translate) + ' ' +processYear : ('monitoring.PERFORMANCE' | translate)"
    [adminActionLabel]="processYear ? ('dashboard.DOWNLOAD_REPORT' | translate) : ''"
    adminActionLabelType="primary fill" [showLoader]="isDownloadingReport" [adminActionDisabled]="isDownloadingReport"
    [isAdminMenu]="processYear? true: false" adminActionIcon="assets/img/icons/chevron-down-white.svg"
    (onAdminAction)="downloadReport($event)">
  </header-dossier>
  <!-- (onAdminAction)="downloadReport()" -->

  <!-- Home processi -->
  <ng-container *ngIf="!processYear">
    <div class="main-content-wrapper admin">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.SEMESTER' | translate)}}" style="width:20%"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}" style="width:50%"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}" style="width:20%"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                              slice:0:processData.numRecords | 
                              paginate: { id: 'processList',
                                          itemsPerPage: processData.numRecords,
                                          currentPage: processData.page,
                                          totalItems: processData.counter }">
                  <td>{{ process.processYearCode + ' ' + process.processYear }}</td>
                  <td>{{ process.name }}</td>
                  <td>
                    {{ process.totalParticipants || 0 }}
                  </td>
                  <td>
                    <item-list-team-status [type]="process.statusType" [message]="process.statusMessage"
                      styleItem="fill">
                    </item-list-team-status>
                  </td>
                  <td class="detail" (click)="goToProcessDetails(process)">
                    {{ 'adminTable.ACCESS' | translate }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <ng-container *ngIf="processYear">
    <div class="main-content-wrapper">

      <!-- Filtri -->
      <div class="filters-recap">
        <!-- Contenuto filtri -->
        <div class="filter-content">
          <div class="title-container">
            <div class="info-title">
              <p class="title">
                {{ ('dashboard.FILTER') | translate }}
              </p>
              <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                (click)="openDashboardTooltip()">
              </svg-icon>
            </div>
          </div>


          <!-- Filtri -->
          <anag-filters [searchEnabled]="false" (updateSelectedFilters)="updateSelectedFilters($event)"
            [keepFilters]="true" (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)"
            (startSearch)="getGraphData($event)" [showSelfAssessmentStatus]="false" [showAppraisalStatus]="false"
            [showCalibrationStatus]="false">
          </anag-filters>

        </div>
      </div>

      <!-- <dxo-common-series-settings argumentField="status" type="bar" valueField="value" [barPadding]="0.5">
      </dxo-common-series-settings>
      <dxo-series-template nameField="structure" [customizeSeries]="customizeSeries">
      </dxo-series-template> -->

      <div class="accordion-list">
        <!-- Overview finale -->
        <div class="item-container">
          <ng-container *ngIf="!isLoadingOverviewData && finalOverviewData">
            <div class="title-container">
              <p translate="dashboard.accordion.FINAL_OVERVIEW"></p>
              <svg-icon src="../../../assets/img/icons/info.svg" (click)="openFinalOverviewModal()"></svg-icon>
            </div>
            <dx-chart id="finalChart" [dataSource]="finalOverviewData">
              <dxo-common-series-settings argumentField="status" [barPadding]="0.5">
              </dxo-common-series-settings>
              <dxi-series type="bar" valueField="value" [name]="finalOverviewData[0]?.structure" color="#006EC7">
              </dxi-series>
              <dxi-series *ngIf="finalOverviewData[0]?.cumulativePercent" type="spline" valueField="cumulativePercent"
                [name]="'dashboard.ENTIRE_COMPANY' | translate" color="#003282"></dxi-series>

              <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
              </dxo-legend>
              <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20"
                [valueMarginsEnabled]="false">
                <dxo-label [customizeText]="customizePercentageText"></dxo-label>
              </dxi-value-axis>
              <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
            </dx-chart>
          </ng-container>

          <!-- Loader -->
          <ng-container *ngIf="isLoadingOverviewData || !finalOverviewData">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </div>


        <!-- Avanzamento processo -->
        <percentage-accordion [title]="'dashboard.accordion.ADVANCEMENT_PROCESS' | translate" [isAccordionOpened]="true"
          [isLoading]="isLoadingPeopleAppraisalData" (onModalMessageOpen)="openAdvancementProcessModal()"
          (onAccordionOpen)="redrawGraphs('people-appraisal')" [isLoading]="isLoadingProgressData"
          [cardsCount]="{all:progressData.totalEligible, shared: progressData.overallSent}"
          [percentage]="progressData.percentage">
          <ng-container *ngIf="!isLoadingProgressData && progressData.list?.length">
            <div class="circularProgress-container">
              <div class="cluster-wrapper" *ngFor="let gauge of progressData.list; let i = index">
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                  <div class="circularProgress" fxLayoutAlign="center center" [ngClass]="gauge.class">
                    <div tabindex="0" class="circularProgress-overlay">{{gauge.value || 0}}</div>
                  </div>
                  <div fxLayout="column" class="full-width" fxLayoutAlign="center start">
                    <p tabindex="0" class="cluster-indicator-label full-width">
                      {{gauge.title}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Loader -->
          <ng-container *ngIf="isLoadingProgressData">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </percentage-accordion>

        <!-- Grafici spider -->
        <percentage-accordion [title]="'recap.COMPETENCE' | translate" [hidePercentage]="true"
          [isAccordionOpened]="true" [isLoading]="isLoadingPeopleAppraisalData" (onModalMessageOpen)="openSpiderModal()"
          (onAccordionOpen)="redrawGraphs('people-appraisal')">
          <div class="graphs-main" *ngIf="!isLoadingCompetencesData && managerialOverallGraph.length">
            <!-- Manageriali -->
            <div class="graphs-container">
              <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="managerialOverallGraph">
                <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
                </dxo-legend>
                <dxo-title text="Competenze manageriali">
                  <dxo-font color="#232323" size="16px" weight="400">
                  </dxo-font>
                </dxo-title>
                <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
                <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
                <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
                <dxo-common-series-settings type="line"></dxo-common-series-settings>
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
              </dx-polar-chart>
            </div>
            <!-- Legenda -->
            <div class="graphs-container gray">
              <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
              <ng-container *ngFor="let competence of managerialOverallGraph">
                <div class="competence-container">
                  <p class="indicator">{{competence.legend.title}}</p>
                  <p class="explanation">{{competence.legend.desc}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="graphs-main" *ngIf="!isLoadingCompetencesData && genericOverallGraph.length">
            <!-- Generali -->
            <div class="graphs-container">
              <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="genericOverallGraph">
                <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
                </dxo-legend>
                <dxo-title text="Competenze generali">
                  <dxo-font color="#232323" size="16px" weight="400">
                  </dxo-font>
                </dxo-title>
                <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
                <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
                <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
                <dxo-common-series-settings type="line"></dxo-common-series-settings>
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
              </dx-polar-chart>
            </div>
            <!-- Legenda -->
            <div class="graphs-container gray">
              <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
              <ng-container *ngFor="let competence of genericOverallGraph">
                <div class="competence-container">
                  <p class="indicator">{{competence.legend.title}}</p>
                  <p class="explanation">{{competence.legend.desc}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- Loader -->
          <ng-container *ngIf="isLoadingCompetencesData">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </percentage-accordion>


        <!-- Competenze-->
        <percentage-accordion [title]="'dashboard.accordion.GENERAL_COMPETENCE' | translate" [hidePercentage]="true"
          [isAccordionOpened]="true" (onModalMessageOpen)="openCompetenceModal()"
          (onAccordionOpen)="redrawGraphs('people-appraisal')">
          <bar-chart *ngIf="!isLoadingCompetencesData" [valueList]="competenceGraphData"></bar-chart>
          <!-- Loader -->
          <ng-container *ngIf="isLoadingCompetencesData">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </percentage-accordion>

        <!-- Feedback -->
        <percentage-accordion [title]="'dashboard.accordion.FEEDBACK' | translate" [isAccordionOpened]="true"
          [isLoading]="isLoadingPeopleAppraisalData" [hidePercentage]="true" (onModalMessageOpen)="openFeedbackModal()"
          (onAccordionOpen)="redrawGraphs('people-appraisal')">
          <ng-container *ngIf="!isLoadingFeedback && feedbackData">
            <div class="circularProgress-container">
              <div class="cluster-wrapper" *ngFor="let gauge of feedbackData; let i = index">
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                  <div class="circularProgress" fxLayoutAlign="center center" [ngClass]="gauge.class">
                    <div tabindex="0" class="circularProgress-overlay">{{gauge.value || 0}}</div>
                  </div>
                  <div fxLayout="column" class="full-width" fxLayoutAlign="center start">
                    <p tabindex="0" class="cluster-indicator-label full-width">
                      {{gauge.title}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Loader -->
          <ng-container *ngIf="isLoadingFeedback">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </percentage-accordion>
      </div>
    </div>
  </ng-container>
</div>


<!-- Filtri -->
<modal id="dashboard-tooltip" (onClose)="closeDashboardTooltip()">
  <modal-text-content modalId="dash002" [title]="'dashboard.modals.DASH_TOOL_TITLE' | translate"
    [text]="'dashboard.modals.DASH_TOOL_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale OverView finale -->
<modal id="finalOverview" (onClose)="closeFinalOverviewModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.FINAL_OVERVIEW_TITLE' | translate"
    [text]="'dashboard.modals.FINAL_OVERVIEW_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale avanzamento processo -->
<modal id="advancementProcess" (onClose)="closeAdvancementProcessModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.ADVANCEMENT_PROCESS_TITLE' | translate"
    [text]="'dashboard.modals.ADVANCEMENT_PROCESS_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale grafico spider -->
<modal id="spiderGraph" (onClose)="closeSpiderModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.COMPETENCE_TITLE' | translate"
    [text]="'dashboard.modals.COMPETENCE_TEXT' | translate"></modal-text-content>
</modal>

<!-- Modale competenze -->
<modal id="competence" (onClose)="closeCompetenceModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.COMPETENCE_TITLE' | translate"
    [text]="'dashboard.modals.COMPETENCE_TEXT_2' | translate"></modal-text-content>
</modal>

<!-- Modale feedback -->
<modal id="feedback" (onClose)="closeFeedbackModal()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.FEEDBACK_TITLE' | translate"
    [text]="'dashboard.modals.FEEDBACK_TEXT' | translate"></modal-text-content>
</modal>