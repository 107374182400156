import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-admin-structure-anag',
  templateUrl: './structure-anag.component.html',
  styleUrls: ['./structure-anag.component.scss']
})
export class StructureAnagComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  isLoadingStructureData: boolean = false;
  getStructureData$: Subscription = new Subscription();
  structureData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]
  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: string = '';
  isLoadingStructureDetails: boolean = false;
  getStructureDetails$: Subscription = new Subscription();
  structureDetail: any[] = [];
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;
  searchedText: string = '';

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<any> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params.subscribe((params: any) => {
              if (params.processYear) {
                this.processYear = params.processYear;
              }

              if (this.processYear) {
                this.getStructureDetails();
              } else {
                this.getStructureData();
              }
            })
          }
        });
  }

  ngOnInit() {
  }


  getStructureData() {
    this.isLoadingStructureData = true;
    if (this.getStructureData$) {
      this.getStructureData$.unsubscribe();
    }
    this.getStructureData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.structureData.counter = counter.response;

              // Calcolo la paginazione
              this.structureData.fromRecord = (this.structureData.page - 1) * this.structureData.numRecords;

              if (this.structureData.counter) {
                return this.adminService.listPerformanceProcesses(this.structureData.fromRecord, this.structureData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingStructureData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.structureData.list = data.response || [];
          }
          this.isLoadingStructureData = false;
        }
        , (err: any) => {
          this.isLoadingStructureData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  structurePageChanged(page: number) {
    this.structureData.page = page;
    this.structureData.list = [];
    this.getStructureData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.structureData.numRecords = item.id;
    this.structureData.list = [];
    this.structureData.fromRecord = 0;
    this.getStructureData();
  }

  getStructureDetails() {
    this.isLoadingStructureDetails = true;
    if (this.getStructureDetails$) {
      this.getStructureDetails$.unsubscribe();
    }
    this.structureDetail = []
    this.getStructureDetails$ = this.adminService.processStructureDetail(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingStructureDetails = false;
        } else {
          let tmpList: any[] = [];
          if (data.response && data.response.length) {
            this.translate.get([
              'phases.FEEDBACK',
              'monitoring.modals.COUNTER_FEEDBACK_1',
              'monitoring.modals.COUNTER_FEEDBACK_2',
              'monitoring.modals.PEER_REC',
              'monitoring.modals.PEER_REQ',
              'monitoring.modals.CLAP_SENT',
              'monitoring.modals.INTERVIEW',
              'monitoring.modals.EVIDENCES',
            ]).subscribe((translations: any) => {
              for (let i = 0; i < data.response.length; i++) {
                tmpList.push({
                  id: data.response[i].structure?.structureId,
                  name: data.response[i].structure?.title,
                  totalFeedback: data.response[i].feedbackTotalCount,
                  developmentPlanStatus: this.getStatus(data.response[i].developmentPlanStatus),
                  finalEvaluationStatus: this.getStatus(data.response[i].finalEvaluationStatus),
                  goalSettingStatus: this.getStatus(data.response[i].goalSettingStatus),
                  midTermReviewStatus: this.getStatus(data.response[i].midTermReviewStatus),
                  modalData: {
                    modalId: 'a006',
                    title: translations['phases.FEEDBACK'] + ' ' + data.response[i].structure?.title,
                    subtitle: translations['monitoring.modals.COUNTER_FEEDBACK_1'] + data.response[i].feedbackTotalCount + translations['monitoring.modals.COUNTER_FEEDBACK_2'],
                    description: this.getModalDescription(data.response[i].feedback, translations)
                  }
                })
              }
              this.structureDetail = tmpList;
              this.isLoadingStructureDetails = false;
            })
          } else {
            this.structureDetail = [];
            this.isLoadingStructureDetails = false;
          }
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingStructureDetails = false;
      })
  }

  getStatus(phase: any) {
    switch (phase.status) {
      case 'TO_START': {
        return {
          status: 'toStart',
          statusMessage: this.translate.instant('itemListTeamStatus.toStart')
        }
      }
      case 'STARTED': {
        return {
          status: 'inProgress',
          statusMessage: this.translate.instant('itemListTeamStatus.inProgress') + ' ' + (phase.percentage.toFixed(1)) + '%'
        }
      }
      case 'COMPLETED': {
        return {
          status: 'completed',
          statusMessage: this.translate.instant('itemListTeamStatus.completed')
        }
      }
      default:
        return {
          status: 'disabled',
          statusMessage: this.translate.instant('itemListTeamStatus.disabled')
        }
    }

  }


  getModalDescription(feedback: any, translations: any) {
    let text = '';
    if (feedback) {
      text += translations['monitoring.modals.PEER_REC'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.PEER_REQ'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseFeedbacks || '-') + '</b><br>';
      text += translations['monitoring.modals.INTERVIEW'] + ' <b>' + (feedback.feedbackDialogueRentCount || '-') + '</b><br>';
      text += translations['monitoring.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceFeedbacks || '-') + '</b><br>';
    }
    return text;
  }

  goToStructureDetail(processYear: string) {
    this.router.navigate(['admin/structureAnag/' + processYear])
  }

  orderBy(fieldName: string) {
  }

  insertList() {
  }

  downloadList() {
  }

  onSearch() {
  }

  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  ngOnDestroy() {
    if (this.getStructureData$) {
      this.getStructureData$.unsubscribe();
    }
    if (this.getStructureDetails$) {
      this.getStructureDetails$.unsubscribe();
    }
  }
}
