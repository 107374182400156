import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pce-rating-item',
  templateUrl: './pce-rating-item.component.html',
  styleUrls: ['./pce-rating-item.component.scss']
})
export class PceRatingItemComponent {
  /**
   * Livello della stella selezionata
   */
  @Input() level!: number;
  /**
   * Stato attivo: evidenzia i componenti del valutatore
   */
  @Input() active?: boolean;
  /**
   * Permette solo di visualizzare i dati del valutatore
   */
  @Input() readOnly?: boolean;
  /**
   * Possibili dimensioni che il componente può avere, di default è settato a medio(md)
   */
  @Input() size: "lg" | "md" | "sm" = "md";

  /**
   * Azione di click che ritorna il livello selezionato
   */
  @Output() onGetLevel = new EventEmitter<number>();


  /**
   * Emette il livello selezionato
   * 
   * @param level 
   */
  protected getLevel(level: number) {
    this.onGetLevel.emit(level)
  }
}
