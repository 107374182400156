import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as moment from 'moment';
import { PerformanceAssessmentAlloyStatuses, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";


@Component({
  selector: 'app-admin-create-update-process',
  templateUrl: './createUpdateProcess.component.html',
  styleUrls: ['./createUpdateProcess.component.scss']
})
export class CreateUpdateProcessComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  processId: string = '';
  isLoadingProcess: boolean = true;

  processName: string = '';
  processNameCopy: string = '';

  semesterOptions: Array<any> = [];
  selectedSemester: any;
  selectedYearCopy: any;

  processStatus!: string;

  statusOptions: Array<{ id: string | number, title: string | number, disabled: boolean }> = [];
  selectedStatus: any;
  selectedStatusCopy: any;

  notes: string = '';
  notesCopy: string = '';
  isUpdate: boolean = false;
  getProcessData$: Subscription = new Subscription();

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.processId = params.processId;
              if (!this.processId) {
                this.isLoadingProcess = false;
              } else {
                this.getProcessData();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'process.status.ACTIVE',
        'process.status.STANDBY',
        'process.status.CLOSED'
      ])
      .subscribe(translations => {
        this.processStatus = translations['process.status.STANDBY'];

        this.semesterOptions = [];
        this.getDataSemester();
        this.statusOptions = [
          {
            id: PerformanceAssessmentAlloyStatuses.RUNNING,
            title: translations['process.status.ACTIVE'],
            disabled: false
          },
          {
            id: PerformanceAssessmentAlloyStatuses.STANDBY,
            title: translations['process.status.STANDBY'],
            disabled: false,
          },
          {
            id: PerformanceAssessmentAlloyStatuses.HISTORY,
            title: translations['process.status.CLOSED'],
            disabled: false
          }
        ];
        if (!this.processId) {
          this.selectedStatus = this.statusOptions[1];
          this.isLoadingProcess = false;
        } else {
          this.isUpdate = true;
          this.getProcessData();
        }
      })

  }

  nameChanged(text: string) {
    this.processName = text;
  }

  changeSemester(value: any) {
    this.selectedSemester = value;
  }

  changeStatus(statusItem: any) {
    this.selectedStatus = statusItem;
  }

  getProcessData() {
    this.isLoadingProcess = true;

    if (this.getProcessData$) {
      this.getProcessData$.unsubscribe();
    }
    this.getProcessData$ = this.adminService.getProcess(this.processId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          this.processName = data.response.processTitle;
          let year = data.response.processYear;
          let code = data.response.processYearCode;
          for (let i = 0; i < this.semesterOptions.length; i++) {
            if (this.semesterOptions[i].title == year && this.semesterOptions[i].code == code) {
              this.selectedSemester = this.semesterOptions[i];
              break;
            }
          }

          this.selectedStatus = this.statusOptions.find((status: any) => status.id == data.response.processStatus);
          this.selectedStatusCopy = { ...this.selectedStatus };

          for (let i = 0; i < this.statusOptions.length; i++) {
            if (this.selectedStatus.id == PerformanceAssessmentAlloyStatuses.HISTORY) {
              this.statusOptions[i].disabled = true;
            } else if (this.selectedStatus.id == PerformanceAssessmentAlloyStatuses.RUNNING
              && this.statusOptions[i].id == PerformanceAssessmentAlloyStatuses.RUNNING || this.statusOptions[i].id == PerformanceAssessmentAlloyStatuses.STANDBY) {
              this.statusOptions[i].disabled = true;
            } else if (this.selectedStatus.id == PerformanceAssessmentAlloyStatuses.STANDBY
              && this.statusOptions[i].id == PerformanceAssessmentAlloyStatuses.STANDBY) {
              this.statusOptions[i].disabled = true;
            }
          }
        }
        this.isLoadingProcess = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProcess = false;
      });
  }

  createUpdate() {
    if (this.processId) {
      this.modalService.open('update-process');
    } else {
      this.modalService.open('create-process');
    }
  }

  closeCreateProcess(confirm?: boolean) {
    this.modalService.close('create-process');
    if (confirm) {
      this.isLoadingProcess = true;
      this.adminService.createProcess(this.selectedSemester.title, this.selectedSemester.code, this.processName)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.redirectService.goBackBrowser();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
      this.isLoadingProcess = false;
    }
  }

  createProcess() {
    if (this.processName == undefined ||
      this.processStatus == undefined ||
      this.selectedSemester == undefined) {
      return;
    }
  }

  closeUpdateModal() {
    this.modalService.close('processUpdateModal');
  }

  closeUpdateProcess(confirm?: boolean) {
    this.modalService.close('create-process');
    if (confirm) {
      this.isLoadingProcess = true;
      this.adminService.updateProcess(this.selectedSemester.title, this.selectedSemester.code, this.selectedStatus.id)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.redirectService.goBackBrowser();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  // onCancel() {

  // closeCreateProcess(confirm?: boolean) {
  //   this.modalService.close('create-process');
  //   if (confirm) {
  //     this.isLoadingProcess = true;
  //     this.adminService.createProcess(this.selectedYear.year, this.selectedYear.code, this.processName, '', this.selectedStatus.id)
  //       .subscribe((data: SenecaResponse<any>) => {
  //         if (data && data.error) {
  //           // Vedo se c'è la traduzione dell'errore
  //           const messageObj: ApplicationModalMessage = {
  //             modalId: "a003",
  //             text: this.translate.instant("errors." + data.error),
  //             title: this.translate.instant("generic.WARNING")
  //           }
  //           this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         } else {
  //           // toaster 
  //           let x: any = document.getElementById("snackbar");
  //           if (x) {
  //             x.className = "show";
  //             setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
  //           }
  //         }
  //         this.redirectService.goBackBrowser();
  //       }, (err) => {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "a004",
  //           text: this.translate.instant("errors." + ((err && err.message) || err)),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       })
  //   }
  // }

  // closeUpdateProcess(confirm?: boolean) {
  //   this.modalService.close('create-process');
  //   if (confirm) {
  //     this.isLoadingProcess = true;
  //     this.adminService.updateProcess(this.selectedYear.year, this.selectedYear.code, this.processId, this.processName, this.notes, this.selectedStatus.id)
  //       .subscribe((data: SenecaResponse<any>) => {
  //         if (data && data.error) {
  //           // Vedo se c'è la traduzione dell'errore
  //           const messageObj: ApplicationModalMessage = {
  //             modalId: "a003",
  //             text: this.translate.instant("errors." + data.error),
  //             title: this.translate.instant("generic.WARNING")
  //           }
  //           this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         } else {
  //           // toaster 
  //           let x: any = document.getElementById("snackbar");
  //           if (x) {
  //             x.className = "show";
  //             setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
  //           }
  //         }
  //         this.redirectService.goBackBrowser();
  //       }, (err) => {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "a004",
  //           text: this.translate.instant("errors." + ((err && err.message) || err)),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       })
  //   }
  // }

  // onCancel() {
  //   this.redirectService.goBackBrowser();
  // }


  // ngOnDestroy() {
  //   if (this.runningYear$) {
  //     this.runningYear$.unsubscribe();
  //   }


  private getDataSemester() {
    this.semesterOptions = [];
    let item = new Date().getFullYear();
    let month = moment().format("M");
    for (let i = 0; i < 15; i++) {
      if (i == 0) {
        if (month <= '6') {
          this.semesterOptions.push({
            id: 'H1-' + (item).toString(),
            title: (item).toString(),
            code: 'H1'
          })
        };
        // Commentato perchè kruk non ha bisogno dell' H2
        // this.semesterOptions.push({
        //   id: 'H2-' + (item).toString(),
        //   title: 'H2 ' + (item).toString(),
        //   code: 'H2'
        // });
      } else {
        this.semesterOptions.push({
          id: 'H2-' + (item + i).toString(),
          title: (item + i).toString(),
          code: 'H1'
        })
        // Commentato perchè kruk non ha bisogno dell' H2
        // this.semesterOptions.push({
        //   id: 'H1-' + (item + i).toString(),
        //   title: 'H2 ' + (item + i).toString(),
        //   code: 'H2'
        // });
      }
    }
  }
}