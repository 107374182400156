import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { SenecaResponse, User, AlloyPerformanceUpwardFeedbackServiceResponses } from 'atfcore-commonclasses';
import { combineLatest, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import * as fromApp from "../../ngrx/app.reducers";
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";

@Component({
  selector: 'app-upward-feedback',
  templateUrl: './upward-feedback.component.html',
  styleUrls: ['./upward-feedback.component.scss']
})
export class UpwardFeedbackComponent implements OnInit, OnDestroy {
  isImpersonate: boolean = false;
  isLoadingUpwardFeedback: boolean = false;

  lastModuleView: boolean = false;

  runningYear$: Subscription = new Subscription();
  runningYear: any;
  loggedUser!: User;
  combinedSelected$: any;

  currentModule?: AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackSurveyForUser;

  isLoadingSurvey: boolean = false;
  roundId: string = '';
  managerId: string = '';
  currentModuleOriginal: any;
  getSurveyModule$: Subscription = new Subscription();
  ratingData: Array<{ id: string, title: string, value: number }> = [];
  showAllAccordion: boolean = false;
  managerData: any;
  isComplete: boolean = false;


  constructor(
    public redirectService: RedirectService,
    public selfAssessmentService: SelfAssessmentService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {

    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const runningYear$ = this.store.select(fromApp.getRunningYear);
    this.combinedSelected$ = combineLatest([loggedUser$, runningYear$])
      .subscribe(([loggedUser, runningYear]) => {
        this.loggedUser = loggedUser && loggedUser.user;
        this.runningYear = runningYear;
        if (this.runningYear && this.runningYear.year) {
          this.route.params
            .subscribe(
              (params: Params) => {
                this.roundId = params.roundId;
                this.managerId = params.managerId;
                //Chiamata per recuperare le domande
                this.getSurveyModule();
              }
            );
        }
      })
  }

  ngOnInit(): void {
  }

  getSurveyModule(moduleId?: string) {
    this.isLoadingSurvey = true;

    if (this.getSurveyModule$) {
      this.getSurveyModule$.unsubscribe();
    }

    this.getSurveyModule$ = this.selfAssessmentService.getUpwardFeedbackSurvey(this.managerId, this.roundId, moduleId)
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackSurveyForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.currentModule = data.response;
          this.managerData = data.response.userToEvaluate;
          this.currentModuleOriginal = JSON.parse(JSON.stringify(this.currentModule));
          this.ratingData = []
          if (this.currentModule.index + 1 == this.currentModule.totalModules) {
            this.lastModuleView = true;
            if (this.currentModule.aggregatedData && this.currentModule.aggregatedData.modulesAverage && this.currentModule.aggregatedData.modulesAverage.modules) {
              for (let i = 0; i < this.currentModule.aggregatedData.modulesAverage.modules.length; i++) {
                this.ratingData.push({
                  id: i + '',
                  title: this.currentModule.aggregatedData.modulesAverage.modules[i].moduleTitle,
                  value: this.currentModule.aggregatedData.modulesAverage.modules[i].moduleAverage
                })
              }

            }
          } else {
            this.lastModuleView = false;
          }
          if (this.currentModule.totalPercentage == 100) {
            this.isComplete = true;
          }
        }
        this.isLoadingSurvey = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingSurvey = false;
      });
  }

  // Porta nella home se non ci sono state modifiche
  goBack() {
    this.redirectService.goBackBrowser();
  }

  // apre la Modale per la conferma
  openConfirmSurveyModal() {
    this.modalService.open('confirm-survey')
  }

  // chiude la modale di conferma
  closeConfirmSurveyModal(confirm?: boolean) {
    this.modalService.close('confirm-survey')
    if (confirm) {
      this.saveCurrentModule(true)
    }
  }

  // apre la Modale salva la modifica
  openSaveEditModal() {
    this.modalService.open('save-edit')
  }

  // chiude la Modale salva la modifica
  closeSaveEditModal(confirm?: boolean) {
    this.modalService.close('save-edit')

    if (confirm) {
      this.getSurveyModule(this.currentModule?.prevModuleId);
    } else {
      this.modalService.close("open-prev-module-modal");
    }
  }

  // apre la Modale della guida alla compilazione
  openGuideInfoModal() {
    this.modalService.open('guide-info')
  }

  // chiude la Modale della guida alla compilazione
  closeGuideInfoModal(confirm?: boolean) {
    this.modalService.close('guide-info')

    if (confirm) {
      let filePath = '';
      filePath = '/assets/docs/Guida_Upward_Feedback.pdf';
      let link = document.createElement('a');
      link.href = filePath;
      link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
      link.click();
    }

  }

  // Salva il contenuto del radio select
  onRadioSelect(event: any, question: any) {
    question.closedAnswer.selectedAnswerIndex = event.id;
  }

  openAnswerUpdate(newAnswer: string, question: any) {
    question.openAnswer.text = newAnswer;
  }

  goPrecModule() {
    if (this.isComplete || this.isSelectedAnswersNotChanged()) {
      this.getSurveyModule(this.currentModule?.prevModuleId);
    } else {
      this.openSaveEditModal();
    }
  }

  isSelectedAnswersNotChanged() {
    return JSON.stringify(this.currentModule) == JSON.stringify(this.currentModuleOriginal);
  }


  onSaveCurrentModule() {
    if (this.isComplete && this.currentModule?.nextModuleId) {
      this.getSurveyModule(this.currentModule?.nextModuleId)
    } else {
      this.saveCurrentModule();
    }
  }

  saveCurrentModule(lastStep?: boolean) {
    if (this.currentModule) {
      this.isLoadingSurvey = true;
      this.selfAssessmentService.saveUpwardFeedbackModuleSurvey(this.managerId, this.roundId, this.currentModule.id, this.currentModule.questions)
        .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackSurveyForUser>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "upf005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!lastStep) {
              this.currentModule = data.response;
              this.currentModuleOriginal = JSON.parse(JSON.stringify(this.currentModule));
              this.ratingData = []
              if (this.currentModule.index + 1 == this.currentModule.totalModules) {
                this.lastModuleView = true;
                if (this.currentModule.aggregatedData && this.currentModule.aggregatedData.modulesAverage && this.currentModule.aggregatedData.modulesAverage.modules) {
                  for (let i = 0; i < this.currentModule.aggregatedData.modulesAverage.modules.length; i++) {
                    this.ratingData.push({
                      id: i + '',
                      title: this.currentModule.aggregatedData.modulesAverage.modules[i].moduleTitle,
                      value: this.currentModule.aggregatedData.modulesAverage.modules[i].moduleAverage
                    })
                  }

                }
              } else {
                this.lastModuleView = false;
              }
              if (this.currentModule.totalPercentage == 100) {
                this.isComplete = true;
              }
            } else if (lastStep && data.response && this.currentModule) {
              this.currentModule.totalPercentage = 100;
              this.isComplete = true;
              this.redirectService.goBackBrowser();
            }
          }
          this.isLoadingSurvey = false;
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf006",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSurvey = false;
        });

    } else {
      this.lastModuleView = true;
    }

  }

  goBackPreviousModule() {
    if (this.currentModule && this.currentModule.index != 0) {

    }
    else {
      this.lastModuleView = false;
    }
  }


  getFormattedAnswers(answers: string[]) {
    let tmp: any[] = [];
    for (let i = 0; i < answers.length; i++) {
      tmp.push({
        id: i,
        value: answers[i]
      })
    }
    return tmp;
  }

  toggleAccordion() {
    this.showAllAccordion = !this.showAllAccordion;
  }


  isModuleComplete() {
    if (this.currentModule && this.currentModule.questions) {
      if (!this.lastModuleView) {
        for (let i = 0; i < this.currentModule.questions.length; i++) {
          let question = this.currentModule.questions[i];
          if (question && question.isClosedAnswer && question.closedAnswer && (question.closedAnswer.selectedAnswerIndex == null || question.closedAnswer.selectedAnswerIndex == undefined)) {
            return false;
          }
        }
      } else {
        for (let i = 0; i < this.currentModule.questions.length; i++) {
          let question = this.currentModule.questions[i];
          if (question && !question.isClosedAnswer && question.text != 'Note e commenti' && (!question.openAnswer || !question.openAnswer.text || !question.openAnswer.text.length || question?.openAnswer?.text?.length < 10)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  ngOnDestroy() {
    if (this.getSurveyModule$) {
      this.getSurveyModule$.unsubscribe();
    }
  }
}
