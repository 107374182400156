import { Component } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../ngrx/app.reducers";
import { KrukOpenAPI, SenecaResponse } from 'atfcore-commonclasses';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-feedback360-round-update',
  templateUrl: './feedback360-round-update.component.html',
  styleUrls: ['./feedback360-round-update.component.scss']
})
export class Feedback360RoundUpdateComponent {
  roundId: string = '';
  yesterday = new Date();
  today: Date = new Date();

  endDate: Date | undefined = undefined;
  startDate: Date | undefined = undefined;

  feedbackStatusList: Array<{ id: string | number, title: string | number, disabled: boolean }> = [];
  selectedFeedbackStatus: { id: string | number, title: string | number } | undefined = undefined;

  isLoadingRoundForFeedback360: boolean = false;
  getRoundForFeedback360$: Subscription = new Subscription();
  // Nessun campo è obbligatorio
  roundFeedback360Data: KrukOpenAPI.PerfAlloyFeedback360Round = {};

  isLoadingUpdateRound: boolean = false;
  updateRound$: Subscription = new Subscription();

  constructor(public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public adminService: AdminService,
    public translate: TranslateService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);

    this.route.params.subscribe((params: Params) => {
      this.roundId = params.id;
      this.getRoundData();
    })

  }


  getRoundData() {
    this.isLoadingRoundForFeedback360 = true;

    if (this.getRoundForFeedback360$) {
      this.getRoundForFeedback360$.unsubscribe;
    }

    this.getRoundForFeedback360$ = this.adminService.listFeedback360Rounds('', [this.roundId])
      .subscribe((data: SenecaResponse<Array<KrukOpenAPI.PerfAlloyFeedback360Round>>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "epd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
          this.isLoadingRoundForFeedback360 = false;
        } else {

          this.feedbackStatusList = [
            { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.RUNNING, title: this.translate.instant('adminTable.status.RUNNING'), disabled: false },
            { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY, title: this.translate.instant('adminTable.status.STANDBY'), disabled: false },
            { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.CLOSED, title: this.translate.instant('adminTable.status.CLOSED'), disabled: false },
          ]

          // Assegno il primo valore valido.
          this.roundFeedback360Data = data.response[0];

          // Cerco lo stato tradotto e se non trovo nulla il Assegno il primo valore selezionato 
          const feedbackStatus = this.feedbackStatusList.find(ele => ele.id == this.roundFeedback360Data.roundStatus);
          this.selectedFeedbackStatus = feedbackStatus ? feedbackStatus : this.feedbackStatusList.shift();

          // if (this.roundFeedback360Data.roundStatus == KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.RUNNING) {
          //   this.feedbackStatusList[i].disabled = 
          // }
          for (let i = 0; i < this.feedbackStatusList.length; i++) {
            if (this.roundFeedback360Data.roundStatus == KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY
              && this.selectedFeedbackStatus?.id !== KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY) {
              this.feedbackStatusList[i].disabled = true;

            } else if (this.roundFeedback360Data.roundStatus == KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.RUNNING
              && this.selectedFeedbackStatus?.id !== KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY
              && this.selectedFeedbackStatus?.id !== KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.RUNNING) {
              this.feedbackStatusList[i].disabled = true;
            } else if (this.roundFeedback360Data.roundStatus == KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.CLOSED) {
              this.feedbackStatusList[i].disabled = true;
            }
          }

          // Fermo il loader
          this.isLoadingRoundForFeedback360 = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "epd002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoundForFeedback360 = false;
      });
  }

  changeRoundName(roundName: string) {
    this.roundFeedback360Data.name = roundName;
  }

  changeSelectedStatus(selectedItem: { id: string | number, title: string | number }) {
    this.selectedFeedbackStatus = selectedItem;
  }
  startDateChanged(newDate: Date) {
    this.roundFeedback360Data.startDate = newDate;
  }

  endDateChanged(newDate: Date) {
    this.roundFeedback360Data.endDate = newDate;
  }


  closeConfirm(confirm?: boolean) {
    this.modalService.close('confirm-edit');
    if (confirm) {
      this.updateFeedback360Round()
    }
  }

  onConfirm() {
    if (!this.roundFeedback360Data.startDate || !this.roundFeedback360Data.endDate) {
      const messageObj: ApplicationModalMessage = {
        modalId: "mrd001",
        title: this.translate.instant("generic.WARNING"),
        text: this.translate.instant("process.phases.MISSING_REQ_DATA"),
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
    } else {
      this.modalService.open('confirm-edit');
    }
  }

  updateFeedback360Round() {
    this.isLoadingUpdateRound = true;

    if (this.updateRound$) {
      this.updateRound$.unsubscribe;
    }
    // Controllo se esistono le date
    const startDate = this.roundFeedback360Data.startDate ? this.roundFeedback360Data.startDate : new Date;
    const endDate = this.roundFeedback360Data.endDate ? this.roundFeedback360Data.endDate : new Date;

    this.updateRound$ = this.adminService.updateFeedback360Round(this.roundId, this.selectedFeedbackStatus?.id as KrukOpenAPI.PerfAlloyFeedback360RoundStatus, startDate, endDate)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "epd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
          this.isLoadingUpdateRound = false;
        } else {
          this.router.navigate(['/admin/feedback360Round/']);
          // Fermo il loader
          this.isLoadingUpdateRound = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "epd002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateRound = false;
      });
  }
}
