import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { SupportComponent } from '../core/support/support.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { SendClapSelfAssessmentComponent } from '../selfAssessment/sendClap/sendClap.component';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { PrivateNoteGuard } from '../shared/services/private-note-guard.service';
import { SelfAssessmentGuard } from '../shared/services/selfAssessment-guard.service';
import { CreateUpdatePrivateNoteComponent } from './createUpdatePrivateNote/createUpdatePrivateNote.component';
import { SelfAssessmentPersonDetailsComponent } from './personDetails/personDetails.component';
import { SelfAssessmentComponent } from './selfAssessment.component';
import { CreateUpdatePeerFeedbackComponent } from './createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { FeedbackToManagerComponent } from './feedback-to-manager/feedback-to-manager.component';
import { TechnicalAssessmentComponent } from './technicalAssessment/technicalAssessment.component';
import { UpwardFeedbackComponent } from './upwardFeedback/upward-feedback.component';
import { PersonalDevelopmentGuard } from '../shared/services/personal-development-guard.service';
import { HomepageComponent } from '../homepage/homepage.component';
import { Receive360FeedbackComponent } from './receive360-feedback/receive360-feedback.component';
import { Request360FeedbackHomeComponent } from './request360-home-feedback/request360-home-feedback.component';
import { Request360FeedbackComponent } from './request360-feedback/request360-feedback.component';


const selfAssessmentRoutes: Routes = [
  {
    path: '', component: SelfAssessmentComponent, children: [
      // { path: 'home', component: HomepageComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'profile', component: ProfilePageComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },

      { path: 'personDetails/:userId/:perfYear/:perfYearCode', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard, PersonalDevelopmentGuard], canDeactivate: [] },
      { path: 'personDetails/myNotes/:userId/:perfYear/:perfYearCode', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard, PrivateNoteGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/:userId/:perfYear/:perfYearCode', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/:userId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'createUpdatePrivateNote', component: CreateUpdatePrivateNoteComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard, PrivateNoteGuard] },
      { path: 'createUpdatePrivateNote/:noteId', component: CreateUpdatePrivateNoteComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard, PrivateNoteGuard] },

      { path: 'notifications', component: NotificationsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'cppSurvey/:roundId', component: TechnicalAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },

      { path: 'upwardFeedback/:roundId/:managerId', component: UpwardFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'personDetails/feedback/smartFeedback/:userId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/smartFeedback/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/evidences/:userId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/evidences/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/privateNotes/:us erId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/privateNotes/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/interview/:userId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/interview/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/peerFeedback/:userId', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/peerFeedback/:userId/:perfYear', component: SelfAssessmentPersonDetailsComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'createUpdateGoal/:userId', component: CreateUpdateGoalSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'createUpdateGoal/:userId/:goalId', component: CreateUpdateGoalSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalSelfAssessmentGuard, OnBoardingGuardService] },
      // { path: 'organizeInterview/:userId', component: SelfAssessmentOrganizeInterviewComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewSelfAssessmentGuard, OnBoardingGuardService] },
      // { path: 'organizeInterview/:userId/:interviewId', component: SelfAssessmentOrganizeInterviewComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewSelfAssessmentGuard, OnBoardingGuardService] },
      { path: 'sendApplause', component: SendClapSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },

      { path: 'createUpdatePeerFeedback', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback:userId:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback:userId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback:userId:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'sendApplause/:userId', component: SendClapSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapSelfAssessmentGuard, OnBoardingGuardService] },
      // { path: 'sendApplause/:userId/:clapId', component: SendClapSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapSelfAssessmentGuard, OnBoardingGuardService] },
      { path: 'createUpdatePeerFeedback/response/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard] },
      { path: 'createUpdatePeerFeedback/manager-response/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard] },
      { path: 'createUpdatePeerFeedback', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidenceOnEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidenceOnEvidence/:userId/:evidenceId', component: CreateUpdateEvidenceComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidence/:userId/:goalId', component: CreateUpdateEvidenceComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'addMidTermReview', component: AddMidTermReviewSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'finalEvaluation/:perfYear/:goalId', component: FinalEvaluationSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      // { path: 'definePerformanceLevel/:perfYear', component: DefinePerformanceLevelSelfAssessmentComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },   
      { path: 'createFeedbackToManager', component: FeedbackToManagerComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'requested360Feedback/request/:feedbackId', component: Request360FeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'requested360Feedback/detail/:feedbackId', component: Request360FeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'requested360Feedback/shared/:feedbackId', component: Request360FeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'requested360Feedback/:roundId', component: Request360FeedbackHomeComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'requested360Feedback', component: Request360FeedbackHomeComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },
      { path: 'receive360Feedback', component: Receive360FeedbackComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },

      { path: 'support', component: SupportComponent, canActivate: [SelfAssessmentGuard, OnBoardingGuard] },

      { path: "", redirectTo: "homepage", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(selfAssessmentRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class SelfAssessmentRoutingModule { }
