<div class="banner-home" [ngClass]="{'narrow': isNarrow, 'final-evaluation': phaseName == 'FINAL_EVALUATION' }">
  <!--Banner maschera immagine-->
  <div class="img-banner-mask" [ngStyle]="{'background-image': 'url(' + backgroundSrc + ')'}"></div>
  <!--Banner testo banner-->
  <div id="bigBannerTag" class="text-banner-mask orange-bg">

    <!--Decorazione Banner-->
    <svg fxHide.xs class="decoration-img" preserveAspectRatio="none" width="33px" height="100%" viewBox="0 0 33 400"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 274.643 12.1388 343.713 32.7478 400V0C12.1388 56.2869 0 125.357 0 200Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 125.357 12.1388 56.2869 32.7478 0V400C12.1388 343.713 0 274.643 0 200Z" />
    </svg>


    <!-- Contenuto "default" del banner -->
    <!-- <ng-container *ngIf="phaseClosed">
      <div class="text-banner">
        <div class="banner-header"> -->
          <!-- Data odierna -->
          <!-- <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="assets/img/icons/kruk-logo-white.svg" class="alloy-logo"></svg-icon>
            <div class="footer-container">
              <p class="footer-title" translate="banner.bannerNew.WELCOME_TO_YOUR_AREA"></p></div>
          </div>
        </div> -->
        <!-- Testo Banner -->
        <!-- <h2 class="title-page full-width">{{ ('banner.CLOSED' | translate)}}</h2>
      </div>
    </ng-container> -->


    <ng-container>
      <div class="text-banner">
        <div class="banner-header">
          <!-- Data e colloqui -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="assets/img/icons/kruk-logo-white.svg" class="alloy-logo"></svg-icon>
          </div>
        </div>
        <!-- Testo Banner -->
        <h2 class="title-page full-width" *ngIf="!isAdminBanner"
          [translate]="('banner.WELCOME') | translate"></h2>
        <div class="admin-container" *ngIf="isAdminBanner">
          <h2 class="title-page full-width" [translate]="'banner.ADMIN_TITLE' | translate">
          </h2>
        </div>
      </div>
    </ng-container>
  </div>
</div>