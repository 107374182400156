import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { PerfAlloy360Feedback, SenecaResponse, User } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { FeedbackUser, UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { KrukOpenAPI } from 'atfcore-commonclasses';


export class UserCardDisabled extends UserCard {
  disabled?: boolean = false;
}

@Component({
  selector: 'app-teamFeedback360-request',
  templateUrl: './teamFeedback360Request.component.html',
  styleUrls: ['./teamFeedback360Request.component.scss']
})
export class TeamFeedback360RequestComponent implements OnInit {
  targetUserId: string = '';
  roundId: string = '';

  isFetchingUserList: boolean = false;
  fromRecord: number = 0;
  numRecords: number = 10;
  currentPage: number = 0;
  counter: number = 0;

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, towards?: boolean }> = [];

  countEvaluation!: 1 | 2 | 3 | 4 | 5;

  userCardData: UserCardDisabled[] = [];
  selectedUserList: any[] = [];

  // Ricerca utente
  searchUserText: string = '';
  loggedUser: any;
  forceBack: boolean = false;

  chargeUsersVariable: boolean = true;
  isImpersonate: boolean = false;

  maxUserCount: number = 8;

  sendFeedbackProposal$: Subscription = new Subscription();
  getMyCollegueUsers$: Subscription = new Subscription();
  isGettingAddedRequest: boolean = true;
  getAlreadyAddedRequests$: Subscription = new Subscription();
  excludedUsers: any[] = [];
  getFeedbackSubordinates$: Subscription = new Subscription();
  personDetails: FeedbackUser | undefined = undefined;
  whoTooltipModal?: ApplicationModalMessage;
  alreadyAddedList: any[] = [];


  constructor(
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })


    this.route.params.subscribe((params: Params) => {
      this.targetUserId = params.targetUserId;
      this.roundId = params.roundId;

      this.getFeedbackSubordinates$ = this.store.select(fromApp.getFeedbackSubordinates).subscribe((feedbackSubordinates) => {
        this.personDetails = feedbackSubordinates.find((user) => user.userId == this.targetUserId);
      })

      this.store.select(fromApp.getLoggedUser)
        .subscribe((loggedUser: any) => {
          this.loggedUser = loggedUser && loggedUser.user;
          this.resetUserData();
          this.getAlreadyAddedRequests()
        });
    })
  }

  ngOnInit() {
    this.translate.get(['feedbackSection.360_HOW_TO_REQ', 'feedbackSection.360_SQUEEZE_MANGER_DESC']).subscribe(translations => {
      this.whoTooltipModal = {
        modalId: "c014",
        title: translations['feedbackSection.360_HOW_TO_REQ'],
        text: translations['feedbackSection.360_SQUEEZE_MANGER_DESC'] + (this.personDetails ? (this.personDetails.forename + ' ' + this.personDetails.surname) : '')
      }
    })
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetUserData(notDeleteSelected?: boolean) {
    this.fromRecord = 0;
    this.numRecords = 10;
    this.currentPage = 1;
    this.counter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  // Esegue una nuova ricerca
  searchUsers() {
    // Resetto i parametri di paginazione dopo la ricerca
    this.fromRecord = 0;
    this.currentPage = 1;

    this.getMyCollegueUsers();
  }

  // Salva il testo che si cerca
  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  getAlreadyAddedRequests() {
    this.isGettingAddedRequest = true;

    if (this.getAlreadyAddedRequests$) {
      this.getAlreadyAddedRequests$.unsubscribe();
    }

    this.getAlreadyAddedRequests$ = this.peopleAppraisalService.listFeedback360ForManager(this.targetUserId, this.roundId)
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe014",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.excludedUsers = [];

          let excludedUser: any[] = [];
          data.response.forEach((feedback: KrukOpenAPI.PerfAlloyFeedback) => {
            if (feedback && feedback.intermediateUser && feedback.intermediateUser.userId) {
              excludedUser.push(feedback.intermediateUser);
            }
          })
          this.excludedUsers = UserDataUtils.getUserCardData(excludedUser);
        }
        this.getMyCollegueUsers();
        this.isGettingAddedRequest = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe015",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isGettingAddedRequest = false;
      });
  }


  // Recupera una lista di utenti per gli inviare gli smart feedback
  getMyCollegueUsers() {
    this.isFetchingUserList = true;

    if (this.getMyCollegueUsers$) {
      this.getMyCollegueUsers$.unsubscribe();
    }

    this.getMyCollegueUsers$ = this.peopleAppraisalService.countMyCollegueUsersForManager(this.searchUserText, [this.loggedUser.userId, this.targetUserId])
      .subscribe((data: SenecaResponse<number>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingUserList = false;

        } else {
          this.counter = data.response;

          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.currentPage && this.numRecords) {
            fromRecord = (this.currentPage - 1) * this.numRecords;
          } else {
            fromRecord = 0;
          }
          if (this.counter) {
            this.peopleAppraisalService.listMyColleagueUsers(fromRecord, this.numRecords, this.searchUserText, [this.loggedUser.userId, this.targetUserId]).subscribe
              ((response: SenecaResponse<User[]>) => {
                if (response && response.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "360fe013",
                    text: this.translate.instant("errors." + response.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else {
                  this.userCardData = UserDataUtils.getUserCardData(response.response) || [];
                  // Se è tra gli utenti a cui ho già fatto la richiesta è disabilitato e checkato
                  this.userCardData.forEach((user: any) => {
                    const excluded = this.excludedUsers.some(usr => usr.id === user.id);
                    user.isChecked = excluded;
                    user.disabled = excluded;

                    const isPresent = this.alreadyAddedList.some((usr: any) => usr.id == user.id);
                    if (user.isChecked && !isPresent) this.alreadyAddedList.push(user);
                  })
                }
                this.isFetchingUserList = false
              }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "360fe016",
                  text: this.translate.instant("errors." + ((err && err.message) || err)),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isFetchingUserList = false
              })
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe011",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingUserList = false;
      });
  }

  openSendFeedbackModal() {
    if (this.personDetails) {
      this.feedbackRecap = [
        {
          title: this.translate.instant('360Feedback.modal.TO_WHOM'),
          avatar: true,
          towards: true
        },
        {
          title: this.translate.instant('360Feedback.modal.MOTIVATION_FOR_REQUEST'),
          text: this.translate.instant('360Feedback.modal.MANAGER_MOTIVATION_RESPONSE_1') + this.personDetails.forename + ' ' + this.personDetails.surname + this.translate.instant("360Feedback.modal.MANAGER_MOTIVATION_RESPONSE_2"),
        }];
      this.modalService.open('360ReceiveFeedback');
    }
  }

  closeSendFeedbackModal(confirm?: boolean) {
    this.modalService.close('360ReceiveFeedback');
    if (confirm) {
      this.sendFeedbackProposal();
    }
  }
  // openInfoPoint() {
  //   this.whoTooltipModal = {
  //     modalId: "c014",
  //     title: this.translate.instant('feedbackSection.360_HOW_TO_REQ'),
  //     text: this.translate.instant('feedbackSection.360_SQUEEZE_MANGER_DESC') + (this.personDetails ? (this.personDetails.forename + ' ' + this.personDetails.surname) : '')
  //   }
  // }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  checkObbligatoryData() {
    const disabledUser = this.selectedUserList.every(user => user.disabled);

    if (this.selectedUserList.length && !disabledUser) {
      return true;
    }
    return false;
  }

  selectUser(user: any) {
    const isPresent = this.selectedUserList.some((usr) => usr.id == user.id && !user.disabled);

    if (isPresent) {
      this.selectedUserList = this.selectedUserList.filter((usr) => usr.id != user.id && !user.disabled);
    } else {
      this.selectedUserList.push(user);
    }
  }

  // invia la richiesta di feedback agli utenti selezionati
  sendFeedbackProposal() {
    this.isFetchingUserList = true;

    if (this.sendFeedbackProposal$) {
      this.sendFeedbackProposal$.unsubscribe();
    }

    let userIds = this.selectedUserList.filter((user: any) => !user.disabled)?.map(user => user.id);

    this.sendFeedbackProposal$ = this.peopleAppraisalService.createFeedback360ForManager(userIds, this.targetUserId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.router.navigate(['/peopleAppraisal/teamFeedback360', this.targetUserId, this.roundId]);
        this.isFetchingUserList = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.router.navigate(['/peopleAppraisal/teamFeedback360', this.targetUserId, this.roundId]);
        this.isFetchingUserList = false;
      });
  }

  // Cambia la paginazione alla lista degli utenti per lo smart feedback
  proposedUserPageChanged(newPage: any) {
    this.currentPage = newPage;
    // Avvio una nuova ricerca
    this.getMyCollegueUsers();
  }


  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Selezione dell'obiettivo su cui è richiesto il feedback
  changeAssociatedObjective(objective: any) {
    // this.selectedObjective = objective;
  }
  changeEvaluation(count: 1 | 2 | 3 | 4 | 5) {
    this.countEvaluation = count;
  }

  getCountLimit() {
    return this.translate.instant('360Feedback.MANAGER_MAX_PROPOSED_1') + this.maxUserCount + this.translate.instant('360Feedback.MANAGER_MAX_PROPOSED_2');
  }

  checkUserSelectDisabled() {
    let count = 0;
    count += this.selectedUserList?.length || 0;
    // count += this.excludedUserIds?.length || 0;
    if (count > (this.maxUserCount - 1)) {
      return true;
    } else {
      return false;
    }
  }

  // blockUserCardAction(user: UserCardDisabled) {
  //   let count = 0;
  //   count += this.selectedUserList?.length || 0;
  //   count += this.excludedUserIds?.length || 0;
  //   return user.disabled || count > (this.maxUserCount - 1);
  // }

  isLoadingData() {
    return this.isFetchingUserList || this.isGettingAddedRequest;
  }

  ngOnDestroy() {
    if (this.sendFeedbackProposal$) {
      this.sendFeedbackProposal$.unsubscribe();
    }
    if (this.getAlreadyAddedRequests$) {
      this.getAlreadyAddedRequests$.unsubscribe();
    }
  }
}
