<div class="filters-wrapper">
  <div class="filter__input">
    <div class="filter__input-input">
      <pce-input
        design="rounded"
        placeholder="{{ 'generic.SEARCH_BY' | translate }}"
        [searchIcon]="true"
        [boxedIcon]="true"
        (onEnter)="onSearch.emit($event)"
        (onClick)="onSearch.emit($event)">
      </pce-input>
    </div>
  </div>
  <!-- Header -->
  <div class="filters__header">
    <div class="filters__header-selected">
      <p *ngIf="!selectedFiltersCount">{{ 'adminSearch.NO_FILTER_ACTIVE' | translate }}</p>
      <p *ngIf="selectedFiltersCount === 1" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
      <p *ngIf="selectedFiltersCount && selectedFiltersCount > 1">{{ selectedFiltersCount }} {{ 'adminSearch.MORE_FILTERS' | translate }}</p>
      <p
        *ngIf="selectedFiltersCount && selectedFiltersCount > 0"
        class="filters__delete"
        (click)="deleteSelectedFilters()">
        {{ 'adminSearch.DELETE_FILTERS' | translate }}
        </p>
    </div>
    <div class="filters__header-show-dropdown" (click)="showFilters = !showFilters" [ngClass]="{ 'open': showFilters }">
      <p>{{ 'adminSearch.MANAGE_FILTERS' | translate }}</p>
      <img src="./assets/img/icons/chevron-down-blue-small.svg">
    </div>
  </div>

  <!-- Dropdown -->
  <pce-dropdown [active]="showFilters">
    <div class="filters__dropdown">
      <div class="filters__dropdown-filters">
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.EVALUATOR' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            bindLabel="label"
            dropdownPosition="bottom"
            [closeOnSelect]="false"
            [multiple]="true"
            [(ngModel)]="selectedFilter['performanceManagerName']"
            [items]="filters.performanceManagerName ?? []"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.POSITION' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            dropdownPosition="bottom"
            bindLabel="label"
            bindValue="value"
            [closeOnSelect]="false"
            [multiple]="true"
            [(ngModel)]="selectedFilter['position']"
            [searchable]="true"
            [items]="filters.position"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.DEPARTMENT' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            bindLabel="label"
            bindValue="value"
            [closeOnSelect]="false"
            [multiple]="true"
            [items]="filters.department"
            [(ngModel)]="selectedFilter['department']"
            [searchable]="true"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.EVALUATION' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            bindLabel="label"
            bindValue="value"
            [closeOnSelect]="false"
            [multiple]="true"
            [items]="filters.evaluationStatus"
            [(ngModel)]="selectedFilter.evaluationStatus"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.AUTO_EVALUATION' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            bindLabel="label"
            bindValue="value"
            dropdownPosition="top"
            [closeOnSelect]="false"
            [multiple]="true"
            [items]="filters.selfEvaluationStatus"
            [(ngModel)]="selectedFilter.selfEvaluationStatus"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
        <div class="filters__dropdown-filter">
          <pce-select
            title="{{ 'adminSearch.GOAL_SETTING' | translate }}"
            placeholder="{{ 'adminSearch.SELECT_PLACEHOLDER' | translate }}"
            bindLabel="label"
            bindValue="value"
            dropdownPosition="top"
            [closeOnSelect]="false"
            [multiple]="true"
            [items]="filters.goalSettingStatus"
            [(ngModel)]="selectedFilter.goalSettingStatus"
            (onChange)="onFilterSelection()">
          </pce-select>
        </div>
      </div>
      <div class="filters__dropdown-apply">
        <alloy-button [label]="('generic.APPLY_FILTERS' | translate)" type="primary"
          (onButtonClicked)="onApplyFilters()" [disabled]="isLoadingFilters">
        </alloy-button>
      </div>
    </div>
  </pce-dropdown>
</div>
