import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import { Console } from 'console';

@Component({
  selector: 'admin-manage-roles',
  templateUrl: './manageRoles.component.html',
  styleUrls: ['./manageRoles.component.scss']
})
export class ManageRolesComponent implements OnInit {
  roleTitle: string = '';
  roleId: string = '';
  macroBehaviorList: any[] = [];

  isLoadingData: boolean = false;

  selectedReferenceValue: any;
  isLoadingModalData: boolean = true;
  getModalData$: Subscription = new Subscription();
  modalData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    }
  ]
  selectedRows: { id: number, title: number };
  translations: any;
  processYearCode: string = '';
  processYear: number = 0;
  getMacroBehaviors$: Subscription = new Subscription();
  getRole$: Subscription = new Subscription();
  modalSearchedText: string = '';
  allChecked: boolean = false;
  selectedData: any[] = [];
  isBehavior: boolean = true;
  dictionariesList: any;

  behaviorOrCompetence: any = [];
  defaultDictionary: any;

  constructor(
    public redirectService: RedirectService,
    public modalService: ModalService,
    private adminService: AdminService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>
  ) {
    this.selectedRows = this.rowPerPageOptions[0];
    this.route.params.subscribe((params: Params) => {
      this.processYear = params.processYear;
      this.processYearCode = params.processYearCode;
      if (window.location.href.includes('softSkills')) {
        this.isBehavior = true;
      } else {
        this.isBehavior = false;
      }
      if (params.roleId) {
        this.roleId = params.roleId
      }
      this.getBehaviorDictionaryData();
    });
  }

  ngOnInit(): void {

  }

  titleChanged(text: string) {
    this.roleTitle = text;
  }

  getBehaviorDictionaryData() {
    this.isLoadingData = true;
    let promiseArray = [
      this.adminService.listMacroBehaviorForAssessment(this.processYear, this.processYearCode).toPromise(),
      this.adminService.listDictionaries(this.processYear, this.processYearCode, 0, 0, '', [], true).toPromise()
    ];

    Promise.all(promiseArray).then((results) => {
      if (results[0] && results[0].response) {
        this.macroBehaviorList = results[0].response;
      }
      if (results[1] && results[1].response) {
        let dictionaries = results[1].response.map((dictionary: any) => { return { tagId: dictionary.tagId, title: dictionary.title, isDefault: dictionary.isDefault } });
        this.dictionariesList = dictionaries;
        this.defaultDictionary = dictionaries.find((dictionary: any) => dictionary.isDefault);
      }
    }).then(() => {
      if (this.roleId) {
        this.getRole();
      } else {
        this.isLoadingData = false;
      }
    });
  }


  getRole() {
    if (this.getRole$) {
      this.getRole$.unsubscribe();
    }

    this.getRole$ = this.adminService.getRole(this.processYear, this.processYearCode, this.roleId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "a003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
          this.redirectService.goBackBrowser();
        } else {
          this.roleTitle = data.response.title;
          for (let i = 0; i < this.macroBehaviorList.length; i++) {
            let tmpCompetence: any[] = []

            for (let j = 0; j < data.response.behaviorOrCompetence.length; j++) {
              if (data.response.behaviorOrCompetence[j].parentTagId == this.macroBehaviorList[i].tagId) {
                tmpCompetence.push(data.response.behaviorOrCompetence[j]);
                if (this.isBehavior) {
                  for (let i = 0; i < this.macroBehaviorList.length; i++) {
                    let behaviorOrCompetence: any[] = []

                    for (let j = 0; j < data.response.behaviorOrCompetence.length; j++) {
                      if (data.response.behaviorOrCompetence[j].parentTagId == this.macroBehaviorList[i].tagId) {
                        behaviorOrCompetence.push(data.response.behaviorOrCompetence[j]);
                      }
                    }

                    this.behaviorOrCompetence.push({
                      parentId: this.macroBehaviorList[i].tagId,
                      title: this.macroBehaviorList[i].title,
                      list: tmpCompetence
                    })
                  }
                } else {
                  // competenze
                  for (let i = 0; i < data.response.behaviorOrCompetence.length; i++) {
                    data.response.behaviorOrCompetence[i].title = data.response.behaviorOrCompetence[i].description;
                    this.behaviorOrCompetence.push({
                      parentId: data.response.behaviorOrCompetence[i].tagId,
                      title: '',
                      list: [data.response.behaviorOrCompetence[i]]
                    })
                  }
                }
                this.isLoadingData = false;
              }
            }
          }
        }
      }, (err: any) => {
        this.isLoadingData = false;
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
      })
  }

  // Aggiungi nuovo
  addNew() {
    this.getModalData();
    this.modalService.open('add-modal');
  }

  getModalData() {
    this.isLoadingModalData = true;
    this.modalData.counter = 0;
    //this.modalData.fromRecord = 0;
    //this.modalData.page = 1;
    if (this.isBehavior) {
      this.getBehaviorList();
    } else {
      this.getCompetenceList();
    }
  }


  getBehaviorList() {
    this.isLoadingModalData = true;
    if (this.getModalData$) {
      this.getModalData$.unsubscribe();
    }

    this.getModalData$ = this.adminService.countBehavior(this.processYear, this.processYearCode, this.modalSearchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.modalData.counter = counter.response;

              // Calcolo la paginazione
              this.modalData.fromRecord = (this.modalData.page - 1) * this.modalData.numRecords;

              if (this.modalData.counter) {
                return this.adminService.listBehavior(this.processYear, this.processYearCode, this.modalData.fromRecord, this.modalData.numRecords, this.modalSearchedText,);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingModalData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {

            this.modalData.list = [];
            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                this.modalData.list.push({
                  id: data.response[i].behaviorId,
                  tagId: data.response[i].behaviorId,
                  title: data.response[i].title,
                  value: data.response[i].parentBehavior?.text,
                  parentId: data.response[i].parentBehavior?.behaviorId,
                  description: data.response[i].text
                })
              }
            } else {

              this.modalData.list = [];
            }
          }
          this.isLoadingModalData = false;
        }
        , (err: any) => {
          this.isLoadingModalData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }


  getCompetenceList() {
    this.isLoadingModalData = true;

    if (this.getModalData$) {
      this.getModalData$.unsubscribe();
    }

    this.getModalData$ = this.adminService.countCompetences(this.processYear, this.processYearCode, this.modalSearchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.modalData.counter = counter.response;

              // Calcolo la paginazione
              this.modalData.fromRecord = (this.modalData.page - 1) * this.modalData.numRecords;

              if (this.modalData.counter) {
                return this.adminService.listCompetences(this.processYear, this.processYearCode, this.modalData.fromRecord, this.modalData.numRecords, this.modalSearchedText,);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingModalData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.modalData.list = [];
            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                this.modalData.list.push({
                  tagId: data.response[i].competenceId,
                  title: data.response[i].text,
                })
              }
            } else {
              this.modalData.list = [];
            }
          }
          this.isLoadingModalData = false;
        }
        , (err: any) => {
          this.isLoadingModalData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  modalPageChanged(page: number) {
    this.isLoadingModalData = true;
    this.modalData.page = page;
    this.modalData.list = [];
    this.getModalData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.modalData.numRecords = item.id;
    this.modalData.list = [];
    this.modalData.fromRecord = 0;
    this.getModalData();
  }

  // Chiudi modale
  closeModal(confirm?: boolean) {
    this.modalService.close('add-modal');
    this.modalData.counter = 0;
    this.modalData.fromRecord = 0;
    this.modalData.numRecords = 10;
    this.modalData.page = 1;
    if (confirm) {
      this.isLoadingData = true;

      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.isBehavior) {
          // assegno il dizionario di default
          this.selectedData[i].scale = this.defaultDictionary;

          let object = null;
          if (this.behaviorOrCompetence) {
            object = this.behaviorOrCompetence.find((x: any) => x.parentId == this.selectedData[i].parentId);
          }
          if (object) {
            object.list.push(this.selectedData[i]);
          } else {
            this.behaviorOrCompetence.push({
              parentId: this.selectedData[i].parentId,
              title: this.selectedData[i].value,
              list: [this.selectedData[i]]
            })
          }
        } else {
          this.behaviorOrCompetence.push({
            parentId: this.selectedData[i].tagId,
            title: '',
            list: [this.selectedData[i]]
          })
        }
      }
      this.isLoadingData = false;
    }
  }

  changeDictionary(dictionary: any, data: any) {
    data.scale = dictionary;
  }

  // Check singolo comportamento / skill
  selectData(data: any) {
    data.isChecked = !data.isChecked;
    this.addRemoveData(data);
  }


  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    if (this.selectedData && this.selectedData.length) {
      this.selectedData.length = 0;
    }
    if (this.modalData.list && this.modalData.list.length > 0) {
      for (let i = 0; i < this.modalData.list.length; i++) {
        this.modalData.list[i].isChecked = this.allChecked;
        if (this.allChecked) {
          this.addRemoveData(this.modalData.list[i]);
        }
      }
    }
  }

  addRemoveData(user: any) {
    if (this.selectedData && this.selectedData.length) {
      if (!user.isChecked) {
        this.selectedData = this.selectedData.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedData.push(user);
      }
    } else {
      this.selectedData = [user];
    }

  }

  deleteCompetenceOrBehavior(data: any) {
    this.isLoadingData = true;
    let newBehaviorOrCompetence = [];
    if (this.isBehavior) {
      for (let i = 0; i < this.behaviorOrCompetence.length; i++) {
        let newList = [];
        for (let j = 0; j < this.behaviorOrCompetence[i].list.length; j++) {
          if (this.behaviorOrCompetence[i].list[j].tagId != data.tagId) {
            newList.push(this.behaviorOrCompetence[i].list[j]);
          }
        }
        if (newList && newList.length) {
          this.behaviorOrCompetence[i].list = newList;
          newBehaviorOrCompetence.push(this.behaviorOrCompetence[i]);
        }
      }
    } else {
      for (let i = 0; i < this.behaviorOrCompetence.length; i++) {
        if (this.behaviorOrCompetence[i].parentId != data.tagId) {
          newBehaviorOrCompetence.push(this.behaviorOrCompetence[i]);
        }
      }
    }
    this.behaviorOrCompetence = newBehaviorOrCompetence;
    this.isLoadingData = false;
  }


  checkSave() {
    if (!this.behaviorOrCompetence || !this.behaviorOrCompetence.length) {
      this.modalService.open('warning-no-data');
    } else {
      this.modalService.open('confirm-save');
    }
    // controllo che tutti i comportamenti abbiano un dizionario, non serve per le competenze, viene assegnato di default
    // for (let i = 0; i < this.behaviorOrCompetence.length; i++) {
    //   for (let j = 0; j < this.behaviorOrCompetence[i].list.length; j++) {
    //     let element = this.behaviorOrCompetence[i].list[j];
    //     if (element && (!element.scale || !element.scale.tagId)) {
    //       return true;
    //     }
    //   }
    // }
    return false;
  }

  closeWarningModal() {
    this.modalService.close('warning-no-data');
  }

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('confirm-save');
    if (confirm) {
      this.isLoadingData = true;
      let serviceToCall = null;
      let dataForService: any = {};
      dataForService.title = this.roleTitle;

      if (this.roleId) {
        dataForService.tagId = this.roleId;
      }
      if (this.isBehavior) {
        dataForService.behaviorTags = [];
        for (let i = 0; i < this.behaviorOrCompetence.length; i++) {
          let object = this.behaviorOrCompetence[i];
          for (let j = 0; j < object.list.length; j++) {
            dataForService.behaviorTags.push({
              tagId: object.list[j].id || object.list[j].tagId,
              evaluationScaleTagId: object.list[j]?.scale?.tagId
            })
          }
        }
      } else {
        dataForService.competenceTags = [];
        for (let i = 0; i < this.behaviorOrCompetence.length; i++) {
          let object = this.behaviorOrCompetence[i];
          dataForService.competenceTags.push({
            tagId: object.parentId,
          })
        }
      }
      // Se update
      if (this.roleId) {
        serviceToCall = this.adminService.updateRole(this.processYear, this.processYearCode, dataForService)
        // this.adminService.createRole(this.processYear, this.processYearCode,)
      } else { // Se create
        serviceToCall = this.adminService.createRole(this.processYear, this.processYearCode, dataForService)
      }

      serviceToCall.subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
          this.redirectService.goBackBrowser();
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        });
    }
  }

  // Annulla
  onCancel() {
    this.redirectService.goBackBrowser();
  }

  modalSearchChanged(text: string) {
    this.modalSearchedText = text;
  }

  ngOnDestroy() {
    if (this.getRole$) {
      this.getRole$.unsubscribe();
    }
    if (this.getMacroBehaviors$) {
      this.getMacroBehaviors$.unsubscribe();
    }
    if (this.getModalData$) {
      this.getModalData$.unsubscribe();
    }
  }

}