<page-container>

    <div id="snackbar-reminder">
        <p translate="upwardFeedback.admin.verify.REMINDERS_SENT"></p>
    </div>

    <div id="snackbar-message">
        <p translate="upwardFeedback.admin.verify.CONTRIBUTE_SHARED"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="('upwardFeedback.peopleAppraisal.SIDENAV_TITLE' | translate)  + (sidebarPeriod ? sidebarPeriod : '') + (managerName ? (' di ' + managerName) : '')"
            [description]="getSidenavDescription()">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingResults">
                <div class="form-container">
                    <ng-container *ngIf="chiefMessage && chiefMessage.length && !this.userId">
                        <div class="chief-message-container">
                            <p class="text-title" translate="upwardFeedback.peopleAppraisal.MESSAGE_TITLE"></p>
                            <input-container class="input-container" [numberCharsTextArea]="300" [isFromTextArea]="true"
                                [isUpwardFeedback]="true" [isWithoutInfoIcon]="true" [isObbligatory]="false"
                                [title]="'upwardFeedback.peopleAppraisal.MESSAGE_DESCR' | translate"
                                [upwardDisabled]="true">
                                <alloy-text-area [bindValue]="chiefMessage" [upwardDisabled]="true">
                                </alloy-text-area>
                                <p class="message-by">
                                    {{('upwardFeedback.peopleAppraisal.MESSAGE_BY' | translate) + this.chiefName +
                                    ('upwardFeedback.peopleAppraisal.ON' | translate) + this.chiefDate}}
                                </p>
                            </input-container>
                        </div>
                    </ng-container>


                    <!-- titolo e recap questionari inviati-->
                    <p class="text-title" translate="upwardFeedback.admin.verify.EXAMPLE"></p>

                    <div class="accordion-wrapper">
                        <alloy-accordion [infoIcon]="false" [title]="'upwardFeedback.admin.verify.STRENGTH' | translate"
                            [black]="true" [isAccordionOpened]="false" [isAdminUpwardFeedback]="true"
                            [smallerTitle]="true">

                            <div class="list-container">
                                <ng-container
                                    *ngIf="listData.strengths && listData.strengths.length; else strengthsNoData">
                                    <ul>
                                        <li *ngFor="let res of listData.strengths">{{ res }}</li>
                                    </ul>
                                </ng-container>
                                <ng-template #strengthsNoData>
                                    <p translate="generic.NO_DATA_INSERT"></p>
                                </ng-template>
                            </div>

                        </alloy-accordion>

                        <alloy-accordion [infoIcon]="false" [smallerTitle]="true"
                            [title]="'upwardFeedback.admin.verify.DEVELOPMENT_AREA' | translate" [black]="true"
                            [isAccordionOpened]="false" [isAdminUpwardFeedback]="true">
                            <div class="list-container">
                                <ng-container
                                    *ngIf="listData.development && listData.development.length; else developmentNoData">
                                    <ul>
                                        <li *ngFor="let res of listData.development">{{ res }}</li>
                                    </ul>
                                </ng-container>
                                <ng-template #developmentNoData>
                                    <p translate="generic.NO_DATA_INSERT"></p>
                                </ng-template>
                            </div>
                        </alloy-accordion>

                        <alloy-accordion *ngIf="listData.notes && listData.notes.length" [infoIcon]="false"
                            [smallerTitle]="true" [title]="'upwardFeedback.admin.verify.NOTES_COMMENTS' | translate"
                            [black]="true" [isAccordionOpened]="false" [isAdminUpwardFeedback]="true">
                            <div class="list-container">
                                <ul>
                                    <li *ngFor="let res of listData.notes">{{ res }}</li>
                                </ul>
                            </div>
                        </alloy-accordion>

                    </div>

                    <linear-rating [ratings]="ratingData" [average]="globalAverage">
                    </linear-rating>

                    <!-- Dai il tuo contributo Manager II livello -->
                    <ng-container *ngIf="userId">
                        <div class="chief-message-container chief">
                            <p class="text-title" translate="upwardFeedback.peopleAppraisal.COMMENT_TITLE"></p>
                            <input-container class="input-container" [numberCharsTextArea]="300" [isFromTextArea]="true"
                                [isUpwardFeedback]="true" [isWithoutInfoIcon]="true" [isObbligatory]="false"
                                [title]="('upwardFeedback.peopleAppraisal.COMMENT_SUBTITLE_1' | translate ) + managerName + ('upwardFeedback.peopleAppraisal.COMMENT_SUBTITLE_2' | translate )"
                                [upwardDisabled]="chiefCommentSent">
                                <alloy-text-area [bindValue]="chiefComment"
                                    (onModelChanged)="onChiefCommentChanged($event)"
                                    [placeholder]="'upwardFeedback.peopleAppraisal.COMMENT_PLACEHOLDER' | translate"
                                    [upwardDisabled]="chiefCommentSent">
                                </alloy-text-area>
                            </input-container>
                        </div>
                    </ng-container>
                    <!-- Mostra dettagli con accordion e grafici -->
                    <div class="accordion-wrapper">
                        <div class="more-details" (click)="toggleAccordion()">
                            <p [translate]="showAllAccordion ? 'generic.HIDE_DETAILS' : 'generic.SHOW_DETAILS'"></p>
                            <svg-icon class="more-details-icon"
                                [src]="showAllAccordion ? './assets/img/icons/chevron-up.svg' : './assets/img/icons/chevron-down.svg'"
                                [applyClass]="true">
                            </svg-icon>
                        </div>
                        <ng-container *ngIf="showAllAccordion">
                            <!-- Accordion e grafici -->
                            <ng-container *ngFor="let module of modulesData">
                                <alloy-accordion [infoIcon]="false" [black]="true" [isAccordionOpened]="false"
                                    [isAdminUpwardFeedback]="true" [smallerTitle]="false"
                                    (accordionOpened)="redrawGraph(module.moduleTitle)"
                                    [title]="module.moduleTitle +' ('+ ('generic.AVERAGE' | translate) + ': ' + (module.moduleAverage | number : '1.2-2') +')'">
                                    <ng-container
                                        *ngFor="let question of module.questions; let i = index; let last = last">
                                        <div class="graph-container">
                                            <p class="graph-title">{{ question.questionTitle }}</p>
                                            <p class="graph-average">
                                                {{ ('generic.AVERAGE' | translate) + ' ' + ( question.questionAverage|
                                                number : '1.2-2') + ' - '
                                                +
                                                question.totalAnswers + ' ' + ('generic.ANSWERS' | translate)}}
                                            </p>
                                            <div class="graph-content">
                                                <dx-chart [id]="module.moduleTitle + ' ' + i"
                                                    [dataSource]="question.graphData"
                                                    (onInitialized)="saveInstance($event, module.moduleTitle + ' ' + i)">
                                                    <dxo-size [height]="180"></dxo-size>
                                                    <dxi-value-axis position="left" [allowDecimals]="false"
                                                        [axisDivisionFactor]="1">
                                                        <dxo-title text="N. di valutazioni"></dxo-title>
                                                    </dxi-value-axis>
                                                    <dxo-argument-axis title="Punteggio assegnato"
                                                        [allowDecimals]="false" [axisDivisionFactor]="1">
                                                    </dxo-argument-axis>
                                                    <dxo-common-series-settings argumentField="title" valueField="value"
                                                        type="bar">
                                                    </dxo-common-series-settings>
                                                    <dxi-series argumentField="title" valueField="value" type="bar"
                                                        color="#FF6600">
                                                    </dxi-series>
                                                    <dxo-legend [visible]="false"></dxo-legend>
                                                </dx-chart>
                                            </div>
                                            <ng-container *ngIf="!last">
                                                <div class="graph-divisor"></div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </alloy-accordion>
                            </ng-container>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="!userId">
                        <div class="action-bar center">
                            <alloy-button class="margin-bottom0 large"
                                (onButtonClicked)="redirectService.goBackBrowser()" type="success default" size="large"
                                [label]="'generic.CLOSE' | translate">
                            </alloy-button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="userId">
                        <div class="action-bar">
                            <alloy-button (onButtonClicked)="redirectService.goBackBrowser()" type="success bordless"
                                size="large" [label]="'generic.CANCEL' | translate">
                            </alloy-button>
                            <alloy-button class="margin-bottom0 large" (onButtonClicked)="openSendChiefMessage()"
                                type="success default" size="large"
                                [disabled]="isSharingManagerResult || chiefCommentSent || (!chiefComment || !chiefComment.length)"
                                [label]="'upwardFeedback.peopleAppraisal.SEND_MESSAGE' | translate">
                            </alloy-button>
                        </div>
                    </ng-container>

                </div>
            </ng-container>

            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingResults">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<!-- Conferma invio scheda -->
<modal id="confirm-send" (onClose)="closeSendChiefMessage()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeSendChiefMessage(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeSendChiefMessage()">
    <modal-text-content [title]="'upwardFeedback.peopleAppraisal.SECOND_LEVEL_MODAL_TITLE' | translate"
        [subtitle]="'upwardFeedback.peopleAppraisal.SECOND_LEVEL_MODAL_SUBTITLE' | translate"
        [text]="'upwardFeedback.peopleAppraisal.SECOND_LEVEL_MODAL_DESCR' | translate">
    </modal-text-content>
</modal>