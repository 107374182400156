import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { KrukOpenAPI, SenecaResponse } from 'atfcore-commonclasses';
import { Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'app-feedback360-round-create',
  templateUrl: './feedback360-round-create.component.html',
  styleUrls: ['./feedback360-round-create.component.scss']
})
export class Feedback360RoundCreateComponent {
  roundId: string = '';
  yesterday = new Date();
  today: Date = new Date();

  endDate: Date | undefined = undefined;
  startDate: Date | undefined = undefined;

  feedbackStatusList: Array<{ id: string | number, title: string | number }> = [];
  selectedFeedbackStatus: { id: string | number, title: string | number } | undefined = undefined;

  roundFeedback360Data: KrukOpenAPI.PerfAlloyFeedback360Round = {};

  isLoadingCreateRound: boolean = false;
  createRound$: Subscription = new Subscription();

  constructor(public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public adminService: AdminService,
    public translate: TranslateService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }

  ngOnInit() {

    this.translate.get(
      [
        'adminTable.status.RUNNING',
        'adminTable.status.STANDBY',
        'adminTable.status.CLOSED'

      ])
      .subscribe(translations => {
        this.feedbackStatusList = [
          { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.RUNNING, title: translations['adminTable.status.RUNNING'] },
          { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY, title: translations['adminTable.status.STANDBY'] },
          { id: KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.CLOSED, title: translations['adminTable.status.CLOSED'] },
        ]
        this.selectedFeedbackStatus = this.feedbackStatusList.find(ele => ele.id == KrukOpenAPI.PerfAlloyFeedback360RoundStatusTypes.STANDBY);
      })
  }

  changeRoundName(roundName: string) {
    this.roundFeedback360Data.name = roundName;
  }

  changeSelectedStatus(selectedItem: { id: string | number, title: string | number }) {
    this.selectedFeedbackStatus = selectedItem;
  }

  startDateChanged(newDate: Date) {
    this.roundFeedback360Data.startDate = newDate;
  }

  endDateChanged(newDate: Date) {
    this.roundFeedback360Data.endDate = newDate;
  }

  isPossibleToCreateRound() {
    if (this.roundFeedback360Data.name?.trim() && this.roundFeedback360Data.startDate && this.roundFeedback360Data.endDate) {
      return true;
    }
    return false;
  }


  closeConfirm(confirm?: boolean) {
    this.modalService.close('confirm-edit');
    if (confirm) {
      this.createFeedback360Round()
    }
  }

  onConfirm() {
    if (!this.roundFeedback360Data.startDate || !this.roundFeedback360Data.endDate) {
      const messageObj: ApplicationModalMessage = {
        modalId: "mrd001",
        title: this.translate.instant("generic.WARNING"),
        text: this.translate.instant("process.phases.MISSING_REQ_DATA"),
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
    } else {
      this.modalService.open('confirm-edit');
    }
  }

  createFeedback360Round() {
    this.isLoadingCreateRound = true;

    if (this.createRound$) {
      this.createRound$.unsubscribe;
    }

    this.createRound$ = this.adminService.createFeedback360Round(this.roundFeedback360Data)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "epd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
          this.isLoadingCreateRound = false;
        } else {
          this.router.navigate(['/admin/feedback360Round/']);
          // Fermo il loader
          this.isLoadingCreateRound = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "epd002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateRound = false;
      });
  }
}
