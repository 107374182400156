<div *ngIf="runningYear" class="wrapper-layout-peopleAppraisal">
  <!-- Sidebar utenti valutazione -->
  <!-- [ngClass]="{'open-on-mobile': isTeamButtonClicked}" -->
  <team-sidebar class="team-sidebar" *ngIf="showSidebar()" [users]="subordinatesWithPhaseStatuses"
    [currentPhaseId]="runningPhase?.phaseId" [activeUserId]="activeUserId" (userClicked)="onUserClicked($event)"
    [isLoadingUsers]="isLoadingSubordinates" [hasImpersonateBanner]="showImpersonateBanner"
    [structureUsers]="structureSubordinatesWithPhaseStatuses" [isOpenedSecondLevel]="isOpenedSecondLevel"
    (secondLevelOpen)="secondLevelOpen($event)" [showFeedbackStatus]="isFeedbackTeamView">
  </team-sidebar>

  <!-- Sidebar utenti Feedback 360 -->
  <team-sidebar class="team-sidebar" *ngIf="showFeedbackSidebar()" [users]="feedbackSubordinateList"
    [activeUserId]="feedbackActiveUserId" (userClicked)="onUserClicked($event)"
    [isLoadingUsers]="isLoadingFeedbackSubordinates" [hasImpersonateBanner]="showImpersonateBanner">
  </team-sidebar>

  <div class="router-outlet-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- Modale utente struttura disabilitato -->
<modal id="disabled-structure-user-modal" (onClose)="closeDisabledStructureUserModal()"
  (onConfirm)="closeDisabledStructureUserModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE' | translate"
    [subtitle]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE_SUBTITLE' | translate"
    [text]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE_DESCR' | translate">
  </modal-text-content>
</modal>