<page-container *ngIf="langs && langs.length">
    <div class="sidenav-information">
        <sidenav-informations></sidenav-informations>
    </div>
    <div class="main-content">
        <div class="page-login-content">
            <ng-container *ngIf="!showLoader">
                <div class="login-wrapper">
                    <svg-icon src="assets/img/icons/kruk-big-logo.svg" class="login-icon" [applyClass]="true">
                    </svg-icon>
                    <div class="container-title">
                        <h1><b translate="login.INSERT_NEW_PASSWORD"></b></h1>
                        <p class="login-title-text" translate="login.passwordRecovery.TITLE_PAGE"></p>
                    </div>
                    <!--Nuova password-->
                    <input-container [removePadding]="true"
                        [inputStatusLabel]="passwordValidate() && passwordRecoveryForm.password.trim().length? ('login.signUp.VALID_PASSWORD' | translate) : ''"
                        instructions="{{'login.signUp.PASSWORD_REQUIREMENTS' | translate}}" [isWithoutInfoIcon]="true"
                        [isValued]="false" [isObbligatory]="true" title="{{'login.PASSWORD' | translate}}">
                        <alloy-input [hasFormControl]="true" [customFormControl]="passwordControl"
                            [isInvalid]="passwordError()"
                            [isValid]="passwordValidate() && passwordRecoveryForm.password.trim().length? ('login.signUp.VALID_PASSWORD' | translate) : ''"
                            [iconRight]="iconRightEye" [typeInput]="typeInput" autocomplete="on" typeInput="password"
                            [bindValue]="passwordRecoveryForm.password" (onIconClicked)="changeTypeInput(typeInput)"
                            (onModelChanged)="passwordChanged($event)"
                            (keyup.enter)="!confirmButtonIsActive()? '': confirmButton()"
                            placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!--Ripeti nuova password-->
                    <input-container [removePadding]="true" [isWithoutInfoIcon]="true"
                        [inputStatusLabel]="isValid()? ('login.signUp.VALID_PASSWORD' | translate) : ''"
                        [isValued]="false" [isObbligatory]="true" title="{{'login.REPEAT_PASSWORD' | translate}}">
                        <alloy-input [hasFormControl]="true" [customFormControl]="passwordControl"
                            (onIconClicked)="changeTypeInput(typeInput)" [isInvalid]="repeatPasswordError()"
                            [isValid]="isValid()" [iconRight]="iconRightEye" [typeInput]="typeInput" autocomplete="on"
                            typeInput="password" [bindValue]="passwordRecoveryForm.repeatPassword"
                            (onModelChanged)="repeatPasswordChanged($event)"
                            placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}"
                            (keyup.enter)="!confirmButtonIsActive()? '': confirmButton()"></alloy-input>
                    </input-container>
                    <!--Pulsante conferma-->
                    <div class="button-login-container">
                        <alloy-button [disabled]="!confirmButtonIsActive()" type="default primary" size="medium"
                            label="{{'login.CONFIRM_BUTTON' | translate}}" (onButtonClicked)="confirmButton()">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="showLoader">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
        <!--Modale passowrd impostata-->
        <modal id="modalPasswordSet" class="modal-Recovery" [isMessageModal]="true" (onClose)="goToSignIn()"
            confirmLabel="{{'login.modals.CLOSE' | translate}}" (onCancel)="goToSignIn()" (onConfirm)="goToSignIn()">
            <modal-text-content title="{{'login.modals.PASSWORD_SET' | translate}}"
                subtitle="{{'login.modals.PASSWORD_SET_SUBTITLE' | translate}}"
                text="{{'login.modals.PASSWORD_SET_DESCR' | translate}}">
            </modal-text-content>
        </modal>
    </div>
</page-container>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
    <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>