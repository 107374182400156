<div class="page-content-wrapper">

  <div id="snackbarPersonDetails">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>
  <div id="objectivesReproposed">
    <p translate="stepper.OBJECTIVES_REPROPOSED"></p>
  </div>
  <div id="objectiveReproposed">
    <p translate="stepper.OBJECTIVE_REPROPOSED"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
      [yearItems]="yearList" [currentPercentage]="currentUserPercentage" [selectedYear]="selectedYear"
      (onSelectedYear)="changeSelectedYear($event)" (onNewInterview)="createPrivateNote()"
      [hideAction]="isImpersonating" [menuButtons]="menuTabs">
    </header-dossier>

    <div class="main-content-wrapper">
      <!-- dati semestre H1 H2-->
      <ng-container *ngIf="!isNoteSection && !isGoalsUpdatePhase">
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab">
          <child-content>
            <stepper id="stepper" [ngClass]="{'central-stepper': isLeadershipTeam}" [statuses]="stepList" [dots]="5"
              [currentStatus]="currentStep" (stepClicked)="onStepClicked($event)"></stepper>

            <!-- 1 - Valutazione obiettivi di sviluppo -->
            <ng-container *ngIf="currentStep.id == 'objectiveEvaluation'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingToEvaluateGoals">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingToEvaluateGoals">
                <!-- Se non ho obiettivi mostro placeholder -->
                <ng-container *ngIf="!toEvaluateList || !toEvaluateList.length">
                  <no-data [title]="('generic.NO_OBJECTIVE_TO_EVALUATE' | translate)"
                    [description]="('generic.NO_OBJECTIVE_TO_EVALUATE_DESCR' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
                <!-- se ho obiettivi -->
                <ng-container *ngIf="toEvaluateList && toEvaluateList.length">
                  <div class="page-header">
                    <div id="firstStepPageHeader">
                      <div class="title-container">
                        <p class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate)}}</p>
                        <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                          (click)="openCompetenceDictionaryModal()">
                        </svg-icon>
                      </div>
                      <p class="description" [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR' | translate"></p>
                    </div>
                  </div>

                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <ng-container *ngFor="let objective of toEvaluateList; let toEvaluateItemIndex = index;">
                    <text-goal [tagTitle]="'finalEvaluation.OBJECTIVE' | translate" [title]="objective.text"
                      [isEvaluation]="true" [customTitle]="objective.evaluation ? ('textGoal.INSERT_LABEL' | translate) : ' '"
                      [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.creationUser.forename + ' '+ objective.creationUser.surname"
                      (evaluationChanged)="onEvaluationChanged($event, objective)" [evaluation]="objective.evaluation"
                      (evaluationStarChanged)="onEvaluationStarChanged($event, objective)" [showMenu]="false"
                      [disabled]="isScheduleShared || isFinalStepCompleted || evaluationTabIsFinished" [evaluationVote]="objective.evaluationVote"
                      [readonly]="isFinalStepCompleted" [id]="'toEvaluateItemIndex' + toEvaluateItemIndex"
                      (showModal)="openCompetenceDictionaryModal()" [isNotificationUpdates]="true">
                    </text-goal>
                  </ng-container>
                </ng-container>
              </ng-container>

              <div class="person-details-main-actions-banner" id="saveGoalToEvaluateBtn">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <!-- <alloy-button [disabled]="true" type="secondary" size="large" label="{{'generic.BACK' | translate}}"
                      iconLeft="/assets/img/icons/chevron-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button> -->
                    <!-- Pulsante mobile -->
                    <alloy-button [mobileShrink]="true" type="black with-just-left-icon" [disabled]="true" size="large"
                      label="" (onButtonClicked)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow
                      fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <!-- Salva e continua (se ci sono obiettivi da valutare) -->
                    <alloy-button
                      *ngIf="toEvaluateList && toEvaluateList.length && !isScheduleShared && !isFinalStepCompleted && !evaluationTabIsFinished"
                      [disabled]="isLoadingToEvaluateGoals" type="primary fill" size="large"
                      label="{{'generic.SAVE_AND_CONTINUE' | translate}}" (onButtonClicked)="saveObjectiveEvaluation()"
                      iconRight="/assets/img/icons/chevron-right.svg">
                    </alloy-button>
                    <!-- Va avanti di step (se non ci sono obiettivi da valutare) -->
                    <alloy-button *ngIf="!toEvaluateList || !toEvaluateList.length || isScheduleShared || isFinalStepCompleted || evaluationTabIsFinished"
                      [disabled]="isLoadingToEvaluateGoals" type="primary fill" size="large"
                      label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()"
                      iconRight="/assets/img/icons/chevron-right.svg">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 2 - Valutazione dei comportamenti organizzativi + Leadership team -->
            <ng-container *ngIf="currentStep.id == 'behaviourEvaluation'">
              <div class="page-header" id="secondStepPageHeader">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_VALUATION"></p>
                  <!-- <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                    (click)="openHelpModal(currentStep.id)">
                  </svg-icon> -->
                </div>
                <p *ngIf="!isLeadershipTeam" class="description"
                  [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR' | translate"></p>
                <p *ngIf="isLeadershipTeam" class="description"
                  [innerHTML]="'stepper.LEADERSHIP_EVALUATION' | translate"></p>
              </div>

              <!-- Comportamenti organizzativi per tutti gli utenti -->
              <ng-container *ngIf="!isLoadingBehaviours">
                <!-- Esempio competenze -->
                <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                  <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                    [ratingScale]="competence.ratingScale" (infoClicked)="openCompetencesModal($event)"
                    (onRating)="onCompetenceRatingChanged($event)"
                    (onCommentInsertion)="onCommentInsertion(competence, $event)"
                    (onCommentList)="onShowCommentList($event)" (onFeedbackList)="onShowFeedbackList($event)"
                    (onSkillMatrixDownload)="onSkillMatrixDownload()"
                    [disabled]="isScheduleShared || isFinalStepCompleted || evaluationTabIsFinished">
                  </competence-evaluation>
                </ng-container>

                <!-- Media finale -->
                <competence-average id="soft-skill-final-avg" *ngIf="finalAvg?.ratingStars" [competenceAvg]="finalAvg"
                  [disabled]="true">
                </competence-average>

                <!-- Area strategia -->
                <input-container title="{{'evaluations.AREA_STRANGTHS' | translate}}" id="competenceEvaluationNotes"
                  [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="manageBehaviorComments && manageBehaviorComments.length" [isObbligatory]="false"
                  [disabled]="isScheduleShared || isFinalStepCompleted || evaluationTabIsFinished">
                  <alloy-text-area placeholder="{{'evaluations.INSERT_PLACEHOLDER' | translate}}" [white]="true"
                    [bindValue]="strangthsComment" [maxlength]="300" [readonly]="isItemDisabled()"
                    (onModelChanged)="onStrangthsCommentChanged($event)">
                  </alloy-text-area>
                </input-container>

                <!-- Area miglioramento -->
                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="manageBehaviorComments && manageBehaviorComments.length" [isObbligatory]="false"
                  title="{{'evaluations.AREA_IMPROVEMENT' | translate}}"
                  [disabled]="isScheduleShared || isFinalStepCompleted || evaluationTabIsFinished"
                  id="competenceEvaluationNotes" class="margin-bottom-last">
                  <alloy-text-area [white]="true" [bindValue]="improvementComment" [maxlength]="300"
                    (onModelChanged)="onImprovementCommentChanged($event)"
                    placeholder="{{'evaluations.INSERT_PLACEHOLDER' | translate}}" [readonly]="isItemDisabled()">
                  </alloy-text-area>
                </input-container>

              </ng-container>

              <ng-container *ngIf="isLoadingBehaviours">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black" size="large" label="{{'generic.BACK' | translate}}"
                      (onButtonClicked)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow.gt-xs
                      fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [mobileShrink]="true" type="black with-just-left-icon" size="large" label=""
                      (onButtonClicked)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow
                      fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button *ngIf="evaluationTabIsFinished || isScheduleShared;
                      else SaveAndContinue" type="primary fill" size="large" label="{{'generic.CONTINUE' | translate}}"
                      iconRight="/assets/img/icons/chevron-right.svg" [disabled]="false"
                      (onButtonClicked)="saveCompetenceEvaluation()">
                    </alloy-button>
                    <ng-template #SaveAndContinue>
                      <alloy-button type="primary fill" size="large" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        iconRight="/assets/img/icons/chevron-right.svg" [disabled]="isLoadingBehaviours"
                        (click)="saveCompetenceEvaluation()">
                      </alloy-button>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 3 e 5 Riepilogo -->
            <ng-container
              *ngIf="(currentStep.id == 'finalEvaluation' || currentStep.id == 'Overall') && !isLoadingFinalEvaluationData && !isLoadingCloseIntermediateEvaluation  && !isLoadingFinalEvaluationList && !isLoadingFinalShareModal">
              <!-- Riepilogo valutazione finale -->
              <ng-container>
                <!-- Select per il profilo nell'overall  -->
                <div class="overall-select" *ngIf="isScheduleShared && !isfinalShare && currentStep.id == 'Overall'">
                  <div class="title-container">
                    <p class="title" translate="recap.OVERALL"></p>
                    <p class="description" translate="recap.SELECT_OVERALL_DESC"></p>
                  </div>
                  <div class="select-container">
                    <p class="select-label" translate="recap.SELECT_OVERALL"></p>
                    <alloy-select [items]="overallSelectData"
                      [placeholder]="'recap.SELECT_DEFINITIVE_VALUE_PLACEHOLDER' | translate" [inMinWidthForIe]="true"
                      (onSelectedItem)="onOverAllSelectedItem($event)" [searchable]="false" bindLabel="id">
                    </alloy-select>
                  </div>
                </div>

                <!-- Sezione condivi per step Riepilogo -->
                <div class="overall-select"
                  *ngIf="evaluationTabIsFinished && !isScheduleShared && currentStep.id == 'finalEvaluation'">
                  <div class="title-container">
                    <p class="title" translate="recap.CONFIRM_SHARE_TAB"></p>
                    <p class="description" translate="recap.CONFIRM_SHARE_TAB_DESC"></p>
                  </div>
                  <alloy-button [disabled]="false" type="primary fill" size="medium"
                    label="{{'recap.SHARE_SCHEDULE' | translate}}"
                    (onButtonClicked)="openShareIntermediateEvaluationModal()">
                  </alloy-button>
                </div>

                <ng-container>
                  <!-- Performer -->
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start center"
                    class="grafics-final-evaluation-container">
                    <performer-evaluator [enablePerformerInfo]="true" [performerValue]="performerAvgValue"
                      [forcePerformer]="forcePerformerPasProfile" [overall]="currentStep.id == 'Overall'"
                      (openPerformerModal)="openPerformerModal($event)">
                    </performer-evaluator>
                    <!-- Divisor -->
                    <div *ngIf="evaluationTabIsFinished && checkProgress()" class="divisor-vertical-recap"></div>

                    <!-- Riepilogo dei progressi-->
                    <div *ngIf="evaluationTabIsFinished && checkProgress()"
                      class="progress-grafic-container full-width">
                      <p class="text-title" translate="recap.GRAPH_TITLE"></p>

                      <div class="recap-development-container">
                        <!-- Obiettivi -->
                        <div fxLayout="row" class="summary-ratings" fxLayoutAlign="start center">
                          <!-- Titolo categoria -->
                          <p class="text-desc margin-right16" translate="recap.GOALS"></p>
                          <!-- Barra completamento -->
                          <div class="width-135 margin-right16" fxLayout="column">
                            <div fxLayout="row">
                              <div class="first-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.currentPerfAssessment?.averageGoalManagerEvaluation)/4) + 'px'}">
                              </div>
                              <div class="first-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.currentPerfAssessment?.averageGoalManagerEvaluation)/4) + 'px'}">
                              </div>
                            </div>
                            <div fxLayout="row">
                              <div class="second-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.previousPerfAssessment?.averageGoalManagerEvaluation)/4) + 'px'}">
                              </div>
                              <div class="second-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.previousPerfAssessment?.averageGoalManagerEvaluation)/4) + 'px'}">
                              </div>
                            </div>
                          </div>

                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.currentPerfAssessment?.averageGoalManagerEvaluation}}</p>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.previousPerfAssessment?.averageGoalManagerEvaluation}}</p>
                            </div>
                          </div>
                        </div>

                        <!-- Competenze -->
                        <div fxLayout="row" class="summary-ratings" fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="recap.COMPETENCE"></p>

                          <div class="width-135 margin-right16" fxLayout="column">
                            <div fxLayout="row">
                              <div class="first-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.currentPerfAssessment?.averageCompetenceEvaluation)/4) + 'px'}">
                              </div>
                              <div class="first-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.currentPerfAssessment?.averageCompetenceEvaluation)/4) + 'px'}">
                              </div>
                            </div>
                            <div fxLayout="row">
                              <div class="second-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.previousPerfAssessment?.averageCompetenceEvaluation)/4) + 'px'}">
                              </div>
                              <div class="second-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.previousPerfAssessment?.averageCompetenceEvaluation)/4) + 'px'}">
                              </div>
                            </div>
                          </div>

                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.currentPerfAssessment?.averageCompetenceEvaluation}}
                              </p>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.previousPerfAssessment?.averageCompetenceEvaluation}}
                              </p>
                            </div>
                          </div>
                        </div>

                        <!-- Overall -->
                        <div fxLayout="row" class="summary-ratings" fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="recap.OVERALL"></p>

                          <div class="width-135 margin-right16" fxLayout="column">
                            <div fxLayout="row">
                              <div class="first-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.currentPerfAssessment?.averageOverallEvaluation)/4) + 'px'}">
                              </div>
                              <div class="first-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.currentPerfAssessment?.averageOverallEvaluation)/4) + 'px'}">
                              </div>
                            </div>

                            <div fxLayout="row">
                              <div class="second-star-row"
                                [ngStyle]="{'width': (243*(finalEvaluation.progress.previousPerfAssessment?.averageOverallEvaluation)/4) + 'px'}">
                              </div>
                              <div class="second-star-row gray"
                                [ngStyle]="{'width': (243*(4-finalEvaluation.progress.previousPerfAssessment?.averageOverallEvaluation)/4) + 'px'}">
                              </div>
                            </div>
                          </div>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.currentPerfAssessment?.averageOverallEvaluation}}</p>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">
                                {{finalEvaluation.progress.previousPerfAssessment?.averageOverallEvaluation}}</p>
                            </div>
                          </div>
                        </div>

                        <!-- Delta -->
                        <div fxLayout="row" class="summary-ratings delta" fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="recap.DELTA"></p>

                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.progress?.delta}}</p>
                          </div>
                        </div>

                        <div class="ellipse-container">
                          <div class="ellipse">
                            <svg-icon src="../../../assets/img/icons/Ellipse-gradiant.svg"></svg-icon>
                            <p class="ellipse-text">{{finalEvaluation.progress.currentPerfAssessment?.perfYear}}</p>
                          </div>
                          <div class="ellipse">
                            <svg-icon src="../../../assets/img/icons/Ellipse.svg"></svg-icon>
                            <p class="ellipse-text">{{finalEvaluation.progress.previousPerfAssessment?.perfYear}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Grafici -->

              <alloy-accordion
                *ngIf="(isScheduleShared && finalEvaluationList?.competenceEvaluationData) || ((isScheduleShared || evaluationTabIsFinished) && finalEvaluation?.competenceEvaluationData)"
                [infoIcon]="true" [smallerTitle]="false" [title]="'recap.COMPETENCE' | translate" [black]="true"
                [isAccordionOpened]="true" [tooltipModalMessage]="graphModal">
                <div class="graphs-main" *ngIf="!isLoadingFinalEvaluationList && managerialOverallGraph.length">
                  <!-- Manageriali -->
                  <div class="graphs-container">
                    <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="managerialOverallGraph">
                      <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
                      </dxo-legend>
                      <dxo-title text="Competenze manageriali">
                        <dxo-font color="#232323" size="16px" weight="400">
                        </dxo-font>
                      </dxo-title>
                      <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
                      <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
                      <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
                      <dxo-common-series-settings type="line"></dxo-common-series-settings>
                      <dxo-tooltip [enabled]="true"></dxo-tooltip>
                    </dx-polar-chart>
                  </div>
                  <!-- Legenda -->
                  <div class="graphs-container gray">
                    <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
                    <ng-container *ngFor="let competence of managerialOverallGraph">
                      <div class="competence-container">
                        <p class="indicator">{{competence.legend.title}}</p>
                        <p class="explanation">{{competence.legend.desc}}</p>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="isLoadingFinalEvaluationList">
                    <div class="loader-container">
                      <div class="loader spinner-big"></div>
                    </div>
                  </ng-container>
                </div>
                <div class="graphs-main" *ngIf="!isLoadingFinalEvaluationList && genericOverallGraph.length">
                  <!-- Generali -->
                  <div class="graphs-container">
                    <dx-polar-chart id="chart" [useSpiderWeb]="true" [dataSource]="genericOverallGraph">
                      <dxo-legend position="outside" horizontalAlignment="center" verticalAlignment="bottom">
                      </dxo-legend>
                      <dxo-title text="Competenze generali">
                        <dxo-font color="#232323" size="16px" weight="400">
                        </dxo-font>
                      </dxo-title>
                      <dxi-series valueField="evaluation" [name]="'recap.EVALUATION'| translate"></dxi-series>
                      <dxi-series valueField="selfAssessment" [name]="'recap.SELF_ASSESSMENT'| translate"></dxi-series>
                      <dxi-series valueField="feedback" [name]="'recap.FEEDBACK'| translate"></dxi-series>
                      <dxo-common-series-settings type="line"></dxo-common-series-settings>
                      <dxo-tooltip [enabled]="true"></dxo-tooltip>
                    </dx-polar-chart>
                  </div>
                  <!-- Legenda -->
                  <div class="graphs-container gray">
                    <p class="legend-title" translate="recap.LEGEND_TITLE"></p>
                    <ng-container *ngFor="let competence of genericOverallGraph">
                      <div class="competence-container">
                        <p class="indicator">{{competence.legend.title}}</p>
                        <p class="explanation">{{competence.legend.desc}}</p>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="isLoadingFinalEvaluationList">
                    <div class="loader-container">
                      <div class="loader spinner-big"></div>
                    </div>
                  </ng-container>
                </div>
              </alloy-accordion>

              <!-- Placeholder obiettivi valutati -->
              <!-- <ng-container *ngIf="isLoadingFinalEvaluationData && !isLeadershipTeam">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                  </div>
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </div>
              </ng-container> -->

              <!-- Recap valutazione obiettivi di sviluppo -->
              <ng-container *ngIf="finalEvaluation">
                <div class="page-header white-bg">
                  <!-- Titolo valutazione obiettivi -->
                  <div class="title-container">
                    <p class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate)}}
                  </div>

                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <div class="objective-tag-container">
                    <!-- Placeholder  obiettivo non valutati -->
                    <ng-container
                      *ngIf="(!allGoalsAreEvaluated() || finalEvaluation.emptyEvaluatedObjective) && (finalEvaluation.evaluatedManagerUserGoals?.length || finalEvaluation.evaluatedSubordinateUserGoals?.length)">
                      <p class="placeholder margin" translate="defineObjectives.OBJECTIVE_NOT_EVALUATED"></p>
                    </ng-container>
                    <!-- Placeholder nessun obiettivo -->
                    <ng-container
                      *ngIf="!(finalEvaluation.evaluatedManagerUserGoals?.length || finalEvaluation.evaluatedSubordinateUserGoals?.length)">
                      <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE_TO_EVALUATE"></p>
                    </ng-container>

                    <!-- recap obiettivi -->
                    <ng-container *ngIf="allGoalsAreEvaluated()">
                      <ng-container *ngFor="let objective of finalEvaluation.evaluatedManagerUserGoals; let i = index">
                        <div class="objective-tag" (click)="selectObjectiveFinalEvaluation(objective)"
                          [ngClass]="{ 'selected': selectedObjective && selectedObjective.goalId && selectedObjective.goalId == objective.goalId }">
                          <p>{{ ('finalEvaluation.OBJECTIVE' | translate) + ' ' + ( i + 1 )}}</p>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>

                  <ng-container *ngIf="selectedObjective && allGoalsAreEvaluated()">
                    <div class="objective-evaluation-content">
                      <!-- Tag Obiettivo -->
                      <goal-box [desc]="selectedObjective.text" [isNew]="false"></goal-box>

                      <!-- Valutazione obiettivo Manager -->
                      <evaluation-rating *ngIf="finalEvaluation.evaluatedManagerUserGoals.length"
                        [user]="finalEvaluation.managerUser"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + finalEvaluation.managerUser?.surname +  ' ' + finalEvaluation.managerUser?.forename"
                        [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                        [canEdit]="!isFinalStepCompleted" [isEditDisabled]="isScheduleShared"
                        [rating]="selectedObjective.evaluationVote" (onEdit)="editCurrentObjective(selectedObjective)">
                      </evaluation-rating>

                      <evaluation-rating
                        *ngIf="finalEvaluation.evaluatedSubordinateUserGoals && finalEvaluation.evaluatedSubordinateUserGoals.length"
                        [user]="finalEvaluation.subordinateUser"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + finalEvaluation.subordinateUser?.surname + ' ' + finalEvaluation.subordinateUser?.forename"
                        [evaluationText]="selectedObjectiveSubordinate.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                        [canEdit]="!isFinalStepCompleted" [isEditDisabled]="isScheduleShared"
                        [rating]="selectedObjectiveSubordinate.evaluationVote"
                        (onEdit)="editCurrentObjective(selectedObjective)">
                      </evaluation-rating>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Recap valutazione competenze -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                </div>

                <!-- NO DATA competenze -->
                <ng-container
                  *ngIf="!isLoadingFinalEvaluationData && !finalEvaluation.managerCompetenceEvaluation && !finalEvaluation.subordinateCompetenceEvaluation">
                  <p class="placeholder margin" translate="finalEvaluation.COMPETENCE_NOT_EVALUATED"></p>
                </ng-container>


                <!-- MANAGER -->
                <evaluation-rating
                  *ngIf="!isLoadingFinalEvaluationData && finalEvaluation.managerCompetenceEvaluation && finalEvaluation.managerCompetenceEvaluation.averageCompetenceEvaluation >= 0"
                  [user]="loggedUser" [evaluationSecondTitle]="('generic.AREAS_IMPROVEMENT' | translate)"
                  [verticalStructure]="true"
                  [title]="('recap.EVALUATION_COMPETENCE_OF' | translate) + finalEvaluation.managerUser.surname + ' ' + finalEvaluation.managerUser.forename"
                  [level]="finalEvaluation.managerCompetenceEvaluation?.stars"
                  [evaluationSecondText]="finalEvaluation.managerCompetenceEvaluation?.overallComment?.areaOfImprovement || ('generic.EVALUATION_INCOMPLETE' | translate)"
                  [rating]="finalEvaluation.managerCompetenceEvaluation?.averageCompetenceEvaluation >= 0?finalEvaluation.managerCompetenceEvaluation?.averageCompetenceEvaluation: ''"
                  [evaluationText]="finalEvaluation.managerCompetenceEvaluation?.overallComment?.areaOfStrength || ('generic.EVALUATION_INCOMPLETE' | translate)"
                  [evaluationTitle]="('generic.STRENGTH' | translate)" [isCompetenceScale]="true">
                </evaluation-rating>

                <!-- SELF -->
                <evaluation-rating
                  *ngIf="!isLoadingFinalEvaluationData && finalEvaluation.subordinateCompetenceEvaluation && finalEvaluation.subordinateCompetenceEvaluation.averageCompetenceEvaluation >= 0"
                  [user]="personDetails" [verticalStructure]="true"
                  [title]="('recap.SELF_EVALUATION_COMPETENCE_OF' | translate) + finalEvaluation.subordinateUser.surname + ' ' + finalEvaluation.subordinateUser.forename"
                  [evaluationSecondTitle]="('generic.AREAS_IMPROVEMENT' | translate)"
                  [level]="finalEvaluation.subordinateCompetenceEvaluation?.stars"
                  [evaluationSecondText]="finalEvaluation.subordinateCompetenceEvaluation?.overallComment?.areaOfImprovement || ('generic.EVALUATION_INCOMPLETE' | translate)"
                  [evaluationText]="finalEvaluation.subordinateCompetenceEvaluation?.overallComment?.areaOfStrength || ('generic.EVALUATION_INCOMPLETE' | translate)"
                  [evaluationTitle]="('generic.STRENGTH' | translate)" [isCompetenceScale]="true"
                  [rating]="finalEvaluation.subordinateCompetenceEvaluation?.averageCompetenceEvaluation >= 0? finalEvaluation.subordinateCompetenceEvaluation?.averageCompetenceEvaluation: null">
                </evaluation-rating>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </ng-container>
              </div>

              <!-- Definizione nuovi obiettivi -->
              <div class="page-header white-bg" *ngIf="currentStep.id == 'Overall'">
                <div class="title-container">
                  <p class="title" translate="recap.NEW_GOALS"></p>
                  <p class="description"
                    [innerHTML]="('recap.DEFINE_NEW_OBJECTIVES_SUBTITLE_1' | translate) + ' ' + personDetails.surname + ' ' + personDetails.forename + ' ' +
                  (isfinalShare ? ('recap.DEFINE_NEW_OBJECTIVES_SUBTITLE_2_POST' | translate):('recap.DEFINE_NEW_OBJECTIVES_SUBTITLE_2' | translate))">
                  </p>
                </div>

                <!-- Obiettivi -->
                <ng-container *ngIf="!isLoadingFinalEvaluationList && !isLoadingDefinedObjectives">
                  <ng-container *ngFor="let obv of finalEvaluationList?.definedManagerUserGoals">
                    <goal-box *ngIf="!obv.isEdit" [desc]="obv.text" [hideMenu]="true"
                      [insertBy]="obv.creationUser.forename + ' ' + obv.creationUser.surname"
                      [menuOptions]="objectiveEditMenuItems" (onClicked)="onNewObjectiveMenuItemClicked($event, obv)">
                    </goal-box>
                    <!-- isfinalShare -->
                    <!-- <input-container *ngIf="obv.isEdit" [numberCharsTextArea]="300" [isFromTextArea]="true"
                      [isWithoutInfoIcon]="true" [isValued]="obv.text && obv.text.length" [isObbligatory]="false"
                      title="{{'defineNewObjectives.OBJECTIVE' | translate}}" [disabled]="isFinalStepCompleted"
                      id="competenceEvaluationNotes">
                      <alloy-text-area [bindValue]="obv.text" [maxlength]="300"
                        (onModelChanged)="onNewObjectiveChanged($event, obv)" [canCancelEdit]="true"
                        placeholder="{{'defineNewObjectives.INSERT_PLACEHOLDER' | translate}}"
                        [readonly]="isItemDisabled()" (saveEdit)="saveDefinedObjective(obv, false, true)"
                        (cancelEdit)="cancelDefinedEdit(obv)">
                      </alloy-text-area>
                    </input-container> -->
                  </ng-container>
                  <ng-container *ngFor="let obv of finalEvaluationList?.definedSubordinateUserGoals">
                    <goal-box *ngIf="!obv.isEdit" [desc]="obv.text" [hideMenu]="true"
                      [insertBy]="obv.creationUser.forename + ' ' + obv.creationUser.surname"
                      [menuOptions]="objectiveEditMenuItems" (onClicked)="onNewObjectiveMenuItemClicked($event, obv)">
                    </goal-box>

                    <!-- <input-container *ngIf="obv.isEdit" [numberCharsTextArea]="300" [isFromTextArea]="true"
                      [isWithoutInfoIcon]="true" [isValued]="obv.text && obv.text.length" [isObbligatory]="false"
                      title="{{'defineNewObjectives.OBJECTIVE' | translate}}" [disabled]="isFinalStepCompleted"
                      id="competenceEvaluationNotes">
                      <alloy-text-area [bindValue]="obv.text" [maxlength]="300"
                        (onModelChanged)="onNewObjectiveChanged($event, obv)" [canCancelEdit]="true"
                        placeholder="{{'defineNewObjectives.INSERT_PLACEHOLDER' | translate}}"
                        [readonly]="isItemDisabled()" (saveEdit)="saveDefinedObjective(obv, true, true)"
                        (cancelEdit)="cancelDefinedEdit(obv)">
                      </alloy-text-area>
                    </input-container> -->
                  </ng-container>
                  <ng-container
                    *ngIf="!finalEvaluationList?.definedSubordinateUserGoals && !finalEvaluationList?.definedManagerUserGoals">
                    <div class="placeholder">
                      <p>{{ 'defineNewObjectives.NO_PROPOSED_OBJECTIVES' | translate}}</p>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="isLoadingFinalEvaluationList || isLoadingDefinedObjectives">
                  <div class="loader-container">
                    <div class="loader spinner-big"></div>
                  </div>
                </ng-container>
              </div>

              <!-- Condivisione finale -->
              <div class="page-header white-bg" *ngIf="!isfinalShare && currentStep.id == 'Overall'">
                <div class="title-container">
                  <p class="title" translate="recap.FINAL_SHARE_TITLE"></p>
                  <p class="description" [innerHTML]="('recap.FINAL_SHARE_SUBTITLE_1' | translate) + ' ' + personDetails.forename +' ' +
                  ('recap.FINAL_SHARE_SUBTITLE_2' | translate)"></p>
                </div>
                <div class="confirm-share">
                  <p translate="recap.CHECK_TITLE"></p>
                  <div class="check-wrapper">
                    <alloy-check [label]="'recap.CHECK' | translate" (onCheck)="onInterviewCheck($event)"></alloy-check>
                  </div>
                </div>
              </div>


              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black" size="large" label="{{'generic.BACK' | translate}}"
                      (onButtonClicked)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow.gt-xs
                      fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [mobileShrink]="true" type="black with-just-left-icon" size="large" label=""
                      (onButtonClicked)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow
                      fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <!-- Termina -->
                    <alloy-button
                      *ngIf="!evaluationTabIsFinished && !isScheduleShared && currentStep.id == 'finalEvaluation'"
                      [disabled]="!(stepList[0].isComplete && stepList[1].isComplete)" type="primary fill" size="large"
                      label="{{'generic.TERMINATES' | translate}}" (onButtonClicked)="openTerminateEvaluationModal()">
                    </alloy-button>
                    <!-- Conferma -->
                    <alloy-button *ngIf="!isfinalShare && isScheduleShared && currentStep.id == 'Overall'"
                      [disabled]="!interviewCheck || !overAllSelectedItem.id" type="primary fill" size="large"
                      label="{{'generic.CONFIRM' | translate}}" (onButtonClicked)="openFinalShareModal()">
                    </alloy-button>
                    <!-- Salva e prosegui -->
                    <alloy-button
                      *ngIf="!isScheduleShared && currentStep.stepId != 5 && !thirdStepLocked && isGoalSettingActive"
                      [disabled]="false" type="primary fill" size="large" label="{{'generic.SAVE_AND_GO' | translate}}"
                      (onButtonClicked)="goToNextStep()" iconRight="/assets/img/icons/chevron-right.svg">
                    </alloy-button>

                    <!-- Continua -->
                    <alloy-button
                      *ngIf="isScheduleShared && currentStep.stepId != 5 && !thirdStepLocked && isGoalSettingActive"
                      [disabled]="false" type="primary fill" size="large" label="{{'generic.CONTINUE' | translate}}"
                      (onButtonClicked)="goToNextStep()" iconRight="/assets/img/icons/chevron-right.svg">
                    </alloy-button>
                    <!-- Proponi valutazione -->
                    <!-- <alloy-button *ngIf="isSendEvalBtnVisibleAndEnabled()" type="primary fill" size="large"
                      label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                      [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                      (onButtonClicked)="openSendFinalEvaluation()">
                    </alloy-button> -->
                    <!-- Condividi scheda -->
                    <!-- <alloy-button *ngIf="isConfirmShareBtnVisibleAndEnabled()" [disabled]="isLoadingFinalEvaluationData"
                      type="primary fill" size="large" label="{{'finalEvaluation.CONFIRM_SHARE' | translate}}"
                      (onButtonClicked)="openFinalShareModal()">
                    </alloy-button> -->
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 4 - Definizione nuovi obiettivi -->
            <ng-container *ngIf="currentStep.id == 'definingNewGoals'">

              <!-- Obiettivi da inserire lato Manager -->
              <div class="defining-new-goals-container">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="defineNewObjectives.ASSOGNMENT_OF_GOALS_TITLE"></p>
                    <p class="description" [innerHTML]="('defineNewObjectives.ASSOGNMENT_OF_GOALS_SUBTITLE_1' | translate) + personDetails.forename + ' ' +
                    ('defineNewObjectives.ASSOGNMENT_OF_GOALS_SUBTITLE_2' | translate)"></p>
                  </div>
                  <ng-container *ngIf="!isLoadingUserGoalToDefine; else stepLoader">
                    <!-- Obiettivi da inserire -->
                    <ng-container *ngIf="newObjectiveList && newObjectiveList.length">
                      <ng-container *ngFor="let objective of newObjectiveList">
                        <!-- [desc]="'prova desc più lunga e scritta a in hard'" -->
                        <goal-box *ngIf="!objective.isEdit" [desc]="objective.text" [hideMenu]="currentStep.isCompleted"
                          [menuOptions]="objectiveDefMenuItems" [hideMenu]="isfinalShare"
                          (onClicked)="onNewObjectiveMenuItemClicked($event, objective)">
                        </goal-box>

                        <input-container *ngIf="objective.isEdit" [numberCharsTextArea]="300" [isFromTextArea]="true"
                          [isWithoutInfoIcon]="true" [isValued]="objective.text && objective.text.length"
                          [isObbligatory]="false" title="{{'defineNewObjectives.OBJECTIVE' | translate}}"
                          [disabled]="isFinalStepCompleted" id="competenceEvaluationNotes">
                          <alloy-text-area [bindValue]="objective.text" [maxlength]="300"
                            (onModelChanged)="onNewObjectiveChanged($event, objective)" [canCancelEdit]="true"
                            placeholder="{{'defineNewObjectives.INSERT_PLACEHOLDER' | translate}}"
                            [readonly]="isItemDisabled()" (saveEdit)="saveDefinedObjective(objective)"
                            (cancelEdit)="cancelDefinedEdit(objective)">
                          </alloy-text-area>
                        </input-container>
                      </ng-container>
                    </ng-container>

                    <!-- Pulsante aggiungi un obiettivo -->
                    <alloy-button *ngIf="!isfinalShare" type="primary fill" size="medium" [disabled]="isGoalChanging"
                      label="{{'defineNewObjectives.ADD_OBJECTIVE' | translate}}" (onButtonClicked)="addNewObjective()">
                    </alloy-button>
                  </ng-container>
                </div>
                <!-- Obiettivi proposti dal collaboratore  -->
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="defineNewObjectives.ASSIGNMENT_OF_GOALS_TITLE_COLLAB"></p>
                    <p class="description" [innerHTML]="('defineNewObjectives.ASSIGNMENT_OF_GOALS_SUBTITLE_1_COLLAB' | translate) + personDetails.forename +
                    ('defineNewObjectives.ASSIGNMENT_OF_GOALS_SUBTITLE_2_COLLAB' | translate)"></p>
                  </div>
                  <ng-container *ngIf="!isLoadingUserGoalToDefine; else stepLoader">
                    <!-- Obiettivi proposti -->
                    <ng-container
                      *ngIf="selfAssessmentProposedObjectives && selfAssessmentProposedObjectives.length; else noSelfAssessmentObjectives">
                      <ng-container *ngFor="let objective of selfAssessmentProposedObjectives">
                        <!-- Obiettivi approvati -->
                        <ng-container *ngIf="!objective.goalToBeApproved">
                          <goal-box *ngIf="!objective.isEdit" [desc]="objective.text"
                            [hideMenu]="currentStep.isCompleted" [isNew]="objective.goalToBeApproved"
                            [menuOptions]="objectiveDefMenuItems" [hideMenu]="isfinalShare"
                            (onClicked)="onNewObjectiveMenuItemClicked($event, objective, true)">
                          </goal-box>
                        </ng-container>
                        <!-- Obiettivi da approvare -->
                        <ng-container *ngIf="objective.goalToBeApproved && !isfinalShare">
                          <goal-box *ngIf="!objective.isEdit" [desc]="objective.text" [menuOptions]="menuItems"
                            (onClicked)="onProposedMenuOptionClicked($event, objective, true)" [isNew]="true">
                          </goal-box>
                        </ng-container>
                        <!-- Edit obiettivo proposto -->
                        <input-container *ngIf="objective.isEdit" [numberCharsTextArea]="300" [isFromTextArea]="true"
                          [isWithoutInfoIcon]="true" [isValued]="objective.text && objective.text.length"
                          [isObbligatory]="false" title="{{'defineNewObjectives.OBJECTIVE' | translate}}"
                          [disabled]="isFinalStepCompleted" id="competenceEvaluationNotes">
                          <alloy-text-area [bindValue]="objective.text" [maxlength]="300"
                            (onModelChanged)="onNewObjectiveChanged($event, objective)" [canCancelEdit]="true"
                            placeholder="{{'defineNewObjectives.INSERT_PLACEHOLDER' | translate}}"
                            [readonly]="isItemDisabled()" (saveEdit)="saveDefinedObjective(objective, true)"
                            (cancelEdit)="cancelDefinedEdit(objective, true)">
                          </alloy-text-area>
                        </input-container>

                      </ng-container>
                    </ng-container>
                    <ng-template #noSelfAssessmentObjectives>
                      <div class="placeholder-step-phrase">
                        <p>{{ 'defineNewObjectives.NO_PROPOSED_OBJECTIVES' | translate}}</p>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black" size="large" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [mobileShrink]="true" type="black with-just-left-icon" size="large" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/chevron-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button type="primary fill" size="large" [disabled]="isSaveDefinedObjectivesStepDisabled()"
                      [label]="isfinalShare? ('generic.CONTINUE' | translate):('generic.SAVE_AND_GO' | translate)"
                      (onButtonClicked)="goToNextStep()" iconRight="/assets/img/icons/chevron-right.svg">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>
          </child-content>
        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container
          *ngIf="(isLoadingTab || isLoadingFinalEvaluationData || isLoadingCloseIntermediateEvaluation || isLoadingShareIntermediateEvaluation || isLoadingFinalEvaluationList || isLoadingFinalEvaluationData || isLoadingFinalShareModal) && (currentStep.id == 'finalEvaluation' || currentStep.id == 'Overall')">
          <div class="loader spinner-big"></div>
        </ng-container>
      </ng-container>
      <!-- Aggiornamento obiettivi -->
      <ng-container *ngIf="isGoalsUpdatePhase && !isNoteSection && !isFeedbackSection">
        <child-content *ngIf="!isLoadingGoalUpdates">
          <ng-container>
            <ng-container>
              <div class="page-header">
                <div id="firstStepPageHeader">
                  <div class="title-container">
                    <p class="title" translate="updateGoals.OBJECTIVE_ADVANCEMENT_TITLE"></p>
                    <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                      (click)="openHelpModal(currentStep.id)">
                    </svg-icon>
                    <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                      [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                    </svg-icon>
                  </div>
                  <p class="description" [innerHTML]="'updateGoals.OBJECTIVE_ADVANCEMENT_DESCR' | translate"></p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngFor="let objective of goalUpdatesData; let toUpdateItemIndex = index;">
              <text-goal [countUpdates]="objective.goalUpdates.length" [isNotificationUpdates]="true"
                [tagTitle]="'Obiettivo ' + (toUpdateItemIndex + 1)" [title]="objective.text" [isEvaluation]="true"
                (evaluationChanged)="onGoalsUpdateChanged($event, toUpdateItemIndex)" [showMenu]="false"
                [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.creationUser?.forename"
                [customPlaceholder]="('textGoal.INSERT_UPDATE_PLACEHOLDER' | translate)"
                [customTitle]="('textGoal.INSERT_UPDATE_TITLE' | translate)">
              </text-goal>
            </ng-container>
            <!-- title="{{'textGoal.INSERT_UPDATE_TITLE' | translate}}"> -->

            <div class="person-details-main-actions-banner" id="saveGoalToUpdateBtn">
              <div class="row page-content-wrapper">
                <div class="center-btn">
                  <ng-container>
                    <alloy-button [disabled]="!textExists" type="primary" size="medium"
                      label="{{'generic.SAVE_UPDATES' | translate}}"
                      (onButtonClicked)="openSaveGoalUpdatesForManagerModal()">
                    </alloy-button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </child-content>
        <ng-container *ngIf="isLoadingGoalUpdates">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </ng-container>

      <!-- I miei appunti -->
      <ng-container *ngIf="isNoteSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="!isLoadingPrivateNotes">

              <ng-container *ngIf="privateNotesList && privateNotesList.length">
                <!-- Titolo -->
                <div class="title-container">
                  <p class="title" translate="headerDossier.PRIVATE_NOTE"></p>
                </div>
                <p class="description" [innerHTML]="'headerDossier.FEEDBACK_DESC' | translate"></p>

                <!-- Filtri -->
                <div class="private-notes-filter" (click)="openPrivateNotesFiltersSection()"
                  (clickOutside)="openPrivateNotesFiltersSection(true)">
                  <p class="manage-filters">{{ orderPrivateNotesFilterSelected ||
                    ('privateNotes.filters.FILTER_LAST_YEAR' | translate)}}</p>
                  <ng-container *ngIf="!isPrivateNotesFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
                  </ng-container>
                  <ng-container *ngIf="isPrivateNotesFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
                  </ng-container>

                  <div class="action">
                    <ng-container *ngIf="isPrivateNotesFilterOpened">
                      <div class="dropdown-menu-table" [ngClass]="{'open-down': true}">
                        <ng-container *ngFor="let item of menuOrderYears">
                          <alloy-dropdown-item class="full-width" [item]="item"
                            (click)="onMenuPrivateNotesFilterListClicked(item)">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="card-list-container">
                  <!-- Lista appunti privati -->
                  <ng-container *ngIf="loggedUser">
                    <card-notes *ngFor="let privateNote of privateNotesList let i = index" [note]="privateNote"
                      [loggedUser]="loggedUser" (onEdit)="goToEditPrivateNote($event)"
                      (onDelete)="openDeleteNoteModal($event)"></card-notes>
                  </ng-container>
                </div>
                <!-- Pulsante
                <alloy-button [type]="'primary'" [label]="'feedbackSection.privateNotes.LABEL_BTN' | translate"
                  (onButtonClicked)="goToSmartFeedback()"></alloy-button> -->
              </ng-container>
              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                <no-data [title]="('feedbackSection.privateNotes.PRIVATE_NOTE_TITLE' | translate)"
                  [src]="'/assets/img/section/notes-smart-feedback-not-found.svg'"
                  [description]="('feedbackSection.privateNotes.PRIVATE_NOTE_SUBTITLE' | translate)">
                </no-data>
              </ng-container>
            </ng-container>
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </child-content>
      </ng-container>

      <!-- Feedback -->
      <ng-container *ngIf="isFeedbackSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="!isLoadingFeedback">
              <!-- Titolo -->
              <div class="title-container">
                <p class="title" translate="headerDossier.FEEDBACK"></p>
              </div>
              <p class="description" [innerHTML]="'headerDossier.FEEDBACK_DESC' | translate"></p>

              <!-- Filtri -->
              <!-- Filtri main-->
              <ng-container *ngIf="filterList && filterList.length">
                <div class="filters-main">
                  <div class="filters-container">
                    <div class="filters">

                      <div class="filter" *ngFor="let filter of filterList">
                        <p>{{filter.title}}</p>
                        <alloy-select [items]="filter.list" [selectedItem]="filter.selected"
                          (onSelectedItem)="selectedItem(filter, $event)"></alloy-select>
                      </div>
                    </div>
                    <!-- Divisore -->
                    <div class="divisor-line"></div>

                    <!-- footer dei filtri  -->
                    <div class="footer-filters">
                      <div class="counter-filters">
                        <p class="counter">{{filterCounter}}
                          <span class="text-counter"
                            translate="feedbackSection.smartFeedback.filters.ACTIVE_FILTERS"></span>
                        </p>
                        <p class="counter" id="results">{{feedbackList.length}}
                          <span class="text-counter" translate="feedbackSection.smartFeedback.filters.RESULTS"></span>
                        </p>
                      </div>

                      <!-- Filtro nella sezione a destra -->
                      <div class="right-section-filter" (click)="openFeedbackFiltersSection()"
                        (clickOutside)="openFeedbackFiltersSection(true)">
                        <p class="manage-filters">{{ orderFilterSelected ||
                          ('feedbackSection.smartFeedback.filters.ORDER_LATE_OR_RECENT' |
                          translate)}}</p>
                        <ng-container *ngIf="!isFeedbackFilterOpened">
                          <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true">
                          </svg-icon>
                        </ng-container>
                        <ng-container *ngIf="isFeedbackFilterOpened">
                          <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true">
                          </svg-icon>
                        </ng-container>

                        <div class="action">
                          <ng-container *ngIf="isFeedbackFilterOpened">
                            <div class="dropdown-menu-table" [ngClass]="{'open-down': true}">
                              <ng-container *ngFor="let item of menuOrderList">
                                <alloy-dropdown-item class="full-width" [item]="item"
                                  (click)="onMenuFilterListClicked(item)">
                                </alloy-dropdown-item>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="feedbackList && feedbackList.length">
                <div class="card-list-container">
                  <!-- Lista feedback -->
                  <ng-container *ngFor="let feedback of feedbackList let i = index">
                    <!-- Card peer feedback -->
                    <card-peer *ngIf="isPeerFeedback(feedback)" [object]="feedback" [loggedUser]="loggedUser"
                      (onFeedbackSend)="goToAnswerPeerFeedback(feedback.feedbackId)" [isPeopleAppraisal]="true"
                      [userIdSelected]="userId"></card-peer>
                    <!-- Card smart feedback -->
                    <card-smart *ngIf="isSmartFeedback(feedback)" [object]="feedback" [loggedUser]="loggedUser">
                    </card-smart>

                    <!-- Card feedback al manager -->
                    <card-feedback-to-manager *ngIf="isFeedbackToManager(feedback)" [object]="feedback"
                      [loggedUser]="loggedUser" [userIdSelected]="userId"></card-feedback-to-manager>

                    <!-- Card feedback 360 -->
                    <card-360 *ngIf="is360Feedback(feedback)" [object]="feedback" [loggedUser]="loggedUser"
                      [feedbackStatus]="feedback?.feedbackStatus || 'SENT'"
                      (onDetailsClicked)="goToFeedback360Details($event)"></card-360>
                  </ng-container>
                </div>
              </ng-container>
              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!feedbackList || !feedbackList.length">
                <no-data [title]="('feedbackSection.smartFeedback.FEEDBACK_TITLE' | translate)"
                  [src]="'/assets/img/section/notes-smart-feedback-not-found.svg'"
                  [description]="('feedbackSection.smartFeedback.FEEDBACK_SUBTITLE' | translate)">
                </no-data>
              </ng-container>
            </ng-container>
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedback">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </child-content>
      </ng-container>
    </div>
  </ng-container>
  <!-- Loader caricamento dati -->
  <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
  (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="wrap-container">
    <p class="modal-title black center" translate="dictionary.TITLE"></p>

    <div class="divisor-line"></div>

    <div class="container">
      <div *ngFor="let evaluation of goalsRatingScaleModalData; let i = index">
        <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="5" [readOnly]="true"
          [level]="(i)" [disabledFirstStar]="true" [valueFirstStarSub]="goalsRatingScaleModalData[0].text"
          [valueSecondStarSub]="goalsRatingScaleModalData[1].text"
          [valueThirdStarSub]="goalsRatingScaleModalData[2].text"
          [valueFourthStarSub]="goalsRatingScaleModalData[3].text"
          [valueFifthStarSub]="goalsRatingScaleModalData[4].text" [showTitles]="true">
        </evaluation-system>
        <p class="percentage">{{evaluation.range}}</p>
      </div>
    </div>
  </div>
</modal>

<!-- Modale scala di valutazione competenze -->
<!-- <modal id="managerial-competence" (onClose)="closeCompetencesModal(true)" (onConfirm)="closeCompetencesModal(true)"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="wrap-container">
    <p class="modal-title black center" translate="dictionary.TITLE"></p>
    <div class="divisor-line"></div>
    <div class="subtitle-container">
      <p class="type" translate="dictionary.MANAGERIAL"></p>
      <p class="title">{{competenceModal.title}}</p>
      <p class="subtitle" [innerHTML]="competenceModal.text"></p>
    </div>
    <div class="divisor-line"></div>
    <div class="container">
      <div *ngFor="let evaluation of [0,1,2,3,4]; let i = index">
        <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="5" [readOnly]="true"
          [level]="(i)" [disabledFirstStar]="true">
        </evaluation-system>
        <p class="percentage">{{getPercentageRange(i)}}</p>
      </div>
    </div>
  </div>
</modal> -->

<modal id="general-competences" confirmLabel="{{ 'generic.CLOSE' | translate}}" (onClose)="closeCompetencesModal()"
  (onConfirm)="closeCompetencesModal()">
  <div class="wrap-container">
    <p class="modal-title black center" translate="dictionary.TITLE"></p>
    <div class="divisor-line"></div>
    <div class="subtitle-container">
      <p class="type">{{( competenceModal.isManagerial ? "dictionary.MANAGERIAL": "dictionary.GENERAL") | translate }}
      </p>
      <p class="title">{{competenceModal.title}}</p>
      <p class="subtitle" [innerHTML]="competenceModal.text"></p>
    </div>

    <div class="divisor-line"></div>

    <div class="container">
      <ng-container *ngIf="competenceModal.scale && competenceModal.scale.length">
        <div *ngFor="let evaluation of competenceModal.scale; let i = index">
          <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="5" [readOnly]="true"
            [level]="i" [disabledFirstStar]="true" [competenceScale]="true"
            [valueFirstStarSub]="competenceModal.scale[0].text" [valueSecondStarSub]="competenceModal.scale[1].text"
            [valueThirdStarSub]="competenceModal.scale[2].text" [valueFourthStarSub]="competenceModal.scale[3].text"
            [valueFifthStarSub]="competenceModal.scale[4].text" [showTitles]="true">
          </evaluation-system>
        </div>
      </ng-container>

    </div>
  </div>
</modal>


<!-- Modale aggiornamento obiettivi-->
<modal id="update-added" (onClose)="closeUpdateModal()" (onConfirm)="closeUpdateModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <modal-text-content [title]="'updateGoals.modals.UPDATES_ADDED' | translate"
    [subtitle]="'updateGoals.modals.LIST_OF_UPDATES' | translate" [modalDataList]="modalDataList"
    [alignDefault]="modalDataList? true: false">
  </modal-text-content>
</modal>

<!-- Modale conferma aggiornamenti obiettivi -->
<modal id="confirm-update-added" (onCancel)="closeSaveGoalUpdatesForManagerModal()"
  (onClose)="closeSaveGoalUpdatesForManagerModal()" (onConfirm)="confirmSaveGoalUpdatesForManagerModal()"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}" [cancelLabel]="('generic.CANCEL' | translate)">
  <modal-text-content [title]="'updateGoals.modals.SAVE_UPDATES' | translate"
    [subtitle]="'updateGoals.modals.SAVE_UPDATES_SUBTITLE' | translate"
    [text]="'updateGoals.modals.SAVE_UPDATES_DESC' | translate">
  </modal-text-content>
</modal>
<!-- Conferma eliminazione obiettivo -->
<!-- <modal id="confirm-delete-saved-objective" (onClose)="closeDeleteSavedObjective()"
  (onConfirm)="closeDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal> -->

<!-- Modale che mostra le informazioni del Performer -->
<modal id="performerEvaluatorInfoModal" (onClose)="closePerformerModal()" [confirmLabel]="('generic.CLOSE' | translate)"
  (onConfirm)="closePerformerModal()">
  <performer-evaluator-info-modal-content [hideTitle]="true"
    [selectedInfoTabPerformerKey]="selectedInfoTabPerformerKey">
  </performer-evaluator-info-modal-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveObjectivesModal(false,true)" (onClose)="closeSaveObjectivesModal(true, true)"
  (onConfirm)="closeSaveObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione comportamenti -->
<modal id="save-behaviours-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveBehavioursModal(false,true)" (onClose)="closeSaveBehavioursModal(true, true)"
  (onConfirm)="closeSaveBehavioursModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione tech skill -->
<modal id="save-tech-skills-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveTechSkillsModal(false,true)" (onClose)="closeSaveTechSkillsModal(true, true)"
  (onConfirm)="closeSaveTechSkillsModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva riepilogo -->
<modal id="save-final-evaluation-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveFinalEvaluationModal(false,true)" (onClose)="closeSaveFinalEvaluationModal(true, true)"
  (onConfirm)="closeSaveFinalEvaluationModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_PAS_PROFILE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_PAS_PROFILE_DESC' | translate">
  </modal-text-content>
</modal>
<!-- Modale salva riepilogo -->
<modal id="private-notes" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closePrivateModal(false,true)"
  (onClose)="closePrivateModal(true, true)" (onConfirm)="closePrivateModal(true, false)"
  confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_PAS_PROFILE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_PAS_PROFILE_DESC' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-defined-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveDefinedObjectivesModal(false,true)" (onClose)="closeSaveDefinedObjectivesModal(true, true)"
  (onConfirm)="closeSaveDefinedObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-subordinate-saved-objective" (onClose)="closeSubordinateDeleteSavedObjective()"
  (onConfirm)="closeSubordinateDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>


<!-- Modale conferma invio HR -->
<modal id="final-share-modal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeFinalShareModal()"
  (onClose)="closeFinalShareModal()" (onConfirm)="closeFinalShareModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="fs001" [title]="'recap.CONFIRM_SHARE_TAB' | translate"
    [subtitle]="'recap.CONFIRM_SHARE_TAB' | translate" [text]="'recap.CONFIRM_FINAL_SHARE_DESC' | translate">
  </modal-text-content>
</modal>

<!-- Modale blocco condivisione finale -->
<modal id="cannot-share-modal" [confirmLabel]="('generic.CLOSE' | translate)" (onConfirm)="closeCannotShareModal()"
  (onClose)="closeCannotShareModal()">
  <modal-text-content modalId="fs002" [title]="'finalEvaluation.CANNOT_SHARE_TITLE' | translate"
    [text]="'finalEvaluation.CANNOT_SHARE_DESCR' | translate">
  </modal-text-content>
</modal>

<modal id="repropose-obj" (onClose)="closeReproposeObjModal()" [confirmLabel]="'defineObjectives.REPROPOSE' | translate"
  (onConfirm)="closeReproposeObjModal(true)" (onCancel)="closeReproposeObjModal()"
  [cancelLabel]="'generic.CANCEL' | translate"
  [isDisabled]="isLoadingReproposeObjData || (!reproposeObjData || !reproposeObjData.length) || (!selectedReproposeObj || !selectedReproposeObj.length)">
  <p class="modal-title center repropose-obj-title" translate='defineObjectives.REPROPOSE_OBJ'>
  </p>
  <div class="repropose-obj-modal-container">

    <ng-container *ngIf="isLoadingReproposeObjData">
      <div class="spinner-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingReproposeObjData">
      <ng-template #noData>
        <div class="no-result-container">
          <p translate="generic.NO_RESULTS"></p>
        </div>
      </ng-template>
      <ng-container *ngIf="reproposeObjData && reproposeObjData.length; else noData">
        <p class="info-text" translate="defineObjectives.REPROPOSE_INFO"></p>
        <p class="info-select" translate="defineObjectives.REPROPOSE_SELECT_OBJ"></p>
        <ng-container *ngFor="let reproposeObj of reproposeObjData">
          <!-- Checkbox -->
          <alloy-check [label]="reproposeObj.text" [checked]="reproposeObj.isSelected"
            (onCheck)="onReproposeObjChecked(reproposeObj)">
          </alloy-check>
          <!-- Inserito da una persona -->
          <ng-container *ngIf="reproposeObj.formattedCreationUser && reproposeObj.formattedCreationUser.length">
            <div class="full-width">
              <p class="inserted-by">{{ 'defineObjectives.INSERTED_BY' | translate }}
                <span class="bold-text">{{ ' ' + reproposeObj.formattedCreationUser }}</span>
              </p>
            </div>
          </ng-container>
          <!-- Inserito dal sistema -->
          <ng-container *ngIf="reproposeObj.enteredBySystem">
            <div class="full-width">
              <p class="inserted-by" translate="defineObjectives.ENTERED_BY_SYSTEM"></p>
            </div>
          </ng-container>
          <!-- Inserito dal manualmente -->
          <ng-container *ngIf="reproposeObj.manuallyEntered">
            <div class="full-width">
              <p class="inserted-by" translate="defineObjectives.MANUALLY_ENTERED"></p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</modal>

<!-- Modifica obiettivo -->
<modal id="edit-objective-modal" (onClose)="closeEditObjectiveModal()" [confirmLabel]="'generic.EDIT' | translate"
  (onConfirm)="closeEditObjectiveModal(true)" (onCancel)="closeEditObjectiveModal()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="custom-modal">
    <!-- Titolo, subtitolo-->
    <modal-text-content modalId="oed001" [title]="'finalEvaluation.modals.EDIT_SUB' | translate"
      [subtitle]="'finalEvaluation.modals.EDIT_SUB' | translate" text=''>
    </modal-text-content>
    <!-- Area di testo-->
    <ng-container *ngIf="editObjectiveData">
      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
        [isValued]="editObjectiveData.title && editObjectiveData.title.trim().length" [isObbligatory]="false">
        <alloy-text-area [bindValue]="editObjectiveData.title" [maxlength]="300"
          (onModelChanged)="onObjectiveEdit($event)" placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
        </alloy-text-area>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Elimina obiettivo -->
<modal id="delete-objective-modal" (onClose)="closeDeleteObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeDeleteObjectiveModal(true)"
  (onCancel)="closeDeleteObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.modals.DELETE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.DELETE_SUB' | translate" [text]="getDeleteModalText()">
  </modal-text-content>
</modal>

<!-- Elimina obiettivo proposto-->
<modal id="delete-objective-proposed-modal" (onClose)="closeDeleteObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeDeleteObjectiveModal(true, true)"
  (onCancel)="closeDeleteObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.modals.DELETE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.DELETE_SUB' | translate" [text]="getDeleteModalText()">
  </modal-text-content>
</modal>


<!-- Approva obiettivo -->
<modal id="approve-objective-modal" (onClose)="closeApproveObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeApproveObjectiveModal(true)"
  (onCancel)="closeApproveObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.modals.APPROVE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.APPROVE_SUB' | translate" [text]="getApproveModalText()">
  </modal-text-content>
</modal>

<modal id="competence-comment" cancelLabel="{{ commentReadOnly ? undefined : 'generic.ANNULL' | translate }}"
  confirmLabel="{{ (commentReadOnly ? 'generic.CLOSE' : 'generic.CONFIRM') | translate }}"
  (onClose)="closeCommentInsertion()" (onCancel)="closeCommentInsertion()" (onConfirm)="confirmCommentInsertion(true)">
  <div class="wrap-container">
    <p class="modal-title black center" *ngIf="!commentReadOnly" translate="evaluations.COMMENT_TITLE"></p>
    <p class="modal-title black center" *ngIf="commentReadOnly" translate="evaluations.COMMENTS"></p>
    <div class="divisor-line"></div>
    <div class="container align-self-start">
      <div class="modal-competence">
        <p class="competence-title">{{ "evaluations.COMPETENCE" | translate }}</p>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-bottom: 25px">
          <avatar-img *ngIf="personDetails" size="medium" [src]="personDetails?.userOptions?.avatarImage"
            [user]="personDetails">
          </avatar-img>
          <p class="competence-desc">{{ personDetails?.forename }} {{ personDetails?.surname }}</p>
        </div>

        <p class="competence-title" *ngIf="selectedCompetenceToComment && !commentReadOnly">{{
          selectedCompetenceToComment?.title }}</p>
        <p class="competence-desc" *ngIf="selectedCompetenceToComment && !commentReadOnly" style="margin-bottom: 25px"
          [innerHTML]="selectedCompetenceToComment?.text"></p>

        <p class="competence-title" *ngIf="competence && commentReadOnly">{{ competence?.title }}</p>
        <p class="competence-desc" *ngIf="competence && commentReadOnly" style="margin-bottom: 25px"
          [innerHTML]="competence?.text"></p>

        <ng-container *ngFor="let comment of competence?.comments">
          <div style="margin-bottom: 34px">
            <p class="competence-title" *ngIf="commentReadOnly">
              {{ "verifySend.COMMENT_BY" | translate }}{{ comment?.creationUser?.forename }} {{
              comment?.creationUser?.surname }}
              <span class="font-normal">del {{ comment?.creationDate | date:'dd/MM/yyyy' }}</span>
            </p>
            <p class="competence-desc" *ngIf="commentReadOnly" style="margin-bottom: 10px">{{ comment?.comment }}</p>

            <p class="comment-action"
              *ngIf="commentReadOnly && comment?.creationUser?.userId === loggedUser?.userId && !isScheduleShared && !evaluationTabIsFinished"
              (click)="confirmCommentDeletion({ comment: comment })" role="button">{{ "evaluations.DELETE_COMMENT" |
              translate }}</p>
          </div>
        </ng-container>

      </div>
      <input-container *ngIf="!commentReadOnly" title="{{ 'evaluations.COMMENT_TITLE' | translate}} *"
        [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true" [isObbligatory]="false">
        <alloy-text-area placeholder="{{ 'evaluations.COMMENT_DESC' | translate }}" [bindValue]="commentText"
          [white]="true" [maxlength]="300" (onModelChanged)="onCommentInsertionChange($event)">
        </alloy-text-area>
      </input-container>
    </div>
  </div>
</modal>

<modal id="feedback-list" confirmLabel="{{ 'generic.CLOSE' | translate }}" [fullPage]="true"
  (onClose)="closeFeedbackList()" (onConfirm)="closeFeedbackList()">
  <div class="wrap-container">
    <p class="modal-title black center" translate="evaluations.FEEDBACK_LIST_TITLE"></p>
    <div class="divisor-line"></div>
    <div class="subtitle-container">
      <p class="type">{{ selectedCompetence?.parentCompetence?.text }}</p>
      <p class="title">{{ selectedCompetence?.title }}</p>
      <p class="subtitle" [innerHTML]="selectedCompetence?.text"></p>
    </div>
    <div class="divisor-line"></div>
    <div class="container">
      <ng-container *ngFor="let feedback of selectedCompetence?.feedbacks ?? []; let last = last;">
        <evaluation-feedback [feedback]="feedback" [level]="feedback.evaluation!"></evaluation-feedback>
        <div class="divisor-line"></div>
      </ng-container>
      <!-- Media parziale dei Feedback -->
      <div class="feedback-wrapper"[ngClass]="{'onlyOne': countSelectedCompetence < 2}" fxLayout="row" fxLayoutAlign="space-between center" style="width: inherit">
        <div class="feedback-average">
          <div class="feedback-average__title">
            {{ "generic.PARTIL_AVG" | translate }}
          </div>
          <div class="feedback-average__subtitle">
            {{ "goalSetting.FEEDBACK" | translate }}
          </div>
        </div>
        <div class="feedback-average__system" fxLayout="column" fxLayoutAlign="center center">
          <ng-container *ngIf="averageSmartFeedbacks >= 0; else NAaverage">
            <evaluation-system direction="column" size="big" kind="default" [items]="5" [readOnly]="true"
              [level]="averageSmartFeedbacks" [disabledFirstStar]="true">
            </evaluation-system>
            <p class="number">{{ averageSmartFeedbacks }}</p>
          </ng-container>
          <!-- Media N/A -->
          <ng-template #NAaverage>
            <!-- Ha più senso non mostrare le stelline -->
            <!-- <evaluation-system direction="column" size="big" kind="default" [items]="5" [readOnly]="true" [level]="-1"
              [disabledFirstStar]="true" [showDescr]="false">
            </evaluation-system> -->
            <p class="number">N/A</p>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="divisor-line"></div>
  </div>
</modal>

<modal id="comment-delete" cancelLabel="{{ 'generic.ANNULL' | translate }}"
  confirmLabel="{{ 'generic.DELETE' | translate }}" (onClose)="closeCommentDeletion()"
  (onCancel)="closeCommentDeletion()" (onConfirm)="confirmCommentDeletion({ dryRun: true })">
  <div class="wrap-container">
    <div class="container align-self-start">
      <p class="competence-title">{{ "evaluations.DELETE_COMMENT" | translate }}</p>
      <p class="competence-desc" style="margin-bottom: 25px"> {{ "evaluations.DELETE_COMMENT_DESC" | translate }}</p>
    </div>
  </div>
</modal>
<!-- <modal id="terminate-evaluation" cancelLabel="{{ 'generic.ANNULL' | translate }}"
  confirmLabel="{{ 'generic.DELETE' | translate }}" (onClose)="closeTerminateEvaluationModal()"
  (onCancel)="closeTerminateEvaluationModal()" (onConfirm)="closeTerminateEvaluationModal(true)"
  [title]="'recap.TERMINATE' | translate">
  <div class="wrap-container">
    <div class="container align-self-start">
      <p class="terminate-evaluation-title">{{ "recap.CLOSE_TAB" | translate }}</p>
      <p class="terminate-evaluation-desc" style="margin-bottom: 25px"> {{ "recap.CLOSE_TAB_DESC" | translate }}</p>
    </div>
  </div>
</modal> -->

<!-- Modale termina valutazione -->
<modal id="terminate-evaluation" (onClose)="closeTerminateEvaluationModal()"
  (onCancel)="closeTerminateEvaluationModal()" (onConfirm)="closeTerminateEvaluationModal(true)"
  cancelLabel="{{ 'generic.ANNULL' | translate }}" confirmLabel="{{ 'recap.TERMINATE' | translate }}">
  <modal-text-content [title]="'recap.TERMINATE' | translate" [text]="'recap.CLOSE_TAB_DESC' | translate"
    [subtitle]="'recap.CLOSE_TAB' | translate">
  </modal-text-content>
</modal>

<!-- Modale per confermare e condividere -->
<modal id="share-intermediate" (onClose)="closeShareIntermediateEvaluationModal()"
  (onCancel)="closeShareIntermediateEvaluationModal()" (onConfirm)="closeShareIntermediateEvaluationModal(true)"
  cancelLabel="{{ 'generic.ANNULL' | translate }}" confirmLabel="{{ 'generic.CONFIRM' | translate }}">
  <modal-text-content [title]="'recap.CONFIRM_AND_SHARE' | translate"
    [text]="'recap.CONFIRM_AND_SHARE_DESC' | translate" [subtitle]="'recap.CONFIRM_AND_SHARE_SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Loader obiettivi step -->
<ng-template #stepLoader>
  <div class="loader spinner-medium"></div>
</ng-template>