import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, PerfCareApplauseFeedback, GetPerformanceCareTagsResponse, NoteTypes, PerfCareTag } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PerfAlloySmartFeedback } from 'atfcore-commonclasses';

@Component({
  selector: 'app-peopleAppraisal-operations-sendClap',
  templateUrl: './sendClap.component.html',
  styleUrls: ['./sendClap.component.scss']
})
export class SendClapComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any = {};
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  isFetchingCreateSmartFeedback: boolean = false;

  countMyCollegueUsers$: Subscription = new Subscription();
  loggedUser$: Subscription = new Subscription();
  loggedUser: any;



  smartFeedbackComment: string = "";

  modalUpdateClapTitle: string = '';
  modalUpdateClapText: string = '';
  modalUpdateClapTitleConfirmTextButton: string = '';
  modalUpdateClapTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;

  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  
  smartFeedbackModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  isLoadingTeam: boolean = false;
  userCardData: UserCard[] = [];
  sendClap$: Subscription = new Subscription();
  selectedUserList: any = [];

  // Ricerca utente
  searchUserText: string = '';

  // Guard
  alreadySaved: boolean = false;
  forceBack: boolean = false;
  modalUpdateId: string = '';
  modalUpdateTitle: any;
  modalUpdateText: any;
  modalUpdateTitleConfirmTextButton: any;
  modalUpdateTextCloseTextButton: any;
  smartFeedbackFromRecord: number = 0;
  smartFeedbackNumRecords: number = 0;
  smartFeedbackCurrentPage: number = 0;
  smartFeedbackCounter: number = 0;
  isFetchingSmartFeedbackUsers: boolean = false;
  chargeUsersVariable: boolean = true;
  isImpersonate: boolean = false;

  constructor(
    private sharedService: SharedService,
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router,
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                
                let subordinates = subordinatesList?.filter((x: any) => {
                  return !x.structureUser
                })

                this.personDetails = subordinates && subordinates.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                if (!this.userId && this.chargeUsersVariable) {
                  this.chargeUsersVariable = false;
                  this.resetSendClapUsersData();
                }
              });

              this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                if (loggedUser && loggedUser.user) {
                  this.loggedUser = loggedUser && loggedUser.user;
                }
              })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.getMyCollegueUsers()
    this.translate.get(
      [
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP',
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR',
        'feedbackSection.sendClapPage.modals.WHICH_CARE',
        'feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR',
      ])
      .subscribe(translations => {
        this.descriptionTooltipModal = {
          modalId: "401",
          title: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO'],
          subtitle: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB'],
          text: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR'],
        };
        this.whoTooltipModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        }
        this.smartFeedbackModal = {
          modalId: "c016",
          title: translations['feedbackSection.sendClapPage.modals.SEND_SMART_FEEDBACK'],
          text: translations['feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR'],
        };

        this.modalUpdateId = this.getModalSaveId();
        // this.modalUpdateTitle = this.getModalSaveTitle();
        // this.modalUpdateText = this.getModalSaveDescription();
        // this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelUpdateModal();
        this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
      })
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetSendClapUsersData(notDeleteSelected?: boolean) {
    this.smartFeedbackFromRecord = 0;
    this.smartFeedbackNumRecords = 10;
    this.smartFeedbackCurrentPage = 1;
    this.smartFeedbackCounter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  searchUsers() {
    this.getMyCollegueUsers(true);
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Recupera una lista di utenti per gli applausi

  getMyCollegueUsers(fromSearch?: boolean) {
    this.isFetchingSmartFeedbackUsers = true;
    this.peopleAppraisalService.countMyCollegueUsersForManager()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {

          this.smartFeedbackCounter = data.response;

          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.smartFeedbackCurrentPage && this.smartFeedbackNumRecords) {
            fromRecord = (this.smartFeedbackCurrentPage - 1) * this.smartFeedbackNumRecords;
          } else {
            fromRecord = 0;
          }

          if (this.smartFeedbackCounter) {
            this.peopleAppraisalService.listMyColleagueUsers(fromRecord, this.smartFeedbackNumRecords, this.searchUserText).subscribe
              ((data: SenecaResponse<any>) => {
                if (data && data.response) {
                  this.userCardData = UserDataUtils.getUserCardData(data.response) || [];
                }
                this.isFetchingSmartFeedbackUsers = false
              })
          }
        }
        else {
          this.isFetchingSmartFeedbackUsers = false;
        }
      }, (err?: any) => {
        this.isFetchingSmartFeedbackUsers = false;
      });
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  smartFeedbackUsersPageChanged(newPage: any) {
    this.smartFeedbackCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getMyCollegueUsers();
  }

  // onSelectCareCard(cards: any) {
  //   this.cardSelected = cards.filter((x: any) => {
  //     return x.isChecked == true;
  //   })[0];
  // }

  userCardId(index: number, el: UserCard) {
    return el.id;
  }

  // Descrizione applausa cambiata
  clapDescriptionChanged(text: string) {
    this.smartFeedbackComment = text;
  }

  goBack() {
    this.redirectService.goBackBrowser();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  // Invia lo smart feedback ad uno o più utenti
  sendSmartFeedback(closeModal?: boolean) {
    if (closeModal) {
      this.modalService.close('smartFeedback');
    }

    let usersIds: string[] = [];
    let smartFeedback: PerfAlloySmartFeedback[] = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      usersIds.push(this.selectedUserList[i].id);
      smartFeedback.push(
        {
          senderUserId: this.loggedUser.userId,
          recipientUserId: this.selectedUserList[i].id,
          smartComment: this.smartFeedbackComment
        })
    }

    this.peopleAppraisalService.createSmartFeedbackForManager(this.runningYear.year, this.runningYear.yearCode, smartFeedback, usersIds)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.router.navigate(['peopleAppraisal/home']);
        }
      })
  }  

  openSmartFeedbackModal() {
    this.modalService.open('smartFeedback');
  }

  closeSmartFeedbackModal() {
    this.modalService.close('smartFeedback');
  }

  // Se non hai inserito nessun dato
  noDataInserted() {
    // if (!this.clap.comment && (!this.cardSelected || !this.cardSelected.careId) && !this.userId && this.selectedUserList && !this.selectedUserList.length) {
    //   return true
    // }
    // return false;
    return true
  }

  checkObbligatoryData() {
    if (this.smartFeedbackComment) {
      if (!this.smartFeedbackComment || !this.smartFeedbackComment.trim().length) {
        return false;
      } else if (!this.userId && this.selectedUserList && !this.selectedUserList.length) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateModal() {
    this.modalUpdateId = this.getModalSaveId();
    // this.modalUpdateTitle = this.getModalSaveTitle();
    // this.modalUpdateText = this.getModalSaveDescription();
    // this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelUpdateModal();
    this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
    this.openModalForUpdate();
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdate() {
    this.modalService.open('modalUpdateForUser');
  }

  // Testo per il modale Salva obiettivo
  // getModalSaveTitle() {
  //   if (this.clapId && this.clapId.length) {
  //     return this.translate.instant('feedbackSection.sendClapPage.UPDATE_CLAP');
  //   } else {
  //     return this.translate.instant('feedbackSection.sendClapPage.SEND_CLAP');
  //   }
  // }

  // Testo per il modale Salva obiettivo
  // getModalSaveDescription() {
  //   if (this.clapId && this.clapId.length) {
  //     return this.translate.instant('feedbackSection.sendClapPage.WANT_TO_UPDATE');
  //   } else {
  //     return this.translate.instant('feedbackSection.sendClapPage.WANT_TO_CREATE');
  //   }
  // }

  // Testo modale pulsante conferma
  // getConfirmLabelUpdateModal(isBack?: boolean) {
  //   if (isBack) {
  //     return this.translate.instant('generic.SAVE_AND_GO_BACK');
  //   } else {
  //     if (this.clapId && this.clapId.length) {
  //       return this.translate.instant('feedbackSection.sendClapPage.YES_UPDATE');
  //     } else {
  //       return this.translate.instant('feedbackSection.sendClapPage.YES_CREATE');
  //     }
  //   }
  // }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.sendClap$) {
      this.sendClap$.unsubscribe();
    }
  }
}