<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="'Stai creando un nuovo round'"
            [description]="'Alla creazione di un novo round, questo verrà creato in stato di standby, si potrà modificare lo stato successivamente.'">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingCreateRound">
                <div class="form-container">
                    <!-- Nome rounde -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="startDate"
                        [isObbligatory]="roundFeedback360Data?.name ? false : true"
                        title="{{'process.phases.ROUND_NAME' | translate}}">
                        <alloy-input [bindValue]="roundFeedback360Data.name" (onModelChanged)="changeRoundName($event)"
                            placeholder="{{'process.phases.INSERT_ROUND_NAME' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!-- data inizio -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="roundFeedback360Data.startDate"
                        [isObbligatory]="roundFeedback360Data?.startDate ? false : true"
                        title="{{'process.phases.START_DATE_ROUND' | translate}}">
                        <alloy-datepicker [minDate]="yesterday" [value]="roundFeedback360Data.startDate"
                            (dateChanged)="startDateChanged($event)"
                            placeholder="{{'process.phases.ROUND_PLACEHOLDER_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- data fine -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="roundFeedback360Data.endDate"
                        [isObbligatory]="roundFeedback360Data?.endDate ? false : true"
                        title="{{'process.phases.END_DATE_ROUND' | translate}}">
                        <alloy-datepicker [minDate]="roundFeedback360Data.startDate"
                            [value]="roundFeedback360Data.endDate" (dateChanged)="endDateChanged($event)"
                            placeholder="{{'process.phases.ROUND_PLACEHOLDER_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- Stato del feedback -->
                    <input-container [isObbligatory]="false" [isWithoutInfoIcon]="true" [readonly]="true"
                        title="{{'process.phases.STATUS_ROUND' | translate}}">
                        <alloy-select [innerHtmlDescription]="true" (onSelectedItem)="changeSelectedStatus($event)"
                            [readonly]="true"
                            [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                            [disabled]="true" [selectedItem]="selectedFeedbackStatus" [items]="feedbackStatusList"
                            [loadingData]="isLoadingCreateRound" [searchable]="true" bindLabel="title"
                            placeholder="{{'process.phases.STATUS_PLACEHOLDER' | translate}}">
                        </alloy-select>
                    </input-container>

                    <div class="action-container">
                        <alloy-button class="m-auto" (onButtonClicked)="onConfirm()"
                            [disabled]="isLoadingCreateRound || !isPossibleToCreateRound()" type="primary fill"
                            size="large" [label]="'process.phases.actions.CREATE' | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingCreateRound">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="confirm-edit" (onClose)="closeConfirm()" [confirmLabel]="'generic.CONFIRM' | translate"
    [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeConfirm(true)" (onCancel)="closeConfirm()">
    <modal-text-content [modalId]="'ed001'" [title]="'process.phases.modals.CONFIRM_TITLE' | translate"
        [text]="'process.phases.modals.CONFIRM_DESCR' | translate">
    </modal-text-content>
</modal>