<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBack()" [isBackButton]="true"
      [title]="'peerFeedback.peopleAppraisal.createUpdate.SIDENAV_TITLE' | translate"
      [description]="'peerFeedback.peopleAppraisal.createUpdate.SIDENAV_DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingFeedback">
        <div class="form-container">
          <!-- Se sta facendo una richiesta di feedback -->
          <!-- Creazione -->
          <ng-container *ngIf="!isResponse">
            <!-- Step 1 -->
            <ng-container *ngIf="!isUserSelected">
              <!-- Utenti del mio team -->
              <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                [isValued]="selectedUser && selectedUser.id"
                [title]="'peerFeedback.createUpdate.WHO_TITLE_REQUEST' | translate">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchTeamUserText"
                  (onModelChanged)="changeSearchedTeamTextValue($event)" (onIconClicked)="searchTeamUsers()"
                  placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

                <div class="user-card-collection-container">
                  <!-- Card utente -->
                  <div *ngIf="!isLoadingTeamUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                    fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                    fxLayoutGap.lt-md="16px">
                    <user-card *ngFor="let user of teamUsersList.list | slice:0:teamUsersList.numRecords | paginate: { id: 'selectUser',
                              itemsPerPage: teamUsersList.numRecords,
                              currentPage: teamUsersList.page,
                              totalItems: teamUsersList.counter }" [user]="user" [isFeedbackCard]="true"
                      (onCheck)="selectTeamUser($event)">
                    </user-card>
                  </div>
                  <div *ngIf="isLoadingTeamUsers" class="full-width min-height500" fxLayout="row wrap"
                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                    <div class="loader spinner-medium"></div>
                  </div>
                  <div class="full-width"
                    *ngIf="!isLoadingTeamUsers && teamUsersList && teamUsersList.list && teamUsersList.list.length"
                    fxLayout="row" fxLayoutAlign="center center">
                    <table-pagination id="selectUser" (pageChanged)="usersTeamPageChanged($event)">
                    </table-pagination>
                  </div>
                </div>

              </input-container>
              <!-- Pulsante richiedi peerfeedback (primo step)-->
              <div class="button-container">
                <alloy-button (onButtonClicked)="clickedRequestFeedback(true)"
                  [disabled]="isLoadingFeedback || !isDisabled()" type="primary fill" size="large"
                  [label]="'peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST' | translate">
                </alloy-button>
              </div>
            </ng-container>

            <!-- Step 2 -->
            <ng-container *ngIf="isUserSelected">
              <!-- Utente singolo selezionato -->
              <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                [isValued]="selectTeamUser" [title]="'peerFeedback.createUpdate.WHO_TITLE_REQUEST' | translate">
                <div class="selected-user">
                  <user-card [user]="selectedUser" [noActions]="true" (onDelete)="openConfirmDeleteUser()"></user-card>
                </div>
              </input-container>
              <!-- user card -->
              <input-container [tooltipModalMessage]="whoUsersTooltipModal" [isObbligatory]="true"
                [isValued]="selectedUserList && selectedUserList.length"
                [title]="'peerFeedback.createUpdate.WHO_REQUEST' | translate">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                  (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                  placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

                <div class="user-card-collection-container">
                  <!-- Card utente -->
                  <div *ngIf="!isFetchingUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                    fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                    fxLayoutGap.lt-md="16px">
                    <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'feedbackUser',
                                  itemsPerPage: userCardData.numRecords,
                                  currentPage: userCardData.page,
                                  totalItems: userCardData.counter }" [user]="user"
                      [selectedUserList]="selectedUserList" [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                    </user-card>
                  </div>
                  <div *ngIf="isFetchingUsers" class="full-width min-height500" fxLayout="row wrap"
                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                    <div class="loader spinner-medium"></div>
                  </div>
                  <div class="full-width"
                    *ngIf="!isFetchingUsers && userCardData && userCardData.list && userCardData.list.length"
                    fxLayout="row" fxLayoutAlign="center center">
                    <table-pagination id="feedbackUser" (pageChanged)="usersPageChanged($event)">
                    </table-pagination>
                  </div>
                </div>

              </input-container>
              <ng-container>
                <!-- Obiettivo su cui è richiesto il feedback -->
                <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                  [tooltipModalMessage]="objectiveTitleModal" [isValued]="true"
                  title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                  <alloy-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                    [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                    [selectedItem]="selectedObjective" [items]="objectiveOptions"
                    [loadingData]="isLoadingGoalsToAssociate" [searchable]="true" bindLabel="title"
                    placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                  </alloy-select>
                </input-container>
              </ng-container>
              <!-- Messaggio di richiesta -->
              <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                [isValued]="requestMessage.length" [isObbligatory]="true"
                title="{{'peerFeedback.peopleAppraisal.createUpdate.INSERT_MESSAGE' | translate}}">
                <alloy-text-area [bindValue]="requestMessage" (onModelChanged)="requestMessageChanged($event)"
                  placeholder="{{'peerFeedback.peopleAppraisal.createUpdate.INSERT_MESSAGE_PLACEHOLDER' | translate}}">
                </alloy-text-area>
              </input-container>
              <!-- Pulsante richiedi peerfeedback -->
              <div class="button-container">
                <alloy-button (onButtonClicked)="goToUpdatePeerFeedback()"
                  [disabled]="isLoadingFeedback || !isDisabledSecondStep()" type="primary fill" size="large"
                  [label]="'peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST' | translate">
                </alloy-button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isLoadingFeedback">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un appunto -->
<!-- (onConfirm)="(feedbackId && feedbackId.length) ? updatePeerFeedback() : createPeerFeedback()" -->
<modal id="modalCreateUpdatePeerFeedback" (onCancel)="closeModalCreateUpdatePeerFeedback()"
  (onClose)="closeModalCreateUpdatePeerFeedback()" (onConfirm)="openConfrimRequest()"
  cancelLabel="{{modalCreateUpdatePeerFeedbackTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdatePeerFeedbackTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdatePeerFeedbackId" [title]="modalCreateUpdatePeerFeedbackTitle">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <!-- Subtitle -->
    <p class="subtitle-text" translate="peerFeedback.modals.REQUEST_FEEDBACK_SUBTITLE"></p>

    <ng-container *ngFor="let feedback of feedbackRecap">
      <div class="custom-container">
        <p class="title-modal-custom">{{feedback.title}}</p>
        <ng-container *ngIf="feedback.avatar">
          <div class="more-info-container" *ngIf="selectedUser && feedback.isUserSelected">
            <div class="user-container">
              <avatar-img [initials]="selectedUser.initials" [src]="selectedUser.avatar" size="small"></avatar-img>
              <p *ngIf="selectedUser.name && selectedUser.surname">{{ selectedUser.surname + ' ' + selectedUser.name |
                truncate: [22, '...']}}
            </div>
          </div>
          <div class="more-info-container" *ngIf="!feedback.isUserSelected">
            <ng-container *ngFor="let user of this.selectedUserList">
              <div class="user-container">
                <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
                <p *ngIf="user.name && user.surname">{{ user.surname + ' ' + user.name | truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <p *ngIf="!feedback.avatar" class="text-modal-custom">{{feedback.text}}</p>
      </div>
    </ng-container>
  </modal-text-content>
</modal>

<!-- Cancellazione utente selezionato -->
<modal id="confirm-remove-user" (onCancel)="closeConfirmDeleteUser()" (onClose)="closeConfirmDeleteUser()"
  (onConfirm)="closeConfirmDeleteUser(true)" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content modalId="delPl001" [title]="'peerFeedback.modals.DELETE_USER_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_USER_DESC' | translate">
  </modal-text-content>
</modal>