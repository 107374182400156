import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import * as moment from 'moment';
import { KrukOpenAPI, User } from 'atfcore-commonclasses';

@Component({
  selector: 'app-teamFeedback360-details',
  templateUrl: './teamFeedback360Details.component.html',
  styleUrls: ['./teamFeedback360Details.component.scss']
})
export class TeamFeedback360DetailsComponent implements OnInit, OnDestroy {
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  isImpersonate: boolean = false;
  isLoadingGoalsRatingScaleModal: boolean = false;
  getGoalsRatingScaleModal$: Subscription = new Subscription();
  goalsRatingScaleModalData: Array<{
    score: number,
    text: string,
    range: string
  }> = [];

  competenceDictionary: Array<{ scale: number, scaleTagId: string, text: string }> | any = [];

  strengthTooltip: ApplicationModalMessage = {
    modalId: "stri360",
    text: "TEST",
    title: "Indica i punti di forza",
  }
  improveTooltip: ApplicationModalMessage = {
    modalId: "stri360",
    text: "TEST",
    title: "Indica i punti di forza",
  }

  strengthsComment: string = "";
  improvementComment: string = "";

  feedbackId: string = '';
  userId: string = '';
  isLoadingFeedbackDetails: boolean = false;
  getFeedbackDetails$: Subscription = new Subscription();


  competenceArray: any;
  competenceArrayCopy: any;
  competenceForLegendModal?: {
    title: string, text: string, isManagerial?: boolean, scale: {
      scale: number,
      scaleTagId: string,
      text: string
    }[]
  } | null = null;
  tempCompetenceUpdated: { competenceEvaluation?: { competenceId: string, evaluationRating: number }[] } = { competenceEvaluation: [] };
  isLoadingFeedbackData: boolean = false;
  getFeedbackData$: Subscription = new Subscription();
  softSkillsFinalAvg: any;
  parentBehaviourIds: any;
  recipientUser: KrukOpenAPI.KrukUser | undefined = undefined;
  intermediateUser: KrukOpenAPI.KrukUser | undefined = undefined;
  senderUser: KrukOpenAPI.KrukUser | undefined = undefined;
  commentDate: string = '';
  isAlreadyShared: any;


  constructor(public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
        this.getGoalsRatingScaleModal();

        this.route.params
          .subscribe((params: Params) => {
            this.feedbackId = params.feedbackId;
            this.userId = params.userId;

            this.getFeedbackDetails();

          })
      })
  }


  ngOnInit(): void {
  }

  getGoalsRatingScaleModal() {
    this.isLoadingGoalsRatingScaleModal = true;
    if (this.getGoalsRatingScaleModal$) {
      this.getGoalsRatingScaleModal$.unsubscribe();
    }

    this.getGoalsRatingScaleModal$ = this.peopleAppraisalService.getPerformanceAssessmentGoalsRatingScaleForManager()
      .subscribe((data: SenecaResponse<Array<{
        score: number,
        text: string,
        range: string
      }>>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalsRatingScaleModal = false;
        } else {
          this.goalsRatingScaleModalData = data.response;
          this.isLoadingGoalsRatingScaleModal = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsRatingScaleModal = false;
      });
  }

  getFeedbackDetails() {
    this.isLoadingFeedbackDetails = true;

    if (this.getFeedbackDetails$) {
      this.getFeedbackDetails$.unsubscribe();
    }

    this.getFeedbackDetails$ = this.peopleAppraisalService.getFeedback360DetailsForManager(this.feedbackId)
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackDetails = false;
        } else {
          this.recipientUser = data.response.recipientUser;
          this.intermediateUser = data.response.intermediateUser;
          this.senderUser = data.response.senderUser;
          this.commentDate = data.response.commentDate ? moment(data.response.commentDate).format('DD/MM/YYYY') : '';

          let ratingCounter = 0;
          let ratingSum = 0;
          let ratingDoneCounter = 0;

          if (data.response && data.response.competences) {
            let parentBehaviours = data.response.competences.map((x: any) => {
              return {
                competenceId: x.parentCompetence.competenceId,
                title: x.parentCompetence.text
              }
            })
            this.parentBehaviourIds = parentBehaviours.map((item: any) => item.competenceId)
              .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
            let tempBehaviour: any = {};
            for (let i = 0; i < this.parentBehaviourIds.length; i++) {
              for (let j = 0; j < data.response.competences.length; j++) {
                let behavior = data.response.competences[j];
                if (behavior.parentCompetence && (behavior.parentCompetence as any).competenceId == this.parentBehaviourIds[i]) {
                  if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
                    tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
                  } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
                    tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
                  }
                }
              }
            }
            this.competenceArray = [];
            // Assegno la scala da inserire nella modale
            this.competenceDictionary = data.response.competences[0].evaluationScale;

            const mappedCompetences = this.groupBy<KrukOpenAPI.PerfAlloyCompetence>(
              data.response.competences,
              a => (a.parentCompetence as any).text
            )


            // const partiaCompetenceslAvg = sumAllCompetences / competences.length;
            for (const [key, val] of mappedCompetences.entries()) {

              const sumPartialCompetences = val.reduce((acc, comp) => {
                if (comp.evaluationRating !== -1) {
                  acc += comp.evaluationRating!;
                }
                return acc
              }, 0);
              const countLenght = val.filter(el => el.evaluationRating !== -1);
              const partiaCompetenceslAvg = sumPartialCompetences / countLenght.length;


              this.competenceArray.push({
                category: key,
                competences: val,
                ratingScale: 5,
                partiaCompetenceslAvg: partiaCompetenceslAvg,
                partiaCompetenceslAvgRound: Math.round(partiaCompetenceslAvg)
              })
            }


            const sumFinalAvg = this.competenceArray.reduce((acc: any, curr: any) => {
              const competencesSum: number = curr.competences.map((comp: any) => comp.evaluationRating != null && comp.evaluationRating != undefined && comp.evaluationRating >= 0 ? comp.evaluationRating : undefined)
                ?.filter(Boolean)
                ?.reduce((a: number, b: number) => a + b, 0);

              acc += competencesSum;
              return acc
            }, 0);

            const divider = this.competenceArray.reduce((acc: any, curr: any) => {
              curr.competences.forEach((comp: any) => comp.evaluationRating != null && comp.evaluationRating != undefined && comp.evaluationRating >= 0 ? acc += 1 : acc += 0)

              return acc;
            }, 0)

            const final = sumFinalAvg / divider


            // Media finale
            let ratingAvgNum;
            let ratingAvgString;

            ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;

            const ratingEvalScale = this.competenceArray && this.competenceArray[0] && this.competenceArray[0].evaluationScale && this.competenceArray[0].evaluationScale.length || 5;

            ratingAvgString = ratingAvgNum.toString();
            if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
              ratingAvgString = ratingAvgString.replace(".", ",");
            }
            // Indica se tutti i comportamenti hanno avuto almeno una valutazione
            let allBehavioursValuated = this.allCompetencesEval();

            // Media competenze
            this.softSkillsFinalAvg = {
              allBehavioursValuated: allBehavioursValuated,
              title: this.translate.instant('generic.FINAL_AVG'),
              subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
              ratingAvgNum: final,
              ratingStars: final,
              ratingStarsRounded: Math.round(final),
              ratingAvgString: ratingAvgString,
              ratingScale: ratingEvalScale
            }
          }


          if (data.response.overallComment) {
            this.strengthsComment = (data.response.overallComment as any)?.areaOfStrength || '';
            this.improvementComment = (data.response.overallComment as any)?.areaOfImprovement || '';
          }
          if (this.competenceArray) {
            this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
          } else {
            this.competenceArrayCopy = undefined;
          }

          this.isAlreadyShared = data.response.sharedWithTarget;
          this.isLoadingFeedbackDetails = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackDetails = false;
      });
  }

  public groupBy<V>(list: V[], keyGetter: (input: V) => string): Map<string, V[]> {
    const map = new Map<string, V[]>();

    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);

      !collection ? map.set(key, [item]) : collection.push(item);
    });

    return map;
  }

  openConfrimShareFeedback() {
    this.modalService.open('confirm-share');
  }

  closeConfrimShareFeedback(confirm?: boolean) {
    this.modalService.close('confirm-share');
    if (confirm) {
      this.shareFeedback();
      this.goBackBrowser();
    }
  }

  allCompetencesEval() {
    let allEval: boolean[] = [];
    this.competenceArray.forEach((cat: any) => {
      const a = cat?.competences.every((c: any) => c["evaluationRating"] >= -1);
      allEval.push(a)
    })
    return allEval.every(v => v);
  }

  // condivide il feedback con l'utente
  shareFeedback() {
    this.isLoadingFeedbackData = true;
    this.peopleAppraisalService.shareFeedback360ToUserForManager(this.feedbackId)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackDetails = false;
        } else {

        }
      }, (err?) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackDetails = false;
      })
  }

  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  openCompetenceLegendModal(competence?: any) {
    if (competence) {
      this.competenceForLegendModal = { title: competence.title, text: competence.text, scale: competence.evaluationScale };
    }
    this.modalService.open("common-competence");
  }

  closeCompetenceLegendModal() {
    this.competenceForLegendModal = null;
    this.modalService.close("common-competence");
  }

  getSidebarDescription() {
    if (this.intermediateUser && this.recipientUser && this.commentDate) {
      return this.translate.instant("360Feedback.people.MANAGER_SIDEBAR_DESCR_1") + this.intermediateUser.forename + ' ' + this.intermediateUser.surname +
        this.translate.instant("360Feedback.people.MANAGER_SIDEBAR_DESCR_2") + this.recipientUser.forename + ' ' + this.recipientUser.surname +
        this.translate.instant("360Feedback.people.MANAGER_SIDEBAR_DESCR_3") + this.commentDate +
        this.translate.instant("360Feedback.people.MANAGER_SIDEBAR_DESCR_4");
    }
    return "";
  }

  ngOnDestroy() {
    if (this.getFeedbackDetails$) {
      this.getFeedbackDetails$.unsubscribe();
    }
  }
}
