<page-container class="process">

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="(processId ? 'process.create.SIDENAV_TITLE_UPDATE' : 'process.create.SIDENAV_TITLE_CREATE') | translate"
            [description]="(processId ? 'process.create.SIDENAV_DESCR_UPDATE' : 'process.create.SIDENAV_DESCR_CREATE') | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingProcess">
                <div class="form-container">
                    <!-- Nome del processo -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="processName && processName.trim().length"
                        [isObbligatory]="true" title="{{'process.create.NAME' | translate}}">
                        <alloy-input [disabled]="this.processId ? true : false" [bindValue]="processName"
                            (onModelChanged)="nameChanged($event)"
                            placeholder="{{'process.create.NAME_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!-- Anno di riferimento -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true"
                        [isValued]="selectedSemester && selectedSemester.id"
                        title="{{'process.create.SEMESTER' | translate}}">
                        <alloy-select [readonly]="this.processId ? true : false"
                            [placeholder]="'process.create.SEMESTER_PLACEHOLDER' | translate"
                            (onSelectedItem)="changeSemester($event)" [selectedItem]="selectedSemester"
                            [items]="semesterOptions" bindLabel="title"></alloy-select>
                    </input-container>
                    <!-- Stato del processo -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [readonly]="!processId"
                        [isValued]="selectedStatus" [isObbligatory]="true"
                        title="{{'process.create.STATUS' | translate}}">
                        <!-- <alloy-input [disabled]="this.processId ? true : false" [bindValue]="processStatus"
                            placeholder="{{'process.create.NAME_PLACEHOLDER' | translate}}">
                        </alloy-input> -->

                        <alloy-select [readonly]="this.processId ? false : true"
                            [placeholder]="'process.create.NAME_PLACEHOLDER' | translate"
                            (onSelectedItem)="changeStatus($event)" [selectedItem]="selectedStatus"
                            [items]="statusOptions" bindLabel="title"></alloy-select>
                    </input-container>

                    <div class="action-container">
                        <alloy-button class="m-auto" (onButtonClicked)="createUpdate()" [disabled]="isLoadingProcess || selectedStatusCopy?.id == 'HISTORY'"
                            type="primary fill" size="large"
                            [label]="(processId && processId?.length) ? ('process.create.UPDATE' | translate) : ('process.create.CREATE' | translate)">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingProcess">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="create-process" (onClose)="closeCreateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeCreateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeCreateProcess()">
    <modal-text-content [title]="'process.create.CREATE' | translate"
        [text]="'process.create.CREATE_MODAL_DESCR' | translate"></modal-text-content>
</modal>

<modal id="update-process" (onClose)="closeUpdateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeUpdateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeUpdateProcess()">
    <modal-text-content [title]="'process.create.UPDATE' | translate"
        [text]="'process.create.UPDATE_MODAL_DESCR' | translate"></modal-text-content>
</modal>