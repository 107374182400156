/* Classi utili alla gestione dei componenti, riprendono il nome del componente che le usa */

export class TabDossier {
  id: string = '';
  title: string = '';
  attrAriaLabel: string = '';
  isDisabled: boolean = true;
  badgeNumberVisible?: boolean;
  badgeNumber?: number;
  goalTypes?: any[];
  noSti?: boolean;
  badgeGrey?: boolean;
  isEditObjective?: boolean;
}

export class UserCard {
  id: string = '';
  initials: string = '';
  avatar: string = '';
  name?: string;
  surname?: string;
  isChecked: boolean = false;
}

export class TabSection {
  id: string = '';
  title: string = '';
  attrAriaLabel?: string = '';
  isDisabled?: boolean = true;
  isActive?: boolean = false;
  isCompleted?: boolean = false;
}