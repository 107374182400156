import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { KrukOpenAPI, SenecaResponse } from 'atfcore-commonclasses';
import { Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-feedback360-round-admin',
  templateUrl: './feedback360-round-admin.component.html',
  styleUrls: ['./feedback360-round-admin.component.scss']
})
export class Feedback360RoundAdminComponent {

  isLoadingRoundForFeedback360: boolean = false;
  getRoundForFeedback360$: Subscription = new Subscription;
  roundForFeedback360Data:
    {
      numRecords: number,
      page: number,
      counter: number,
      list: Array<KrukOpenAPI.PerfAlloyFeedback360Round>
    } = { numRecords: 0, page: 0, counter: 0, list: [] }
  countRounds$: Subscription = new Subscription();

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]

  selectedRows: { id: number, title: number };

  constructor(private adminService: AdminService,
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.selectedRows = this.rowPerPageOptions[2];
    this.getRoundForFeedback360();
  }

  ngOninit() {
    this.getRoundForFeedback360();
  }

  goToFeedback360RoundCreation() {
    this.router.navigate(['/admin/feedback360Round/create/']);
  }

  getRoundForFeedback360() {
    //Avvio il loader
    this.isLoadingRoundForFeedback360 = true;

    if (this.countRounds$) {
      this.countRounds$.unsubscribe();
    }
    this.countRounds$ = this.adminService.countFeedback360Rounds()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          const countRounds = data.response;
          if (this.getRoundForFeedback360$) {
            this.getRoundForFeedback360$.unsubscribe();
          }

          this.getRoundForFeedback360$ = this.adminService.listFeedback360Rounds(undefined, undefined, this.roundForFeedback360Data.page, this.selectedRows.id)
            .subscribe((data: SenecaResponse<Array<KrukOpenAPI.PerfAlloyFeedback360Round>>) => {
              if (data.response) {
                this.roundForFeedback360Data = {
                  numRecords: countRounds,
                  page: 0,
                  counter: 0,
                  list: data.response
                }
                this.isLoadingRoundForFeedback360 = false;
              } else {
                const messageObj: ApplicationModalMessage = {
                  modalId: "da001",
                  text: this.translate.instant("errors." + data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingRoundForFeedback360 = false;
              }
            })
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundForFeedback360 = false;
        }
      }, (err: any) => {
        this.isLoadingRoundForFeedback360 = false;
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        return throwError(new Error(err.message));
      });
  }

  goToFeedback360Round(idRound?: string) {
    this.router.navigate(['/admin/feedback360Round/edit/' + idRound]);
  }

  processPageChanged(page: number) {
    this.roundForFeedback360Data.page = page;
    this.roundForFeedback360Data.list = [];
    this.getRoundForFeedback360();
  }

  getDateFromUTC(dateUTC?: Date) {
    return moment(dateUTC).format("DD/MM/YYYY");
  }

  // cambia il numero di record recuperati
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.selectedRows.id = 2;
    this.getRoundForFeedback360();
  }
}
