import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KrukOpenAPI, PerfAlloy360Feedback, SenecaResponse, User } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription, combineLatest } from 'rxjs';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-request360-feedback',
  templateUrl: './request360-home-feedback.component.html',
  styleUrls: ['./request360-home-feedback.component.scss']
})
export class Request360FeedbackHomeComponent implements OnInit {
  userId: string = '';
  combinedSelected$: Subscription = new Subscription();

  loggedUser!: User;
  requestList: KrukOpenAPI.PerfAlloyFeedback[] = [];
  isLoadingRequestList: boolean = false;
  getRequestList$: Subscription = new Subscription();
  requestCounter: number = 0;
  currentPage: number = 1;
  numRecords: number = 10;
  getFeedbackRunningRound$: Subscription = new Subscription();
  feedbackRunningRound: KrukOpenAPI.PerfAlloyFeedback360Round | null = null;
  isLoadingRoundList: boolean = true;
  feedbackRoundList: KrukOpenAPI.PerfAlloyFeedback360Round[] = [];
  roundId: string = '';
  selectedRound: KrukOpenAPI.PerfAlloyFeedback360Round | undefined = undefined;

  constructor(private translate: TranslateService,
    private selfAssessmentService: SelfAssessmentService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,) {

    const getLoggedUser$ = this.store.select(fromApp.getLoggedUser);
    const getFeedbackRunningRound$ = this.store.select(fromApp.getFeedbackRunningRound)
    const getFeedbackRoundList$ = this.store.select(fromApp.getFeedbackRoundList)
    this.combinedSelected$ = combineLatest(([getLoggedUser$, getFeedbackRunningRound$, getFeedbackRoundList$])).subscribe(([loggedUser, feedbackRunningRound, feedbackRoundList]) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser.user;
      }
      if (feedbackRunningRound) {
        this.feedbackRunningRound = feedbackRunningRound;
      }
      this.feedbackRoundList = feedbackRoundList;
      this.route.params
        .subscribe((params: Params) => {
          this.roundId = params.roundId;

          if (this.roundId) {
            this.selectedRound = this.feedbackRoundList.find((round: any) => round.roundId == this.roundId);
          } else if (this.feedbackRunningRound) {
            this.selectedRound = this.feedbackRunningRound;
          }

          this.isLoadingRoundList = false;
          this.isLoadingRequestList = true;
          if (this.selectedRound && this.selectedRound.roundId) {
            this.getRequestList();
          }
        });
    });
  }


  ngOnInit(): void {
  }

  createPrivateNote() {
    this.redirectService.goToCreatePrivateNote();
  }

  changeSelectedRound(round: any) {
    let previous = null;
    if (this.selectedRound) {
      previous = this.selectedRound.roundId;
    }
    this.selectedRound = round;
    let url = this.router.url.split(('/' + previous))[0];
    if (this.selectedRound) {
      const newUrl: string = url + '/' + this.selectedRound.roundId;
      this.router.navigate([newUrl])
    }
  }

  getRequestList() {
    if (!this.selectedRound || !this.selectedRound.roundId) {
      return;
    }
    this.isLoadingRequestList = true;

    if (this.getRequestList$) {
      this.getRequestList$.unsubscribe();
    }
    this.getRequestList$ = this.selfAssessmentService.listFeedback360RequestForUser(this.selectedRound.roundId)
      .subscribe(
        (data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback[]>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "360fe003",
              title: this.translate.instant("generic.WARNING"),
              text: this.translate.instant("errors." + data.error)
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          } else {
            this.requestList = data.response || [];
          }
          this.isLoadingRequestList = false
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe004",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRequestList = false;
        });
  }


  goToRequest(feedbackId: string, complete?: boolean) {
    if (complete) {
      this.router.navigate(['/selfAssessment/requested360Feedback/detail/' + feedbackId]);
    } else {
      this.router.navigate(['/selfAssessment/requested360Feedback/request/' + feedbackId]);
    }
  }

  isCurrentRound() {
    return this.selectedRound && this.feedbackRunningRound && this.selectedRound?.roundId == this.feedbackRunningRound?.roundId;
  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.getRequestList$) {
      this.getRequestList$.unsubscribe();
    }
  }

}