import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { SelfAssessmentComponent } from './selfAssessment.component';
import { SelfAssessmentRoutingModule } from './selfAssessment-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { selfAssessmentReducer } from './ngrx/selfAssessment.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SelfAssessmentEffects } from './ngrx/selfAssessment.effects';
import { Subordinateservice } from '../peopleAppraisal/services/subordinates.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { SelfAssessmentService } from '../shared/services/selfAssessment.service';
import { SelfAssessmentPersonDetailsComponent } from './personDetails/personDetails.component';
import { SelfAssessmentOrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewSelfAssessmentGuardService } from './services/can-deactivate-update-interview-selfAssessment-guard.component';
import { SendClapSelfAssessmentComponent } from './sendClap/sendClap.component';
import { CanDeactivateUpdateClapSelfAssessmentGuardService } from './services/can-deactivate-update-clap-selfAssessment-guard.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { PrivateNoteGuard } from '../shared/services/private-note-guard.service';
import { FeedbackToManagerComponent } from './feedback-to-manager/feedback-to-manager.component';
import { TechnicalAssessmentComponent } from './technicalAssessment/technicalAssessment.component';
import { UpwardFeedbackComponent } from './upwardFeedback/upward-feedback.component';
import { PersonalDevelopmentGuard } from '../shared/services/personal-development-guard.service';
import { Receive360FeedbackComponent } from './receive360-feedback/receive360-feedback.component';
import { Request360FeedbackHomeComponent } from './request360-home-feedback/request360-home-feedback.component';
import { Request360FeedbackComponent } from './request360-feedback/request360-feedback.component';


@NgModule({
  declarations: [
    SelfAssessmentComponent,
    SelfAssessmentPersonDetailsComponent,
    SelfAssessmentOrganizeInterviewComponent,
    SendClapSelfAssessmentComponent,
    CreateUpdateEvidenceComponent,
    FeedbackToManagerComponent,
    Receive360FeedbackComponent,
    Request360FeedbackHomeComponent,
    Request360FeedbackComponent,
    TechnicalAssessmentComponent,
    UpwardFeedbackComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SelfAssessmentRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('selfAssessment', selfAssessmentReducer),
    EffectsModule.forFeature([SelfAssessmentEffects])
  ],
  providers: [
    Subordinateservice,
    SelfAssessmentService,
    PersonDetailsGuard,
    CanDeactivateUpdateInterviewSelfAssessmentGuardService,
    CanDeactivateUpdateClapSelfAssessmentGuardService,
    PrivateNoteGuard,
    PersonalDevelopmentGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class SelfAssessmentModule { }
