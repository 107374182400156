<div class="page-content-wrapper">
    <!-- Toaster -->
    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>


    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="('calibration.FEEDBACK_360' | translate)"
        [adminBack]="true" [hasTabs]="false" [adminPageSubtitle]="('calibration.FEEDBACK_360_SUBTITLE' | translate)"
        [adminActionLabel]="('calibration.CREATE_NEW_ROUND' | translate)" [isAdminMenu]="false"
        (onAdminAction)="goToFeedback360RoundCreation()" adminActionLabel="assets/img/icons/file/xls.svg"
        adminActionLabelType="primary fill"></header-dossier>


    <div class="main-content-wrapper admin">
        <!-- Home processi -->
        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingRoundForFeedback360">
            <div class="admin-table-container">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th translate="{{ ('adminTable.ROUND_NAME' | translate)}}"></th>
                                <th translate="{{ ('adminTable.OPENING_DATE' | translate)}}"></th>
                                <th translate="{{ ('adminTable.CLOSING_DATE' | translate)}}"></th>
                                <th class="small-col" translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                                <th class="small-col" translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let round of roundForFeedback360Data.list | 
                                    slice:0:roundForFeedback360Data.numRecords | 
                                    paginate: { id: 'processList',
                                                itemsPerPage: roundForFeedback360Data.numRecords,
                                                currentPage: roundForFeedback360Data.page,
                                                totalItems: roundForFeedback360Data.counter }">
                                <td>{{ round.name }}</td>
                                <td>{{ getDateFromUTC(round.startDate) || 'NO_DATA' }}</td>
                                <td>
                                    {{ getDateFromUTC(round.endDate) || 'NO_DATA' }}
                                </td>
                                <td>
                                    <ng-container *ngIf="round.roundStatus == 'STANDBY'">
                                        <item-list-team-status type="inProgress"
                                            [message]="'adminTable.status.STANDBY' | translate" styleItem="fill">
                                        </item-list-team-status>
                                    </ng-container>
                                    <ng-container *ngIf="round.roundStatus == 'RUNNING'">
                                        <item-list-team-status type="completed"
                                            [message]="'adminTable.status.RUNNING' | translate" styleItem="fill">
                                        </item-list-team-status>
                                    </ng-container>
                                    <ng-container *ngIf="round.roundStatus == 'CLOSED'">
                                        <item-list-team-status type="disabled"
                                            [message]="'adminTable.status.CLOSED' | translate" styleItem="fill">
                                        </item-list-team-status>
                                    </ng-container>
                                </td>
                                <td class="detail" (click)="goToFeedback360Round(round.roundId)">
                                    {{ 'adminTable.ACCESS' | translate }}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </alloy-select>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- loader -->
        <ng-container *ngIf="isLoadingRoundForFeedback360">
            <div class="loader-container">
                <div class="loader spinner-big"></div>
            </div>
        </ng-container>
    </div>
</div>