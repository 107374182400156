import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as PeopleAppraisalActions from "../ngrx/peopleAppraisal.actions";
import { Subscription, combineLatest } from 'rxjs';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { KrukOpenAPI, SenecaResponse, User } from 'atfcore-commonclasses';
import { GuidedTourService } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { take } from 'rxjs/operators';
import { FeedbackUser } from 'src/app/utils/user-data.utils';

@Component({
  selector: 'app-peopleAppraisal-team-feedback360',
  templateUrl: './teamFeedback360.component.html',
  styleUrls: ['./teamFeedback360.component.scss']
})
export class TeamFeedback360Component implements OnInit, OnDestroy {
  personDetails: FeedbackUser | undefined = undefined;
  isLoadingPersonDetails = true;
  routeParams$!: Subscription;
  userId: string = '';
  loggedUser$: any;

  feedbackSubordinates$: Subscription = new Subscription();
  isImpersonating: boolean = false;
  loggedUser!: User;
  isLoadingMySubordinateUserData: boolean = false;
  isLeadershipTeam: boolean = false;

  userForRequest: KrukOpenAPI.PerfAlloySuggestedUserForFeedback360 | undefined;

  requestedList: KrukOpenAPI.PerfAlloyFeedback[] = [];
  proposedList: KrukOpenAPI.PerfAlloySuggestedUserForFeedback360[] = [];

  isLoadingFeedback360RoundList: boolean = true;
  feedbackRoundList: KrukOpenAPI.PerfAlloyFeedback360Round[] = [];
  feedbackRunningRound: KrukOpenAPI.PerfAlloyFeedback360Round | null = null;
  feedbackSubordinates: FeedbackUser[] = [];
  combineSelected$: Subscription = new Subscription();
  isLoadingManagerFeedback: boolean = false;
  listFeedback360ForManager$: Subscription = new Subscription();

  selectedRound: KrukOpenAPI.PerfAlloyFeedback360Round | null = null;
  roundId: string = '';
  listSuggestedUserFeedback360$: Subscription = new Subscription();
  isLoadingSuggestedList: boolean = false;
  showImpersonification$: Subscription = new Subscription();
  translations: any;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.isLoadingPersonDetails = true;
    this.showImpersonification$ = this.store.select(fromApp.getShowImpesonificaitonBanner)
      .pipe(take(1))
      .subscribe(isImpersonating => {
        this.isImpersonating = isImpersonating;
      });

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
        if (!this.isLoadingMySubordinateUserData) {
          this.isLoadingMySubordinateUserData = true;
        }
      }
    })

    this.routeParams$ = this.route.params
      .subscribe(
        (params: Params) => {
          this.userId = params.userId;
          this.roundId = params.roundId;
          if (!this.roundId) {
            this.store.dispatch(CoreActions.GetFeedbackRoundData({ payload: null }));
            this.store.select(fromApp.getFeedbackRunningRound).subscribe((feedbackRunningRound: KrukOpenAPI.PerfAlloyFeedback360Round | undefined) => {
              if (feedbackRunningRound) {
                this.feedbackRunningRound = feedbackRunningRound;
                this.selectedRound = this.feedbackRunningRound;
                this.store.dispatch(PeopleAppraisalActions.SetFeedbackSelectedRound({ payload: this.selectedRound }));
                this.store.dispatch(PeopleAppraisalActions.RetrieveFeedbackSubordinates({ roundId: this.feedbackRunningRound?.roundId }));
              }
            })
          } else {
            this.store.dispatch(PeopleAppraisalActions.RetrieveFeedbackSubordinates({ roundId: this.roundId }));
          }

          const getFeedbackSubordinates$ = this.store.select(fromApp.getFeedbackSubordinates)
          const getFeedbackRoundList$ = this.store.select(fromApp.getFeedbackRoundList)

          this.combineSelected$ = combineLatest([
            getFeedbackSubordinates$,
            getFeedbackRoundList$,
          ]).subscribe(([feedbackSubordinates, feedbackRoundList]) => {
            this.isLoadingPersonDetails = true;
            // recupero dati del feedback
            this.feedbackSubordinates = feedbackSubordinates;
            this.feedbackRoundList = feedbackRoundList;

            this.isLoadingFeedback360RoundList = false;

            if (this.feedbackRoundList && this.feedbackRoundList.length && this.feedbackSubordinates && this.feedbackSubordinates.length) {
              this.isLoadingPersonDetails = true;
              if (this.feedbackSubordinates && this.userId) {
                this.personDetails = this.feedbackSubordinates.find((user: any) => user.userId == this.userId);
                // Se non lo trovo potrei aver cambiato round e l'utente che avevo selezionato potrebbe non aver partecipato,
                // quindi scelgo il primo utente della lista
                if (!this.personDetails) {
                  this.personDetails = this.feedbackSubordinates[0];
                }
                this.isLoadingPersonDetails = false;
              } else {
                this.personDetails = this.feedbackSubordinates[0];
                this.userId = this.personDetails && this.personDetails.userId;
                this.isLoadingPersonDetails = false;
              }

              if (this.feedbackRoundList) {
                this.selectedRound = this.feedbackRoundList.find((round: any) => round.roundId == this.roundId) || null;
                this.feedbackRunningRound = this.feedbackRoundList.find((round: any) => round.roundStatus == "RUNNING") || null;
              }
              if (!this.selectedRound) {
                this.selectedRound = this.feedbackRunningRound;
              }
              this.store.dispatch(PeopleAppraisalActions.SetFeedbackSelectedRound({ payload: this.selectedRound }));
              this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));

              if (this.selectedRound && this.selectedRound.roundId && this.personDetails && this.personDetails.userId) {
                this.getFeedbackData();
              }
              this.personDetails = JSON.parse(JSON.stringify(this.personDetails));
            }

          });
        });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.OF'
      ])
      .subscribe(translations => {
        this.translations = translations;
      });
  };


  changeSelectedRound(round: any) {
    if (this.selectedRound && this.personDetails) {
      let previous = this.selectedRound.roundId;
      this.selectedRound = round;
      this.store.dispatch(PeopleAppraisalActions.SetFeedbackSelectedRound({ payload: round }));
      let url = this.router.url.split(('/' + (this.personDetails.userId + '/' + previous)))[0];
      const newUrl: string = url + '/' + this.personDetails.userId + '/' + round.roundId;
      this.router.navigate([newUrl])
    }
  }

  listFeedback360ForManager() {
    if (!this.selectedRound || !this.selectedRound.roundId) {
      return;
    }
    this.isLoadingManagerFeedback = true;
    if (this.listFeedback360ForManager$) {
      this.listFeedback360ForManager$.unsubscribe();
    }

    this.listFeedback360ForManager$ = this.peopleAppraisalService.listFeedback360ForManager(this.userId, this.selectedRound.roundId)
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe009",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.requestedList = data.response;
        }
        this.isLoadingManagerFeedback = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe010",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerFeedback = false;
      });
  }

  // Recupera le richieste proposte e quelle inviate
  getFeedbackData() {
    this.listFeedback360ForManager();
    this.listSuggestedUserFeedback360();
  }

  listSuggestedUserFeedback360() {
    if (!this.selectedRound || !this.selectedRound.roundId) {
      return;
    }
    this.isLoadingSuggestedList = true;

    if (this.listSuggestedUserFeedback360$) {
      this.listSuggestedUserFeedback360$.unsubscribe();
    }

    this.listSuggestedUserFeedback360$ = this.peopleAppraisalService.listSuggestedCollaboratorsFeedback360ForManager(this.selectedRound.roundId, this.userId)
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloySuggestedUserForFeedback360[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe009",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.proposedList = data.response;
        }
        this.isLoadingSuggestedList = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe010",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingSuggestedList = false;
      });
  }

  // Richiedi feedback
  goToRequestFeedback() {
    if (!this.selectedRound || !this.selectedRound.roundId) {
      return;
    }
    this.router.navigate(["peopleAppraisal/teamFeedback360Request/", this.userId, this.selectedRound.roundId]);
  }

  // Richiedi feedback ad utente proposto
  requestFeedbackToUser(user: any) {
    this.userForRequest = user;
    this.modalService.open('confirm-request-to-user');
  }

  goToFeedbackDetails(feedback: any) {
    this.router.navigate(['peopleAppraisal/teamFeedback360Details/', this.userId, feedback.feedbackId]);
  }


  closeRequestToUser(confirm?: boolean) {
    this.modalService.close('confirm-request-to-user')
    if (confirm) {
      if (!this.userForRequest || !this.userForRequest.userId) {
        return;
      }
      this.isLoadingManagerFeedback = true;
      this.isLoadingSuggestedList = true;

      this.peopleAppraisalService.createFeedback360ForManager([this.userForRequest.userId], this.userId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "360fe007",
              title: this.translate.instant("generic.WARNING"),
              text: this.translate.instant("errors." + data.error)
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingManagerFeedback = false;
            this.isLoadingSuggestedList = false;
          } else {
            if (this.selectedRound && this.selectedRound.roundId) {
              this.store.dispatch(PeopleAppraisalActions.RetrieveFeedbackSubordinates({ roundId: this.selectedRound && this.selectedRound.roundId }))
            }
            this.getFeedbackData();
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + ((err && err.message) || err))
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingManagerFeedback = false;
          this.isLoadingSuggestedList = false;
        })
    }
  }

  createPrivateNote() {
    if (this.personDetails) {
      this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, '');
    }
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbarPersonDetails");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }

  canAddRequest() {
    return this.requestedList && this.requestedList.length < 8
  }

  isRunningRound() {
    return this.selectedRound && this.feedbackRunningRound && this.selectedRound.roundId == this.feedbackRunningRound.roundId;
  }

  ngOnDestroy(): void {
    if (this.combineSelected$) {
      this.combineSelected$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.showImpersonification$) {
      this.showImpersonification$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.feedbackSubordinates$) {
      this.feedbackSubordinates$.unsubscribe();
    }
    if (this.listFeedback360ForManager$) {
      this.listFeedback360ForManager$.unsubscribe();
    }
    if (this.listSuggestedUserFeedback360$) {
      this.listSuggestedUserFeedback360$.unsubscribe();
    }
  }
}