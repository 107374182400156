import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'evaluation-system',
  templateUrl: './evaluation-system.component.html',
  styleUrls: ['./evaluation-system.component.scss']
})
export class EvaluationSystemComponent implements OnInit {
  /**
   * @ignore  
   * Array di dati(evaluation-item) presenti 
   */
  public data!: any[];

  /**
   * @ignore  
   * Indica il numero di item selezionati
   */
  public index: number | null | undefined = null;

  /**
   * Imposta se gli evalution-item e messaggio devono essere visti in orizzontale o verticale
  */
  @Input() direction!: "row" | "column";

  /**
   * Imposta gli evaluation-item da settare con active a true
   */
  @Input() level!: number | null | undefined;  //"0 | 1 | 2 | 3 | 4"

  /**
   * Imposta la dimensione degli evaluation-item
   */
  @Input() size!: "big" | "small";

  /**
   * Imposta l'aspetto
   */
  @Input() aspect!: "manager" | "collaborator";

  /**
  * Imposta l'icona da mostrare per la valutazione
  */
  @Input() kind: string = "default";

  /**
  * Numero di item presenti 
  */
  @Input() items!: 1 | 2 | 3 | 4 | 5 | 6

  /**
   *  Testo se selezionato item 0
   */
  @Input() valueZeroStar?: string;

  /**
   *  Testo se selezionato item 0
   */
  @Input() valueZeroStarSub?: string;

  /**
   *  Testo se selezionato solo primo item
   */
  @Input() valueFirstStar!: string;

  /**
   *  Testo se selezionato solo primi 2 item
   */
  @Input() valueSecondStar!: string;

  /**
  *  Testo se selezionato solo primi 3 item
  */
  @Input() valueThirdStar!: string;

  /**
   *  Testo se selezionato solo primi 4 item
   */
  @Input() valueFourthStar!: string;

  /**
  *  Testo se selezionato tutti gli item
  */
  @Input() valueFifthStar!: string;

  /**
   *  Testo se selezionato solo primo item
   */
  @Input() valueFirstStarSub!: string;

  /**
   *  Testo se selezionato solo primi 2 item
   */
  @Input() valueSecondStarSub!: string;

  /**
  *  Testo se selezionato solo primi 3 item
  */
  @Input() valueThirdStarSub!: string;

  /**
   *  Testo se selezionato solo primi 4 item
   */
  @Input() valueFourthStarSub!: string;

  /**
  *  Testo se selezionato tutti gli item
  */
  @Input() valueFifthStarSub!: string;

  /**
  *  Deseleziona la prima stella 
  */
  @Input() disabledFirstStar = false;

  /**
  *  Rende tutto il componente readOnly
  */
  @Input() readOnly = false;

  /**
   * Visualizzazione label non disponibile (N/A)
   */
  @Input() isNotApplicable?: boolean = false;
  /**
     * Possibilità di valutare (N/A)
     */
  @Input() canValueNA?: boolean = false;
  /**
  *  Testo se selezionato N/A come "valutazione"
  */
  @Input() valueNA!: string;
  /**
  *  Testo se selezionato N/A come "valutazione"
  */
  @Input() valueNASub!: string;
  /**
  *  Mostra nome della valutazione
  */
  @Input() showTitles = false;
  /**
    *  Mostra descrizione della valutazione
    */
  @Input() showDescr = true;
  /**
   * Definisce quali label usare per le scale di valutazione
   */
  @Input() competenceScale: boolean = false;
  /**
   * Usa lo stile della descrizione per mostrare i titoli della scala,
   * utile es nella valutazione obiettivi (1 step)
   */
  @Input() titleStyleAsDescription: boolean = false;

  /**
  *  Invia il numero di stelle selezionate 
  */
  @Output() onButtonClicked: EventEmitter<-1 | 1 | 2 | 3 | 4 | 5> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.index = this.level !== undefined && this.level !== null ? this.level : undefined;
  }

  /**
   *
   * @ignore
   * @param changes Oggetto con i valori cambiati
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.direction == undefined)
      this.direction = "column";


    this.data = [];
    for (let item = 0; item < this.items; item++) {
      // cambio kind del value N/A
      if (this.level == -1 && item == 5) {
        this.data.push({
          active: this.level == -1,
          disabled: this.disabledFirstStar ? true : false,
          kind: 'na'
        });
      } else {
        this.data.push({
          active: this.level !== null && this.level !== undefined && this.level >= 0 && this.level >= item ? true : false,
          disabled: this.disabledFirstStar && item == 0 ? true : false,
          kind: this.kind == 'default' ? 'star' : ''
        });
      }

      if (this.level == null || this.level == undefined || this.level > 0) {
        this.data[0].active = false
      }
    }
    // Durante l'inizializazione, quando ho finito di creare gli oggetti, se il livello è -1 (N/A)
    // modifico active e kind per rispecchiare la valutazione
    if (this.canValueNA && this.level == -1) {
      this.data[5].active = true
      this.data[5].kind = 'na';
    }

    this.index = this.level;
  }

  /**
   * Viene inviato un evento al padre, se il pulsante è disabilitato l'evento non viene lanciato.
   * @param event 
  */
  emitOnClick(index: number) {
    if (!this.readOnly) {
      // Gestione stato N/A
      if (index == -1) {
        // la posizione dentro a data è al 5 posto
        this.data[5].active = true;
        for (let i = 0; i < this.items - 1; i++) {
          this.data[i].active = false;
        }
      } else {
        for (let i = 0; i < this.items; i++) {
          this.data[i].active = index >= i ? true : false;
          this.data[0].active = index == 0 ? true : false;
        }
      }
      this.index = index;
    }
    this.onButtonClicked.emit(this.index as -1 | 1 | 2 | 3 | 4 | 5);
  }
}
