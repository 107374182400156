<div class="page-content-wrapper matrix hide-matrix">

  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="('calibration.TITLE' | translate)"
    [adminPageSubtitle]="('calibration.COMPRATION_MATRIX' | translate)" [adminBack]="true" [hasTabs]="false"
    [counter]="totalUsers" [counterLabel]="'calibration.SELECTED_USERS' | translate">
  </header-dossier>

  <div class="search-wrapper">
    <div class="search-btn-wrapper">
      <!-- Ricerca testuale -->
      <alloy-input (onIconClicked)="filterList()" typeInput="text" [bindValue]="searchedText"
        (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
        [placeholder]="'generic.SEARCH_BY' | translate" [isSearchBar]="true" [disabled]="isLoadingUsers">
      </alloy-input>
      <!-- Download report [disabled]="isDownloadingReport || isLoadingUsers" -->
      <alloy-button type="primary link" size="medium" iconLeft="assets/img/icons/file/xls.svg" label="Report"
        (onButtonClicked)="downloadReport()" [disabled]="isDownloadingReport || isLoadingUsers"></alloy-button>
      <!-- Scarica simulazione [disabled]="isDownloadingSimulationReport || isLoadingUsers" -->
      <alloy-button type="primary link" size="medium" iconLeft="assets/img/icons/file/xls.svg"
        [disabled]="isDownloadingSimulationReport || isLoadingUsers"
        label="{{ 'evaluations.DOWNLOAD_SIMULATION' | translate  }}" (onButtonClicked)="downloadSimulationReport()">
      </alloy-button>
    </div>

    <!-- Distribuzione -->
    <div class="distribution-box">
      <div class="label-wrapper">
        <p translate="calibration.BOX_DISTRIBUTION"></p>
        <!--svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer" [applyClass]="true"
          (click)="openBoxDistributionTooltip()">
        </svg-icon-->
      </div>
      <div class="percentage-wrapper" *ngIf="!isLoadingUsers">
        <div class="box box-1">{{ grayBox }}%</div>
        <div class="box box-2">{{ yellowBox }}%</div>
        <div class="box box-3">{{ greenBox }}%</div>
      </div>
    </div>
  </div>

  <div>
    <ng-container *ngIf="isLoadingUsers">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingUsers">

      <div class="matrix-lock">
        <svg-icon src="assets/img/icons/lock.svg" class="locker" [applyClass]="true"></svg-icon>
      </div>

      <!-- Nessun dato trovato -->
      <ng-container *ngIf="noData">
        <div class="center-container">
          <p translate="calibration.NO_VALID_USER"></p>
        </div>
      </ng-container>

      <!-- Lista dei dati -->
      <ng-container *ngIf="!noData">
        <div class="ninebox-calibration-wrapper">
          <div class="levels-wrapper">
            <div class="box-level">
              <p translate="evaluations.LOW"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.DEVELOPING"></p>
            </div>
            <div class="box-level yellow">
              <p translate="evaluations.SOLID"></p>
            </div>
            <div class="box-level green">
              <p translate="evaluations.ADVANCE"></p>
            </div>
            <div class="box-level green">
              <p translate="evaluations.EXCEPTIONAL"></p>
            </div>
          </div>
          <div class="boxes-wrapper">
            <div class="columns-wrapper">
              <ng-container *ngFor="let key of keyList; let last = last">
                <!-- Contenuto colonna -->
                <!-- [cdkDropListData]="matrixData[key]" [cdkDropListConnectedTo]="matrixData[key]"-->
                <div class="box-ninebox" cdkDropListOrientation="vertical" cdkDropList
                  (cdkDropListDropped)="drop($event, key)" [cdkDropListData]="matrixData[key]"
                  [cdkDropListConnectedTo]="getConnectedList(key)" id="{{ 'column-' + key }}">
                  <!-- Placeholder nessun utente-->
                  <ng-container *ngIf="!matrixData[key] || !matrixData[key].length">
                    <p class="no-data-box" translate="calibration.NO_ONE_PRESENT"></p>
                  </ng-container>

                  <ng-container *ngIf="matrixData[key] && matrixData[key].length">
                    <div class="tag-action-container" *ngFor="let user of matrixData[key]; trackBy trackByUserId"
                      cdkDrag [cdkDragData]="user" (cdkDragStarted)="setSelected(user)">

                      <!-- Card utente -->
                      <div id="{{ user.userId }}" class="tag-user" cdkDragHandle
                        [ngClass]="{'new': user.edited, 'sent': user.postCalibrationSent}">
                        <!-- Cognome e nome -->
                        <div class="name-container">
                          <p class="name">{{ user.surname || '' }}</p>
                          <p class="name">{{ user.forename || ''}}</p>
                        </div>
                        <!-- Delta -->
                        <ng-container *ngIf="!user.postCalibrationSent">
                          <div class="delta-container">
                            <!-- Delta positivo -->
                            <div (click)="openUserMenu(user)" (clickOutside)="closeUserMenu(user)"
                              class="delta-counter green" *ngIf="user.deltaValueAbs <= 0.5">
                              <p>{{ (user.deltaValue > 0 ? '+' : '') + user.deltaValue }}</p>
                            </div>
                            <!-- Delta 0 -->
                            <div (click)="openUserMenu(user)" (clickOutside)="closeUserMenu(user)"
                              class="delta-counter green" *ngIf="user.deltaValue === 0 ">
                              <p>{{ user.deltaValue }}</p>
                            </div>
                            <!-- Delta negativo -->
                            <div (click)="openUserMenu(user)" (clickOutside)="closeUserMenu(user)"
                              class="delta-counter red" *ngIf="user.deltaValueAbs > 0.5">
                              <p>{{ (user.deltaValue > 0 ? '+' : '') + user.deltaValue }}</p>
                            </div>
                            <div class="delta-label" [ngClass]="{'red': user.edited }">
                              <p>{{ user.status }}</p>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.postCalibrationSent">
                          <div class="delta-container">
                            <svg-icon src="assets/img/icons/lock.svg" [applyClass]="true"></svg-icon>
                            <div class="delta-label">
                              <p>{{ 'evaluations.SHARED' | translate }}</p>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                      <ng-container *ngIf="user.isMenuOpen">
                        <div class="user-info-tooltip" [ngClass]="{ 'last-item' : key && key === 'EXCEPTIONAL' }">
                          <div class="user-info-container">
                            <avatar-img [user]="user" size="small"></avatar-img>
                            <p class="full-name">{{ user.surname + ' ' + user.forename }}</p>
                          </div>

                          <div class="details-container">
                            <!-- Stato -->
                            <div class="row-info-container">
                              <p class="desc" translate="evaluations.STATUS"></p>
                              <div class="status">
                                <p>{{ user.status }}</p>
                              </div>
                            </div>
                            <!-- Delta -->
                            <div class="row-info-container">
                              <p class="desc" translate="evaluations.DELTA"></p>
                              <!-- Delta positivo -->
                              <div class="cursor-default delta-counter green" *ngIf="user.deltaValueAbs <= 0.5">
                                <p>{{ (user.deltaValue > 0 ? '+' : '') + user.deltaValue }}</p>
                              </div>
                              <!-- Delta 0 -->
                              <div class="cursor-default delta-counter green" *ngIf="user.deltaValue === 0 ">
                                <p>{{ user.deltaValue }}</p>
                              </div>
                              <!-- Delta negativo -->
                              <div class="cursor-default delta-counter red" *ngIf="user.deltaValueAbs > 0.5">
                                <p>{{ (user.deltaValue > 0 ? '+' : '') + user.deltaValue }}</p>
                              </div>
                            </div>
                            <!-- Appraisal-->
                            <div class="row-info-container">
                              <p class="desc" translate="evaluations.APPRAISAL"></p>
                              <div>
                                <p>{{ user.appraisalValue }}</p>
                              </div>
                            </div>
                            <!-- Self assessment-->
                            <div class="row-info-container">
                              <p class="desc" translate="evaluations.SELFASSESSMENT"></p>
                              <div>
                                <p>{{ user.selfAssessmentlValue }}</p>
                              </div>
                            </div>
                          </div>

                          <div class="btn-actions">
                            <p translate="generic.CLOSE" class="close"></p>
                            <p (click)="impersonate(user.userId)" translate="generic.DETAIL" class="detail"></p>
                            <p (click)="goToVerifySend(user.userId)" translate="evaluations.SEND_SCHEDULE"
                              class="send-schedule"></p>
                          </div>

                          <div class="down-arrow"></div>
                        </div>
                      </ng-container>
                    </div>

                  </ng-container>
                </div>
                <div *ngIf="!last" class="separator"></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="action-container">
          <!-- Salva (cioè cambia lo stato degli utenti in "In attesa")  -->
          <alloy-button [label]="'generic.SAVE' | translate" type="success" (onButtonClicked)="openSaveScheduleModal()">
          </alloy-button>
          <!-- Invia schede -->
          <alloy-button [label]="'calibration.SEND_CARDS' | translate" type="primary"
            (onButtonClicked)="openSendScheduleModal()">
          </alloy-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="unusable">
  <div class="page-content">
    <svg-icon class="logo-container" [applyClass]="true" src="assets/img/icons/kruk-big-logo.svg">
    </svg-icon>
    <p>
      <span class="small-font-weight" translate="404.WE_ARE_SORRY"></span><br>
      <span translate="genericError.PAGE_NOT_USABLE" class="bold-text"></span>
    </p>
    <div>
      <p translate="genericError.MATRIX_ERROR"></p>
    </div>
    <div class="back-button-container">
      <alloy-button type="success default" size="large" label="{{'generic.GO_BACK' | translate}}"
        (onButtonClicked)="redirectService.goBackBrowser()"></alloy-button>
    </div>
  </div>
</div>

<!-- Tooltip per box distribuzione matrice -->
<modal id="box-distribution-tooltip" (onClose)="closeBoxDistributionTooltip()">
  <modal-text-content modalId="cal022" [title]="'calibration.BOX_DISTRIBUTION' | translate"
    [text]="'calibration.tooltip.BOX_DISTRIBUTION_DESCR' | translate">
  </modal-text-content>
</modal>


<modal id="massive-send" (onClose)="closeSendScheduleModal()"
  [confirmLabel]="selectedUserList.length <= 1 ? ('verifySend.modals.SEND_ACTION' | translate) : ('verifySend.modals.SEND_ACTION_MANY' | translate)"
  (onConfirm)="closeSendScheduleModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeSendScheduleModal()">

  <!-- se tanti utenti -->
  <ng-container *ngIf="selectedUserList && selectedUserList.length > 1">
    <modal-text-content [modalId]="'matrix003'" [title]="'verifySend.modals.SEND_TITLE_MANY' | translate"
      [subtitle]="'verifySend.modals.SEND_SUBTITLE_MANY' | translate" [text]="massiveModalText"></modal-text-content>
  </ng-container>

  <!-- Se singolo utente -->
  <ng-container *ngIf="selectedUserList && selectedUserList.length <= 1">
    <modal-text-content modalId="matrix004" [title]="'verifySend.modals.SEND_TITLE' | translate"
      [subtitle]="'verifySend.modals.SEND_SUBTITLE' | translate" [text]="'verifySend.modals.SEND_DESCR' | translate">
    </modal-text-content>
  </ng-container>
</modal>


<!-- Modale conferma salva scheda -->
<modal id="save-schedule-modal" (onClose)="closeSaveScheduleModal()" (onConfirm)="closeSaveScheduleModal(true)"
  [confirmLabel]="'verifySend.modals.SAVE_ACTION' | translate" (onCancel)="closeSaveScheduleModal()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="matrix005" [title]="'verifySend.modals.SAVE_TITLE' | translate"
    [subtitle]="'verifySend.modals.SAVE_SUBTITLE' | translate"
    [text]="'verifySend.modals.SAVE_DESCR_MATRIX' | translate">
  </modal-text-content>
</modal>