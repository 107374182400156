import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareTag } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { PerfAlloyCompetence, PerfAlloySmartFeedback } from 'atfcore-commonclasses';
import { catchError, map, take, tap } from 'rxjs/operators';
@Component({
  selector: 'app-selfAssessment-sendClap',
  templateUrl: './sendClap.component.html',
  styleUrls: ['./sendClap.component.scss']
})
export class SendClapSelfAssessmentComponent implements OnInit {
  FREE_FEEDBACK?: { id: string, title: string };
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  userId: string = '';
  personDetails: any;

  modalUpdateClapTitle: string = '';
  modalUpdateClapText: string = '';
  modalUpdateClapTitleConfirmTextButton: string = '';
  modalUpdateClapTextCloseTextButton: string = '';

  isFetchingCreateSmartFeedback: boolean = false;
  isFetchingSmartFeedbackUsers: boolean = false;
  smartFeedbackFromRecord: number = 0;
  smartFeedbackNumRecords: number = 0;
  smartFeedbackCurrentPage: number = 0;
  smartFeedbackCounter: number = 0;
  smartFeedbackComment: string = "";

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, towards?: boolean, isFeedback?: boolean }> = [];
  recipientUser!: UserCard;

  countEvaluation!: number;

  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  competenceModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  evalCompetenceModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  countEvaluationModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  userCardData: UserCard[] = [];
  clapId: string = '';

  selectedUserList: any = [];

  // Ricerca utente
  searchUserText: string = '';
  // modalUpdateId: string = '';
  // modalUpdateTitle: any;
  // modalUpdateText: any;
  // modalUpdateTitleConfirmTextButton: any;
  // modalUpdateTextCloseTextButton: any;
  loggedUser: any;
  forceBack: boolean = false;

  chargeUsersVariable: boolean = true;
  isImpersonate: boolean = false;

  fullCompetenceList: PerfAlloyCompetence[] = [];
  competenceList: { id: string, title: string }[] = [];
  selectedCompetence?: { id: string, title: string };

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;

              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.loggedUser = loggedUser.user;

                    if (!this.userId && this.chargeUsersVariable) {
                      this.chargeUsersVariable = false;
                      this.resetSmartFeedbackUsersData();
                      this.getMyCollegueUsers()
                    }
                  })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.getMyCollegueUsers()
    this.translate.get(
      [
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP',
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR',
        'feedbackSection.sendClapPage.modals.WHICH_CARE',
        'feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR',
        'feedbackSection.sendClapPage.modals.SELECT_COMPETENCE_TITLE',
        'feedbackSection.sendClapPage.modals.SELECT_COMPETENCE_DESCR',
      ])
      .subscribe(translations => {
        this.descriptionTooltipModal = {
          modalId: "401",
          title: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO'],
          subtitle: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB'],
          text: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR'],
        };
        this.whoTooltipModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        };
        this.competenceModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.SELECT_COMPETENCE_TITLE'],
          text: translations['feedbackSection.sendClapPage.modals.SELECT_COMPETENCE_DESCR'],
        }
        this.evalCompetenceModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        }
        this.countEvaluationModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        }
      })
  }

  onInfo() {
    if (this.selectedCompetence) {
      this.modalService.open("competence-dictionary")
    }
  }

  closeCompetenceDictionary() {
    this.modalService.close("competence-dictionary")
  }

  populateCompetenceListSelect() {
    if (this.runningYear.year && this.runningYear.yearCode) {
      this.competenceList = [];
      this.fullCompetenceList = [];

      this.selfAssessmentService
        .listValidCompetetnceForSmartFeedback(this.runningYear.year, this.runningYear.yearCode, this.selectedUserList[0].id)
        .pipe(
          take(1),
          map(res => this.fullCompetenceList = res),
          map(res => res.map((competence) => {
            if (competence.isFreeFeedback) {
              this.FREE_FEEDBACK = {
                id: competence.competenceId,
                title: competence.title
              }
              this.selectedCompetence = this.FREE_FEEDBACK;
            }

            this.competenceList.push({
              id: competence.competenceId,
              title: competence.title
            })
          })),
          catchError((err) => {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            return []
          })
        ).subscribe();
    }
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetSmartFeedbackUsersData(notDeleteSelected?: boolean) {
    this.smartFeedbackFromRecord = 0;
    this.smartFeedbackNumRecords = 10;
    this.smartFeedbackCurrentPage = 1;
    this.smartFeedbackCounter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  // Esegue una nuova ricerca
  searchUsers() {
    this.getMyCollegueUsers();
  }

  // Salva il testo che si cerca
  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Recupera una lista di utenti per gli inviare gli smart feedback
  getMyCollegueUsers() {
    this.isFetchingSmartFeedbackUsers = true;
    this.selfAssessmentService.countMyCollegueUsersForManager(this.searchUserText)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {

          this.smartFeedbackCounter = data.response;

          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.smartFeedbackCurrentPage && this.smartFeedbackNumRecords) {
            fromRecord = (this.smartFeedbackCurrentPage - 1) * this.smartFeedbackNumRecords;
          } else {
            fromRecord = 0;
          }

          if (this.smartFeedbackCounter) {
            this.selfAssessmentService.listMyColleagueUsers(fromRecord, this.smartFeedbackNumRecords, this.searchUserText).subscribe
              ((data: SenecaResponse<any>) => {
                if (data && data.response) {
                  this.userCardData = UserDataUtils.getUserCardData(data.response) || [];
                }
                this.isFetchingSmartFeedbackUsers = false
              })
          }
        }
        else {
          this.isFetchingSmartFeedbackUsers = false;
        }
      }, (err?: any) => {
        this.isFetchingSmartFeedbackUsers = false;
      });
  }


  // Cambia la paginazione alla lista degli utenti per lo smart feedback
  smartFeedbackUsersPageChanged(newPage: any) {
    this.smartFeedbackCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getMyCollegueUsers();
  }



  // Descrizionde dello smart feedback 
  smartFeedbackDescriptionChanged(text: string) {
    this.smartFeedbackComment = text;
  }

  // Invia lo smart feedback ad uno o più utenti
  sendSmartFeedback(closeModal?: boolean) {
    if (closeModal) {
      this.modalService.close('smartFeedback');
    }

    let usersIds: string[] = [];
    let smartFeedback: PerfAlloySmartFeedback[] = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      usersIds.push(this.selectedUserList[i].id);
      smartFeedback.push(
        {
          senderUserId: this.loggedUser.userId,
          recipientUserId: this.selectedUserList[i].id,
          smartComment: this.smartFeedbackComment
        })
    }

    this.selfAssessmentService.createSmartFeedback(this.loggedUser.userId, usersIds[0], this.smartFeedbackComment, this.countEvaluation, this.selectedCompetence?.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.router.navigate(['homepage']);
        }
      })
  }

  openSmartFeedbackModal() {
    this.feedbackRecap = [
      {
        title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
        avatar: true,
        towards: true
      },
      {
        title: this.translate.instant('peerFeedback.modals.REFERS_TO'),
        text: this.getTitleCompetence(this.selectedCompetence?.id)
      },
      {
        title: this.translate.instant('peerFeedback.modals.MOTIVATION_FOR_REQUEST'),
        text: this.smartFeedbackComment
      }];

    // Valuta la competenza
    if (this.selectedCompetence?.id !== this.FREE_FEEDBACK?.id) {
      this.feedbackRecap.splice(2, 0, {
        title: this.translate.instant('peerFeedback.modals.EVALUATES_COMPETENCE'),
        isFeedback: true
      })
    }
    this.modalService.open('smartFeedback');
  }

  closeSmartFeedbackModal() {
    this.modalService.close('smartFeedback');
  }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateModal() {
    // this.modalUpdateId = this.getModalSaveId();
    // this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
    this.openModalForUpdate();
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdate() {
    this.modalService.open('modalUpdateForUser');
  }

  checkObbligatoryData() {
    const isFreeFeedback = this.selectedCompetence?.id == this.FREE_FEEDBACK?.id;
    const isMessageInserted = this.smartFeedbackComment?.trim()?.length;
    const isUserSelected = this.selectedUserList.length;
    const isEvaluated = this.countEvaluation >= 0;

    if (isFreeFeedback && isMessageInserted && isUserSelected) {
      return true
    }

    if (!isFreeFeedback && isMessageInserted && isUserSelected && isEvaluated) {
      return true
    }

    return false
  }

  selectUser(user: any) {
    this.countEvaluation = -1;
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent.length) {
      this.selectedUserList = [];
    } else if (this.selectedUserList.length) {
      this.selectedUserList = [];
      this.selectedUserList.push(user);
      this.populateCompetenceListSelect()
    } else {
      this.selectedUserList.push(user);
      this.populateCompetenceListSelect()
    }
    // this.selectedCompetence = this.FREE_FEEDBACK!;
  }


  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio dell'applauso
  closeModalUpdate(backAnyway?: boolean) {
    if (backAnyway) {
      this.forceBack = true;
      this.redirectService.goToPersonDetailsFeedbackSelfAssessment(this.loggedUser.userId, this.runningYear);
    }
    this.modalService.close('modalUpdateForUser');
  }
  // Selezione dell'obiettivo su cui è richiesto il feedback
  changeAssociatedCompetence(objective: { id: string, title: string }) {
    this.countEvaluation = -1;
    this.selectedCompetence = objective;
  }

  changeEvaluation(count: -1 | 1 | 2 | 3 | 4 | 5) {
    this.countEvaluation = count;
  }

  getTitleCompetence(competenceId?: string) {
    return (this.fullCompetenceList.find(competence => competence.competenceId === competenceId) as any)?.title ?? ""
  }
  getSubtitleCompetence(competenceId?: string) {
    return this.fullCompetenceList.find(competence => competence.competenceId === competenceId)?.text ?? ""
  }

  getPercentageRange(index: number): string {
    switch (index) {
      case 0:
        return '(<35%)';

      case 1:
        return '(35% - 75%)';

      case 2:
        return '(76% - 99%)';

      case 3:
        return '(100%)';

      default:
        return '(>100%)';
    }
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}