<div class="page-content-wrapper">

    <div id="snackbarPersonDetails">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <!-- Se ho finito di caricare i dati dell'utente -->
    <ng-container *ngIf="!isLoadingRoundList && loggedUser; else bigStepLoader">
        <header-dossier [user]="loggedUser" [roundList]="feedbackRoundList" [selectedRound]="selectedRound"
            (onSelectedRound)="changeSelectedRound($event)" [hasTabs]="false" (onNewInterview)="createPrivateNote()" [isFeedback360]="true"> 
        </header-dossier>
        <div class="main-content-wrapper">
            <div class="admin-table-container">
                <ng-container *ngIf="!isLoadingRequestList; else stepLoader">
                    <div class="container">
                        <p translate="360Feedback.ACCORDION_TITLE"></p>
                        <div class="no-data" *ngIf="!requestList || !requestList.length">
                            <svg-icon src="../../../assets/img/icons/placeholder-img.svg"></svg-icon>
                            <p translate="360Feedback.NO_DATA"></p>
                        </div>
                    </div>

                    <div class="table-content" *ngIf="requestList && requestList.length">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="{{ ('360Feedback.table.SEND_FEEDBACK' | translate)}}"
                                        style="width:50%">
                                    </th>
                                    <th translate="{{ ('360Feedback.table.REQUEST_FEEDBACK' | translate)}}"
                                        style="width:50%"></th>
                                    <th translate="{{ ('360Feedback.table.STATE' | translate)}}"></th>
                                    <th translate="{{ ('360Feedback.table.ACTIONS' | translate)}}">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let request of requestList">
                                    <td>
                                        <div class="more-info-container">
                                            <div class="user-container" *ngIf="request.recipientUser">
                                                <avatar-img [user]="request.recipientUser" size="small"></avatar-img>
                                                <p
                                                    *ngIf="request.recipientUser.forename && request.recipientUser.surname">
                                                    {{request.recipientUser.surname + ' ' +
                                                    request.recipientUser.forename |truncate: [22,
                                                    '...']}}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="more-info-container">
                                            <div class="user-container" *ngIf="request.senderUser">
                                                <avatar-img [user]="request.senderUser" size="small"></avatar-img>
                                                <p *ngIf="request.senderUser.forename && request.senderUser.surname">{{
                                                    request.senderUser.surname +
                                                    ' '
                                                    + request.senderUser.forename |
                                                    truncate: [22, '...']}}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="status-tab">
                                        <ng-container
                                            *ngIf="request && request.feedbackStatus == 'TO_SEND'; else sentStatus">
                                            <item-list-team-status type="inProgress"
                                                [message]="'360Feedback.statuses.REQUESTED' | translate"
                                                styleItem="fill">
                                            </item-list-team-status>
                                        </ng-container>
                                        <ng-template #sentStatus>
                                            <item-list-team-status type="completed"
                                                [message]="'360Feedback.statuses.SENT' | translate" styleItem="fill">
                                            </item-list-team-status>
                                        </ng-template>
                                    </td>
                                    <td class="detail" [ngClass]="{'disabled': !isCurrentRound()}"
                                        (click)="goToRequest(request.feedbackId, request.feedbackStatus != 'TO_SEND')">
                                        {{ (request.feedbackStatus == 'TO_SEND' ?
                                        '360Feedback.table.SEND_FEEDBACK_ACTION' : '360Feedback.table.FEEDBACK_DETAILS')
                                        | translate }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>


<!-- Loader medio -->
<ng-template #stepLoader>
    <div class="loader spinner-medium"></div>
</ng-template>
<!-- Loader grande -->
<ng-template #bigStepLoader>
    <div class="loader spinner-big"></div>
</ng-template>