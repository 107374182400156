import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { DeletePrivateNoteFeedbackForManagerResponse, SenecaResponse } from 'src/commonclasses';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { Subordinateservice } from '../services/subordinates.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as PeopleAppraisalActions from "../ngrx/peopleAppraisal.actions";
import { Subscription, forkJoin } from 'rxjs';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { PerfAlloyAssessmentPhaseKeys, PerfAlloyUserPhaseStatuses, PerfAlloyUserGoal, PerfAlloyYearCodes, privateNoteAndSmartFeedback, RentStatusTypes, PerfAlloyFeedbackTypes, PerfAlloyPeerFeedbackParams, PerfAlloyPeerFeedback, PerfAlloyPrivateNoteFeedback, PerfAlloyCommonFeedback, PerfAlloySmartFeedback, PeerFeedbackServiceResponses, CountFeedbacksForManagerResponse, PerfAlloyFeedbackStatus, PerfAlloyFeedbackSorting, PerfAlloyManagerFeedback } from 'atfcore-commonclasses';
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as CommonClasses from 'src/commonclasses'
import { catchError, take, tap } from 'rxjs/operators';
import * as fromPeopleAppraisal from "../ngrx/peopleAppraisal.reducers";


interface c {
  competences: any
}
export interface Production {
  arg: string;

  selfAssessment: number | null;

  evaluation: number | null;

  feedback?: number | null;

  legend: { title: string, desc: string }
}
export const YEARS_FILTER = {
  LAST_YEAR: 'LAST_YEAR',
  LAST_2_YEARS: 'LAST_2_YEARS',
  LAST_3_YEARS: 'LAST_3_YEARS',
  LAST_4_YEARS: 'LAST_4_YEARS',
  ALL: 'ALL'
}

@Component({
  selector: 'app-peopleAppraisal-operations-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class PeopleAppraisalPersonDetailsComponent implements OnInit, OnDestroy {
  getPersonDetails$: any;
  subordinatesUsers: any;
  personDetails$!: Subscription;
  personDetails: any;
  isLoadingPersonDetails = false;
  routeParams$!: Subscription;
  userId: string = '';
  loggedUser$: any;

  getUserProfileTab$!: Subscription;
  isLoadingTab: boolean = true;
  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  competenceFeedbackChars: number = 600;
  maxTextAreaChars: number = 600;

  getRunningPhase$: Subscription = new Subscription();
  runningPhase: any;
  isGoalsUpdatePhase: boolean = false;
  getGoalUpdates$: Subscription = new Subscription();
  goalUpdatesData: any = [];
  saveGoalUpdatesForManager$: Subscription = new Subscription();
  updatesForManager: Array<{
    goalId: string;
    description: string;
  }> = [];
  textGoals: string[] = [];
  textExists: boolean = false;
  isLoadingGoalUpdates: boolean = true;

  // Anno selezionato per storico
  selectedYear: {
    perfYear: number;
    perfYearCode: string;
    name: string;
  } | null = null;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;
  graphModal: ApplicationModalMessage = {
    modalId: 'spiderGraph',
    title: '',
    text: ''
  }

  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];

  // Variabili gestione stepper
  stepList: { id: string; referenceId: string; number: number; stepId: number; title: any; status: string, isWaiting?: boolean, evaluationTabIsFinished?: boolean, isComplete?: boolean, disabled?: boolean }[] = [];
  currentStep: { id: string; referenceId: string; number: number; stepId: number; title: any; status: string, isWaiting?: boolean, isComplete?: boolean } | any = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  toEvaluateList: Array<PerfAlloyUserGoal & { isSelected: boolean, count: number, forename: string }> = [];
  toEvaluateListCopy: Array<PerfAlloyUserGoal & { isSelected: boolean, count: number, forename: string }> = [];
  isLoadingToEvaluateGoals: boolean = false;

  stars: any[] = [];
  manageBehaviorComments: string = '';
  manageBehaviorCommentsCopy: string = '';


  competenceModal: {
    title: string, text: string, isManagerial: boolean, scale?: {
      scale: number;
      text: string
      scaleTagId: string
    }[]
  } = { title: '', text: '', isManagerial: false };

  techSkills: any = {};
  techSkillsCopy: any;
  isLoadingTechSkills: boolean = false;
  techSkillComment: string = '';
  techSkillCommentCopy: string = '';

  newObjectiveList: any[] = [];
  isLoadingDefinedObjectives: boolean = false;
  objectiveToDelete: any;
  selectedObjective: any;
  selectedObjectiveSubordinate: any;
  recapDevelopmentDescription: any;
  isNoteSection: boolean = false;
  isFeedbackSection: boolean = false;
  translations: any;
  loggedUser: any;
  competenceForModal: any;

  isGoalChanging: boolean = false;

  counterTabs: PeerFeedbackServiceResponses.CountNotesAndFeedbacksForUser = { privateNotes: 0, feedbacks: 0 }
  // Filtri
  feedbackType: Array<string> = [];
  feedbackStatus: PerfAlloyFeedbackStatus | undefined = undefined;
  sorting: PerfAlloyFeedbackSorting = "DATE_DESC";
  filterList: Array<{ title: string, list: Array<{ id: string, title: string }>, selected?: { id: string, title: string } }> = [];

  filterCounter: number = 0;
  orderFilterSelected: string = '';
  isFeedbackFilterOpened: boolean = false;
  isPrivateNotesFilterOpened: boolean = false;
  menuOrderList: Array<{ id: string, title: string }> = []
  menuOrderYears: Array<{ id: string, title: string }> = [];
  privateNotesMinCreationDate: string = '';
  feedbackMinCreationDate: { id: string, date: string } = { id: YEARS_FILTER.LAST_YEAR, date: moment().subtract(1, 'years').toISOString() };

  orderPrivateNotesFilterSelected: string = '';

  selectedInfoTabPerformerKey: string | undefined = '';
  hrRemarks: string = 'Sed scelerisque nisl laoreet ex aliquam, sed scelerisque lorem rutrum. Donec bibendum neque a rhoncus tristique. Vestibulum in felis risus. Pellentesque euismod lectus ac sem faucibus rutrum. Proin tincidunt ipsum risus, vitae porttitor odio gravida ut. Maecenas ut purus dolor.';
  noteIdToDelete: string = '';

  privateNotesAndSmartFeedbackList: privateNoteAndSmartFeedback[] = [];
  getPrivateNotesAndSmartFeedbackList$: Subscription = new Subscription();
  smartFeedbackDataList: { isSmartFeedback: boolean, smartFeedbackType?: string }[] = [];

  // Al momento lascio any da cambiare (non ho ancora i nuovi type)
  feedbackList: Array<PerfAlloyPeerFeedback> = [];
  isLoadingFeedback: boolean = false;

  privateNotesList: Array<any> = [];
  isLoadingPrivateNotes: boolean = false;

  menuButtons: any = [];
  menuTabs: any = [];

  getUserGoalToEvaluate$: Subscription = new Subscription();
  getUserGoalToDefine$: Subscription = new Subscription();
  createOrUpdatePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  deletePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  isLoadingBehaviours: boolean = false;
  parentBehaviours: any;
  parentBehaviourIds: any;
  competenceArray: any[] = [];
  competenceArrayCopy: any;
  competenceDictionary: Array<{ scale: number, scaleTagId: string, text: string }> = [];

  getGoalsRatingScaleModal$: Subscription = new Subscription();
  isLoadingGoalsRatingScaleModal: boolean = false;
  goalsRatingScaleModalData: Array<{
    score: number,
    text: string,
    range: string
  }> = [];

  techSkillArray: any;
  techSkillArrayCopy: any;
  techSkillParentBehaviourIds: any;
  techSkillCompetenceToChange: any;
  techSkillsFinalAvg: any;
  softSkillsFinalAvg: any;


  intro!: GuidedTour;
  steps?: TourStep[];

  insertByUser: string = '';
  insertByManager: string = '';

  SENT_TO: string = 'INVIATO';
  RECEIVED_FROM: string = 'RICEVUTO';

  titleRightSection = {
    textLeft: 'Self-assessment',
    textRight: 'Tua valutazione'
  }

  strangthsComment: string = '';
  improvementComment: string = '';

  createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$: any;
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  techSkillRatingScale: any;
  getCompetenceToEvaluateForManager$: Subscription = new Subscription();
  skipObjectiveCheck: boolean = false;
  isLoadingFinalEvaluationData: boolean = false;
  getFinalEvaluationData$: Subscription = new Subscription();
  finalEvaluation: any = {};
  tempStep: any;
  newObjectiveListCopy: any;
  isLoadingUserGoalToDefine: any;
  interviewCheck: boolean = false;
  overAllSelectedItem: { id: string | number, title: string | number } = { id: '', title: '' };

  textAreaActions: any[] = [];
  isScheduleShared: boolean = false;
  editFinalEvaluationComment: boolean = false;
  editBehaviorComment: boolean = false;
  editCompetenceComment: boolean = false;
  objectiveToDeleteFromEdit: boolean | undefined;

  createOrUpdatePerformanceAssessmentGalEvaluationForManager$: any;
  getCompetencesToEvaluateForManager$: any;
  recapSubordinateDevelopmentDescription: any;
  isPostCalibration: boolean = false;
  finalShareDescripton: string = '';
  feedbackCheck: boolean = false;
  finalShareModalDescription: any;
  finalShare$: Subscription = new Subscription();
  isFinalStepCompleted: boolean = false;
  isManagement: boolean = false;
  yearsWithSpecialisticGoalsToEvaluate: boolean = false;
  isLoadingMySubordinateUserData: boolean = false;
  getMySubordinateUserData$: any;
  forcePasStructureTutorial: string | null;
  forcePasTutorialStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };
  behaviourCompetenceToChange: any;
  finalEvaluationCommentCopy: any;
  finalEvaluationBehaviourCommentCopy: any;
  finalEvaluationDataCopy: any;
  finalEvaluationCompetenceCommentCopy: any;
  finalDefinedObjectivesCopy: any;
  finalSelfDefinedObjectivesCopy: any;
  finalSelfDefinedObjectivesToDelete: any;
  finalDefinedObjectivesToDelete: any;
  // Dati step leadership team
  isLeadershipTeam: boolean = false;
  leadershipTeamData: any = {};
  leadershipTeamComment: string = '';
  leadershipTeamCommentCopy: string = '';
  runningPhaseEndDate: any;
  isHistory: boolean = false;
  objectivesToDelete: any[] = [];

  editObjectiveData: any;
  objectiveToApprove: any;
  currentUserPercentage: any;

  modalDataList!: CommonClasses.PerfAlloyGoalUpdatePhase.PerfAlloyUserGoalUpdate[];

  isLoadingReproposeObjData: boolean = false;
  reproposeObjData: any;
  openReproposeObjModal$: any;
  selectedReproposeObj: any;
  clonePerformanceAssessmentGoalsByGoalIds$: any;

  titlesRightSection = {
    left: 'Self-assessment',
    right: 'Tua valutazione'
  }

  menuItems: Array<{
    id: string,
    title: string,
    icon: string,
    isChecked: boolean
  }> = [];

  managerialOverallGraph: Array<Production> = [];
  genericOverallGraph: Array<Production> = [];




  // Serve per cambiare stato dello step 3, STATO -> scheda terminata
  evaluationTabIsFinished: boolean = false;

  // termina fase
  isLoadingCloseIntermediateEvaluation: boolean = false;
  closeIntermediateEvaluation$: Subscription = new Subscription();
  // Condivisi fase
  shareIntermediateEvaluation$: Subscription = new Subscription();
  isLoadingShareIntermediateEvaluation: boolean = false;

  // media per il performer
  performerAvgValue: number | undefined = undefined;

  // Recupero dati overall
  finalEvaluationList: any = {};
  finalEvaluationData$: Subscription = new Subscription();
  isLoadingFinalEvaluationList: boolean = false;
  forcePerformerPasProfile: string | undefined = undefined;

  isLoadingFinalShareModal: boolean = false;
  finalShareModal$: Subscription = new Subscription();
  isfinalShare: boolean = false;

  /** Se true, sto attualmente impersonificando l'utente. In questo caso non dovrò mostrare gli appunti privati */
  public isImpersonating: boolean = false;
  thirdStepLocked: any;

  objectiveDefMenuItems: Array<{
    id: string,
    title: string,
    icon: string,
    isChecked: boolean
  }> = [];

  objectiveEditMenuItems: Array<{
    id: string,
    title: string,
    icon: string,
    isChecked: boolean
  }> = [];

  selfAssessmentProposedObjectives: any;
  selfAssessmentProposedObjectivesCopy: any;
  saveDefinedObjective$: Subscription = new Subscription();
  phaseDates: any;
  isGoalSettingActive: boolean = false;

  runningYear$: Subscription = new Subscription();

  overallSelectData: Array<{ id: string | number, title: string | number }> = [];

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "PeopleAppraisal - Scheda utente");
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    this.forcePasStructureTutorial = openPasStructureTutorial || openSetGoalsTutorial || openTechSkillEvaluationTutorial || openOrganizationalBehaviorsTutorial;

    // Mi interessa sapere se sto impersonificando l'utente, in modo da non recuperare gli appunti privati
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .pipe(take(1))
      .subscribe(isImpersonating => {
        this.isImpersonating = isImpersonating;
      });
  }

  ngOnInit() {
    this.runningYear$ = this.store.select(fromApp.getRunningYear)
      .subscribe((runningYear) => {
        this.translate.get(
          [
            'accordion.CHALLENGE',
            "modals.CHALLENGE.TITLE",
            "modals.CHALLENGE.SUBTITLE",
            "modals.CHALLENGE.TEXT",
            "modals.ROLE.TITLE",
            "modals.ROLE.TEXT",
            "peopleAppraisalOperations.CARE_TITLE",
            "peopleAppraisalOperations.SHARED_COMPETENCE",
            'peopleAppraisalOperations.SHARED_COMPETENCE',
            "modals.OPERATIONS.TITLE",
            "modals.OPERATIONS.SUBTITLE",
            "modals.OPERATIONS.TEXT",
            'accordion.GUIDELINES_STI',
            "performance.ACCORDION_TITLE",
            "performance.ACCORDION_TOOLTIP",
            "performance.statuses.TO_DEFINE",
            "performance.statuses.TO_CONFIRM",
            "performance.statuses.COMPLETED",
            "calibration.statuses.TO_CONFIRM",
            'finalEvaluation.ASSIGNED_LIST_1',
            'finalEvaluation.ASSIGNED_LIST_2',
            'finalEvaluation.ASSIGNED_SPECIALISED_LIST_1',
            'finalEvaluation.ASSIGNED_SPECIALISED_LIST_2',
            "stepper.OBJECTIVE_EVALUATION",
            "stepper.BEHAVIOUR_EVALUATION",
            "stepper.TECH_SKILL_EVALUATION",
            "stepper.DEFINE_OBJECTIVES",
            "stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES",
            "stepper.FINAL_EVALUATION",
            "objectiveEvaluation.MODAL_TITLE",
            "objectiveEvaluation.MODAL_SUB",
            "objectiveEvaluation.MODAL_TEXT",
            "generic.NO_COMMENT_INSERTED",
            "finalEvaluation.FINAL_SHARE_DESCR_1",
            "finalEvaluation.FINAL_SHARE_DESCR_2",
            "stepper.TECH_OBJECTIVES",
            'finalEvaluation.SELF_ASSIGNED_LIST_1',
            'finalEvaluation.SELF_ASSIGNED_LIST_2',
            "stepper.LEADERSHIP_TEAM",
            'finalEvaluation.SELF_ASSIGNED_LIST_1',
            'finalEvaluation.SELF_ASSIGNED_LIST_2',
            'finalEvaluation.modals.DELETE_DESCR_1',
            'finalEvaluation.modals.DELETE_DESCR_2',
            'finalEvaluation.modals.APPROVE_DESCR_1',
            'finalEvaluation.modals.APPROVE_DESCR_2',
            'feedbackSection.smartFeedback.filters.TYPE',
            'feedbackSection.smartFeedback.filters.RECIPIENT',
            'feedbackSection.smartFeedback.filters.ORDER_LIST',
            'feedbackSection.smartFeedback.filters.FILTER_ALL',
            'feedbackSection.smartFeedback.filters.FILTER_SMART_FEEDBACK',
            'feedbackSection.smartFeedback.filters.FILTER_PEER_FEEDBACK',
            'feedbackSection.smartFeedback.filters.FILTER_TO_MANAGER',
            'feedbackSection.smartFeedback.filters.FILTER_SENT',
            'feedbackSection.smartFeedback.filters.FILTER_RECEIVED',
            'feedbackSection.smartFeedback.filters.FILTER_RECENT',
            'feedbackSection.smartFeedback.filters.FILTER_LATE',
            'feedbackSection.smartFeedback.filters.FILTER_WAITING',
            'feedbackSection.smartFeedback.filters.FILTER_TO_SEND',
            'feedbackSection.smartFeedback.filters.PERIOD',
            'feedbackSection.smartFeedback.filters.FILTER_LAST_YEAR',
            'feedbackSection.smartFeedback.filters.FILTER_LAST_2_YEARS',
            'feedbackSection.smartFeedback.filters.FILTER_LAST_3_YEARS',
            'feedbackSection.smartFeedback.filters.FILTER_LAST_4_YEARS',
            'feedbackSection.smartFeedback.filters.ALL_YEARS',
            'privateNotes.filters.FILTER_LAST_YEAR',
            'privateNotes.filters.FILTER_LAST_2_YEARS',
            'privateNotes.filters.FILTER_LAST_3_YEARS',
            'privateNotes.filters.FILTER_LAST_4_YEARS',
            'stepper.COMPETENCE_EVALUATION',
            'stepper.RECAP',
            'stepper.DEFINE_OBJECTIVES',
            'stepper.OVERALL',
            "defineNewObjectives.menu.APPROVE_GOAL",
            "defineNewObjectives.menu.EDIT_GOAL",
            "defineNewObjectives.menu.DELETE_GOAL",
            "defineNewObjectives.menu.EDIT",
            'performerEvaluators.UNSATISFACTONARY_PERFORMER.title',
            'performerEvaluators.MARGINAL_PERFORMER.title',
            'performerEvaluators.STANDARD_PERFORMER.title',
            'performerEvaluators.STRONG_PERFORMER.title',
            'performerEvaluators.TOP_PERFORMER.title',
            'feedbackSection.smartFeedback.filters.FILTER_360_FEEDBACK',
            'recap.modal.COMPETENCE_TITLE',
            'recap.modal.COMPETENCE_TEXT'
          ])
          .subscribe(translations => {
            this.translations = translations;

            this.graphModal = {
              modalId: 'spiderGraph',
              title: this.translations['recap.modal.COMPETENCE_TITLE'],
              text: this.translations['recap.modal.COMPETENCE_TEXT'],
            }
            this.overallSelectData = [
              {
                id: 'UNSATISFACTONARY_PERFORMER',
                title: translations['performerEvaluators.UNSATISFACTONARY_PERFORMER.title']
              },
              {
                id: 'MARGINAL_PERFORMER',
                title: translations['performerEvaluators.MARGINAL_PERFORMER.title']
              },
              {
                id: 'STANDARD_PERFORMER',
                title: translations['performerEvaluators.STANDARD_PERFORMER.title']
              },
              {
                id: 'STRONG_PERFORMER',
                title: translations['performerEvaluators.STRONG_PERFORMER.title']
              },
              {
                id: 'TOP_PERFORMER',
                title: translations['performerEvaluators.TOP_PERFORMER.title']
              }
            ]
            this.textAreaActions = [
              {
                id: 'delete',
                label: "generic.DELETE",
                class: 'secondary'
              },
              {
                id: 'save',
                label: "generic.SAVE",
                class: 'primary'
              }
            ];

            this.filterList = [
              {
                title: this.translations["feedbackSection.smartFeedback.filters.PERIOD"],
                list: [
                  {
                    id: YEARS_FILTER.LAST_YEAR,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_YEAR"]
                  },
                  {
                    id: YEARS_FILTER.LAST_2_YEARS,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_2_YEARS"]
                  },
                  {
                    id: YEARS_FILTER.LAST_3_YEARS,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_3_YEARS"]
                  },
                  {
                    id: YEARS_FILTER.LAST_4_YEARS,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_4_YEARS"]
                  },
                  {
                    id: YEARS_FILTER.ALL,
                    title: this.translations["feedbackSection.smartFeedback.filters.ALL_YEARS"]
                  }
                ],
              },
              {
                title: this.translations["feedbackSection.smartFeedback.filters.TYPE"],
                list: [
                  {
                    id: 'ALL_TYPE',
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_ALL"]
                  },
                  {
                    id: PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_SMART_FEEDBACK"]
                  },
                  {
                    id: PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_PEER_FEEDBACK"]
                  },
                  {
                    id: PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_TO_MANAGER"]
                  },
                  {
                    id: PerfAlloyFeedbackTypes.PERFALLOY_360_FEEDBACK,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_360_FEEDBACK"]
                  }
                ],
              },
              {
                title: this.translations["feedbackSection.smartFeedback.filters.RECIPIENT"],
                list: [
                  {
                    id: 'ALL_STATUS',
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_ALL"]
                  },
                  {
                    id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.SENT,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_SENT"]
                  },
                  {
                    id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.RECEIVED,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_RECEIVED"]
                  },
                  {
                    id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.WAITING,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_WAITING"]
                  },
                  {
                    id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.TO_SEND,
                    title: this.translations["feedbackSection.smartFeedback.filters.FILTER_TO_SEND"]
                  },
                ]
              }
            ];

            this.menuOrderList = [
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC,
                title: translations['feedbackSection.smartFeedback.filters.FILTER_LATE']
              },
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC,
                title: translations['feedbackSection.smartFeedback.filters.FILTER_RECENT']
              }
            ]

            this.menuOrderYears = [
              {
                id: YEARS_FILTER.LAST_YEAR,
                title: translations['privateNotes.filters.FILTER_LAST_YEAR']
              },
              {
                id: YEARS_FILTER.LAST_2_YEARS,
                title: translations['privateNotes.filters.FILTER_LAST_2_YEARS']
              },
              {
                id: YEARS_FILTER.LAST_3_YEARS,
                title: translations['privateNotes.filters.FILTER_LAST_3_YEARS']
              },
              {
                id: YEARS_FILTER.LAST_4_YEARS,
                title: translations['privateNotes.filters.FILTER_LAST_4_YEARS']
              }
            ]

            this.menuTabs = [
              {
                id: 'pas',
                name: this.translate.instant('headerDossier.CARD')
              },
              {
                id: 'feedback',
                name: this.translate.instant('headerDossier.FEEDBACK_TITLE')
              },
              {
                id: 'notes',
                name: this.translate.instant('headerDossier.PRIVATE_NOTE')
              }]


            for (let i = 0; i < this.filterList.length; i++) {
              this.filterList[i].selected = this.filterList[i].list[0];
            }

            this.menuItems = [{
              id: 'APPROVE',
              title: this.translations['defineNewObjectives.menu.APPROVE_GOAL'],
              icon: '../../../../assets/img/icons/clipboard-check.svg',
              isChecked: false
            }, {
              id: 'EDIT',
              title: this.translations['defineNewObjectives.menu.EDIT_GOAL'],
              icon: '../../../../assets/img/icons/edit.svg',
              isChecked: false
            },
            {
              id: 'DELETE',
              title: translations['defineNewObjectives.menu.DELETE_GOAL'],
              icon: '../../../../assets/img/icons/trash-2.svg',
              isChecked: false
            }]

            this.objectiveDefMenuItems = [
              {
                id: 'EDIT',
                title: this.translations['defineNewObjectives.menu.EDIT'],
                icon: '../../../../assets/img/icons/edit.svg',
                isChecked: false
              },
              {
                id: 'DELETE',
                title: translations['defineNewObjectives.menu.DELETE_GOAL'],
                icon: '../../../../assets/img/icons/trash-2.svg',
                isChecked: false
              }
            ]
            this.objectiveEditMenuItems = [
              {
                id: 'EDIT',
                title: this.translations['defineNewObjectives.menu.EDIT'],
                icon: '../../../../assets/img/icons/edit.svg',
                isChecked: false
              }
            ]

            this.contentTabList = [];


            this.routeParams$ = this.route.params
              .subscribe(
                (params: Params) => {
                  // Se ho cambiato utente (dalla sidebar) il componente non entrerà nell'onDestroy; quindi devo eliminare qui ogni referenza all'apertura automatica del tutorial dal session storage
                  if (this.userId && params.userId && this.userId !== params.userId) {
                    this.editFinalEvaluationComment = false;
                    this.finalAvg = undefined;
                    this.isGoalChanging = false;
                    this.forcePerformerPasProfile = undefined;
                    this.removeTutorialsRefFromSessionStorage();
                    this.getPerformanceAssessmentYears();
                  }
                  this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                    if (loggedUser && loggedUser.user) {

                      this.loggedUser = loggedUser && loggedUser.user;
                      if (!this.isLoadingMySubordinateUserData) {
                        this.isLoadingMySubordinateUserData = true;
                      }
                    }
                  })
                  this.userId = params.userId;
                  if (params.perfYear) {
                    this.selectedYear = {
                      perfYear: params.perfYear,
                      perfYearCode: params.perfYearCode,
                      name: params.perfYearCode + ' ' + params.perfYear
                    }

                  } else {
                    this.selectedYear = {
                      perfYear: 2021,
                      perfYearCode: PerfAlloyYearCodes.H1,
                      name: 'H1 ' + 2021
                    }
                  }

                  this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));

                  this.getPerformanceAssessmentYears();
                  // this.getCounterFeedbackTabs();
                  this.isNoteSection = window.location.href.indexOf('myNotes') > -1;
                  this.isFeedbackSection = window.location.href.indexOf('feedback') > -1;

                  if (this.selectedYear && this.selectedYear.perfYear) {
                    this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses({ getStrucureSubordinates: true }));
                  }
                  // Recupero la lista di subordinati con lo stato delle fasi
                  this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
                    this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
                  });
                }
              );


            this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));
          })
      });
  };

  getMySubordinateUserData() {
    this.isLoadingMySubordinateUserData = true;
    this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {

      if (subordinatesWithPhaseStatuses && subordinatesWithPhaseStatuses.length && this.selectedYear) {
        if (this.getMySubordinateUserData$) {
          this.getMySubordinateUserData$.unsubscribe();
        }

        this.getMySubordinateUserData$ = this.sharedService.getMySubordinateUserData(subordinatesWithPhaseStatuses[0].userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode)
          .subscribe((res: any) => {
            if (res && res.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pn017",
                text: this.translate.instant("errors." + res.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const techYears = res.response && res.response.yearsWithSpecialisticGoalsToEvaluate && res.response.yearsWithSpecialisticGoalsToEvaluate;
              this.isThisSpecialTechYear(techYears);
            }
            this.isLoadingMySubordinateUserData = false;
          }, (err?: any) => {
            this.isLoadingMySubordinateUserData = false;
            const messageObj: ApplicationModalMessage = {
              modalId: "109",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        this.isLoadingMySubordinateUserData = false;
      }
    })
  }

  getPercentageSubordinates() {
    for (let i = 0; i < this.subordinatesUsers?.length; i++) {
      if (this.subordinatesUsers[i]?.userId == this.personDetails.userId) {
        this.currentUserPercentage = this.subordinatesUsers[i].completedPasPercentage
      }
    }
  }

  isThisSpecialTechYear(techYears: any) {
    if (techYears && techYears.length) {
      for (let i = 0, yearsLength = techYears.length; i < yearsLength; i++) {
        const currentSpecialYear = techYears[i];

        if (currentSpecialYear.year && currentSpecialYear.year === this.selectedYear?.perfYear?.toString() && currentSpecialYear.yearCode === this.selectedYear?.perfYearCode) {
          this.yearsWithSpecialisticGoalsToEvaluate = true;
          break;
        }
      }
    }
  }

  getPersonDetails() {
    if (!this.selectedYear) {
      return;
    }

    this.isLoadingPersonDetails = true;
    this.isLoadingTab = true;

    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.sharedService.getMySubordinateUserData(this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode)
      .subscribe((personDetailsData: any) => {
        if (personDetailsData && personDetailsData.error) {
          this.isLoadingPersonDetails = false;
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.isLeadershipTeam = false;
          this.personDetails = {};
          this.personDetails = personDetailsData.response;

          // Controllo se fa parte della valuzione della leadership
          if (this.selectedYear && this.personDetails.yearsWithLeadershipTeam) {
            let leadershipYear = this.personDetails.yearsWithLeadershipTeam.find((data: any) => data.year == this.selectedYear?.perfYear && data.yearCode == this.selectedYear?.perfYearCode);
            if (leadershipYear) {
              this.isLeadershipTeam = true;
            }
          }

          if (this.isLeadershipTeam) {
            this.finalShareDescripton = this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_1') + this.personDetails.forename + '.';
          } else {
            this.finalShareDescripton = this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_2');
          }

          this.finalShareModalDescription = this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_2');
          this.isManagement = false;
          this.yearsWithSpecialisticGoalsToEvaluate = false;
          const techYears = personDetailsData.response && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate;
          this.getPercentageSubordinates();
          this.isThisSpecialTechYear(techYears);


          if (this.personDetails && this.personDetails.userId) {
            this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
            if (this.isNoteSection) {
              this.isLoadingTab = false;
              this.getPrivateNoteForManager()
            } else if (this.isFeedbackSection) {
              this.isLoadingTab = false;
              this.getFeedbacksForManager();
            } else {
              this.getUserProfileTab();
            }
          } else {
            this.isLoadingTab = false;
          }

          if (this.yearsWithSpecialisticGoalsToEvaluate) {
            this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_SPECIALISED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_SPECIALISED_LIST_2');
          } else {
            this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
          }
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  onMenuPrivateNotesFilterListClicked(item: { id: string, title: string }) {
    // Salvo il nome del filtro selezionato
    this.orderPrivateNotesFilterSelected = item.title;

    // Imposto il filtro dell'anno
    if (item.id == YEARS_FILTER.LAST_YEAR) {
      this.privateNotesMinCreationDate = moment().subtract(1, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_2_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(2, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_3_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(3, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_4_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(4, 'years').toISOString();
    } else {
      this.privateNotesMinCreationDate = '';
    }

    this.isPrivateNotesFilterOpened = false;
    this.getPrivateNoteForManager();
  }

  getPrivateNoteForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }
    //Faccio partire il loader
    this.isLoadingPrivateNotes = true;

    // Nel caso stia impersonificando, devo recuperare solo gli smart feedback
    const onlySmartFeedback: boolean = this.isImpersonating;

    // Inizio a fare la chiamata per le note private
    this.peopleAppraisalService.getPrivateNoteFeedbackForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<Array<PerfAlloyPrivateNoteFeedback>>) => {
        if (data && data.response) {
          this.privateNotesList = data.response;
          this.isLoadingPrivateNotes = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedback = false;
      });
  }

  // Apro i filtri dal più recente al meno recente FEEDBACK
  openFeedbackFiltersSection(close?: boolean) {
    if (close) {
      this.isFeedbackFilterOpened = false;
    } else {
      this.isFeedbackFilterOpened = !this.isFeedbackFilterOpened;
    }
  }
  // Apro i filtri per anno APPUNTI PRIVATI
  openPrivateNotesFiltersSection(close?: boolean) {
    if (close) {
      this.isPrivateNotesFilterOpened = false;
    } else {
      this.isPrivateNotesFilterOpened = !this.isPrivateNotesFilterOpened;
    }
  }

  onMenuFilterListClicked(item: { id: string, title: string }) {
    // Salvo il nome del filtro selezionato
    this.orderFilterSelected = item.title;

    // Filtro recenti
    if (item.id == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC) {

      // Incremento il counter
      if (this.sorting == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC) {
        this.filterCounter--;
      }

      this.sorting = item.id;
    }

    // Filtro meno recente
    if (item.id == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC) {

      // Incremento il counter
      if (this.sorting == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC) {
        this.filterCounter++;
      }

      // Assegno il nuovo valore 
      this.sorting = item.id;
    }
    this.isFeedbackFilterOpened = false;
    this.getFeedbacksForManager();
  }

  // Recupero i feedback lato manager
  getFeedbacksForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }
    //Faccio partire il loader
    this.isLoadingFeedback = true;

    // Inizio a fare la chiamata per le note private
    this.peopleAppraisalService.getFeedbacksForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId, this.feedbackType, this.feedbackStatus, this.sorting, this.feedbackMinCreationDate.date)
      .subscribe((data: SenecaResponse<Array<PerfAlloyPeerFeedback>>) => {
        if (data && data.response) {
          this.feedbackList = data.response;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        }
        this.isLoadingFeedback = false;
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedback = false;
      });
  }

  goToSmartFeedback() {
    this.router.navigate(['/peopleAppraisal/sendApplause']);
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.peopleAppraisalService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          for (let i = (data.response.length - 1); i >= 0; i--) {
            this.yearList.push({
              id: data.response[i].assessmentYear,
              code: data.response[i].assessmentYearCode,
              name: data.response[i].assessmentYearCode + ' ' + data.response[i].assessmentYear,
              disabled: !data.response[i].isInProcess
            });
          }
          this.isLoadingYearList = false;
          this.currentStep = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };
          this.getPerformanceAssessmentRunningPhase();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  getPerformanceAssessmentRunningPhase() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingGoalUpdates = true;
    this.textExists = false;
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }

    this.getRunningPhase$ = this.authService.getPerformanceAssessmentRunningPhase(this.selectedYear.perfYear, this.selectedYear.perfYearCode)
      .subscribe((data) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "phase000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.isLoadingGoalUpdates = false;
        } else {
          if (!this.selectedYear) {
            console.log("No selected year");
            return;
          }
          if (data.response) {
            this.phaseDates = data.response;
          }
          // TODO probabilmente non serve più. robe di facile o webranking
          /*
          if (data && moment().isBetween(moment(data.response.goalUpdateStartDate), moment(data.response.goalUpdateEndDate)) && data.response.prevProcessHasGoalUpdateAvailable) {
            if (window.location.href.indexOf('myNotes') < 0) {
              this.isGoalsUpdatePhase = true;

              if (this.getGoalUpdates$) {
                this.getGoalUpdates$.unsubscribe();
              }
              let serviceYear = this.getEvaluationYear();
              this.getGoalUpdates$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId])
                .subscribe((data: SenecaResponse<any>) => {
                  if (data && data.error) {
                  } else {
                    this.goalUpdatesData = data.response;
                    this.isLoadingGoalUpdates = false;
                  }
                })
            }
            this.isLoadingGoalUpdates = false;
          } else {
            this.isGoalsUpdatePhase = false;
          }*/
        }
        this.getPersonDetails();
        this.isLoadingGoalUpdates = false;
      });
  }

  changeSelectedYear(year: {
    id: number;
    code: string;
    name: string;
    disabled: boolean;
    isChecked: boolean;
  }) {
    let previous = this.selectedYear?.perfYear;
    this.selectedYear = {
      perfYear: year.id,
      perfYearCode: year.code,
      name: `${year.code} ${year.code}`
    };
    let url = this.router.url.split(('/' + previous))[0];
    const newUrl: string = url + '/' + this.selectedYear?.perfYear + '/' + this.selectedYear.perfYearCode;
    this.router.navigate([newUrl])
  }

  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab(updateOnly?: boolean) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    if (!updateOnly) {
      this.isLoadingTab = true;
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    this.isScheduleShared = false;
    this.isPostCalibration = false;
    this.getUserProfileTab$ = this.peopleAppraisalService.getUserPhaseDataForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && (data.error || !data.response)) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(data && data.error);
          }
        } else {
          this.contentTabList = []
          let stepNumber = 0;
          this.stepList = [];


          let tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_EVALUATION);
          // STEP VALUTAZIONE OBIETTIVI
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'objectiveEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.OBJECTIVE_EVALUATION"],
                status: tempStep.statusType,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED || tempStep.statusType == "SYSTEM_COMPLETED",
                disabled: false
              }
            )
          }
          // STEP VALUTAZIONE COMPETENZE
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.COMPETENCES_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'behaviourEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.COMPETENCE_EVALUATION"],
                status: tempStep.statusType,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED,
                disabled: false
              }
            )
          }
          // STEP RIEPILOGO
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.INTERMEDIATE_EVALUATION)
          if (tempStep) {
            this.isScheduleShared = tempStep.statusType == PerfAlloyUserPhaseStatuses.COMPLETED;
            this.evaluationTabIsFinished = tempStep.statusType == PerfAlloyUserPhaseStatuses.CLOSED_INTERMEDIATE_FORM,
              stepNumber += 1;
            this.stepList.push(
              {
                id: 'finalEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.RECAP"],
                status: tempStep.statusType,
                isComplete: tempStep.statusType == PerfAlloyUserPhaseStatuses.COMPLETED,
                disabled: false
              }
            )

            // La fase di goal setting è da considerarsi aperta quando la data di inizio non è valorizzata (che significa che è sempre aperta)
            // o se la data di inizio della fase è precedente alla data odierna
            this.isGoalSettingActive = !this.phaseDates?.peopleAppraisalGoalSettingStartDate
              || moment.utc().isAfter(this.phaseDates.peopleAppraisalGoalSettingStartDate);
            this.thirdStepLocked = tempStep.statusType != PerfAlloyUserPhaseStatuses.COMPLETED || !this.isGoalSettingActive;

          }

          let preparatoryStepIncompleted = !this.stepList[0].isComplete || !this.stepList[1].isComplete;

          // STEP DEFINIZIONE OBIETTIVI
          let gsTempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_DEFINITION)
          if (gsTempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'definingNewGoals',
                referenceId: gsTempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.DEFINE_OBJECTIVES"],
                status: gsTempStep.statusType,
                isComplete: gsTempStep.statusType == RentStatusTypes.COMPLETED,
                disabled: this.thirdStepLocked || preparatoryStepIncompleted
              }
            )
          }
          // STEP OVERALL
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.FINAL_EVALUATION)
          if (tempStep) {
            this.isfinalShare = tempStep.statusType == RentStatusTypes.COMPLETED;
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'Overall',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.OVERALL"],
                status: tempStep.statusType,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED,
                disabled: this.thirdStepLocked || preparatoryStepIncompleted || gsTempStep.statusType != RentStatusTypes.COMPLETED
              }
            )
            // this.isScheduleShared = tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM;
            // this.isPostCalibration = tempStep.statusType == 'RECEIVED_FINAL_FORM'; //TODO aggiorna con common class appena c'è
            // this.isFinalStepCompleted = tempStep.statusType == RentStatusTypes.COMPLETED;
          }
          this.hasToOpenTutorial();
          if (!updateOnly && !this.forcePasStructureTutorial) {
            for (let i = 0; i < this.stepList.length; i++) {
              if (!this.stepList[i].isComplete) {
                this.currentStep = this.stepList[i];
                break;
              }
            }
            if (!this.currentStep.stepId) {
              this.currentStep = this.stepList[this.stepList.length - 1];
            }
          } else if (this.forcePasStructureTutorial) {
            // Nel caso del tutorial della valutazione obiettivi bisogna prima caricare i dati, quindi passo direttamente lo step anche se non è stato preselezionato
            if (!this.forcePasTutorialStep.id) {
              this.currentStep = this.stepList[0];
            } else {
              this.currentStep = this.forcePasTutorialStep;
            }
          }

          if (this.thirdStepLocked && this.currentStep.stepId > 3) {
            this.currentStep = this.stepList[2];
          }
          this.allStepCompleted();
        }

        this.cdr.detectChanges();
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;
        if (!updateOnly && !this.forcePasStructureTutorial) {
          this.onStepClicked(this.currentStep, true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;
      });
  }
  // 
  onStrangthsCommentChanged(text: string) {
    this.strangthsComment = text.trim();
    this.tempCompetenceUpdated.overallComment.areaOfStrength = text.trim();
  }

  onImprovementCommentChanged(text: string) {
    this.improvementComment = text.trim();
    this.tempCompetenceUpdated.overallComment.areaOfImprovement = text.trim();
  }

  // Cambio scheda header dossier
  changeButtonHeaderDossier(id: string) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    switch (id) {
      case 'pas':
        this.router.navigate(['/peopleAppraisal/personDetails/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
      case 'feedback':
        this.router.navigate(['/peopleAppraisal/personDetails/feedback/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
      case 'notes':
        this.router.navigate(['/peopleAppraisal/personDetails/myNotes/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
    }
  }

  // Cambio tab
  onContentTabChanged(tab: TabDossier) {
    this.currentContentTab = tab;
  }

  // Cambio step
  async onStepClicked(step: any, skipCheck?: boolean) {
    this.tempStep = step;
    if (!skipCheck && this.currentStep.stepId == 1 && this.anyEvaluationChangesToSave()) {
      this.modalService.open('save-objective-modal');
    } else if (!skipCheck && this.currentStep.stepId == 2 && this.anyBehaviourEvaluationChangesToSave()) {
      this.modalService.open('save-behaviours-modal');
    } else if (!skipCheck && this.currentStep.stepId == 3 && this.anyTechSkillEvaluationChangesToSave()) {
      this.modalService.open('save-tech-skills-modal');
    } else if (!skipCheck && this.currentStep.stepId == 4 && this.anyDefineObjectiveChangesToSave()) {
      this.modalService.open('save-defined-objective-modal');
    } else if (!skipCheck && this.currentStep.stepId == 5 && this.anythingToSaveInFinalEvaluation()) {
      this.modalService.open('save-final-evaluation-modal');
    } else {
      this.currentStep = step;
      this.isGoalChanging = false;
      if (this.currentStep.stepId == 1 || this.isGoalsUpdatePhase) {
        this.getGoalsRatingScaleModal();
        this.getUserGoalListToEvaluate();
      } else if (this.currentStep.stepId == 2) {
        this.getCompetencesToEvaluateForManager();
        // } else if (this.currentStep.id == 'techEvaluation') {
        //   this.getCompetenceToEvaluateForManager();
      } else if (this.currentStep.id == 'definingNewGoals') {
        this.getUserGoalListToDefine();
      } else if (this.currentStep.stepId == 3) {
        this.getFinalEvaluationData();
      } else if (this.currentStep.id == 'Overall') {
        this.interviewCheck = false;
        this.overAllSelectedItem = { id: '', title: '' };
        this.finalEvaluationData();
      }
      this.updateUserPhaseStatus();
    }
  }

  closeSaveObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveObjectiveEvaluation();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 1);
      }
    }
  }

  closeSaveBehavioursModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-behaviours-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveBehavioursRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 2);
        //  Resetta l'oggetto dove vengono salvate le modifiche della pagina
        this.tempCompetenceUpdated = { overallComment: {}, competenceEvaluation: [] };
      }
    }
  }

  closeSaveTechSkillsModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-tech-skills-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveTechSkillsRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 3);
      }
    }
  }

  openHelpModal(stepId: string) {
    switch (stepId) {
      case 'objectiveEvaluation':
        const messageObjEva: ApplicationModalMessage = {
          modalId: "infoObjEv",
          title: this.translations["objectiveEvaluation.MODAL_TITLE"],
          subtitle: this.translations["objectiveEvaluation.MODAL_SUB"],
          text: this.translations["objectiveEvaluation.MODAL_TEXT"],
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObjEva }));
        break;
      case 'techEvaluation':
        this.modalService.open('competence-dictionary');
        break;
      case 'notes':
        break;
      default:
        break;
    }
  }

  // Da aggioranre passando l'oggetto dell'obiettivo
  onEvaluationChanged(text: string, objective: any) {
    objective.evaluation = text;
  }

  onEvaluationStarChanged(evaluatedData: number, objective: any) {
    objective.evaluationVote = evaluatedData;
  }

  onGoalsUpdateChanged(text: string, index: number) {
    this.textGoals[index] = text;
    if (this.textGoals?.length && !this.textGoals.every(val => val === "")) {
      this.textExists = true;
    }
    else {
      this.textExists = false;
    }

  }

  getPerformanceAssessmentIntermediateEvaluationDataForManager() {

  }

  closeUpdateModal() {
    this.modalService.close('update-added')
  }

  // Salva la valutazione degli obiettivi 
  saveObjectiveEvaluation(isUpdate?: boolean) {
    this.isLoadingToEvaluateGoals = true;
    let evaluationForService: { goalId: string, evaluation: string | undefined, evaluationVote: number | undefined }[] = [];
    // Commentato perchè non viene mai usato il paramentro isUpdate
    // if (isUpdate) {
    //   for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
    //     evaluationForService.push({
    //       goalId: this.finalEvaluation.evaluatedData[i].goalId,
    //       evaluation: this.finalEvaluation.evaluatedData[i].evaluation,
    //       evaluationVote: this.toEvaluateList[i].evaluationVote
    //     })
    //   }
    // } else {
    //   if (this.toEvaluateList && this.toEvaluateList.length) {
    //     for (let i = 0; i < this.toEvaluateList.length; i++) {
    //       evaluationForService.push({
    //         goalId: this.toEvaluateList[i].goalId,
    //         evaluation: this.toEvaluateList[i].evaluation,
    //         evaluationVote: this.toEvaluateList[i].evaluationVote
    //       })
    //     }
    //   }
    // }

    if (this.toEvaluateList && this.toEvaluateList.length) {
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        evaluationForService.push({
          goalId: this.toEvaluateList[i].goalId,
          evaluation: this.toEvaluateList[i].evaluation,
          evaluationVote: this.toEvaluateList[i].evaluationVote
        })
      }
    }

    this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
      .subscribe((data: SenecaResponse<boolean>) => {
        this.isLoadingToEvaluateGoals = false;
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.goToNextStep();
        }
      }, (err?: any) => {
        this.isLoadingToEvaluateGoals = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  openSaveGoalUpdatesForManagerModal() {
    this.modalService.open('confirm-update-added');
  }

  //Salvo gli aggiornamenti degli obiettivi
  saveGoalUpdatesForManager() {
    this.isLoadingGoalUpdates = true;
    //Controllo se la chiamata esiste
    if (this.saveGoalUpdatesForManager$) {
      this.saveGoalUpdatesForManager$.unsubscribe();
    }

    //Inserisco tutti gli aggiornamenti di ogni obiettivo
    for (let i = 0; i < this.goalUpdatesData.length; i++) {
      if (this.textGoals[i]) {
        this.updatesForManager.push({
          goalId: this.goalUpdatesData[i].goalId,
          description: this.textGoals[i]
        })
      }
    }

    //Eseguo la chiamata passando gli aggiornamenti di ogni obiettivo
    this.saveGoalUpdatesForManager$ = this.peopleAppraisalService.addOrUpdateGoalsForManager(this.goalUpdatesData[0].targetUserId, this.updatesForManager, this.goalUpdatesData[0].perfYear, this.goalUpdatesData[0].perfYearCode)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "updateGoals001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          //Resetto le varibili e faccio un refresh della pagina
          this.updatesForManager = [];
          this.textGoals = [];
          this.getPerformanceAssessmentRunningPhase()
        }
        this.isLoadingGoalUpdates = false;
      });
  }
  confirmSaveGoalUpdatesForManagerModal() {
    this.modalService.close('confirm-update-added');
    this.saveGoalUpdatesForManager();
  }

  closeSaveGoalUpdatesForManagerModal() {
    this.modalService.close('confirm-update-added');
  }

  openCompetenceDictionaryModal(competence?: any) {
    if (competence) {
      this.competenceForModal = competence;
    }
    this.modalService.open("competence-dictionary");
  }

  openCompetencesModal(competence?: any) {
    let scaleForModal = competence.evaluationScale.filter((item: any) => item.scale != -1);
    this.competenceModal = { title: competence.title, text: competence.text, isManagerial: competence.parentCompetence.isManagerial, scale: scaleForModal }
    this.modalService.open("general-competences");
  }

  closeCompetencesModal() {
    this.modalService.close("general-competences");
  }

  getPercentageRange(index: number): string {
    if (index == 0) {
      return '(<35%)';
    } else if (index == 1) {
      return '(35% - 75%)';
    }
    else if (index == 2) {
      return '(76% - 99%)';
    }
    else if (index == 3) {
      return '(100%)';
    }
    else if (index == 4) {
      return '(>100%)';
    } else {
      return '';
    }
  }

  closeCompetenceDictionaryModal() {
    this.modalService.close("competence-dictionary");
  }

  // generica per i commenti nelle textarea, prende testo e variabile dove salvarlo
  onTextAreaChanged(text: string, object: string) {
    object = text;
  }

  // Aggiungi nuovo obiettivo
  addNewObjective() {
    this.isGoalChanging = true;
    this.newObjectiveList.push({
      tmpId: this.newObjectiveList.length.toString(),
      goalId: '',
      text: '',
      isEdit: true
    })
  }


  onNewObjectiveChanged(text: string, objective: any) {
    objective.text = text;
  }

  saveDefinedObjective(objective?: any, isProposedObjective?: boolean, isOverallView?: boolean) {
    if (objective) {
      objective.isEdit = false;
    }

    this.isLoadingDefinedObjectives = true;

    if (this.saveDefinedObjective$) {
      this.saveDefinedObjective$.unsubscribe()
    }
    let anyEmptyObjective = false;
    let tmpList: { goalId: string, text: string }[] = [];
    if (isProposedObjective) {

      if (isOverallView && this.finalEvaluationList && this.finalEvaluationList.definedManagerUserGoals) {
        // Mappo la lista di obiettivi per i servizi
        this.finalEvaluationList.definedSubordinateUserGoals.forEach((obj: any) => {
          if (obj.text.trim().length) {
            tmpList.push({
              goalId: obj.goalId,
              text: obj.text
            })
          }
        });
      } else {
        tmpList = [
          {
            goalId: objective.goalId,
            text: objective.text
          }
        ]
      }
      // this.selfAssessmentProposedObjectives.forEach((obj: any) => {
      //   if (obj.text.trim().length) {
      //     tmpList.push({
      //       goalId: obj.goalId,
      //       text: obj.text
      //     })
      //   } else {
      //     anyEmptyObjective = true;
      //   }
      // });
    } else {
      if (isOverallView && this.finalEvaluationList && this.finalEvaluationList.definedManagerUserGoals) {
        // Mappo la lista di obiettivi per i servizi
        this.finalEvaluationList.definedManagerUserGoals.forEach((obj: any) => {
          if (obj.text.trim().length) {
            tmpList.push({
              goalId: obj.goalId,
              text: obj.text
            })
          } else {
            anyEmptyObjective = true;
          }
        });
      } else {

        // Mappo la lista di obiettivi per i servizi
        this.newObjectiveList.forEach(obj => {
          if (obj.text.trim().length && obj.text.length) {
            tmpList.push({
              goalId: obj.goalId,
              text: obj.text
            })
          } else {
            anyEmptyObjective = true;
          }
        });

      }
    }

    if (anyEmptyObjective) {
      const messageObj: ApplicationModalMessage = {
        modalId: "sdo001",
        text: this.translate.instant("defineNewObjectives.errors.EMPTY_OBJECTIVE"),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingDefinedObjectives = false;
    } else {
      let serviceToCall = isProposedObjective ? this.peopleAppraisalService.updatePerfAssessmentGoalsProposedBySubordinateForManager(this.userId, tmpList) : this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, tmpList);
      this.saveDefinedObjective$ = serviceToCall
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "sdo001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.updatePhase2Steps(data.response, isOverallView);
          }
          // Se sto modificando gli obiettivi dalla pagina di overall
          if (isOverallView) {
            this.finalEvaluationData();
          } else {
            this.getUserGoalListToDefine();
          }
          this.isLoadingDefinedObjectives = false
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "sdo002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDefinedObjectives = false
        });
    }
    this.isGoalChanging = false;
  }

  cancelDefinedEdit(objective: any, isSelfAssessment?: boolean) {
    if (objective.goalId) {
      let oldObjectiveList;
      if (isSelfAssessment && this.selfAssessmentProposedObjectivesCopy && this.selfAssessmentProposedObjectivesCopy.length) {
        oldObjectiveList = this.selfAssessmentProposedObjectivesCopy;
      } else if (!isSelfAssessment && this.newObjectiveListCopy && this.newObjectiveListCopy.length) {
        oldObjectiveList = this.newObjectiveListCopy;
      }
      for (let i = 0; i < oldObjectiveList.length; i++) {
        if (oldObjectiveList[i].goalId == objective.goalId) {
          objective.text = oldObjectiveList[i].text;
          break;
        }
      }
      objective.isEdit = false;
    } else {
      let tmpList = [];
      for (let i = 0; i < this.newObjectiveList.length; i++) {
        let tmp = this.newObjectiveList[i];
        if (tmp.text !== objective.text) {
          tmpList.push(tmp);
        }
      }
      this.newObjectiveList = tmpList;
    }
    this.isGoalChanging = false;
  }

  onNewObjectiveMenuItemClicked(actionId: string, objective: any, isProposedGoal?: boolean) {
    if (actionId == 'EDIT') {
      objective.isEdit = true;
      this.isGoalChanging = true;
    } else if (actionId == 'DELETE') {
      this.objectiveToDelete = objective;
      if (isProposedGoal) {
        this.modalService.open('delete-objective-proposed-modal');
      } else {
        this.modalService.open('delete-objective-modal');
      }
    }
  }

  // Chiudi modale cancellazione obiettivo
  closeDeleteObjectiveModal(confirm?: boolean, isProposedObjective?: boolean) {
    this.modalService.close('delete-objective-modal');
    this.modalService.close('delete-objective-proposed-modal');
    if (confirm) {
      if (isProposedObjective || (!this.objectiveToDelete.goalToBeApproved && this.objectiveToDelete.creationUserId != this.loggedUser.userId)) {
        this.objectiveToDelete.isSelfDefined = true;
      }
      this.isLoadingDefinedObjectives = true;
      if (isProposedObjective) {
        this.deleteObjectiveProposed(this.objectiveToDelete);
      } else {
        this.deleteObjective(this.objectiveToDelete);
      }
      this.objectiveToDelete = null;
    }
  }

  onProposedMenuOptionClicked(option: string, objective: any, isSelfDefined?: boolean) {
    if (option == 'EDIT') {
      this.editObjectiveData = JSON.parse(JSON.stringify(objective));
      this.editObjectiveData.isSelfDefined = isSelfDefined;
      objective.isEdit = true;
      // this.modalService.open('edit-objective-modal');
    } else if (option == 'DELETE') {
      this.objectiveToDelete = objective;
      this.objectiveToDelete.isSelfDefined = isSelfDefined;
      // if ((this.finalEvaluation?.definedObjectives?.length || 0) + (this.finalEvaluation?.selfDefinedObjectives?.length || 0) == 1) {
      //   const messageObj: ApplicationModalMessage = {
      //     modalId: "delobj001",
      //     title: this.translate.instant('finalEvaluation.modals.WARNING'),
      //     subtitle: this.translate.instant('finalEvaluation.modals.CANT_DELETE_SUB'),
      //     text: this.translate.instant('finalEvaluation.modals.CANT_DELETE_DESCR')
      //   }
      //   this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      // } else {
      //   this.modalService.open('delete-objective-modal');
      // }
      this.modalService.open('delete-objective-proposed-modal');
    } else if (option == 'APPROVE') {
      this.objectiveToApprove = objective;
      this.modalService.open('approve-objective-modal');
    }
  }

  isSaveDefinedObjectivesStepDisabled() {
    if (this.currentStep.id == 'definingNewGoals' && this.currentStep.isComplete) {
      return false;
    }
    return true;
  }

  onReproposeObjChecked(obj: any) {
    if (obj) {
      if (obj.isSelected) {
        // Obiettivo già selezionato, quindi lo devo rimuovere
        for (let i = 0, selectedReproposeObjLength = this.selectedReproposeObj.length; i < selectedReproposeObjLength; i++) {
          if (this.selectedReproposeObj[i].goalId === obj.goalId) {
            this.selectedReproposeObj.splice(i, 1);
            break;
          }
        }
      } else {
        // Obiettivo che è appena stato selezionato, quindi lo aggiungo tra i selezionati
        if (!this.selectedReproposeObj) {
          this.selectedReproposeObj = [];
        }

        this.selectedReproposeObj.push(obj);
      }
      obj.isSelected = !obj.isSelected;
    }
  }

  // Apre la modale per riproporre un obiettivo
  openReproposeObjModal() {
    this.reproposeObjData = null;
    this.selectedReproposeObj = null;
    this.modalService.open('repropose-obj');

    this.isLoadingReproposeObjData = true;
    if (this.openReproposeObjModal$) {
      this.openReproposeObjModal$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.openReproposeObjModal$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId])
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          this.closeReproposeObjModal();
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingReproposeObjData = false;
        } else {
          this.reproposeObjData = [];
          this.selectedReproposeObj = [];

          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              let currentData = data.response[i];
              if (currentData.creationUser) {
                let formattedCreationUser = '';
                if (currentData.creationUser.forename) {
                  formattedCreationUser = currentData.creationUser.forename;
                }
                if (currentData.creationUser.surname) {
                  formattedCreationUser = formattedCreationUser + ' ' + currentData.creationUser.surname;
                }

                currentData.formattedCreationUser = formattedCreationUser;
              } else if (currentData.creationUserId) {
                if (currentData.creationUserId === '**SYSTEM**') {
                  currentData.enteredBySystem = true;
                } else if (currentData.creationUserId === '**MANUAL**') {
                  currentData.manuallyEntered = true;
                }
              }

              this.reproposeObjData.push({
                ...currentData,
                isSelected: false
              })
            }
          }
          this.isLoadingReproposeObjData = false;
        }
      }, (err?: any) => {
        this.closeReproposeObjModal();
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingReproposeObjData = false;
      });
  }

  // Chiude la modale per riproporre un obiettivo
  closeReproposeObjModal(confirm?: boolean) {
    this.modalService.close('repropose-obj');
    if (confirm) {
      this.isLoadingDefinedObjectives = true;
      if (this.clonePerformanceAssessmentGoalsByGoalIds$) {
        this.clonePerformanceAssessmentGoalsByGoalIds$.unsubscribe();
      }

      let goalIds = [];

      for (let i = 0, selectedReproposeObjLength = this.selectedReproposeObj.length; i < selectedReproposeObjLength; i++) {
        goalIds.push(this.selectedReproposeObj[i].goalId);
      }

      let serviceYear = this.getEvaluationYear();

      this.clonePerformanceAssessmentGoalsByGoalIds$ = this.peopleAppraisalService.clonePerformanceAssessmentGoalsByGoalIds(this.userId, goalIds, serviceYear.year, serviceYear.code)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            this.closeReproposeObjModal();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Gli obiettivi riproposti sono stati salvati. Quindi li aggiungi nell'array di obiettivi già esistenti (potrebbe essere che, prima di riproporre, l'utente abbia aggiunto qualche obiettivo custom senza salvare)
            const addedGoals = data.response && data.response.createdUserGoals || [];
            const toastrToShow = addedGoals && addedGoals.length && addedGoals.length === 1 ? "objectiveReproposed" : "objectivesReproposed";
            let x: any = document.getElementById(toastrToShow);
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }

            let objsRemovedWithoutSaving = this.newObjectiveListCopy.length != this.newObjectiveList.length;
            let hasCustomGoalToSave;
            if (this.newObjectiveList && this.newObjectiveList.length) {
              for (let q = 0, goalsListLength = this.newObjectiveList.length; q < goalsListLength; q++) {
                if (this.newObjectiveList[q].tmpId) {
                  hasCustomGoalToSave = true;
                  break;
                }
              }
            }

            if ((hasCustomGoalToSave || objsRemovedWithoutSaving || (this.objectivesToDelete && this.objectivesToDelete.length)) && addedGoals && addedGoals.length) {
              for (let m = 0, resLength = addedGoals.length; m < resLength; m++) {
                const currentRes = addedGoals[m];
                const formattedGoal = {
                  id: currentRes.goalId,
                  text: currentRes.text,
                  cloned: currentRes.cloned,
                  count: [m + 1]
                };
                this.newObjectiveList.push(formattedGoal);
                this.newObjectiveListCopy.push(formattedGoal);
              }
            } else {
              if (this.newObjectiveList && this.newObjectiveList.length) {
                this.newObjectiveList.length = 0;
              }
              this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
              this.updateUserPhaseStatus();
              this.getUserGoalListToDefine();
              this.getUserProfileTab(true);
            }

            /* Da decommentare se si vuole fare in modo che, dopo il riproponi, venga fatto il refresh dello step
              if (this.newObjectiveList && this.newObjectiveList.length) {
                this.newObjectiveList.length = 0;
              }
              this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
              this.updateUserPhaseStatus();
              this.getUserGoalListToDefine();
              this.showSaveSuccessfulToaster();
              this.getUserProfileTab(true); */
          }
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm006",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          this.isLoadingDefinedObjectives = false;
        });
    } else {
      this.reproposeObjData = null;
      this.selectedReproposeObj = null;
    }
  }

  // Elimina definitivamente tutti gli obiettivi
  deleteAllSelectedObjectives() {
    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, objectivesToDeleteLength = this.objectivesToDelete.length; j < objectivesToDeleteLength; j++) {
      const objectivesToDelete = this.objectivesToDelete[j];

      promises.push(new Promise((resolve: Function, reject: Function) => {
        this.peopleAppraisalService.deleteAssessmentGoalsForManager(this.userId, [objectivesToDelete.goalId])
          .subscribe((data: SenecaResponse<boolean>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm017",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              resolve();
            } else {
              resolve();
            }
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm018",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Controlla se tra gli obiettivi del goal setting ho qualcosa da salvare
  anyDefineObjectiveChangesToSave() {
    if (!this.isLoadingDefinedObjectives && this.newObjectiveList && this.newObjectiveListCopy) {
      let isEditMode = this.newObjectiveList.find(x => x.isEdit);
      let isEditModeProposed = this.selfAssessmentProposedObjectives.find((x: any) => x.isEdit)
      if ((this.newObjectiveList.length != this.newObjectiveListCopy.length) || (this.objectivesToDelete && this.objectivesToDelete.length) || isEditMode || isEditModeProposed) {
        return true;
      } else {
        for (let i = 0; i < this.newObjectiveList.length; i++) {
          if (!this.newObjectiveList[i].goalId) {
            return true;
          }
          if (this.newObjectiveList[i].text != this.newObjectiveListCopy[i].text) {
            return true;
          }
        }
      }
    }
    return false;
  }

  // Gestisce il cambio step (da step goal setting) con modifiche da salvare
  closeSaveDefinedObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-defined-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveDefinedObjective();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 4);
      }
    }
  }

  closeSaveFinalEvaluationModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-final-evaluation-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveFinalEvaluationStep();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 5);
      }
    }
  }

  deleteObjective(objective: any) {
    // rimuovo l'obiettivo dalla lista
    if (this.objectiveToDelete.isSelfDefined) {
      this.selfAssessmentProposedObjectives = this.selfAssessmentProposedObjectives.filter((x: any) => x.goalId != objective.goalId);
    } else {
      this.newObjectiveList = this.newObjectiveList.filter((x: any) => x.goalId != objective.goalId);
    }

    // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deleteAssessmentGoalsForManager(this.userId, [objective.goalId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let gsTempStep = this.stepList.find((step) => step.id == 'definingNewGoals');
          if (gsTempStep) {
            gsTempStep.status = data.response;
            gsTempStep.isComplete = data.response == RentStatusTypes.COMPLETED;
          }
          this.currentStep = gsTempStep;

          this.selfAssessmentProposedObjectivesCopy = JSON.parse(JSON.stringify(this.selfAssessmentProposedObjectives));
          this.newObjectiveListCopy = JSON.parse(JSON.stringify(this.newObjectiveList));
          this.updatePhase2Steps(data.response);
          if (data.response == RentStatusTypes.COMPLETED) {
            this.goToNextStep();
          } else {
            this.getUserGoalListToDefine();
          }
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }
  deleteObjectiveProposed(objective: any) {
    // rimuovo l'obiettivo dalla lista
    if (this.objectiveToDelete.isSelfDefined) {
      this.selfAssessmentProposedObjectives = this.selfAssessmentProposedObjectives.filter((x: any) => x.goalId != objective.goalId);
    } else {
      this.newObjectiveList = this.newObjectiveList.filter((x: any) => x.goalId != objective.goalId);
    }

    // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deleteAssessmentGoalsProposedForManager(this.userId, [objective.goalId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let gsTempStep = this.stepList.find((step) => step.id == 'definingNewGoals');
          if (gsTempStep) {
            gsTempStep.status = data.response;
            gsTempStep.isComplete = data.response == RentStatusTypes.COMPLETED;
          }
          this.currentStep = gsTempStep;

          this.selfAssessmentProposedObjectivesCopy = JSON.parse(JSON.stringify(this.selfAssessmentProposedObjectives));
          this.newObjectiveListCopy = JSON.parse(JSON.stringify(this.newObjectiveList));
          this.updatePhase2Steps(data.response);
          if (data.response == RentStatusTypes.COMPLETED) {
            this.goToNextStep();
          } else {
            this.getUserGoalListToDefine();
          }
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  onMenuOptionClicked(option: string, objective: any) {
    if (option == 'edit') {
      objective.isEdit = true;
    } else {
      if (!objective.goalId && !objective.isEdit) {
        this.deleteObjective(objective);
      } else {
        // this.openDeleteSavedObjective(objective);
        for (let i = 0, objectivesLength = this.newObjectiveList.length; i < objectivesLength; i++) {
          const currentObjective = this.newObjectiveList[i];

          if (currentObjective && currentObjective.goalId && objective && objective.goalId
            && currentObjective.goalId === objective.goalId) {
            this.objectivesToDelete.push(currentObjective);
            this.newObjectiveList.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  // Apre la modale con la spiegazione dei performer
  openPerformerModal(performerKey?: string) {
    this.selectedInfoTabPerformerKey = performerKey;
    this.modalService.open("performerEvaluatorInfoModal");
  }

  // Chiude la modale con la spiegazione dei performer
  closePerformerModal() {
    this.modalService.close("performerEvaluatorInfoModal");
    this.selectedInfoTabPerformerKey = '';
  }

  selectObjectiveFinalEvaluation(objective: any) {
    this.selectedObjective = objective;
    this.selectedObjectiveSubordinate = this.finalEvaluation.evaluatedSubordinateUserGoals?.find((element: { goalId: string; }) => element.goalId == objective.goalId)
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.peopleAppraisalService.deletePrivateNote(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.noteIdToDelete, this.userId)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.isLoadingPrivateNotes = false;
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          // this.getCounterFeedbackTabs();
          this.getPrivateNoteForManager();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, noteId);
  }


  createPrivateNote() {
    if (this.anyEvaluationChangesToSave()
      || this.anyBehaviourEvaluationChangesToSave()
      || this.anyTechSkillEvaluationChangesToSave()
      || this.anyDefineObjectiveChangesToSave()
      || this.anythingToSaveInFinalEvaluation()) {
      this.modalService.open('private-notes');
    } else {
      this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, '');
    }
  }
  closePrivateModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('private-notes');
    if (!closeOnly) {
      if (confirm) {
        this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, '');
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 5);
      }
    }
  }

  /** Ritorna il processo precendente a quello attuale */
  getEvaluationYear() {
    if (this.selectedYear?.perfYearCode == PerfAlloyYearCodes.H1) {
      return {
        year: this.selectedYear.perfYear - 1,
        code: PerfAlloyYearCodes.H1
      }
    } else {
      return {
        year: <number>this.selectedYear?.perfYear,
        code: PerfAlloyYearCodes.H1
      }
    }
  }

  getUserGoalListToEvaluate() {
    this.isLoadingToEvaluateGoals = true;
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.getUserGoalToEvaluate$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId])
      .subscribe((data: SenecaResponse<PerfAlloyUserGoal[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else if (data && data.response) {
          this.getGoalsRatingScaleModal();
          this.toEvaluateList = [];
          for (let i = 0; i < data.response.length; i++) {
            this.toEvaluateList.push({
              ...data.response[i],
              isSelected: false,
              count: (i + 1),
              forename: this.insertByManager
            })
          }
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          if (this.currentStep.isComplete == true) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.isLoadingToEvaluateGoals = false;
        }
        // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
        this.hasToOpenTutorial(true);
        this.isLoadingToEvaluateGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  getGoalsRatingScaleModal() {
    this.isLoadingGoalsRatingScaleModal = true;
    if (this.getGoalsRatingScaleModal$) {
      this.getGoalsRatingScaleModal$.unsubscribe();
    }

    this.getGoalsRatingScaleModal$ = this.peopleAppraisalService.getPerformanceAssessmentGoalsRatingScaleForManager()
      .subscribe((data: SenecaResponse<Array<{
        score: number,
        text: string,
        range: string
      }>>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalsRatingScaleModal = false;
        } else {
          this.goalsRatingScaleModalData = data.response;
          this.isLoadingGoalsRatingScaleModal = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsRatingScaleModal = false;
      });
  }

  anyEvaluationChangesToSave(noGoals?: boolean) {
    if (noGoals) {
      return false;
    }
    if (JSON.stringify(this.toEvaluateListCopy) == JSON.stringify(this.toEvaluateList)) {
      return false;
    }
    if (this.tempCompetenceUpdated.competenceEvaluation?.length) {
      return true;
    }
    if (this.tempCompetenceUpdated.overallComment.areaOfImprovement?.length) {
      return true;
    }
    if (this.tempCompetenceUpdated.overallComment.areaOfStrength?.length) {
      return true;
    }

    if (this.toEvaluateList) {
      let count: number = 0;
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        // Aggiunto -1 per evitare errore evaluationVote con undefined
        const evaluationVote = this.toEvaluateList[i].evaluationVote || -1;
        if (evaluationVote >= 0) {
          count++;
        }
      }
      if (count == this.toEvaluateList.length) {
        return false;
      }
    }
    return true;
  }

  // Recupera gli obiettivi da definire
  getUserGoalListToDefine() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingUserGoalToDefine = true;

    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }

    this.newObjectiveList = [];
    this.selfAssessmentProposedObjectives = [];

    this.getUserGoalToDefine$ = this.peopleAppraisalService.getPerformanceGoalsForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, [this.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          for (let m = 0, resLength = data.response.length; m < resLength; m++) {
            const currentRes = data.response[m]
            if (currentRes.creationUserId == this.loggedUser.userId) {
              this.newObjectiveList.push(currentRes);
            } else {
              this.selfAssessmentProposedObjectives.push(currentRes);
            }
          }
        }

        this.hasToOpenTutorial();
        this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
        this.selfAssessmentProposedObjectivesCopy = this.selfAssessmentProposedObjectives.length ? JSON.parse(JSON.stringify(this.selfAssessmentProposedObjectives)) : [];
        if (this.currentStep.isComplete == true) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.isLoadingUserGoalToDefine = false;
      }, (err?: any) => {
        this.isLoadingUserGoalToDefine = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm008",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  groupBy<V, K>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  }

  competencesGrouped$?: any;
  finalAvg?: { title: string, subtitle: string, ratingStars: number, ratingStarsRounded: number };
  getCompetencesToEvaluateForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingBehaviours = true;
    if (this.getCompetencesToEvaluateForManager$) {
      this.getCompetencesToEvaluateForManager$.unsubscribe();
    }

    this.getCompetencesToEvaluateForManager$ = this.peopleAppraisalService.getCompetenceToEvaluate(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.isLoadingBehaviours = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const res: CommonClasses.PerfAlloyCompetence[] = data.response.competences;
          const mappedCompetences = this.groupBy<CommonClasses.PerfAlloyCompetence, string>(res, competence => competence.parentCompetence!.text);
          const iterator = mappedCompetences.entries();
          this.competenceArray = [];

          for (let i = 0; i < mappedCompetences.size; i++) {
            const [parentCompetenceName, competences]: [string, CommonClasses.PerfAlloyCompetence[]] = iterator.next().value;
            const allEvaluated = competences.every(comp => comp.evaluationRating !== undefined && comp.evaluationRating !== null && comp.evaluationRating >= 0);
            if (!allEvaluated) {
              this.competenceArray.push({
                category: parentCompetenceName,
                competences: competences,
                ratingScale: 5
              });

              continue;
            }

            const sumAllCompetences = competences.reduce((acc, comp) => {
              acc += comp.evaluationRating!;
              return acc
            }, 0);

            const partiaCompetenceslAvg = sumAllCompetences / competences.length;
            this.competenceArray.push({
              category: parentCompetenceName,
              competences: competences,
              ratingScale: 5,
              partiaCompetenceslAvg: partiaCompetenceslAvg,
              partiaCompetenceslAvgRound: Math.round(partiaCompetenceslAvg)
            });
          }

          // Assegno la scala da inserire nella modale
          this.competenceDictionary = data.response?.competences[0]?.evaluationScale;
          // Rimuovo il primo elemento perchè contine N/A che in questo caso non va bene
          this.competenceDictionary.splice(0, 1);
          const canCalcFinalAvg = this.competenceArray.every(parentComp => parentComp.partiaCompetenceslAvg !== undefined && parentComp.partiaCompetenceslAvg >= 0);
          if (canCalcFinalAvg) {
            const sumFinalAvg = this.competenceArray.reduce((acc, curr) => {
              const compeNum: number = (curr.competences.map((comp: any) => comp.evaluationRating) as number[]).reduce((a, b) => a + b, 0);
              acc += compeNum;
              return acc
            }, 0);

            const divider = this.competenceArray.reduce((acc, curr) => {
              acc += curr.competences.length;
              return acc;
            }, 0)

            const finalAvg = {
              title: this.translate.instant('generic.FINAL_AVG'),
              subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
              ratingStars: sumFinalAvg / divider,
              ratingStarsRounded: Math.round(sumFinalAvg / divider)
            }
            this.finalAvg = finalAvg;
          }

          this.isLoadingBehaviours = false;
          // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
          this.hasToOpenTutorial();

          const { areaOfImprovement, areaOfStrength } = this.tempCompetenceUpdated.overallComment;
          this.strangthsComment = areaOfStrength ? areaOfStrength : data.response.overallComment.areaOfStrength;
          this.improvementComment = areaOfImprovement ? areaOfImprovement : data.response.overallComment.areaOfImprovement;
          this.getUserProfileTab(true);
        }

        // In caso venga inserito un commento, ripristina le valutazioni fatte dall'utente
        this.competenceArray.forEach((categories) => {
          categories.competences.forEach((competence: any) => {
            const insertedValuation = this.tempCompetenceUpdated.competenceEvaluation
              ?.find((c: any) => c.competenceId == competence.competenceId);

            if (insertedValuation) competence.evaluationRating = insertedValuation.evaluationRating;
          });
        });
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });

    // this.getBehavioursToEvaluateForManager$ = this.peopleAppraisalService.getBehavioursToEvaluateForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
    //   .subscribe((data: SenecaResponse<any>) => {
    //     if (data && data.error) {
    //       this.isLoadingBehaviours = false;
    //       const messageObj: ApplicationModalMessage = {
    //         modalId: "pdm009",
    //         text: this.translate.instant("errors." + data.error),
    //         title: this.translate.instant("generic.WARNING")
    //       }
    //       this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //     } else {
    //       let ratingCounter = 0;
    //       let ratingSum = 0;
    //       let selfRatingCounter = 0;
    //       let selfRatingSum = 0;
    //       let ratingDoneCounter = 0;
    //       let parentBehaviours = data.response && data.response.behaviors && data.response.behaviors.map((x: any) => {
    //         return {
    //           behaviorId: x.parentBehavior.behaviorId,
    //           title: x.parentBehavior.text
    //         }
    //       })
    //       this.parentBehaviourIds = parentBehaviours.map((item: any) => item.behaviorId)
    //         .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
    //       let tempBehaviour: any = {};
    //       for (let i = 0; i < this.parentBehaviourIds.length; i++) {
    //         for (let j = 0; j < data.response.behaviors.length; j++) {
    //           let behavior = data.response.behaviors[j];
    //           if (behavior.parentBehavior.behaviorId == this.parentBehaviourIds[i]) {
    //             if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
    //               tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
    //             } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
    //               tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
    //             }
    //           }
    //         }
    //       }
    //       this.competenceArray = [];
    //       let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

    //       for (let i = 0; i < this.parentBehaviourIds.length; i++) {
    //         let currentCompetenceRatingCounter = 0;
    //         let currentCompetenceRatingSum = 0;
    //         let currentCompetenceSelfRatingCounter = 0;
    //         let currentCompetenceSelfRatingSum = 0;

    //         let currentCompetenceRatingAvgNum = 0;
    //         let currentCompetenceSelfRatingAvgNum = 0;
    //         let currentCompetenceRatingAvgString = null;
    //         let currentCompetenceSelfRatingAvgString = null;
    //         let currentCompetenceRatingDoneCounter = 0;

    //         let parent = tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && tempBehaviour[this.parentBehaviourIds[i]][0].parentBehavior;
    //         let tempCompetence = [];
    //         if (isPostCalibrationOrFinalStepCompleted) {
    //           for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
    //             const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;
    //             const currentSelfRating = tempBehaviour[this.parentBehaviourIds[i]][j].colleagueEvaluationRating;

    //             let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
    //             if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
    //               formattedTitle = formattedTitle.split('|')[0];
    //             }

    //             tempCompetence.push({
    //               ...tempBehaviour[this.parentBehaviourIds[i]][j],
    //               title: formattedTitle,
    //               rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length),
    //               selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
    //             });

    //             // Media finale
    //             ratingCounter++;
    //             selfRatingCounter++;
    //             ratingSum = ratingSum + (currentRating || 0);
    //             selfRatingSum = selfRatingSum + (currentSelfRating || 0);

    //             // Media parziale
    //             currentCompetenceRatingCounter++;
    //             currentCompetenceSelfRatingCounter++;
    //             currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
    //             currentCompetenceSelfRatingSum = currentCompetenceSelfRatingSum + (currentSelfRating || 0);

    //             if (currentRating && currentRating > 0) {
    //               currentCompetenceRatingDoneCounter++;
    //               ratingDoneCounter++;
    //             }
    //           }
    //         } else {
    //           for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
    //             const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;

    //             let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
    //             if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
    //               formattedTitle = formattedTitle.split('|')[0];
    //             }

    //             tempCompetence.push({
    //               ...tempBehaviour[this.parentBehaviourIds[i]][j],
    //               title: formattedTitle,
    //               rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
    //             });

    //             // Media finale
    //             ratingCounter++;
    //             ratingSum = ratingSum + (currentRating || 0);

    //             // Media parziale
    //             currentCompetenceRatingCounter++;
    //             currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

    //             if (currentRating && currentRating > 0) {
    //               currentCompetenceRatingDoneCounter++;
    //               ratingDoneCounter++;
    //             }
    //           }
    //         }

    //         // Alla fine, aggiungo la media parziale
    //         if (tempCompetence && tempCompetence.length) {
    //           currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    //           currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;
    //           const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

    //           currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    //           if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
    //             currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    //           }

    //           currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    //           if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
    //             currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    //           }

    //           tempCompetence.push({
    //             disabled: true,
    //             isPartialAverage: true,
    //             avgTitle: this.translate.instant("generic.PARTIL_AVG"),
    //             ratingScale: currentCompetenceRatingEvalScale || 5,
    //             ratingAvgNum: currentCompetenceRatingAvgNum,
    //             ratingStars: Math.round(currentCompetenceRatingAvgNum),
    //             selfRatingAvgNum: currentCompetenceSelfRatingAvgNum,
    //             selfRatingStars: Math.round(currentCompetenceSelfRatingAvgNum),
    //             selfRatingAvgString: currentCompetenceSelfRatingAvgString,
    //             allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
    //           });
    //         }
    //         this.competenceArray.push({
    //           category: parent.text,
    //           competences: tempCompetence,
    //           ratingScale: tempCompetence[0].evaluationScale.length || 5
    //         })

    //       }

    //       // Media finale
    //       let ratingAvgNum;
    //       let selfRatingAvgNum;
    //       let ratingAvgString;
    //       let selfRatingAvgString;

    //       ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
    //       selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

    //       const ratingEvalScale = this.competenceArray && this.competenceArray[0] && this.competenceArray[0].evaluationScale && this.competenceArray[0].evaluationScale.length || 5;

    //       ratingAvgString = ratingAvgNum.toString();
    //       if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
    //         ratingAvgString = ratingAvgString.replace(".", ",");
    //       }

    //       selfRatingAvgString = selfRatingAvgNum.toString();
    //       if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
    //         selfRatingAvgString = selfRatingAvgString.replace(".", ",");
    //       }

    //       // Indica se tutti i comportamenti hanno avuto almeno una valutazione
    //       let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

    //       this.softSkillsFinalAvg = {
    //         allBehavioursValuated: allBehavioursValuated,
    //         title: this.translate.instant('generic.FINAL_AVG'),
    //         subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
    //         ratingAvgNum: ratingAvgNum,
    //         ratingStars: Math.round(ratingAvgNum),
    //         selfRatingAvgNum: selfRatingAvgNum,
    //         selfRatingStars: Math.round(selfRatingAvgNum),
    //         ratingAvgString: ratingAvgString,
    //         selfRatingAvgString: selfRatingAvgString,
    //         ratingScale: ratingEvalScale,
    //         isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted
    //       }

    //       this.manageBehaviorComments = data.response && data.response.overallComment || '';
    //       if (this.manageBehaviorComments) {
    //         this.manageBehaviorCommentsCopy = JSON.parse(JSON.stringify(this.manageBehaviorComments));
    //       }

    //       this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
    //       this.isLoadingBehaviours = false;
    //       // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
    //       this.hasToOpenTutorial();

    //       // Calcolo le medie parziali di ogni comportamento


    //       /*
    //                 let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.competenceArray);
    //                 getCalculatedStarsCountByAvgPromise.then(() => {

    //                   if (isPostCalibrationOrFinalStepCompleted) {
    //                     // Calcolo le medie parziali del self assessment
    //                     let getCalculatedStarsCountByAvgSelfPromise = this.getCalculatedStarsCountByAvg(this.competenceArray, true);
    //                     getCalculatedStarsCountByAvgSelfPromise.then(() => {
    //                       this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));

    //                       // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
    //                       if (allBehavioursValuated) {
    //                         let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
    //                         getFinalAvgStarRatingPromise.then(() => {
    //                           // Calcolo la media finale del self assessment
    //                           let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg, true);
    //                           getFinalAvgStarRatingSelfPromise.then(() => {
    //                             this.isLoadingBehaviours = false;

    //                             // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
    //                             this.hasToOpenTutorial();
    //                           });
    //                         });
    //                       } else {
    //                         this.isLoadingBehaviours = false;

    //                         // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
    //                         this.hasToOpenTutorial();
    //                       }
    //                     });
    //                   } else {
    //                     // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
    //                     if (allBehavioursValuated) {
    //                       let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
    //                       getFinalAvgStarRatingPromise.then(() => {
    //                         this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
    //                         this.isLoadingBehaviours = false;

    //                         // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
    //                         this.hasToOpenTutorial();
    //                       });
    //                     } else {
    //                       this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
    //                       this.isLoadingBehaviours = false;

    //                       // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
    //                       this.hasToOpenTutorial();
    //                     }
    //                   }
    //                 });


    //                 */
    //     }
    //   }, (err?: any) => {
    //     const messageObj: ApplicationModalMessage = {
    //       modalId: "pdm010",
    //       text: this.translate.instant("errors." + err?.message),
    //       title: this.translate.instant("generic.WARNING")
    //     }
    //     this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //     this.isLoadingBehaviours = false;
    //   });
  }

  // Controlla se aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
  hasToOpenTutorial(checkPersonalObjectiveEvaluationTutorial?: boolean) {
    this.checkPassStructureTutorial();
    if (checkPersonalObjectiveEvaluationTutorial) {
      this.checkPersonalObjectiveEvaluationTutorial();
    }
    this.checkOrganizationalBehaviorsTutorial();
    this.checkTechSkillEvaluationTutorial();
    this.checkSetGoalsTutorial();
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  updateAverage() {
    // this.softSkillsFinalAvg = {
    //   allBehavioursValuated: allBehavioursValuated,
    //   title: this.translate.instant('generic.FINAL_AVG'),
    //   subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
    //   ratingAvgNum: ratingAvgNum,
    //   ratingStars: Math.round(ratingAvgNum),
    //   selfRatingAvgNum: selfRatingAvgNum,
    //   selfRatingStars: Math.round(selfRatingAvgNum),
    //   ratingAvgString: ratingAvgString,
    //   selfRatingAvgString: selfRatingAvgString,
    //   ratingScale: ratingEvalScale,
    //   isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted
    // }
    const softSkillsFinalAvgCopy = { ...this.softSkillsFinalAvg };
    softSkillsFinalAvgCopy.ratingStars = 3;

  }

  onManageBehaviorCommentChanged(text: string) {
    this.manageBehaviorComments = text
  }

  getCompetenceToEvaluateForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingTechSkills = true;
    this.techSkillCompetenceToChange = null;
    this.techSkillsFinalAvg = null;

    this.techSkillCompetenceToChange = null;
    this.techSkillsFinalAvg = null;

    if (this.getCompetenceToEvaluateForManager$) {
      this.getCompetenceToEvaluateForManager$.unsubscribe();
    }

    this.getCompetenceToEvaluateForManager$ = this.peopleAppraisalService.getCompetenceToEvaluateForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          /*
          this.techSkills.competences = []
          if (this.isPostCalibration || this.isFinalStepCompleted) {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
                selfRating: this.getBehaviourRating(data.response.competences[j].colleagueEvaluationRating, data.response.competences[j].evaluationScale.length)
              })
            }
          } else {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
              })
            }
          }
          this.competenceForModal = {};
          this.competenceForModal.evaluationScale = data.response.competences[0].evaluationScale;
          this.techSkillRatingScale = (this.techSkills.competences && this.techSkills.competences[0] && this.techSkills.competences[0].evaluationScale.length) || 5;*/

          // Parte aggiunta per modificare le competenze tech come le competenze soft
          let ratingCounter = 0;
          let ratingSum = 0;
          let selfRatingCounter = 0;
          let selfRatingSum = 0;
          let ratingDoneCounter = 0;

          let parentCompetences = data.response && data.response.competences && data.response.competences.map((x: any) => {
            return {
              competenceId: x.parentCompetence.competenceId,
              title: x.parentCompetence.text
            }
          })
          this.techSkillParentBehaviourIds = parentCompetences && parentCompetences.map((item: any) => item.competenceId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.competences.length; j++) {
              let behavior = data.response.competences[j];
              if (behavior.parentCompetence.competenceId == this.techSkillParentBehaviourIds[i]) {
                if (tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && !tempBehaviour[this.techSkillParentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.techSkillParentBehaviourIds[i]] || !tempBehaviour[this.techSkillParentBehaviourIds[i]].length) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }

          this.techSkillArray = [];
          let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;
          let allCompetences = [];
          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            let currentCompetenceSelfRatingSum = 0;

            let currentCompetenceRatingAvgNum = 0;
            let currentCompetenceSelfRatingAvgNum = 0;
            let currentCompetenceRatingAvgString = null;
            let currentCompetenceSelfRatingAvgString = null;
            let currentCompetenceRatingDoneCounter = 0;

            let parent = tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && tempBehaviour[this.techSkillParentBehaviourIds[i]][0].parentCompetence;
            let tempCompetence = [];

            if (isPostCalibrationOrFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;
                const currentSelfRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].colleagueEvaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length),
                  selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                selfRatingCounter++;
                ratingSum = ratingSum + (currentRating || 0);
                selfRatingSum = selfRatingSum + (currentSelfRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            } else {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);


                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            }

            // Alla fine, aggiungo la media parziale
            if (tempCompetence && tempCompetence.length) {
              // currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
              // currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

              const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

              currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
              if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
              }

              currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
              if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
              }

              tempCompetence.push({
                disabled: true,
                isPartialAverage: true,
                avgTitle: this.translate.instant("generic.PARTIL_AVG"),
                ratingScale: currentCompetenceRatingEvalScale || 5,
                ratingAvgNum: currentCompetenceRatingAvgNum,
                ratingStars: Math.round(currentCompetenceRatingAvgNum),
                selfRatingAvgNum: currentCompetenceSelfRatingAvgNum,
                selfRatingStars: Math.round(currentCompetenceSelfRatingAvgNum),
                ratingAvgString: currentCompetenceRatingAvgString,
                selfRatingAvgString: currentCompetenceSelfRatingAvgString,
                // allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
              });
            }

            if (parent.text && parent.text.length && parent.text.includes('|')) {
              parent.text = parent.text.split('|')[0];
            }
            this.techSkillArray.push({
              category: parent.text,
              competences: tempCompetence,
              ratingScale: tempCompetence[0].evaluationScale.length || 5
            });
          }

          // Media finale
          let ratingAvgNum;
          let selfRatingAvgNum;
          let ratingAvgString;
          let selfRatingAvgString;

          ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
          selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

          const ratingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

          ratingAvgString = ratingAvgNum.toString();
          if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
            ratingAvgString = ratingAvgString.replace(".", ",");
          }

          selfRatingAvgString = selfRatingAvgNum.toString();
          if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
            selfRatingAvgString = selfRatingAvgString.replace(".", ",");
          }

          // Indica se tutti i comportamenti hanno avuto almeno una valutazione
          let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

          this.techSkillsFinalAvg = {
            allBehavioursValuated: allBehavioursValuated,
            title: this.translate.instant('generic.FINAL_AVG'),
            subtitle: this.translate.instant('generic.TECH_COMPETENCES'),
            ratingAvgNum: ratingAvgNum,
            ratingStars: Math.round(ratingAvgNum),
            selfRatingAvgNum: selfRatingAvgNum,
            selfRatingStars: Math.round(selfRatingAvgNum),
            ratingAvgString: ratingAvgString,
            selfRatingAvgString: selfRatingAvgString,
            ratingScale: ratingEvalScale,
            isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted
          }

          this.techSkillComment = data.response.overallComment || '';
          if (this.isFinalStepCompleted && !this.techSkillComment) {
            //  this.techSkillComment = this.translations['generic.NO_COMMENT_INSERTED']
          }

          if (this.techSkillComment) {
            this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
          }

          this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
          if (this.techSkills) {
            this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
          }

          this.isLoadingTechSkills = false;
          this.hasToOpenTutorial();


          // Calcolo le medie parziali di ogni comportamento
          /*
          let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.techSkillArray);
          getCalculatedStarsCountByAvgPromise.then(() => {
            if (isPostCalibrationOrFinalStepCompleted) {
              // Calcolo le medie parziali del self assessment
              let getCalculatedStarsCountByAvgSelfPromise = this.getCalculatedStarsCountByAvg(this.techSkillArray, true);
              getCalculatedStarsCountByAvgSelfPromise.then(() => {
                this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
   
                if (this.techSkills) {
                  this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                }
   
                // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
                if (allBehavioursValuated) {
                  let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                  getFinalAvgStarRatingPromise.then(() => {
                    // Calcolo la media finale del self assessment
                    let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg, true);
                    getFinalAvgStarRatingSelfPromise.then(() => {
                      this.isLoadingTechSkills = false;
                      this.hasToOpenTutorial();
                    });
                  });
                } else {
   
                  this.isLoadingTechSkills = false;
                  this.hasToOpenTutorial();
                }
              });
            } else {
              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                  if (this.techSkills) {
                    this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                  }
   
                  this.isLoadingTechSkills = false;
                  this.hasToOpenTutorial();
                });
              } else {
                this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                if (this.techSkills) {
                  this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                }
   
                this.isLoadingTechSkills = false;
                this.hasToOpenTutorial();
              }
            }
          });
   
          */
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTechSkills = false;
      });
  }

  onTechSkillCommentChanged(text: string) {
    this.techSkillComment = text
  }

  // Aggiornamento valutazione comportamento
  techSkillRatingChanged(competence: any) {
    let competenceEvaluation: { competenceId: string, evaluationScore: number };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    competenceEvaluation = { competenceId: competence.competenceId, evaluationScore: score };
    let comment;
    if (this.techSkillComment != this.techSkillCommentCopy) {
      comment = this.techSkillComment;
    }

    this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, [competenceEvaluation], comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbarPersonDetails");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }

  checkPassStructureTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    if (forcePasStructureTutorial) {
      this.forcePasTutorialStep = this.stepList[0];
      this.openPasStructureTutorial();
    }
  }

  checkPersonalObjectiveEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');
    if (forcePasStructureTutorial) {
      if (!this.currentStep.id && this.stepList && this.stepList[0]) {
        this.forcePasTutorialStep = this.stepList[0];
        this.onStepClicked(this.stepList[0], true);
      } else {
        this.openPersonalObjectiveEvaluationTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openPersonalObjectiveEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.personObjectiveEvaluation.1.TITLE',
        'tutorials.personObjectiveEvaluation.1.DESC',
        'tutorials.personObjectiveEvaluation.2.TITLE',
        'tutorials.personObjectiveEvaluation.2.DESC',
        'tutorials.personObjectiveEvaluation.3.TITLE',
        'tutorials.personObjectiveEvaluation.3.DESC',
        'tutorials.personObjectiveEvaluation.4.TITLE',
        'tutorials.personObjectiveEvaluation.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#firstStepPageHeader',
          /** Titolo */
          title: translations['tutorials.personObjectiveEvaluation.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.personObjectiveEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.toEvaluateList && this.toEvaluateList.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-title',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-description',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#saveGoalToEvaluateBtn',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'PERSONAL OBJECTIVE EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        // aggiunto il timeout altrimenti partiva prima che terminasse il loader dei dati
        setTimeout(() => this.guidedTour.startTour(intro), 500);
      });
  }

  checkOrganizationalBehaviorsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 2 && this.stepList && this.stepList[1]) {
        this.forcePasTutorialStep = this.stepList[1];
        this.onStepClicked(this.stepList[1], forcePasStructureTutorial === "true");
      } else {
        this.openOrganizationalBehaviorsTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openOrganizationalBehaviorsTutorial() {
    this.translate.get(
      [
        'tutorials.organizationalBehaviors.1.TITLE',
        'tutorials.organizationalBehaviors.1.DESC',
        'tutorials.organizationalBehaviors.2.TITLE',
        'tutorials.organizationalBehaviors.2.DESC',
        'tutorials.organizationalBehaviors.3.TITLE',
        'tutorials.organizationalBehaviors.3.DESC',
        'tutorials.organizationalBehaviors.4.TITLE',
        'tutorials.organizationalBehaviors.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#secondStepPageHeader',
          /** Titolo */
          title: translations['tutorials.organizationalBehaviors.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.organizationalBehaviors.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.competenceArray && this.competenceArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceEvaluationNotes',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'ORGANIZATIONAL BEHAVIOURS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  checkTechSkillEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 3 && this.stepList && this.stepList[2]) {
        this.forcePasTutorialStep = this.stepList[2];
        this.onStepClicked(this.stepList[2], forcePasStructureTutorial === "true");
      } else {
        this.openTechSkillEvaluationTutorial();
      }
    }
  }

  checkSetGoalsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 4 && this.stepList && (this.stepList.length > 4 ? this.stepList[3] : this.stepList[2])) {
        if (this.stepList.length > 4) {
          this.forcePasTutorialStep = this.stepList[3];
        } else {
          this.forcePasTutorialStep = this.stepList[2];
        }
        this.onStepClicked(this.forcePasTutorialStep, forcePasStructureTutorial === "true");
      } else {
        this.openSetGoalsTutorial();
      }
    }
  }

  // Apre il tutorial delle competenze tecniche
  openSetGoalsTutorial() {
    this.translate.get(
      [
        'tutorials.setGoals.1.TITLE',
        'tutorials.setGoals.1.DESC',
        'tutorials.setGoals.1_SPECIALIST.TITLE',
        'tutorials.setGoals.1_SPECIALIST.DESC',
        'tutorials.setGoals.2.TITLE',
        'tutorials.setGoals.2.DESC',
        'tutorials.setGoals.3.TITLE',
        'tutorials.setGoals.3.DESC',
        'tutorials.setGoals.4.TITLE',
        'tutorials.setGoals.4.DESC',
        'tutorials.setGoals.3_SPECIALIST.TITLE',
        'tutorials.setGoals.3_SPECIALIST.DESC'

      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#fourthStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.1_SPECIALIST.TITLE'] : translations['tutorials.setGoals.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.1_SPECIALIST.DESC'] : translations['tutorials.setGoals.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#addGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.2.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#saveGoalBtn',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.3_SPECIALIST.TITLE'] : translations['tutorials.setGoals.3.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.3_SPECIALIST.DESC'] : translations['tutorials.setGoals.3.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'SET GOALS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial delle competenze tecniche
  openTechSkillEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.techSkillEvaluation.1.TITLE',
        'tutorials.techSkillEvaluation.1.DESC',
        'tutorials.techSkillEvaluation.2.TITLE',
        'tutorials.techSkillEvaluation.2.DESC',
        'tutorials.techSkillEvaluation.3.TITLE',
        'tutorials.techSkillEvaluation.3.DESC',
        'tutorials.techSkillEvaluation.4.TITLE',
        'tutorials.techSkillEvaluation.4.DESC',
        'tutorials.otsEvaluation.1.TITLE',
        'tutorials.otsEvaluation.1.DESC',
        'tutorials.otsEvaluation.2.TITLE',
        'tutorials.otsEvaluation.2.DESC',
        'tutorials.otsEvaluation.3.TITLE',
        'tutorials.otsEvaluation.3.DESC',
        'tutorials.otsEvaluation.4.TITLE',
        'tutorials.otsEvaluation.4.DESC',
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#thirdStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.TITLE'] : translations['tutorials.techSkillEvaluation.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.DESC'] : translations['tutorials.techSkillEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.techSkillArray && this.techSkillArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.TITLE'] : translations['tutorials.techSkillEvaluation.2.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.DESC'] : translations['tutorials.techSkillEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.TITLE'] : translations['tutorials.techSkillEvaluation.3.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.DESC'] : translations['tutorials.techSkillEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#techEvaluationNotes',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.TITLE'] : translations['tutorials.techSkillEvaluation.4.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.DESC'] : translations['tutorials.techSkillEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'TECH SKILL EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial della struttura PAS
  openPasStructureTutorial() {
    this.translate.get(
      [
        'tutorials.pasStructure.1.TITLE',
        'tutorials.pasStructure.1.DESC',
        'tutorials.pasStructure.2.TITLE',
        'tutorials.pasStructure.2.DESC',
        'tutorials.pasStructure.3.TITLE',
        'tutorials.pasStructure.3.DESC',
        'tutorials.pasStructure.4.TITLE',
        'tutorials.pasStructure.4.DESC',
        'tutorials.pasStructure.5.TITLE',
        'tutorials.pasStructure.5.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#userAvatar',
          /** Titolo */
          title: translations['tutorials.pasStructure.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPasStructureTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#takeNoteBtn',
          /** Titolo */
          title: translations['tutorials.pasStructure.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#pas',
          /** Titolo */
          title: translations['tutorials.pasStructure.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notes',
          /** Titolo */
          title: translations['tutorials.pasStructure.4.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.4.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#stepper',
          /** Titolo */
          title: translations['tutorials.pasStructure.5.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.5.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 25
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'PAS STRUCTURE TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPasStructureTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }


  anyEmptyEvaluation() {
    if (this.finalEvaluation.evaluatedManagerUserGoals && this.finalEvaluation.evaluatedManagerUserGoals?.length) {
      for (let i = 0; i < this.finalEvaluation.evaluatedManagerUserGoals?.length; i++) {
        if (!this.finalEvaluation.evaluatedManagerUserGoals[i].evaluation || !this.finalEvaluation.evaluatedManagerUserGoals[i].evaluation.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  getFinalEvaluationData() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingFinalEvaluationData = true;

    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }

    this.getFinalEvaluationData$ = this.peopleAppraisalService.getPerformanceAssessmentIntermediateEvaluationDataForManager(this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        }
        // In alcuni casi potrei aver effettuato la chiamata per un assessment che non esiste (nel caso il processo sia fake per la fase di goal update)
        else if (data.response) {
          this.finalEvaluation.evaluatedManagerUserGoals = [];
          this.selectedObjective = [];
          // this.insertByUser = this.personDetails.forename;
          // this.insertByManager = data.response.evaluationManager.forename;
          this.finalEvaluation = {};
          this.finalEvaluation = data.response;

          this.forcePerformerPasProfile = data.response?.pasProfile;
          // Se ci sono obiettivi assengo il primo valido
          if (this.finalEvaluation.evaluatedManagerUserGoals?.length) {
            this.selectedObjective = this.finalEvaluation.evaluatedManagerUserGoals[0];
          }
          if (this.finalEvaluation.evaluatedSubordinateUserGoals?.length) {
            this.selectedObjectiveSubordinate = this.finalEvaluation.evaluatedSubordinateUserGoals[0];
          }
          // Calcolo media per il performer
          const managerAvgCompetence = this.finalEvaluation.managerCompetenceEvaluation?.averageCompetenceManagerEvaluation ? this.finalEvaluation.managerCompetenceEvaluation?.averageCompetenceManagerEvaluation : 0;
          const subordinateAvgCompetence = this.finalEvaluation.subordinateCompetenceEvaluation?.averageCompetenceSubordinateEvaluation ? this.finalEvaluation.subordinateCompetenceEvaluation?.averageCompetenceSubordinateEvaluation : 0;
          // this.performerAvgValue = (managerAvgCompetence + subordinateAvgCompetence) / 2;
          let goals = this.finalEvaluation.evaluatedManagerUserGoals;
          this.performerAvgValue = undefined;
          for (let i = 0; i < goals?.length; i++) {
            if (goals[i].evaluationVote >= 0) {
              this.performerAvgValue += goals[i].evaluationVote;
            }
          }


          this.managerialOverallGraph = [];
          this.genericOverallGraph = [];
          let k = 0;
          for (let i = 0; i < this.finalEvaluation?.competenceEvaluationData?.length; i++) {
            const competence = this.finalEvaluation?.competenceEvaluationData[i];
            if (competence.isManagerial) {
              this.managerialOverallGraph.push({
                arg: String.fromCharCode(65 + i),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageFeedbackEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + i), desc: competence.macroCompetence }
              })
            } else {
              this.genericOverallGraph.push({
                arg: String.fromCharCode(65 + k),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageFeedbackEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + k), desc: competence.macroCompetence }
              })
              k++;
            }
          }

          if (this.currentStep.isComplete == true) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.isLoadingFinalEvaluationData = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  onFinalEvaluationCommentChanged(text: string) {
    this.finalEvaluation.comment = text
  }

  // sendFinalEvaluation() {
  //   this.isLoadingFinalEvaluationData = true;
  //   this.peopleAppraisalService.sendFinalEvaluationFormForManager(this.userId)
  //     .subscribe((data: SenecaResponse<any>) => {
  //       if (data && data.error) {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "fe001",
  //           text: this.translate.instant("errors." + data.error),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       } else {
  //         this.updateUserPhaseStatus();
  //         this.showSaveSuccessfulToaster();
  //         this.getUserProfileTab();
  //       }
  //     })
  // }

  updateUserPhaseStatus() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }
    this.subordinatesService.getSubordinatesForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.store.dispatch(PeopleAppraisalActions.UpdateSelfAssessmentUserStatusType({
            payload: {
              userId: this.userId,
              userStatus: data.response[0].status
            }
          }));
        }
      })
  }

  manageFinalEvaluationAction(id: string) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    } else if (id == 'delete') {
      this.finalEvaluation.comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    }
  }

  onEditFinalEvaluationComment() {
    this.editFinalEvaluationComment = !this.editFinalEvaluationComment;
  }

  onEditBehaviourComment() {
    this.editBehaviorComment = !this.editBehaviorComment;
  }

  onBehaviorCommentEdited(text: string) {
    this.finalEvaluation.behaviorData.comment = text;
  }


  onEditCompetenceComment(text: string) {
    this.editCompetenceComment = !this.editCompetenceComment;
  }

  onCompetenceCommentEdited(text: string) {
    this.finalEvaluation.competenceData.comment = text;
  }


  manageBehaviorEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              this.showSaveSuccessfulToaster();
            }
          }
          this.editBehaviorComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.behaviorData.comment = '';
      } else {
        this.manageBehaviorComments = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    }
  }

  manageCompetenceEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              this.showSaveSuccessfulToaster();
            }
          }
          this.editCompetenceComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.competenceData.comment = '';
      } else {
        this.techSkillComment = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em006",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editCompetenceComment = false;
        })
    }
  }


  editCurrentObjective(objective: any) {
    objective.editObjectiveEvaluation = !objective.editObjectiveEvaluation;
  }

  onObjectiveEvaluationEdited(text: string) {
    this.selectedObjective.evaluation = text;
  }
  // MITODO controllo 
  manageObjectiveEvaluationAction(id: string) {
    if (id == 'save') {
      let evaluationForService: Array<{ goalId: string, evaluation: string | undefined, evaluationVote: number | undefined }> = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluation: this.finalEvaluation.evaluatedData[i].evaluation,
          evaluationVote: this.finalEvaluation.evaluatedData[i].evaluationVote
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    } else if (id == 'delete') {
      this.selectedObjective.evaluation = null;
      let evaluationForService: Array<{ goalId: string, evaluation: string | undefined, evaluationVote: number | undefined }> = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluation: this.finalEvaluation.evaluatedData[i].evaluation,
          evaluationVote: this.finalEvaluation.evaluatedData[i].evaluationVote
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em008",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.selectedObjective.evaluation = '';
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    }
  }

  onObjectiveEdit(newText: any) {
    this.editObjectiveData.title = newText;
  }

  // Chiudi modale edit obiettivo
  closeEditObjectiveModal(confirm?: boolean) {
    this.modalService.close('edit-objective-modal');

    if (confirm) {
      if (this.editObjectiveData.isSelfDefined) {
        for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
          if (!this.finalEvaluation.selfDefinedObjectives[i].cloned) {
            if (this.finalEvaluation.selfDefinedObjectives[i].id == this.editObjectiveData.id) {
              this.finalEvaluation.selfDefinedObjectives[i].title = this.editObjectiveData.title;
              this.finalEvaluation.selfDefinedObjectives[i].approved = true;
            }
          }
        }
        this.finalSelfDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.selfDefinedObjectives));
        // this.saveSubordinateDefinedObjective(true);
      } else {
        for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
          if (!this.finalEvaluation.definedObjectives[i].cloned) {
            if (this.finalEvaluation.definedObjectives[i].id == this.editObjectiveData.id) {
              this.finalEvaluation.definedObjectives[i].title = this.editObjectiveData.title;
            }
          }
        }
        this.saveFinalDefinedObjectives().then(() => {
          if (this.finalEvaluation.definedObjectives) {
            this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
          }
        });
      }
    }
    this.editObjectiveData = {};
  }

  // Chiudi modale cancellazione obiettivo
  closeApproveObjectiveModal(confirm?: boolean) {
    this.modalService.close('approve-objective-modal');
    if (confirm) {
      if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
        this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      let goals = [
        {
          goalId: this.objectiveToApprove.goalId,
          text: this.objectiveToApprove.text
        }
      ];
      this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.approvePerfAssessmentGoalsProposedBySubordinateForManager(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.objectiveToApprove.goalToBeApproved = false;
            this.objectiveToApprove = null;
            this.showSaveSuccessfulToaster();
            this.updatePhase2Steps(data.response);
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });

      // for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
      //   if (this.finalEvaluation.selfDefinedObjectives[i].id == this.objectiveToApprove.id) {
      //     this.finalEvaluation.selfDefinedObjectives[i].title = this.objectiveToApprove.title;
      //   }
      // }
      // this.manageSubordinateDefineObjectiveActions('save', this.objectiveToApprove);
    }
  }

  // Descrizione modale cancellazione obiettivo 
  getDeleteModalText() {
    if (this.objectiveToDelete && this.objectiveToDelete.text) {
      return this.translations['finalEvaluation.modals.DELETE_DESCR_1'] + ' <b>' + this.objectiveToDelete.text + '</b> ' + this.translations['finalEvaluation.modals.DELETE_DESCR_2'];
    } else {
      return '';
    }
  }

  getApproveModalText() {
    if (this.objectiveToApprove && this.objectiveToApprove.text) {
      return this.translations['finalEvaluation.modals.APPROVE_DESCR_1'] + ' <b>' + this.objectiveToApprove.text + '</b> ' + this.translations['finalEvaluation.modals.APPROVE_DESCR_2'];
    } else {
      return '';
    }
  }

  // Elimina ogni riferimento al tutorial dal session storage
  removeTutorialsRefFromSessionStorage() {
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');

    if (openPasStructureTutorial) {
      sessionStorage.removeItem('openPasStructureTutorial');
    }

    const openPersonalObjectiveEvaluationTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');

    if (openPersonalObjectiveEvaluationTutorial) {
      sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
    }

    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');

    if (openOrganizationalBehaviorsTutorial) {
      sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
    }

    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');

    if (openTechSkillEvaluationTutorial) {
      sessionStorage.removeItem('openTechSkillEvaluationTutorial');
    }

    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');

    if (openSetGoalsTutorial) {
      sessionStorage.removeItem('openSetGoalsTutorial');
    }
  }

  allStepCompleted() {
    if (this.isLeadershipTeam) {
      let leadershipStep = this.stepList.find((step: any) => step.id == 'behaviourEvaluation');
      if (leadershipStep && leadershipStep.isComplete) {
        return true;
      }
      return false;
    } else {
      if (this.isManagement) {
        for (let i = 0; i < 3; i++) {
          if (!this.stepList[i].isComplete) {
            return false;
          }
        }
        return true;
      } else {
        for (let i = 0; i < 4; i++) {
          if (!this.stepList[i].isComplete) {
            return false;
          }
        }
        return true;
      }
    }
  }

  canSaveDefinedObjectives() {
    if (this.newObjectiveList && this.newObjectiveList.length) {
      for (let i = 0; i < this.newObjectiveList.length; i++) {
        if (!this.newObjectiveList[i].text || !this.newObjectiveList[i].text.trim().length) {
          return false;
        }
      }
    }
    return true;
  }

  openSendFinalEvaluation() {
    this.modalService.open('send-hr-schedule')
  }

  // closeSendFinalEvaluation(confirm?: boolean) {
  //   this.modalService.close('send-hr-schedule');
  //   if (confirm) {
  //     this.isLoadingFinalEvaluationData = true;
  //     if (this.finalEvaluation.comment && this.finalEvaluation.comment.length) {
  //       let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
  //       saveFinalEvaluationStepPromise.then(() => {
  //         this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
  //           .subscribe((data: SenecaResponse<any>) => {
  //             if (data && data.error) {
  //               const messageObj: ApplicationModalMessage = {
  //                 modalId: "em001",
  //                 text: this.translate.instant("errors." + data.error),
  //                 title: this.translate.instant("generic.WARNING")
  //               }
  //               this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //             } else {
  //               this.sendFinalEvaluation();
  //             }
  //             this.editFinalEvaluationComment = false;
  //           })
  //       })
  //     } else {
  //       let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
  //       saveFinalEvaluationStepPromise.then(() => {
  //         this.sendFinalEvaluation();
  //       })
  //     }
  //   }
  // }

  getYearName(): string {
    if (!this.selectedYear) {
      console.log("No selected year");
      return '';
    }
    let yearName = '';
    if (this.selectedYear.perfYearCode == 'H1') {
      yearName = 'H2 ' + this.selectedYear?.perfYear
    } else {
      yearName = 'H1 ' + (this.selectedYear.perfYear + 1);
    }
    return yearName;
  }

  openFinalShareModal() {
    this.modalService.open('final-share-modal');
  }

  closeFinalShareModal(confirm?: boolean) {
    this.modalService.close('final-share-modal');
    if (confirm) {
      this.confirmFinalShare();
    }
  }

  onCancelEditFinalEvaluation(objective: any) {
    objective.title = objective.originalTitle;
    objective.isEdit = false;
    objective.originalTitle = undefined;
  }

  closeCannotShareModal() {
    this.modalService.close('cannot-share-modal');
  }

  onFeedbackCheck() {
    this.feedbackCheck = !this.feedbackCheck;
  }

  openSubordinateDeleteSavedObjective(objective: any, fromEdit?: boolean) {
    this.objectiveToDelete = objective;
    this.objectiveToDeleteFromEdit = fromEdit;
    this.modalService.open('confirm-delete-subordinate-saved-objective');
  }

  closeSubordinateDeleteSavedObjective(confirm?: boolean) {
    this.modalService.close('confirm-delete-subordinate-saved-objective');
    if (confirm) {
      this.deleteSubordinateObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
    }
  }


  deleteSubordinateObjective(objective: any) {
    // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deletePerformanceAssessmentGoalsBySubordinateForManager(this.userId, [objective.id])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }


  finalShare() {
    this.isLoadingFinalEvaluationData = true;
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }
    this.saveFinalEvaluationComment();
    this.finalShare$ = this.peopleAppraisalService.sharePostCalibrationForManager(this.userId, this.feedbackCheck)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fs001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluationData = false;
        } else {
          // Reset dati
          this.finalEvaluation = {};
          this.finalEvaluation.evaluatedData = [];
          this.finalEvaluation.behaviorData = {};
          this.finalEvaluation.competenceData = {};
          this.finalEvaluation.pasPerformer = {};
          this.finalEvaluation.evaluatedData = {};
          this.finalEvaluation.definedObjectives = [];
          this.finalEvaluation.selfDefinedObjectives = [];
          this.finalEvaluation.comment = null;
          this.finalEvaluationBehaviourCommentCopy = null;
          this.finalEvaluationCompetenceCommentCopy = null;
          this.finalEvaluationCommentCopy = null;
          this.finalEvaluationDataCopy = {};
          this.finalDefinedObjectivesCopy = [];
          this.finalSelfDefinedObjectivesCopy = [];
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "fs003",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFinalEvaluationData = false;
      });
  }

  // Controllo messaggio obiettivi
  checkNewObjectives() {
    if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
      let anyNew = this.finalEvaluation.selfDefinedObjectives.find((x: any) => x.goalToBeApproved);
      if (anyNew && anyNew.id) {
        return true;
      }
    }
    return false;
  }

  // Salva le note e i commenti della valutazione
  saveCompetenceEvaluation() {
    this.isLoadingBehaviours = true;

    if (!this.tempCompetenceUpdated.competenceEvaluation || !this.personDetails.userId || !this.tempCompetenceUpdated.overallComment) {
      return
    }

    this.isLoadingBehaviours = true;

    this.peopleAppraisalService.createUpdateCompetenceEvaluationForManager({
      competenceEvaluation: this.tempCompetenceUpdated.competenceEvaluation,
      userId: this.personDetails.userId,
      overallComment: { areaOfStrength: this.strangthsComment, areaOfImprovement: this.improvementComment }
    }).pipe(
      take(1),
      catchError((err) => this.handleServerError(err)))
      .subscribe(() => {
        this.isLoadingBehaviours = false
        //  Resetta l'oggetto dove vengono salvate le modifiche della pagina
        this.tempCompetenceUpdated = { overallComment: {}, competenceEvaluation: [] };
        this.getUserProfileTab(true);
        this.goToNextStep(true);
      });
  }

  // Salva le note e i commenti della valutazione
  saveBehavioursRatings() {
    // Salvo il commento
    this.manageBehaviorEvaluationAction("save", this.manageBehaviorComments, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, competenceArrayLength = this.competenceArray.length; j < competenceArrayLength; j++) {
      let competenceArray = this.competenceArray[j];

      if (competenceArray.competences && competenceArray.competences.length) {
        for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = competenceArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {

            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
              let score = 0;
              for (let i = 0; i < currentCompetence.rating.length; i++) {
                if (currentCompetence.rating[i].isClicked) {
                  score = currentCompetence.rating[i].id + 1;
                }
              }
              behaviorEvaluation = { behaviorId: currentCompetence.behaviorId, evaluationScore: score.toString() };
              // let comment;
              // if (this.manageBehaviorComments != this.manageBehaviorCommentsCopy) {
              //   comment = this.manageBehaviorComments;
              // }

              this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, behaviorEvaluation, this.manageBehaviorComments)
                .subscribe((data: SenecaResponse<boolean>) => {
                  if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm017",
                      text: this.translate.instant("errors." + data.error),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    resolve();
                  } else {
                    this.behaviourCompetenceToChange = null;
                    resolve();
                  }
                }, (err?: any) => {
                  resolve();
                  const messageObj: ApplicationModalMessage = {
                    modalId: "pdm018",
                    text: this.translate.instant("errors." + err?.message),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isLoadingBehaviours = false;
                });
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  onCompetenceRatingChanged(competence: any) {
    if (competence) {
      // let softSkill;

      // for (let i = 0, competencesLength = this.competenceArrayCopy.length; i < competencesLength; i++) {
      //   let currentCompetence = this.competenceArrayCopy[i];
      //   let competenceFound;

      //   if (currentCompetence.competences && !competenceFound) {
      //     for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
      //       let originalCompetence = currentCompetence.competences[m];

      //       if (originalCompetence.competenceId && originalCompetence.competenceId === competence.competenceId) {
      //         competenceFound = true;
      //         softSkill = currentCompetence;

      //         // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
      //         let originalScore = 0;
      //         for (let t = 0; t < originalCompetence.rating.length; t++) {
      //           if (originalCompetence.rating[t].isClicked) {
      //             originalScore = originalCompetence.rating[t].id + 1;
      //           }
      //         }
      //         let newScore = 0;
      //         for (let y = 0; y < competence.rating.length; y++) {
      //           if (competence.rating[y].isClicked) {
      //             newScore = competence.rating[y].id + 1;
      //           }
      //         }

      //         if (originalScore !== newScore) {
      //           this.behaviourCompetenceToChange = originalCompetence;
      //         } else {
      //           this.behaviourCompetenceToChange = null;
      //         }

      //         break;
      //       }
      //     }
      //   }
      // }
      // this.updateSoftSkillsPartialAvg(softSkill);
      // se non è disabilitata la valutazione competenze non modifico le valutazioni, neanche in temp
      if (!(this.isScheduleShared || this.isFinalStepCompleted || this.evaluationTabIsFinished)) {
        this.saveUpdateCompetenceEvaluation(competence)
      }
    }
  }

  tempCompetenceUpdated: {
    competenceEvaluation?: { competenceId: string, evaluationRating: number }[],
    overallComment: {
      areaOfStrength?: string,
      areaOfImprovement?: string
    }
  } = {
      competenceEvaluation: [],
      overallComment: {}
    };

  saveUpdateCompetenceEvaluation(competence: any) {
    this.tempCompetenceUpdated.competenceEvaluation = this.tempCompetenceUpdated.competenceEvaluation?.filter(c => c.competenceId !== competence.competenceId);
    this.tempCompetenceUpdated.competenceEvaluation?.push({ competenceId: competence.competenceId, evaluationRating: competence.evaluationScore });
  }

  // Aggiorna in real time la media parziale delle soft skill
  updateSoftSkillsPartialAvg(softSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (currentCompetence.isPartialAverage) {

            if (isPostCalibrationOrFinalStepCompleted) {
              currentCompetence.selfRatingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.selfRatingAvgString = currentCompetenceRatingAvgString;
            } else {
              currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            }
            break;
          }
        }

        break;
      }
    }
  }

  // Aggiorna in real time la media parziale delle tech skill
  updateTechSkillsPartialAvg(techSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];
      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];

      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (currentCompetence.isPartialAverage) {

            if (isPostCalibrationOrFinalStepCompleted) {
              currentCompetence.selfRatingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.selfRatingAvgString = currentCompetenceRatingAvgString;
            } else {
              currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            }
            break;
          }
        }

        break;
      }
    }
  }

  onTechSkillRatingChanged(competence: any) {
    if (competence) {
      let techSkill;

      for (let i = 0, competencesLength = this.techSkillArrayCopy.length; i < competencesLength; i++) {
        let currentCompetence = this.techSkillArrayCopy[i];
        let competenceFound;

        if (currentCompetence.competences && !competenceFound) {
          for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
            let originalCompetence = currentCompetence.competences[m];

            if (originalCompetence.competenceId && originalCompetence.competenceId === competence.competenceId) {
              competenceFound = true;
              techSkill = currentCompetence;

              // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
              let originalScore = 0;
              for (let t = 0; t < originalCompetence.rating.length; t++) {
                if (originalCompetence.rating[t].isClicked) {
                  originalScore = originalCompetence.rating[t].id + 1;
                }
              }
              let newScore = 0;
              for (let y = 0; y < competence.rating.length; y++) {
                if (competence.rating[y].isClicked) {
                  newScore = competence.rating[y].id + 1;
                }
              }

              if (originalScore !== newScore) {
                this.techSkillCompetenceToChange = originalCompetence;
              } else {
                this.techSkillCompetenceToChange = null;
              }

              break;
            }
          }
        }
      }

      this.updateTechSkillsPartialAvg(techSkill);
    }
  }

  // Verifica se qualcosa nello step 2 (valutazione comportamenti organizzativi) è cambiato e non è stato salvato
  anyBehaviourEvaluationChangesToSave() {
    // controlla se ci sono competenze non valutate
    // if (this.competenceNotEvaluated()) {
    //   return true
    // }
    if (this.tempCompetenceUpdated.competenceEvaluation?.length) {
      return true;
    }
    if (this.tempCompetenceUpdated.overallComment.areaOfImprovement?.length) {
      return true;
    }
    if (this.tempCompetenceUpdated.overallComment.areaOfStrength?.length) {
      return true;
    }
    return false;
  }

  competenceNotEvaluated() {
    let notEvaluated: string[] = []
    this.competenceArray.forEach(area => {
      area.competences.forEach((competence: any) => {
        if (competence.evaluationScore == null || competence.evaluationScore == undefined) {
          notEvaluated.push(competence.competenceId)
        }
      });
    });
    notEvaluated = notEvaluated.filter(Boolean)

    for (let i = 0; i < this.tempCompetenceUpdated.competenceEvaluation?.length! ?? 0; i++) {
      const isAllEvaluated = notEvaluated.findIndex(el => {
        return el == this.tempCompetenceUpdated.competenceEvaluation?.[i]?.competenceId
      })

      if (typeof isAllEvaluated == "number" && isAllEvaluated >= 0) {
        notEvaluated.splice(isAllEvaluated, 1)
      }
    }
    return notEvaluated.length ? true : false;
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkills() {
    this.isLoadingTechSkills = true;
    let saveTechSkillsRatingsPromise = this.saveTechSkillsRatings();

    saveTechSkillsRatingsPromise.then(() => {
      this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
      this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
      this.techSkillCompetenceToChange = null;
      this.isLoadingTechSkills = false;

      this.updateUserPhaseStatus();
      this.showSaveSuccessfulToaster();
      this.getUserProfileTab(true);

      // Vado allo step successivo
      this.goToNextStep(true);
    })
      .catch((e?) => {
        console.log(e);
        this.isLoadingTechSkills = false;
      })
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkillsRatings() {
    // Salvo il commento
    this.manageCompetenceEvaluationAction("save", this.techSkillComment, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, techSkillArrayLength = this.techSkillArray.length; j < techSkillArrayLength; j++) {
      let techSkillArray = this.techSkillArray[j];

      if (techSkillArray.competences && techSkillArray.competences.length) {
        for (let t = 0, competencesLength = techSkillArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = techSkillArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {
            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let competenceEvaluation: { competenceId: string, evaluationScore: number };
              let score = 0;
              for (let i = 0; i < currentCompetence.rating.length; i++) {
                if (currentCompetence.rating[i].isClicked) {
                  score = currentCompetence.rating[i].id + 1;
                }
              }

              if (score > 0) {
                // Se lo score fosse a 0, il servizio tornerebbe "INVALID_OBJECT"
                competenceEvaluation = { competenceId: currentCompetence.competenceId, evaluationScore: score };
                let comment;
                if (this.techSkillComment != this.techSkillCommentCopy) {
                  comment = this.techSkillComment;
                }

                this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, [competenceEvaluation], comment)
                  .subscribe((data: SenecaResponse<boolean>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    this.isLoadingTechSkills = false;
                  });
              } else {
                resolve();
              }
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare in base alla votazione
  getCalculatedStarsCountByAvg(arrayRef: any, isSelfRating?: boolean) {
    let promises = [];
    if (arrayRef && arrayRef.length) {
      for (let j = 0, competenceArrayLength = arrayRef.length; j < competenceArrayLength; j++) {
        let competenceArray = arrayRef[j];

        if (competenceArray.competences && competenceArray.competences.length) {
          for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
            let currentCompetence = competenceArray.competences[t];
            promises.push(new Promise((resolve: Function, reject: Function) => {

              if (!currentCompetence.isPartialAverage) {
                resolve();
              } else {
                const avg = isSelfRating ? currentCompetence.selfRatingAvgNum : currentCompetence.ratingAvgNum;

                this.peopleAppraisalService.getCalculatedStarsCountByAvg(avg)
                  .subscribe((data: SenecaResponse<number>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      if (isSelfRating) {
                        currentCompetence.selfRatingStars = data.response;
                      } else {
                        currentCompetence.ratingStars = data.response;
                      }

                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  });
              }
            }));
          }
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare per la media finale
  getFinalAvgStarRating(objRef: any, isSelfRating?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      const avg = isSelfRating ? objRef.selfRatingAvgNum : objRef.ratingAvgNum;

      this.peopleAppraisalService.getCalculatedStarsCountByAvg(avg)
        .subscribe((data: SenecaResponse<number>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm017",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          } else {
            if (isSelfRating) {
              objRef.selfRatingStars = data.response;
            } else {
              objRef.ratingStars = data.response;
            }

            resolve();
          }
        }, (err?: any) => {
          resolve();
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm018",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    });
  }

  // Verifica se qualcosa nello step 3 è cambiato e non è stato salvato
  anyTechSkillEvaluationChangesToSave() {
    if ((this.techSkillComment != this.techSkillCommentCopy)
      || this.techSkillCompetenceToChange) {
      return true;
    }
    return false;
  }

  saveFinalBehaviourComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  saveFinalBehaviourCompetenceComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCompetenceCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  // MITODO
  saveFinalEvaluationData() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalEvaluationDataChanged()) {
        resolve();
      } else {
        let evaluationForService: Array<{ goalId: string, evaluation: string | undefined, evaluationVote: number | undefined }> = [];
        for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
          evaluationForService.push({
            goalId: this.finalEvaluation.evaluatedData[i].goalId,
            evaluation: this.finalEvaluation.evaluatedData[i].evaluation,
            evaluationVote: this.finalEvaluation.evaluatedData[i].evaluationVote
          })
        }
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  deleteFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (this.finalDefinedObjectivesToDelete && this.finalDefinedObjectivesToDelete.length) {
        let goalIds = [];
        for (let t = 0, finalDefinedObjectivesToDeleteLength = this.finalDefinedObjectivesToDelete.length; t < finalDefinedObjectivesToDeleteLength; t++) {
          let currentObj = this.finalDefinedObjectivesToDelete[t];
          goalIds.push(currentObj.id);
        }

        this.peopleAppraisalService.deleteAssessmentGoalsForManager(this.userId, goalIds)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            this.finalDefinedObjectivesToDelete.length = 0;

            resolve();
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm004",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        resolve();
      }
    })
  }

  saveFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalDefinedObjectivesChanged()) {
        resolve();
      } else {
        let goals = [];
        for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
          if (!this.finalEvaluation.definedObjectives[i].cloned) {
            goals.push({
              goalId: this.finalEvaluation.definedObjectives[i].id,
              text: this.finalEvaluation.definedObjectives[i].title
            });
          }
        }

        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, goals)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  deleteFinalSelfDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (this.finalSelfDefinedObjectivesToDelete && this.finalSelfDefinedObjectivesToDelete.length) {
        let goalIds = [];
        for (let t = 0, finalSelfDefinedObjectivesToDeleteLength = this.finalSelfDefinedObjectivesToDelete.length; t < finalSelfDefinedObjectivesToDeleteLength; t++) {
          let currentObj = this.finalSelfDefinedObjectivesToDelete[t];
          goalIds.push(currentObj.id);
        }

        this.peopleAppraisalService.deletePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goalIds)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            this.finalSelfDefinedObjectivesToDelete.length = 0;

            resolve();
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm004",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        resolve();
      }
    })
  }

  saveFinalSelfDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalSelfDefinedObjectivesChanged()) {
        resolve();
      } else {
        let deleteFinalSelfDefinedObjectivesPromise = this.deleteFinalSelfDefinedObjectives();

        deleteFinalSelfDefinedObjectivesPromise.then(() => {
          if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
            let goals = [];
            for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
              goals.push({
                goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
                text: this.finalEvaluation.selfDefinedObjectives[i].title
              });
            }
            /*
                      this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
                        .subscribe((data: SenecaResponse<any>) => {
                          if (data && data.error) {
                            const messageObj: ApplicationModalMessage = {
                              modalId: "em001",
                              text: this.translate.instant("errors." + data.error),
                              title: this.translate.instant("generic.WARNING")
                            }
                            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                          }
          
                          resolve();
                        }, (err?: any) => {
                          const messageObj: ApplicationModalMessage = {
                            modalId: "pdm006",
                            text: this.translate.instant("errors." + err?.message),
                            title: this.translate.instant("generic.WARNING")
                          }
                          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                          resolve();
                        });
          */
          } else {
            resolve();
          }
        })
      }
    })
  }

  saveFinalEvaluationComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalCommentChanged()) {
        resolve();
      } else {
        this.editFinalEvaluationComment = false;
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  // Salva i dati dell'utimo step
  saveFinalEvaluationStep(preventReload?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      // Textarea delle note e dei commenti
      this.isLoadingFinalEvaluationData = true;
      let saveFinalEvaluationCommentPromise = this.saveFinalEvaluationComment();

      saveFinalEvaluationCommentPromise.then(() => {
        this.finalEvaluationCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.comment));

        // Valutazione comportamenti organizzativi
        let saveFinalBehaviourCommentPromise = this.saveFinalBehaviourComment();
        saveFinalBehaviourCommentPromise.then(() => {
          this.editBehaviorComment = false;
          const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
          this.finalEvaluationBehaviourCommentCopy = JSON.parse(JSON.stringify(comment));

          // Valutazione degli obiettivi
          let saveFinalEvaluationDataPromise = this.saveFinalEvaluationData();
          saveFinalEvaluationDataPromise.then(() => {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.finalEvaluationDataCopy = JSON.parse(JSON.stringify(this.finalEvaluation.evaluatedData));

            // Valutazione competenze tecniche
            let saveFinalBehaviourCompetenceCommentPromise = this.saveFinalBehaviourCompetenceComment();
            saveFinalBehaviourCompetenceCommentPromise.then(() => {
              this.editCompetenceComment = false;
              const techComment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
              this.finalEvaluationCompetenceCommentCopy = JSON.parse(JSON.stringify(techComment));

              // Obiettivi di sviluppo personale
              let saveFinalDefinedObjectivesPromise = this.saveFinalDefinedObjectives();
              saveFinalDefinedObjectivesPromise.then(() => {
                this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));

                // Obiettivi di sviluppo personale
                let saveFinalSelfDefinedObjectivesPromise = this.saveFinalSelfDefinedObjectives();
                saveFinalSelfDefinedObjectivesPromise.then(() => {
                  this.finalSelfDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.selfDefinedObjectives));
                  if (!preventReload) {
                    this.getFinalEvaluationData();
                    this.showSaveSuccessfulToaster();
                  }

                  this.isLoadingFinalEvaluationData = false;
                  resolve();
                });
              });
            });
          });
        });
      });
    });
  }

  isSendEvalBtnVisibleAndEnabled() {
    return !this.isScheduleShared && !this.isPostCalibration && !this.isFinalStepCompleted
      && !this.isLoadingFinalEvaluationData && this.allStepCompleted();
  }

  isConfirmShareBtnVisibleAndEnabled() {
    return this.isPostCalibration && !this.isFinalStepCompleted
      && !this.isLoadingFinalEvaluationData;
  }

  isFinalEvaluationDataChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0, dataLength = this.finalEvaluation.evaluatedData.length; i < dataLength; i++) {
        const currentData = this.finalEvaluation.evaluatedData[i];

        if (isChanged) {
          break;
        }

        if (currentData && currentData.evaluationItemId) {
          for (let t = 0, originalDataLength = this.finalEvaluationDataCopy.length; t < originalDataLength; t++) {
            const currentOriginalData = this.finalEvaluationDataCopy[t];

            if (currentOriginalData && currentOriginalData.evaluationItemId
              && currentOriginalData.evaluationItemId === currentData.evaluationItemId) {

              if (currentOriginalData.evaluation !== currentData.evaluation) {
                isChanged = true;
              }
              break;
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalDefinedObjectivesChanged() {
    let isChanged = false;

    // Controllo prima la lunghezza (magari qualche obiettivo è stato eliminato)
    const definedObjectivesCounter = this.finalEvaluation && this.finalEvaluation.definedObjectives && this.finalEvaluation.definedObjectives.length || 0;
    const originalDefinedObjectivesCounter = this.finalDefinedObjectivesCopy && this.finalDefinedObjectivesCopy.length || 0;

    if (definedObjectivesCounter != originalDefinedObjectivesCounter) {
      isChanged = true;
    } else {
      if (definedObjectivesCounter) {
        for (let i = 0; i < definedObjectivesCounter; i++) {
          const currentData = this.finalEvaluation.definedObjectives[i];

          if (isChanged) {
            break;
          }

          if (currentData && currentData.id) {
            for (let t = 0; t < originalDefinedObjectivesCounter; t++) {
              const currentOriginalData = this.finalDefinedObjectivesCopy[t];

              if (currentOriginalData && currentOriginalData.id
                && currentOriginalData.id === currentData.id) {

                if (currentOriginalData.title !== currentData.title) {
                  isChanged = true;
                }
                break;
              }
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalSelfDefinedObjectivesChanged() {
    let isChanged = false;

    // Controllo prima la lunghezza (magari qualche obiettivo è stato eliminato)
    const definedObjectivesCounter = this.finalEvaluation && this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length || 0;
    const originalDefinedObjectivesCounter = this.finalSelfDefinedObjectivesCopy && this.finalSelfDefinedObjectivesCopy.length || 0;

    if (definedObjectivesCounter != originalDefinedObjectivesCounter) {
      isChanged = true;
    } else {
      if (definedObjectivesCounter) {
        for (let i = 0; i < definedObjectivesCounter; i++) {
          const currentData = this.finalEvaluation.selfDefinedObjectives[i];

          if (isChanged) {
            break;
          }

          if (currentData && currentData.id) {
            for (let t = 0; t < originalDefinedObjectivesCounter; t++) {
              const currentOriginalData = this.finalSelfDefinedObjectivesCopy[t];

              if (currentOriginalData && currentOriginalData.id
                && currentOriginalData.id === currentData.id) {

                if (currentOriginalData.title !== currentData.title) {
                  isChanged = true;
                }
                break;
              }
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalBehaviourCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment
      && this.finalEvaluation.behaviorData.comment !== this.finalEvaluationBehaviourCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  isFinalBehaviourCompetenceCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment
      && this.finalEvaluation.competenceData.comment !== this.finalEvaluationCompetenceCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  isFinalCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.comment
      && this.finalEvaluation.comment !== this.finalEvaluationCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  // Verifica se qualcosa nello step 5 (riepilogo) è cambiato e non è stato salvato
  anythingToSaveInFinalEvaluation() {
    // if (this.isFinalCommentChanged() || this.isFinalBehaviourCommentChanged()
    //   || this.isFinalEvaluationDataChanged() || this.isFinalBehaviourCompetenceCommentChanged()
    //   || this.isFinalDefinedObjectivesChanged() || this.isFinalSelfDefinedObjectivesChanged()) {
    //   return true;
    // }
    // return false;
    if (this.interviewCheck || this.overAllSelectedItem.id) {
      return true
    }
    return false;
  }

  // Porta indietro di uno step
  goToPrevStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number - 2], skipCheck);
  }

  goToNextStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number], skipCheck);
  }

  goToAnswerPeerFeedback(feedbackId?: string) {
    this.router.navigate(['/peopleAppraisal/createUpdatePeerFeedback/response/' + this.userId + '/' + feedbackId])
  }

  /**  Type guard per definire se un feedback common è peer feedback */
  isPeerFeedback(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloyPeerFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK;
  }

  isSmartFeedback(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloySmartFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK;
  }

  isFeedbackToManager(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloyManagerFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK;
  }

  is360Feedback(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloyManagerFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_360_FEEDBACK;
  }

  // Recupero il count delle Tab
  getCounterFeedbackTabs() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    //Inizio la chimata per recuperare gli utenti 
    this.peopleAppraisalService.getCounterFeedbackTabs(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<PeerFeedbackServiceResponses.CountNotesAndFeedbacksForManager>) => {
        if (data && data.response) {
          this.counterTabs = data.response;
          this.menuButtons = [
            {
              id: 'pas',
              name: this.translate.instant('headerDossier.CARD')
            },
            {
              id: 'feedback',
              name: this.translate.instant('headerDossier.FEEDBACK_TITLE'),
              count: this.counterTabs.feedbacks.toString()
            },
            {
              id: 'notes',
              name: this.translate.instant('headerDossier.PRIVATE_NOTE'),
              count: this.counterTabs.privateNotes.toString()
            }]
          this.menuButtons = JSON.parse(JSON.stringify(this.menuButtons));
          this.isLoadingPersonDetails = false;
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "count2",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPersonDetails = false;
        }

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "count1",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonDetails = false;
      });
  }

  selectedItem(filter: any, selectInfo: { id: string, title: string }) {
    // cambio il filtro selezionato
    filter.selected = selectInfo;

    // Assegno il filtro in base al tipo di feedback
    if (selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK || selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK || selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK || selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_360_FEEDBACK) {
      // Incremento il counter solo se non è stato selezionato nulla
      if (!this.feedbackType.length) {
        this.filterCounter++;
      }

      this.feedbackType = [selectInfo.id];
    }

    // Assegno il filtro in base allo stato del feedback
    if (selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.RECEIVED ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.SENT ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.TO_SEND ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.WAITING) {

      // Incremento il counter solo se non è stato selezionato nulla
      if (!this.feedbackStatus) {
        this.filterCounter++;
      }

      // imposto il nuovo id al filtro 
      this.feedbackStatus = selectInfo.id;
    }

    if (selectInfo.id == YEARS_FILTER.LAST_YEAR ||
      selectInfo.id == YEARS_FILTER.LAST_2_YEARS ||
      selectInfo.id == YEARS_FILTER.LAST_3_YEARS ||
      selectInfo.id == YEARS_FILTER.LAST_4_YEARS ||
      selectInfo.id == YEARS_FILTER.ALL) {

      switch (selectInfo.id) {
        case YEARS_FILTER.LAST_YEAR:
          if (this.feedbackMinCreationDate.id !== YEARS_FILTER.LAST_YEAR) {
            this.filterCounter--;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_YEAR,
            date: moment().subtract(1, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_2_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_2_YEARS,
            date: moment().subtract(2, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_3_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_3_YEARS,
            date: moment().subtract(3, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_4_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_4_YEARS,
            date: moment().subtract(4, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.ALL:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.ALL,
            date: ''
          }
          break;
      }
    }

    // Reset filtri
    if (selectInfo.id == 'ALL_STATUS') {
      this.feedbackStatus = undefined;
      this.filterCounter--;
    }

    if (selectInfo.id == 'ALL_TYPE') {
      this.feedbackType = [];
      this.filterCounter--;
    }
    this.getFeedbacksForManager();
  }

  isItemDisabled() {
    let tmpStep = this.stepList.find((step: any) => step.id == "Overall");
    return (tmpStep && tmpStep.status == RentStatusTypes.COMPLETED) || false;
  }

  selectedCompetenceToComment?: any;
  commentText?: string;
  commentReadOnly?: boolean;
  onCommentInsertion(competence: any, competenceIdx: number) {
    this.commentReadOnly = false
    this.selectedCompetenceToComment = competence.competences[competenceIdx];
    this.modalService.open("competence-comment");
  }

  closeCommentInsertion() {
    this.modalService.close("competence-comment");
    this.selectedCompetenceToComment = undefined;
    this.commentText = undefined;
    this.commentReadOnly = false;
  }

  confirmCommentInsertion(dryRun?: boolean) {
    this.modalService.close("competence-comment");

    if (this.commentReadOnly) {
      this.commentReadOnly = false;
    }

    if (dryRun && this.commentText) {
      this.peopleAppraisalService.createComepetenceCommentForManager(
        this.selectedCompetenceToComment.competenceId,
        this.personDetails.userId,
        this.commentText
      ).pipe(
        tap(() => this.getCompetencesToEvaluateForManager()),
        catchError(err => this.handleServerError(err))
      ).subscribe();
    }


    this.selectedCompetenceToComment = undefined;
    this.commentText = undefined
  }

  onCommentInsertionChange(text: string) {
    this.commentText = text?.trim();
  }

  competence?: any;
  onShowCommentList(competence?: any[]) {
    this.modalService.open("competence-comment");
    this.competence = competence;
    this.commentReadOnly = true
  }

  closeCommentDeletion() {
    this.modalService.close("comment-delete");
  }

  commentIdToDelete?: string;
  confirmCommentDeletion(param?: { comment?: any, dryRun?: boolean }) {
    this.modalService.close("competence-comment");
    if (param?.comment) {
      this.commentIdToDelete = param.comment.commentId;
    }

    if (param?.dryRun && this.commentIdToDelete) {
      // Esegui chiamata per cancellazione
      this.peopleAppraisalService.deleteComepetenceCommentForManager(this.personDetails.userId, this.commentIdToDelete).pipe(
        take(1),
        tap(() => this.commentIdToDelete = undefined),
        tap(() => this.getCompetencesToEvaluateForManager()),
        catchError(err => this.handleServerError(err))
      ).subscribe();
      this.modalService.close("comment-delete");
    } else {
      this.modalService.open("comment-delete");
    }

    this.competence = undefined;
  }

  selectedCompetence: CommonClasses.PerfAlloyCompetence | undefined;
  averageSmartFeedbacks: number = -1;
  countSelectedCompetence: number = 0;
  onShowFeedbackList(selectedCompetence: CommonClasses.PerfAlloyCompetence) {
    this.selectedCompetence = selectedCompetence;
    const selectedSmartFeedbacks = selectedCompetence.feedbacks;
    this.countSelectedCompetence = this.selectedCompetence.feedbacks?.length ? this.selectedCompetence.feedbacks?.length : 0;
    let feedbackSum = -1;
    let selectedSmartFeedbacksValid = 0;
    if (selectedSmartFeedbacks) {
      for (const feedback of selectedSmartFeedbacks) {
        if (feedback?.evaluation != null && feedback?.evaluation != undefined && feedback.evaluation >= 0) {
          selectedSmartFeedbacksValid += 1;
          if (feedbackSum < 0) {
            feedbackSum = feedback.evaluation
          } else {
            feedbackSum += feedback.evaluation;
          }
        }
      }
    }
    if (feedbackSum >= 0 && selectedSmartFeedbacksValid) {
      this.averageSmartFeedbacks = feedbackSum / selectedSmartFeedbacksValid;
    }
    this.modalService.open("feedback-list")
  }

  closeFeedbackList() {
    this.selectedCompetence = undefined;
    this.modalService.close("feedback-list")
  }

  openTerminateEvaluationModal() {
    this.modalService.open('terminate-evaluation')
  }
  closeTerminateEvaluationModal(confirm?: boolean) {
    this.modalService.close('terminate-evaluation');

    if (confirm) {
      this.closeIntermediateEvaluationForm();
    }
  }

  // /close-intermediate-evaluation-form-for-manager

  closeIntermediateEvaluationForm() {
    this.isLoadingCloseIntermediateEvaluation = true;

    if (this.closeIntermediateEvaluation$) {
      this.closeIntermediateEvaluation$.unsubscribe();
    }

    if (this.selectedYear) {
      this.peopleAppraisalService.closeIntermediateEvaluationFormForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.response) {
            this.evaluationTabIsFinished = true;
            this.getFinalEvaluationData();
            this.isLoadingCloseIntermediateEvaluation = false;
          } else {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "count2",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingCloseIntermediateEvaluation = false;
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "count1",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCloseIntermediateEvaluation = false;
        });
    }
  }

  // Controlla se esistono obiettivi nell'anno precedente per poi fare il confronto nel riepilogo
  checkProgress() {
    if (this.finalEvaluation?.progress?.previousPerfAssessment?.averageCompetenceManagerEvaluation && this.finalEvaluation?.progress?.previousPerfAssessment?.averageGoalManagerEvaluation) {
      return true;
    }
    return false;
  }

  openShareIntermediateEvaluationModal() {
    this.modalService.open('share-intermediate')
  }

  closeShareIntermediateEvaluationModal(confirm?: boolean) {
    this.modalService.close('share-intermediate');
    if (confirm) {
      this.shareIntermediateEvaluation();
    }
  }

  // Condividi scheda
  shareIntermediateEvaluation() {
    this.isLoadingShareIntermediateEvaluation = true;

    if (this.shareIntermediateEvaluation$) {
      this.shareIntermediateEvaluation$.unsubscribe();
    }

    if (this.selectedYear) {
      this.shareIntermediateEvaluation$ = this.peopleAppraisalService.shareIntermediateEvaluationFormForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.response) {
            this.getUserProfileTab();
            this.isLoadingShareIntermediateEvaluation = false;
          } else {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "count2",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingShareIntermediateEvaluation = false;
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "count1",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingShareIntermediateEvaluation = false;
        });
    }
  }

  onSkillMatrixDownload() {
    const fileNameReq = this.peopleAppraisalService.exportSkillMatrixForManager(this.userId);
    const ssortkqpReq = this.peopleAppraisalService.createRetriveTokenAfterLogin()
    forkJoin([fileNameReq, ssortkqpReq]).pipe(
      tap(([filename, ssortkqp]) => this.peopleAppraisalService.downloadTempFile(encodeURIComponent(filename.response), ssortkqp.response)),
      catchError(err => this.handleServerError(err))
    ).subscribe()
  }

  handleServerError(err?: any) {
    const messageObj: ApplicationModalMessage = {
      modalId: "pdm010",
      text: this.translate.instant("errors." + err?.message),
      title: this.translate.instant("generic.WARNING")
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    this.isLoadingBehaviours = false;
    return []
  }

  // Controlla se esistono obiettivi e che questi siano tutti con una valutazioen
  allGoalsAreEvaluated() {
    if (this.finalEvaluation.evaluatedManagerUserGoals?.length) {
      let goals = this.finalEvaluation.evaluatedManagerUserGoals;
      let count: number = 0;
      for (let i = 0; i < goals?.length; i++) {
        if (goals[i].evaluationVote >= 0) {
          count++;
        }
      }
      if (count == this.finalEvaluation.evaluatedManagerUserGoals.length) {
        return true;
      }
    }

    if (this.finalEvaluationList.evaluatedManagerUserGoals?.length) {
      let goals = this.finalEvaluationList.evaluatedManagerUserGoals;
      let count: number = 0;
      for (let i = 0; i < goals?.length; i++) {
        if (goals[i].evaluationVote >= 0) {
          count++;
        }
      }
      if (count == this.finalEvaluationList.evaluatedManagerUserGoals.length) {
        return true;
      }
    }

    return false
  }

  updatePhase2Steps(status: string, isOverallView?: boolean) {
    let gsTempStep = this.stepList.find((step) => step.id == 'definingNewGoals');
    if (gsTempStep) {
      gsTempStep.status = status;
      gsTempStep.isComplete = status == RentStatusTypes.COMPLETED;
    }
    let overall = this.stepList.find((step: any) => step.id == 'Overall');
    if (overall) {
      if (gsTempStep && gsTempStep.isComplete) {
        overall.disabled = false;
      } else {
        overall.disabled = true;
      }
    }
    this.currentStep = isOverallView ? overall : gsTempStep;
  }

  onInterviewCheck(event: boolean) {
    this.interviewCheck = event;
  }

  onOverAllSelectedItem(event: { id: string | number, title: string | number }) {
    this.overAllSelectedItem = event;
    this.forcePerformerPasProfile = (event.id).toString();
  }

  // Recupera dati per Overall
  finalEvaluationData() {
    this.isLoadingFinalEvaluationList = true;
    this.finalEvaluation = {};
    this.finalEvaluationList = {};

    if (this.finalEvaluationData$) {
      this.finalEvaluationData$.unsubscribe();
    }

    if (this.selectedYear) {
      this.finalEvaluationData$ = this.peopleAppraisalService.getPerformanceAssessmentFinalEvaluationDataForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.response) {
            this.finalEvaluationList = data.response;
            this.finalEvaluation = data.response;
            this.forcePerformerPasProfile = data.response?.pasProfile;
            this.managerialOverallGraph = [];
            this.genericOverallGraph = [];
            let k = 0;
            for (let i = 0; i < this.finalEvaluationList?.competenceEvaluationData.length; i++) {
              const competence = this.finalEvaluationList.competenceEvaluationData[i];
              if (competence.isManagerial) {
                this.managerialOverallGraph.push({
                  arg: String.fromCharCode(65 + i),
                  evaluation: competence.averageManagerEvaluation,
                  selfAssessment: competence.averageSubordinateEvaluation,
                  feedback: competence.averageFeedbackEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                  legend: { title: String.fromCharCode(65 + i), desc: competence.macroCompetence }
                })
              } else {
                this.genericOverallGraph.push({
                  arg: String.fromCharCode(65 + k),
                  evaluation: competence.averageManagerEvaluation,
                  selfAssessment: competence.averageSubordinateEvaluation,
                  feedback: competence.averageFeedbackEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                  legend: { title: String.fromCharCode(65 + k), desc: competence.macroCompetence }
                })
                k++;
              }

            }
            if (this.currentStep.isComplete == true) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }
            // Se ci sono obiettivi assengo il primo valido
            if (this.finalEvaluation.evaluatedManagerUserGoals?.length) {
              this.selectedObjective = this.finalEvaluation.evaluatedManagerUserGoals[0];
            }
            if (this.finalEvaluation.evaluatedSubordinateUserGoals?.length) {
              this.selectedObjectiveSubordinate = this.finalEvaluation.evaluatedSubordinateUserGoals[0];
            }

            this.isLoadingFinalEvaluationList = false;
          } else {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "count2",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingFinalEvaluationList = false;

            if (this.currentStep.isComplete == true) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "count1",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluationList = false;
        });
    }
  }

  confirmFinalShare() {
    this.isLoadingFinalShareModal = true;

    if (this.finalShareModal$) {
      this.finalShareModal$.unsubscribe();
    }
    if (this.selectedYear) {
      this.finalShareModal$ = this.peopleAppraisalService.sendFinalEvaluationFormForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId, this.overAllSelectedItem.id as string, this.interviewCheck)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.response) {
            this.isfinalShare = true;
            this.isLoadingFinalShareModal = false;
            this.finalEvaluationData();
            this.getFinalEvaluationData();
            this.getUserProfileTab();
          } else {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "count2",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingFinalShareModal = false;
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "count1",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalShareModal = false;
        });
    }
  }

  // Redirect al dettaglio del feedback 360
  goToFeedback360Details(feedbackId: string) {
    this.router.navigate(['peopleAppraisal/teamFeedback360Details/', this.userId, feedbackId]);
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.clonePerformanceAssessmentGoalsByGoalIds$) {
      this.clonePerformanceAssessmentGoalsByGoalIds$.unsubscribe();
    }
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.personDetails$) {
      this.personDetails$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    // if (this.getPrivateNotesList$) {
    //   this.getPrivateNotesList$.unsubscribe();
    // }
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$.unsubscribe();
    }
    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$.unsubscribe();
    }
    if (this.getCompetencesToEvaluateForManager$) {
      this.getCompetencesToEvaluateForManager$.unsubscribe();
    }
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }

    this.removeTutorialsRefFromSessionStorage();
  }
}