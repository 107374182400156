import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PeopleAppraisalService } from '../shared/services/peopleAppraisal.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { PeopleAppraisalPersonDetailsComponent } from './personDetails/personDetails.component';
import { Subordinateservice } from './services/subordinates.service';
import { SelfAssessmentService } from '../shared/services/selfAssessment.service';
import { CanDeactivateUpdateInterviewPeopleAppraisalGuardService } from './services/can-deactivate-update-interview-peopleAppraisal-guard.component';
import { CanDeactivateUpdateClapPeopleAppraisalGuardService } from './services/can-deactivate-update-clap-guard.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { PeopleAppraisalComponent } from './peopleAppraisal.component';
import { SendClapComponent } from './sendClap/sendClap.component';
import { PeopleAppraisalRoutingModule } from './peopleAppraisal-routing.module';
import { OrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { StoreModule } from '@ngrx/store';
import { peopleAppraisalReducer } from './ngrx/peopleAppraisal.reducers';
import { PeopleAppraisalEffects } from './ngrx/peopleAppraisal.effects';
import { EffectsModule } from '@ngrx/effects';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DxChartModule, DxCircularGaugeModule, DxPieChartModule } from 'devextreme-angular';
import { PrivateNoteGuard } from '../shared/services/private-note-guard.service';
import { OutcomesComponent } from './upwardFeedback/upwardFeedback.component';
import { TeamFeedback360Component } from './teamFeedback360/teamFeedback360.component';
import { TeamFeedback360RequestComponent } from './teamFeedback360Request/teamFeedback360Request.component';
import { TeamFeedback360DetailsComponent } from './teamFeedback360Details/teamFeedback360Details.component';


@NgModule({
  declarations: [
    PeopleAppraisalComponent,
    PeopleAppraisalPersonDetailsComponent,
    SendClapComponent,
    CreateUpdateEvidenceComponent,
    OrganizeInterviewComponent,
    SendClapComponent,
    CreateUpdateEvidenceComponent,
    DashboardComponent,
    OutcomesComponent,
    TeamFeedback360Component,
    TeamFeedback360RequestComponent,
    TeamFeedback360DetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PeopleAppraisalRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    DxCircularGaugeModule,
    DxChartModule,
    DxPieChartModule,
    StoreModule.forFeature('peopleAppraisal', peopleAppraisalReducer),
    EffectsModule.forFeature([PeopleAppraisalEffects])
  ],
  providers: [
    Subordinateservice,
    PeopleAppraisalService,
    PersonDetailsGuard,
    PrivateNoteGuard,
    SelfAssessmentService,
    CanDeactivateUpdateInterviewPeopleAppraisalGuardService,
    CanDeactivateUpdateClapPeopleAppraisalGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class PeopleAppraisalModule { }

