import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from '../../shared/services/shared.service';
import { GetDashboardFinalOverviewForAdminResponseType, KrukOpenAPI, PerformanceAssessmentAlloyStatuses } from 'atfcore-commonclasses';
import { error } from 'console';
import { Filter } from 'src/app/shared/components/anag-filters/anag-filters.component';
import { Production } from 'src/app/peopleAppraisal/personDetails/personDetails.component';


export interface CompetenceGraph {
  macroCompetence: string;
  averageManagerEvaluation: number;
  averageSubordinateEvaluation: number;
  averageFeedbackEvaluation: number;
  isManagerial?: boolean;
}
export interface ProgressGraph {
  totalEligible: number,
  appraisalSent: number,
  selfAssessmentSent: number,
  overallSent: number,
  progress: number
}
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  loggedUser: any;
  // dashboard
  dashboardType: string = 'MY_TEAM';
  dashboardTypeStructure: boolean = false;
  // Filtri
  filtersData: KrukOpenAPI.DistinctFieldFilterResponseType = {};
  isLoadingFiltersData: boolean = false;

  getFiltersData$: Subscription = new Subscription();

  filtersObject: any = {};
  allChecked: boolean = false;
  // selectedFilters: { def } = {};

  // selfAssessment
  selfAssessmentPercentage: number = 0;
  selfAssessmentOpen: boolean = false;
  selfAssessmentProgress: any;
  getSelfAssessmentData$: Subscription = new Subscription();
  isLoadingSelfAssessmentData: boolean = false;
  selfAssessmentNoData: boolean = false;

  filtersSelected: Array<Filter> = [];

  // People appraisal
  peopleAppraisalPercentage: number = 0;
  peopleAppraisalOpen: boolean = false;
  peopleAppraisalData: any;
  getPeopleAppraisalData$: Subscription = new Subscription();
  isLoadingPeopleAppraisalData: boolean = false;
  peopleAppraisalNoData: boolean = false;

  // Calibration
  calibrationPercentage: number = 0;
  calibrationOpen: boolean = false;
  calibrationData: any;
  getCalibrationData$: Subscription = new Subscription();
  isLoadingCalibrationData: boolean = false;
  calibrationNoData: boolean = false;

  // Pas
  pasPercentage: number = 0;
  pasOpen: boolean = false;
  pasData: any;
  feedbackGraphData: Array<{ title: string, value: string, class: string }> = [];
  getPasData$: Subscription = new Subscription();
  isLoadingPasData: boolean = false;

  // Comportamenti e competenze
  behaviorCompetenceOpen: boolean = false;
  isLoadingBehaviorCompetenceData: boolean = false;
  behaviorCompetenceData: any;
  getBehaviorCompetenceData$: Subscription = new Subscription();

  //  Overview
  isLoadingOverviewData: boolean = false;
  getOverviewData$: Subscription = new Subscription();
  isDownloadingReport: boolean = false;

  // Progress
  isLoadingProgressData: boolean = false;
  getProgressData$: Subscription = new Subscription();
  progressData: {
    overallSent: number,
    totalEligible: number,
    percentage: number,
    list: Array<{ value: string, class: string, title: string }>
  } = { overallSent: 0, totalEligible: 0, percentage: 0, list: [] };

  // Competences
  isLoadingCompetencesData: boolean = false;
  getompetencesData$: Subscription = new Subscription;
  competencesData: Array<CompetenceGraph> = [];
  managerialOverallGraph: Array<Production> = [];
  genericOverallGraph: Array<Production> = [];

  // Feedback
  feedbackData: Array<{ value: string, class: string, title: string }> = [];
  isLoadingFeedback: boolean = false;
  feedbackData$: Subscription = new Subscription;

  careColors: string[] = ["#C6EBB3", "#58BC5D", "#378C0C"];

  actionColor: string[] = ["#E4002B", "#F5B336", "#25BB48"];
  midTermColor: string[] = ["#E4002B", "#25BB48", "#DBDBDB"];

  /** Overview finale */
  finalOverviewData: Array<{
    status: string,
    structure: string,
    value: number,
    tooltip: number
    tooltipAllUser: number
    cumulativePercent?: number
  }> = [];
  finalPalette: string[] = ['#006EC7', '#003282']
  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];

  // lista processi
  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription();
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  processYear!: number;
  selectedRows: any;

  isProcessClosed$: Subscription = new Subscription();
  isProcessClosed: boolean = false;
  downloadReport$: Subscription = new Subscription();
  processYearCode: any;
  selectedFiltersNumber: any;
  translations: any;
  showOverviewGraph: boolean = true;
  competenceGraphData: Array<{ title: string, first: number, second: number, third: number }> = [{ title: '', first: 0, second: 0, third: 0 }];
  menuOptions: Array<{ id: string, title: string, icon?: string }> = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.redrawGraphs();
  }

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private adminService: AdminService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private sharedService: SharedService,
    public redirectService: RedirectService) {

    this.selectedRows = this.rowPerPageOptions[0];

    this.route.params
      .subscribe((params: Params) => {
        if (params.processYear) {
          this.processYear = params.processYear;
        }
        if (params.processYearCode) {
          this.processYearCode = params.processYearCode;
        }

        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Dashboard - Admin");

        this.store.select(fromApp.getLoggedUser)
          .subscribe((loggedUser: any) => {
            this.loggedUser = loggedUser && loggedUser.user;

            if (this.processYear) {
              this.getFiltersData();
              this.getGraphData();
            } else {
              this.isLoadingSelfAssessmentData = true;
              this.isLoadingPeopleAppraisalData = true;
              this.getProcessesData();
            }
          }
          );
      });
  }

  ngOnInit(): void {
    this.translate.get([
      'dashboard.feedbackTypes.SENT_SMART_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK',
      'dashboard.feedbackTypes.SENT_PEER_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK',
      'dashboard.feedbackTypes.EVIDENCES',
      'dashboard.feedbackTypes.INTERVIEWS',
      'dashboard.FILTERED',
      'dashboard.TO_START',
      'dashboard.STARTED',
      'dashboard.SENT_HR',
      'dashboard.accordion.SELF_ASSESSMENT',
      'dashboard.accordion.EVALUATION',
      'dashboard.POST',
      'dashboard.pas.ELIGIBLE',
      'dashboard.pas.EVALUATION',
      'dashboard.pas.SELF_ASSESSMENT',
      'dashboard.pas.OVERALL',
      'dashboard.pas.SMART_ENVOYS',
      'dashboard.pas.PEER_FEEDBACK_SENT_OR_REQUESTED',
      'dashboard.pas.FEEDBACK360_SENT_OR_REQUESTED',
      'dashboard.pas.PROPOSED_FEEDBACK360',
      'dashboard.ENTIRE_COMPANY',
      'dashboard.PAS_EVALUATED',
      'process.status.STANDBY',
      'process.status.ACTIVE',
      'process.status.CLOSED',
      'recap.DELEGATION',
      'recap.EXTERNAL_RELATIONS',
      'recap.GUIDING_PEOPLE_THROUGH_CHANGE',
      'recap.ORIENTATION_AND_RESULTS_MANAGEMENT',
      'recap.PEOPLE_DEVELOPMENT',
      'headerDossier.REPORT_PROCESS',
      'headerDossier.REPORT_FEEDBACK'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.menuOptions = [
        {
          id: 'process',
          title: translations['headerDossier.REPORT_PROCESS'],
        },
        {
          id: 'feedback',
          title: translations['headerDossier.REPORT_FEEDBACK'],
        }
      ]
    })
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            for (let i = 0; i < data.response.length; i++) {
              if (data.response[i].status == PerformanceAssessmentAlloyStatuses.STANDBY) {
                data.response[i].statusType = 'inProgress';
                data.response[i].statusMessage = this.translations['process.status.STANDBY'];
              } else if (data.response[i].status == PerformanceAssessmentAlloyStatuses.RUNNING) {
                data.response[i].statusType = 'completed';
                data.response[i].statusMessage = this.translations['process.status.ACTIVE'];
              } else {
                data.response[i].statusType = 'disabled';
                data.response[i].statusMessage = this.translations['process.status.CLOSED'];
              }
            }
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  goToProcessDetails(process: any) {
    this.router.navigate(['/admin/dashboard/', process.processYear, process.processYearCode]);
  }

  getFiltersData() {
    this.isLoadingFiltersData = true;
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe;
    }

    this.getFiltersData$ = this.sharedService.getDistinctFilters(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<KrukOpenAPI.DistinctFieldFilterResponseType>) => {
        if (data.response) {
          this.filtersData = data.response;
          this.isLoadingFiltersData = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFiltersData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFiltersData = false;
        });
  }

  openDashboardTooltip() {
    this.modalService.open('dashboard-tooltip');
  }

  closeDashboardTooltip() {
    this.modalService.close('dashboard-tooltip');
  }

  // Personalizza le label delle tabelle
  customizePercentageText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeSeries(valueFromNameField: string) {

    return valueFromNameField === 'Azienda intera'
      ? { type: 'spline', label: { visible: true }, color: '#006EC7' } : {};
  }

  customizeLabel(arg: any) {
    return `${arg.valueText} (${arg.percentText})`;
  }

  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  redrawGraphs(graphSection?: any) {
    let graphToRedraw: string[] = [];
    switch (graphSection) {
      case 'self-assessment': {
        graphToRedraw = ["selfAssessmentGauge", "selfAssessmentChart"];
        break;
      }
      case 'people-appraisal': {
        graphToRedraw = ["peopleAppraisalGauge", "peopleAppraisalChart"];
        break;
      }
      case 'calibration': {
        graphToRedraw = ["calibrationGauge", "calibrationChart"];
        break;
      }
      default:
        graphToRedraw = Object.keys(this.chartInstances);
        break;
    }
    if (graphToRedraw && graphToRedraw.length) {
      setTimeout(() => {
        //let keys = Object.keys(this.chartInstances);
        for (let i = 0; i < graphToRedraw.length; i++) {
          if (this.chartInstances[graphToRedraw[i]]) {
            this.chartInstances[graphToRedraw[i]].render();
          }
        }
      }, 100);
    }
  }


  // inizializza i dati dei grafici
  getGraphData(filtersData?: Array<Filter>) {

    this.selfAssessmentNoData = false;
    this.peopleAppraisalNoData = false;
    this.calibrationNoData = false;
    // Ho creato questa variabile perchè i filtri mi servono anche per scaricare il report
    this.filtersSelected = filtersData ? filtersData : [];
    if (this.selectedFiltersNumber > 0) {
      // sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
      // sessionStorage.setItem('savedFilters', JSON.stringify());
      sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    }
    // Recupera overview con eventuali filtri
    this.getOverviewData(filtersData ? filtersData : []);
    // Recupera Competenze con eventuali filtri
    this.getCompetencesData(filtersData ? filtersData : []);
    // Recupera i progressi
    this.getProgressData(filtersData ? filtersData : []);
    // Recupera i feedback
    this.getFeedbackData(filtersData ? filtersData : []);
  }


  // controlla se sto caricando qualcosa
  isLoading() {
    return this.isLoadingSelfAssessmentData || this.isLoadingPeopleAppraisalData;
  }

  getFeedbackData(filtersData: Array<Filter>) {
    //Avvio il loader
    this.isLoadingFeedback = true;

    if (this.feedbackData$) {
      this.feedbackData$.unsubscribe;
    }

    let onlyFilters: Array<{ id: string, value: string }> = [];

    for (let i = 0; i < filtersData.length; i++) {
      if (filtersData[i]?.selected?.id !== 'all') {
        onlyFilters.push({
          // id del filtro
          id: filtersData[i].id,
          // valore del filtro che corrisponde all'id del filtro selezionato
          value: filtersData[i].selected?.id ? filtersData[i].selected?.id.toString() as string : ''
        })
      }
    }

    this.getProgressData$ = this.adminService.getFeedback(this.processYear, this.processYearCode, onlyFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {

          this.feedbackData = [
            {
              title: this.translations['dashboard.pas.SMART_ENVOYS'],
              class: "percentage slim",
              value: data.response.smartFeedbackItemsCount
            },
            {
              title: this.translations['dashboard.pas.PEER_FEEDBACK_SENT_OR_REQUESTED'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.peerFeedbackSent / data.response.peerFeedbackRequested) * 100)) + ' primary',
              value: data.response.peerFeedbackRequested ? data.response.peerFeedbackSent + '/' + data.response.peerFeedbackRequested: '--'
            },
            {
              title: this.translations['dashboard.pas.FEEDBACK360_SENT_OR_REQUESTED'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.feedback360Sent / data.response.feedback360Requested) * 100)) + ' primary',
              value: data.response.feedback360Requested ? data.response.feedback360Sent + '/' + data.response.feedback360Requested :'--'
            },
            {
              title: this.translations['dashboard.pas.PROPOSED_FEEDBACK360'],
              class: "percentage slim",
              value: data.response.feedback360Suggestions

            }]
          this.isLoadingFeedback = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });

  }

  // getBehaviorCompetenceData() {
  //   this.isLoadingBehaviorCompetenceData = true;
  //   if (this.getBehaviorCompetenceData$) {
  //     this.getBehaviorCompetenceData$.unsubscribe()
  //   }

  //   this.getBehaviorCompetenceData$ = this.adminService.getDashboardBehaviorCompetence(this.processYear, this.processYearCode, this.selectedFilters)
  //     .subscribe((data: SenecaResponse<any>) => {
  //       if (data && data.error) {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "da001",
  //           text: this.translate.instant("errors." + data.error),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         this.isLoadingBehaviorCompetenceData = false;
  //       } else {
  //         this.behaviorCompetenceData = {};
  //         this.behaviorCompetenceData.behaviors = data.response.behaviours;
  //         this.behaviorCompetenceData.competences = data.response.competences;

  //         this.isLoadingBehaviorCompetenceData = false;
  //       }
  //     },
  //       (err: any) => {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "da002",
  //           text: this.translate.instant("errors." + err?.message || err),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         this.isLoadingBehaviorCompetenceData = false;
  //       });
  // }

  getOverviewData(filtersData: Array<Filter>) {
    // Avvvio il loader
    this.isLoadingOverviewData = true;

    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }

    let onlyFilters: Array<{ id: string, value: string }> = [];

    for (let i = 0; i < filtersData.length; i++) {
      if (filtersData[i]?.selected?.id !== 'all') {
        onlyFilters.push({
          // id del filtro
          id: filtersData[i].id,
          // valore del filtro che corrisponde all'id del filtro selezionato
          value: filtersData[i].selected?.id ? filtersData[i].selected?.id.toString() as string : ''
        })
      }
    }

    this.getOverviewData$ = this.adminService.getDashboardOverview(this.processYear, this.processYearCode, onlyFilters)
      .subscribe((data: SenecaResponse<GetDashboardFinalOverviewForAdminResponseType>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        } else {
          this.finalOverviewData = [];
          this.showOverviewGraph = !data.response.hideChart;
          let evaluations = [
            "UNSATISFACTONARY_PERFORMER",
            "MARGINAL_PERFORMER",
            "STANDARD_PERFORMER",
            "STRONG_PERFORMER",
            "TOP_PERFORMER",
          ]

          let filteredTotal = data.response.totalFiltered;
          let compiledFiltered = data.response.totalFilteredEvaluated;

          for (let i = 0; i < evaluations.length; i++) {
            let response = data.response[evaluations[i]];
            if (response) {
              this.finalOverviewData.push({
                status: (evaluations[i].charAt(0).toUpperCase() + evaluations[i].slice(1).toLowerCase()).split('_')[0],
                structure: this.translations['dashboard.PAS_EVALUATED'] + data.response.totalFilteredEvaluated + '/' + filteredTotal,
                value: Math.round((response.filtered / filteredTotal) * 100),
                tooltip: response.filtered,
                tooltipAllUser: filteredTotal,
                cumulativePercent: data.response.hideChart ? undefined : Math.round((response.filtered / filteredTotal) * 100)
              })
            }
          }
          this.isLoadingOverviewData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }

  getCompetencesData(filtersData: Array<Filter>) {
    // Avvvio il loader
    this.isLoadingCompetencesData = true;

    if (this.getompetencesData$) {
      this.getompetencesData$.unsubscribe()
    }

    let onlyFilters: Array<{ id: string, value: string }> = [];

    for (let i = 0; i < filtersData.length; i++) {
      if (filtersData[i]?.selected?.id !== 'all') {
        onlyFilters.push({
          // id del filtro
          id: filtersData[i].id,
          // valore del filtro che corrisponde all'id del filtro selezionato
          value: filtersData[i].selected?.id ? filtersData[i].selected?.id.toString() as string : ''
        })
      }
    }


    this.getompetencesData$ = this.adminService.getDashboardCompetencesForAdmin(this.processYear, this.processYearCode, onlyFilters)
      .subscribe((data: SenecaResponse<{ competences: Array<CompetenceGraph> }>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetencesData = false;
        } else {
          this.competencesData = [];
          this.competencesData = data.response.competences;

          this.managerialOverallGraph = [];
          this.genericOverallGraph = [];
          this.competenceGraphData = [];

          //Ordino i dati in modo da avere prima le competenze manageriali e poi le competenze generali
          let managerial = this.competencesData.filter(x => x.isManagerial);
          let generic = this.competencesData.filter(x => !x.isManagerial);
          const arrayCopytidy = managerial.concat(generic);


          for (let i = 0; i < arrayCopytidy.length; i++) {
            const competence = arrayCopytidy[i];
            this.competenceGraphData.push({
              title: competence.macroCompetence,
              first: competence.averageSubordinateEvaluation,
              second: competence.averageManagerEvaluation,
              third: competence.averageFeedbackEvaluation
            })
          }

          let k = 0;
          let j = 0
          for (let i = 0; i < this.competencesData.length; i++) {
            const competence = this.competencesData[i];
            if (competence.isManagerial) {
              this.managerialOverallGraph.push({
                arg: String.fromCharCode(65 + j),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageSubordinateEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + j), desc: competence.macroCompetence }
              })
              j++
            } else {
              this.genericOverallGraph.push({
                arg: String.fromCharCode(65 + k),
                evaluation: competence.averageManagerEvaluation,
                selfAssessment: competence.averageSubordinateEvaluation,
                feedback: competence.averageSubordinateEvaluation == null ? undefined : competence.averageFeedbackEvaluation,
                legend: { title: String.fromCharCode(65 + k), desc: competence.macroCompetence }
              })
              k++;
            }
          }

          this.isLoadingCompetencesData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetencesData = false;
        });
  }

  getProgressData(filtersData: Array<Filter>) {
    // Avvvio il loader
    this.isLoadingProgressData = true;

    if (this.getProgressData$) {
      this.getProgressData$.unsubscribe()
    }

    let onlyFilters: Array<{ id: string, value: string }> = [];

    for (let i = 0; i < filtersData.length; i++) {
      if (filtersData[i]?.selected?.id !== 'all') {
        onlyFilters.push({
          // id del filtro
          id: filtersData[i].id,
          // valore del filtro che corrisponde all'id del filtro selezionato
          value: filtersData[i].selected?.id ? filtersData[i].selected?.id.toString() as string : ''
        })
      }
    }

    this.getProgressData$ = this.adminService.getDashboardProgress(this.processYear, this.processYearCode, onlyFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.progressData = {
            totalEligible: data.response.totalEligible,
            overallSent: data.response.overallSent,
            percentage: data.response.progress,
            list: [
              {
                title: this.translations['dashboard.pas.ELIGIBLE'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.totalEligible / data.response.totalUsers) * 100)) + ' secondary',
                value: data.response.totalEligible
              },
              {
                title: this.translations['dashboard.pas.EVALUATION'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.appraisalSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.appraisalSent
              },
              {
                title: this.translations['dashboard.pas.SELF_ASSESSMENT'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.selfAssessmentSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.selfAssessmentSent
              },
              {
                title: this.translations['dashboard.pas.OVERALL'],
                class: "percentage" + Math.trunc(Math.ceil((data.response.overallSent / data.response.totalEligible) * 100)) + ' secondary',
                value: data.response.overallSent
              }
            ]
          }
          this.isLoadingProgressData = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingProgressData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }

  downloadReport(type: string) {
    this.isDownloadingReport = true;

    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }

    let onlyFilters: Array<{ id: string, value: string }> = [];

    for (let i = 0; i < this.filtersSelected.length; i++) {
      if (this.filtersSelected[i]?.selected?.id !== 'all') {
        onlyFilters.push({
          // id del filtro
          id: this.filtersSelected[i].id,
          // valore del filtro che corrisponde all'id del filtro selezionato
          value: this.filtersSelected[i].selected?.id ? this.filtersSelected[i].selected?.id.toString() as string : ''
        })
      }
    }


    if (type == 'process') {
      this.downloadReport$ = this.adminService.downloadProcessReport(this.processYear, this.processYearCode, onlyFilters)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          } else {
            this.downloadnFile(data.response);
            this.isDownloadingReport = false;
          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a005",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        })
    } else {
      this.downloadReport$ = this.adminService.downloadFeedbackReport(this.processYear, this.processYearCode, onlyFilters)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          } else {
            this.downloadnFile(data.response);
            this.isDownloadingReport = false;
          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a005",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        })
    }
  }

  downloadnFile(data: any) {
    let filename = data
    this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
      if (data && data.response) {
        let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
        setTimeout(() => {
          window.open(downloadUrl, '_blank');
        }, 500)
        this.isDownloadingReport = false;
      } else {
        const messageObj: ApplicationModalMessage = {
          modalId: "a007",
          text: this.translate.instant("errors." + data && data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      }
    }, (err: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "a008",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isDownloadingReport = false;
    })
  }

  customizeTooltip = (info: any) => (
    {
      html: `<div><div class='tooltip-header'>${this.translate.instant('dashboard.NUM_PAS')}</div>`
        + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
        + `<span class=\'top-series-name\'>${info.points[0].point.data.value + '% - ' + info.points[0].point.data.tooltip + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[0].point.data.tooltipAllUser + ' ' + this.translate.instant('dashboard.TOTAL')}</span><br>`
        + `${info.points[0].point.data.selectedFiltersNumber > 0 ? `<span class=\'top-series-name\'>${info.points[1].point.data.value + '% - ' + info.points[1].point.data.tooltip + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[1].point.data.tooltipAllUser + ' ' + this.translate.instant('dashboard.TOTAL_FILTERED')}</span>` : ``}`
        + `</div></div></div>`
    }
  );

  updateSelectedFilters(newSelectedFilters: any) {
    // this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }
  openFinalOverviewModal() {
    this.modalService.open('finalOverview')
  }
  closeFinalOverviewModal() {
    this.modalService.close('finalOverview')
  }

  openAdvancementProcessModal() {
    this.modalService.open('advancementProcess')
  }

  closeAdvancementProcessModal() {
    this.modalService.close('advancementProcess')
  }

  openSpiderModal() {
    this.modalService.open('spiderGraph')
  }
  closeSpiderModal() {
    this.modalService.close('spiderGraph')
  }

  openCompetenceModal() {
    this.modalService.open('competence')
  }
  closeCompetenceModal() {
    this.modalService.close('competence')
  }

  openFeedbackModal() {
    this.modalService.open('feedback')
  }
  closeFeedbackModal() {
    this.modalService.close('feedback')
  }


  ngOnDestroy(): void {
    // if (this.combinedSelected$) {
    //   this.combinedSelected$.unsubscribe();
    // }
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }
    if (this.getPeopleAppraisalData$) {
      this.getPeopleAppraisalData$.unsubscribe()
    }
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    if (this.getCalibrationData$) {
      this.getCalibrationData$.unsubscribe()
    }
    if (this.getPasData$) {
      this.getPasData$.unsubscribe()
    }
    if (this.getBehaviorCompetenceData$) {
      this.getBehaviorCompetenceData$.unsubscribe()
    }
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }
  }

}
