import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as CoreActions from "../../core/ngrx/core.actions";
import { AlloyPerformanceCppServiceResponses, PerformanceAssessmentAlloyStatuses, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-create-update-cpp',
  templateUrl: './createUpdateCPP.component.html',
  styleUrls: ['./createUpdateCPP.component.scss']
})
export class CreateUpdateCppComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  roundId: string = '';
  isLoadingRound: boolean = true;

  roundName: string = '';
  roundNameCopy: string = '';

  startDate: any;
  startDateCopy: any;
  endDate: any;
  endDateCopy: any;

  statusOptions: any;
  selectedStatus: any;
  selectedStatusCopy: any;
  getProcessData$: Subscription = new Subscription();
  isUpdate: boolean = false;
  today = new Date();
  selectedStatusOriginal: any;
  isThereActiveRound: boolean = false;
  minStartDate: any;
  yesterday = new Date();

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.roundId = params.roundId;
              if (window.location.href.indexOf('createUpdateCppWithActiveRound') > -1) {
                this.isThereActiveRound = true;
              }
              this.translate.get(
                [
                  'process.status.ACTIVE',
                  'process.status.STANDBY',
                  'process.status.CLOSED'
                ])
                .subscribe(translations => {

                  this.statusOptions = [
                    {
                      id: PerformanceAssessmentAlloyStatuses.RUNNING,
                      title: translations['process.status.ACTIVE'],
                      disabled: true
                    },
                    {
                      id: PerformanceAssessmentAlloyStatuses.STANDBY,
                      title: translations['process.status.STANDBY']
                    },
                    {
                      id: "CLOSED",
                      title: translations['process.status.CLOSED']
                    }
                  ];
                  if (!this.roundId) {
                    this.selectedStatus = this.statusOptions[1];
                    this.isLoadingRound = false;
                    this.getMinStartDate();
                  } else {
                    this.isUpdate = true;
                    this.getProcessData();
                  }
                })
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  nameChanged(text: string) {
    this.roundName = text;
  }


  startDateChanged(startDate: any) {
    this.startDate = startDate;
  }

  endDateChanged(endDate: any) {
    this.endDate = endDate;
  }

  changeStatus(statusItem: any) {
    this.selectedStatus = statusItem;
  }

  getProcessData() {
    this.isLoadingRound = true;

    if (this.getProcessData$) {
      this.getProcessData$.unsubscribe();
    }

    this.getProcessData$ = this.adminService.getCppRounds('0', '1', [this.roundId])
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppRoundsForAdmin>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else if (data.response?.length) {
          this.roundName = data.response[0].name;
          this.startDate = data.response[0].startDate;
          this.endDate = data.response[0].endDate;
          this.selectedStatusOriginal = this.statusOptions.find((status: any) => status.id == data.response[0].roundStatus);
          this.selectedStatus = this.statusOptions.find((status: any) => status.id == data.response[0].roundStatus);

          if (this.selectedStatusOriginal?.id == 'RUNNING') {
            this.statusOptions[1].disabled = true;
          }
          this.getMinStartDate();
        }
        this.isLoadingRound = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRound = false;
      });
  }

  getMinStartDate() {
    this.isLoadingRound = true;
    this.adminService.getAvailableCppRoundCppDate(this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "a003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRound = false;
        } else {
          if(data.response?.minStartDate) {
            this.minStartDate = new Date(data.response?.minStartDate)
          } else {
            this.minStartDate = new Date(data.response?.minStartDate)
            this.minStartDate.setDate(this.minStartDate.getDate() - 1);
          }
          this.isLoadingRound = false;
        }
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRound = false;
      })
  }

  createUpdate() {
    if (this.roundId) {
      this.modalService.open('update-process');
    } else {
      this.modalService.open('create-process');
    }
  }

  closeCreateProcess(confirm?: boolean) {
    this.modalService.close('create-process');
    if (confirm) {
      this.isLoadingRound = true;
      this.adminService.createCppProcess(this.roundName, new Date(this.startDate), new Date(this.endDate))
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingRound = false;
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.redirectService.goBackBrowser();
          }
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRound = false;
        })
    }
  }

  closeUpdateProcess(confirm?: boolean) {
    this.modalService.close('update-process');
    if (confirm) {
      this.isLoadingRound = true;
      this.adminService.updateCppProcess(this.roundId, this.roundName, new Date(this.startDate), new Date(this.endDate), this.selectedStatus.id)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingRound = false;
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.redirectService.goBackBrowser();
          }
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRound = false;
        })
    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}