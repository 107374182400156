import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { SenecaResponse } from 'src/commonclasses';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { AdminService } from '../../services/admin.service';
import { KrukOpenAPI } from 'atfcore-commonclasses';
import { Subscription } from 'rxjs';


export interface Filter {
  id: string,
  title: string,
  list: Array<{ id: string | number, title: string | number }>,
  selected?: { id: string | number, title: string | number }
}
@Component({
  selector: 'anag-filters',
  templateUrl: 'anag-filters.component.html',
  styleUrls: ['./anag-filters.component.scss']
})
export class AnagFiltersComponent implements OnInit, OnDestroy {
  @Input() processYear?: any;
  @Input() processYearCode?: any;
  @Input() roundId?: any;
  @Input() searchByTextPlaceholder?: string;
  @Input() noActiveFilterText?: string;
  @Input() oneFilterText?: string;
  @Input() moreFiltersText?: string;
  @Input() deleteFiltersText?: string;
  @Input() manageFiltersText?: string;
  @Input() applyFiltersText?: string;
  @Input() isLoadingUsers: boolean = false;
  @Input() showSubCompany: boolean = true;
  @Input() showCdc: boolean = true;
  @Input() showAreaRef: boolean = true;
  @Input() showDepartment: boolean = true;
  @Input() showTeam: boolean = true;
  @Input() showSubTeam: boolean = false;
  @Input() showPosition: boolean = true;
  @Input() showSupervisor: boolean = true;
  @Input() showContractual: boolean = true;
  @Input() showOffice: boolean = true;
  @Input() showSelfAssessmentStatus: boolean = true;
  @Input() showAppraisalStatus: boolean = true;
  @Input() showCalibrationStatus: boolean = true;
  @Input() showUpwardFeedbackStatus: boolean = false;
  @Input() searchEnabled: boolean = true;
  @Input() keepFilters: boolean = false; // salvare selectedFilters in 'savedFilters' nel session storage alla ricerca
  @Input() monitoringFilters: boolean = false; // Filtri per la pagina di monitoring

  @Output() searchedTextChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFiltersNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() startSearch: EventEmitter<any> = new EventEmitter<any>();

  filtersObject: any = {};
  isFilterOpened: boolean = false;
  translations: any;
  selectedFiltersNumber: number = 0;
  searchedText: string = '';
  isPeopleAppraisal: boolean = false;


  getFiltersData$: Subscription = new Subscription;
  isLoadingFiltersData: boolean = false;
  filterList: Array<Filter> = [];

  @Input() isCpp?: boolean = false;
  @Input() isUpwardFeedback?: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private sharedService: SharedService,
    private adminService: AdminService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.translate.get([
      'filter.ALL',
      'filter.M',
      'filter.F',
      'filter.defTpDip',
      'filter.ramo',
      'filter.manager',
      'filter.performanceManagerName',
      'monitoring.filters.SENT',
      'monitoring.filters.NOT_SENT',
      'monitoring.WAITING',
      'monitoring.TO_SEND',
      'monitoring.SENT',
      'upwardFeedback.userAdminStatus.TO_CHECK',
      'upwardFeedback.userAdminStatus.WAITING',
      'upwardFeedback.userAdminStatus.CHECKED',
      'upwardFeedback.userAdminStatus.SHARED',
    ]).subscribe((translations) => {
      this.translations = translations;

      this.route.params
        .subscribe(
          (params: Params) => {
            if (window.location.href.indexOf('peopleAppraisal') > -1) {
              this.isPeopleAppraisal = true;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYear) {
              this.processYear = params.processYear;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYearCode) {
              this.processYearCode = params.processYearCode;
            }
            this.getFiltersData();
          })
    })
  }

  onSearch() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendSearchEvent(this.searchedText || '');
    // this.emitStartSearch(true);
  }

  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.emitStartSearch(this.filterList);
  }

  // recupera il distinct dei filtri disponibili
  getFiltersData() {
    this.isLoadingFiltersData = true;
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe;
    }

    this.getFiltersData$ = this.sharedService.getDistinctFilters(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<KrukOpenAPI.DistinctFieldFilterResponseType>) => {
        if (data.response) {
          let dataFil: any = data.response;
          for (let key in dataFil) {
            // Creo l'array con nuovo type
            let listUtiles: Array<{ id: string, title: string }> = [{ id: 'all', title: this.translations['filter.ALL'] }];
            if (dataFil[key]) {
              for (let i = 0; i < dataFil[key].length; i++) {

                // Controllo se sono composti da string

                if (typeof (dataFil[key][i]) == 'string') {
                  listUtiles.push({ id: dataFil[key][i], title: dataFil[key][i] });
                } else if (dataFil[key][i] && dataFil[key][i].forename && dataFil[key][i].surname) {
                  listUtiles.push({ id: dataFil[key][i].userId, title: dataFil[key][i].forename + ' ' + dataFil[key][i].surname });
                }
              }
            }
            // Faccio un push sempre con il type utilizzato per le select dinamiche
            this.filterList.push({
              // Devo fare una distinzione per il parametro manager, perchè è stato cambiato il nome nella classe AnagUserFilters
              id: (key === "manager") ? "performanceManagerName" : key,
              title: this.translations['filter.' + key] ? this.translations['filter.' + key] : 'ERRORE TRADUZIONE',
              list: listUtiles
            })
          }
          this.isLoadingFiltersData = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFiltersData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFiltersData = false;
        });
  }

  clearFilters() {
    for (let i = 0; i < this.filterList.length; i++) {
      this.filterList[i].selected = undefined;
    }
    this.selectedFiltersNumber = 0;
    this.isFilterOpened = false;
    // this.updateSelectedFiltersNumber.emit(0);
    // this.updateSelectedFilters.emit(this.selectedFilters);
    this.emitStartSearch(this.filterList);
  }

  emitSearchedTextChanged(data?: any) {
    this.searchedTextChanged.emit(data);
  }

  emitStartSearch(filtersData: Array<Filter>) {
    this.startSearch.emit(filtersData);
  }

  selectFilter(data: any, filterId: any) {
    this.selectedFiltersNumber = 0;

    let selectedFilter = this.filterList.find(el => el.id == filterId);
    if (selectedFilter) {
      selectedFilter.selected = { id: data.id, title: data.title };
    }
    for (let i = 0; i < this.filterList.length; i++) {
      if (this.filterList[i].selected && this.filterList[i].selected?.id) {
        if (this.filterList[i].selected?.id !== 'all') {
          this.selectedFiltersNumber++;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }
  }
}
