<div id="snackbar-reminder">
    <p translate="upwardFeedback.admin.verify.REMINDERS_SENT"></p>
</div>

<page-container>
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="('upwardFeedback.admin.verify.SIDEBAR_TITLE' | translate) + (titleData ? titleData.managerName : '')"
            [description]="'upwardFeedback.admin.verify.SIDEBAR_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingManagerData">
                <div class="form-container">
                    <!-- titolo e recap questionari inviati-->
                    <div class="title-container">
                        <p class="title-form" translate="upwardFeedback.TITLE"></p>
                        <div class="title-recap-container">
                            <p class="hint-text">{{ getSurveyStatusTitle() }}</p>
                            <div class="send-reminder-link" *ngIf="!isShared">
                                <p class="link" translate="upwardFeedback.admin.verify.SEND_REMAINING_REMINDER"
                                    (click)="openSendReminderModal()"></p>
                                <svg-icon class="send-rem-icon" src="./assets/img/icons/send.svg" [applyClass]="true">
                                </svg-icon>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-wrapper">
                        <h3 translate="upwardFeedback.admin.verify.EXAMPLE"></h3>
                        <alloy-accordion [infoIcon]="false" [smallerTitle]="true"
                            [title]="'upwardFeedback.admin.verify.STRENGTH' | translate" [black]="true"
                            [isAccordionOpened]="false" [isAdminUpwardFeedback]="true">

                            <div class="list-container">
                                <ng-container
                                    *ngIf="listData.strengths && listData.strengths.length; else strengthsNoData">
                                    <ul>
                                        <li *ngFor="let res of listData.strengths">{{ res }}</li>
                                    </ul>
                                </ng-container>
                                <ng-template #strengthsNoData>
                                    <p translate="generic.NO_DATA_INSERT"></p>
                                </ng-template>
                            </div>

                        </alloy-accordion>

                        <alloy-accordion [infoIcon]="false" [smallerTitle]="true"
                            [title]="'upwardFeedback.admin.verify.DEVELOPMENT_AREA' | translate" [black]="true"
                            [isAccordionOpened]="false" [isAdminUpwardFeedback]="true">
                            <div class="list-container">
                                <ng-container
                                    *ngIf="listData.development && listData.development.length; else developmentNoData">
                                    <ul>
                                        <li *ngFor="let res of listData.development">{{ res }}</li>
                                    </ul>
                                </ng-container>
                                <ng-template #developmentNoData>
                                    <p translate="generic.NO_DATA_INSERT"></p>
                                </ng-template>
                            </div>
                        </alloy-accordion>

                        <alloy-accordion *ngIf="listData.notes && listData.notes.length" [infoIcon]=" false"
                            [smallerTitle]="true" [title]="'upwardFeedback.admin.verify.NOTES_COMMENTS' | translate"
                            [black]="true" [isAccordionOpened]="false" [isAdminUpwardFeedback]="true">
                            <div class="list-container">
                                <ul>
                                    <li *ngFor="let res of listData.notes">{{ res }}</li>
                                </ul>
                            </div>
                        </alloy-accordion>

                    </div>

                    <linear-rating [ratings]="ratingData" [average]="globalAverage">
                    </linear-rating>

                    <div class="accordion-wrapper">
                        <div class="more-details" (click)="toggleAccordion()">
                            <p [translate]="showAllAccordion ? 'generic.HIDE_DETAILS' : 'generic.SHOW_DETAILS'"></p>
                            <svg-icon class="more-details-icon"
                                [src]="showAllAccordion ? './assets/img/icons/chevron-up.svg' : './assets/img/icons/chevron-down.svg'"
                                [applyClass]="true">
                            </svg-icon>
                        </div>
                        <ng-container *ngIf="showAllAccordion">
                            <!-- Accordion e grafici -->
                            <ng-container *ngFor="let module of modulesData">
                                <alloy-accordion [infoIcon]="false" [black]="true" [isAccordionOpened]="false"
                                    [isAdminUpwardFeedback]="true" [smallerTitle]="false"
                                    (accordionOpened)="redrawGraph(module.moduleTitle)"
                                    [title]="module.moduleTitle +' ('+ ('generic.AVERAGE' | translate) + ': ' + (module.moduleAverage | number : '1.2-2') +')'">
                                    <ng-container
                                        *ngFor="let question of module.questions; let i = index; let last = last">
                                        <div class="graph-container">
                                            <p class="graph-title">{{ question.questionTitle }}</p>
                                            <p class="graph-average">
                                                {{ ('generic.AVERAGE' | translate) + ' ' + ( question.questionAverage|
                                                number : '1.2-2') + ' - '
                                                +
                                                question.totalAnswers + ' ' + ('generic.ANSWERS' | translate)}}
                                            </p>
                                            <div class="graph-content">
                                                <dx-chart [id]="module.moduleTitle + ' ' + i"
                                                    [dataSource]="question.graphData"
                                                    (onInitialized)="saveInstance($event, module.moduleTitle + ' ' + i)">
                                                    <dxo-size [height]="180"></dxo-size>
                                                    <dxi-value-axis position="left" [allowDecimals]="false"
                                                        [axisDivisionFactor]="1">
                                                        <dxo-title text="N. di valutazioni"></dxo-title>
                                                    </dxi-value-axis>
                                                    <dxo-argument-axis title="Punteggio assegnato"
                                                        [allowDecimals]="false" [axisDivisionFactor]="1">
                                                    </dxo-argument-axis>
                                                    <dxo-common-series-settings argumentField="title" valueField="value"
                                                        type="bar">
                                                    </dxo-common-series-settings>
                                                    <dxi-series argumentField="title" valueField="value" type="bar"
                                                        color="#FF6600">
                                                    </dxi-series>
                                                    <dxo-legend [visible]="false"></dxo-legend>
                                                </dx-chart>
                                            </div>
                                            <ng-container *ngIf="!last">
                                                <div class="graph-divisor"></div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </alloy-accordion>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="action-bar">
                        <alloy-button (onButtonClicked)="setManagerAsVerified()" type="success bordless" size="large"
                            [disabled]="isSettingManagerStatus" [disabled]="isVerified || isShared"
                            [label]="'upwardFeedback.admin.verify.SET_AS_VERIFIED' | translate">
                        </alloy-button>
                        <alloy-button class="margin-bottom0 large" (onButtonClicked)="openShareManagerResults()"
                            type="success default" size="large" [disabled]="isSharingManagerResult || isShared"
                            [label]="'upwardFeedback.admin.verify.SHARE_RESULTS' | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>

            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingManagerData">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="confirm-share-results" (onClose)="closeShareManagerResults()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeShareManagerResults(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeShareManagerResults()">
    <modal-text-content [title]="'upwardFeedback.admin.modals.SHARE_RESULT_TITLE'| translate"
        [subtitle]="'upwardFeedback.admin.modals.SHARE_RESULT_SUBTITLE'| translate"
        [text]="'upwardFeedback.admin.modals.SHARE_RESULT_DESCR'| translate"></modal-text-content>
</modal>

<modal id="set-as-verified" (onClose)="closeSetAsVerifiedModal()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeSetAsVerifiedModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeSetAsVerifiedModal()">
    <modal-text-content [title]="'upwardFeedback.admin.modalse.SET_VERIFIED_TITLE'| translate"
        [text]="'upwardFeedback.admin.modalse.SET_VERIFIED_DESCR'| translate"></modal-text-content>
</modal>

<!-- Conferma invio solleciti -->
<modal id="send-reminders" (onClose)="closeSendRemindersModal()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeSendRemindersModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeSendRemindersModal()">
    <modal-text-content [title]="'upwardFeedback.admin.modals.SEND_REMINDERS_TITLE' | translate"
        [text]="'upwardFeedback.admin.modals.SEND_REMINDERS_DESCR' | translate">
    </modal-text-content>
</modal>