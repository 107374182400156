<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

    <!-- Side nav -->
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="'360Feedback.people.MANAGER_SIDEBAR_TITLE' | translate" [description]="getSidebarDescription()">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <div class="form-container" *ngIf="!isLoadingFeedbackDetails; else pageLoader">
                <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                        [ratingScale]="competence.ratingScale" (infoClicked)="openCompetenceLegendModal($event)"
                        [hideComments]="true" [hideFeedbacks]="true" [canValueNA]="true" [disabled]="true"
                        [hideSkillMatrix]="true" [noContainer]="true">
                    </competence-evaluation>
                </ng-container>
                <!-- Media finale -->
                <competence-average *ngIf="softSkillsFinalAvg.allBehavioursValuated"
                    [disabled]="true" id="soft-skill-final-avg" [competenceAvg]="softSkillsFinalAvg"
                    [ratingScale]="softSkillsFinalAvg.ratingScale">
                </competence-average>


                <!-- Indica i punti di forza -->
                <div class="indication">
                    <p class="title" translate="evaluations.AREA_STRANGTHS"></p>
                    <p class="text">
                        {{ strengthsComment || ('360Feedback.people.NO_OBSERVATION_ADDED' | translate) }}
                    </p>
                </div>
                <!-- Indica le aree di miglioramento -->
                <div class="indication">
                    <p class="title" translate="evaluations.AREA_IMPROVEMENT"></p>
                    <p class="text">
                        {{ improvementComment || ('360Feedback.people.NO_OBSERVATION_ADDED' | translate) }}
                    </p>
                </div>

                <ng-container *ngIf="!isAlreadyShared; else sharedActions">
                    <div class="action-container">
                        <alloy-button type="black" size="large" [label]="'generic.CLOSE' | translate"
                            (onButtonClicked)="goBackBrowser()">
                        </alloy-button>
                        <alloy-button type="primary fill" size="large" (onButtonClicked)="openConfrimShareFeedback()"
                            [label]="'360Feedback.people.SHARE_FEEDBACK_TO_COLLABORATOR' | translate">
                        </alloy-button>
                    </div>
                </ng-container>
                <ng-template #sharedActions>
                    <div class="action-container center">
                        <alloy-button type="primary fill" size="large" [label]="'generic.CLOSE' | translate"
                            (onButtonClicked)="goBackBrowser()">
                        </alloy-button>
                    </div>
                </ng-template>
            </div>

        </div>
    </div>
</page-container>

<!-- Modale scala di valutazione competenze -->
<modal id="common-competence" (onClose)="closeCompetenceLegendModal()" (onConfirm)="closeCompetenceLegendModal()"
    confirmLabel="{{ 'generic.CLOSE' | translate}}">
    <div class="wrap-container" *ngIf="competenceForLegendModal">
        <p class="modal-title black center" translate="dictionary.TITLE"></p>
        <div class="divisor-line"></div>
        <div class="subtitle-container">
            <p class="type">{{ (competenceForLegendModal.isManagerial ? "dictionary.MANAGERIAL" : "dictionary.GENERAL")
                | translate }}</p>
            <p class="title">{{competenceForLegendModal.title}}</p>
            <p class="subtitle" [innerHTML]="competenceForLegendModal.text"></p>
        </div>
        <div class="divisor-line"></div>
        <div class="modal-evaluation-system-container">
            <ng-container *ngIf="competenceForLegendModal.scale && competenceForLegendModal.scale.length">
                <div *ngFor="let evaluation of competenceForLegendModal.scale; let i = index">
                    <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="6"
                        [readOnly]="true" [level]="i-1" [disabledFirstStar]="true" [competenceScale]="true"
                        [valueNASub]="competenceForLegendModal.scale[0].text"
                        [valueFirstStarSub]="competenceForLegendModal.scale[1].text"
                        [valueSecondStarSub]="competenceForLegendModal.scale[2].text"
                        [valueThirdStarSub]="competenceForLegendModal.scale[3].text"
                        [valueFourthStarSub]="competenceForLegendModal.scale[4].text"
                        [valueFifthStarSub]="competenceForLegendModal.scale[5].text" [showTitles]="true">
                    </evaluation-system>
                </div>
            </ng-container>
        </div>
    </div>
</modal>

<modal id="confirm-share" (onClose)="closeConfrimShareFeedback()" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeConfrimShareFeedback()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeConfrimShareFeedback(true)">
    <modal-text-content modalId="360fe003" [title]="'360Feedback.people.SHARE_FEEDBACK_TO_COLLABORATOR' | translate"
        [subtitle]="'360Feedback.people.SHARE_FEEDBACK_SUB' | translate"
        [text]="('360Feedback.people.SHARE_FEEDBACK_DESCR' | translate)">
    </modal-text-content>
</modal>


<ng-template #pageLoader>
    <div class="loader spinner-big"></div>
</ng-template>