// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  customBaseRef: '',
  googleTagManagerCode: "XXX",
  skipSendTestUsersToAnalytics: false,

   // Accesso alla scheda di sviluppo
   canAccessPersonalDevelopment: true,
   // Accesso alla dashboard
   canAccessTheDashboard: true,
   // Accesso agli appunti privati
   canAccessToPrivateNotes: true,
   // Accesso allo smart Feedback
   canAccessToSmartFeedback: true,
   // Accesso allo peer Feedback
   canAccessToPeerFeedback: true,
   // Accesso allo Feedback 360
   canAccessToFeedback360: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
