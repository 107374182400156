<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <!-- Side nav -->
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="'360Feedback.SIDEBAR_REQUEST_MANAGER_TITLE' | translate"
      [description]="('360Feedback.SIDEBAR_REQUEST_MANAGER_DESCR' | translate) + (personDetails ? (personDetails.forename + ' ' + personDetails.surname + '.<b>') : '<b>')">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingData(); else pageLoader">
        <div class="form-container">
          <div class="recipient-user-container" *ngIf="personDetails">
            <p class="recipient-label" translate="360Feedback.modal.FOR_WHOM"></p>
            <div class="avatar-name">
              <avatar-img [user]="personDetails" size="small"></avatar-img>
              <p>{{personDetails.surname + ' ' + personDetails.forename}}</p>
            </div>
          </div>

          <!-- user card -->
          <input-container [isObbligatory]="true" [isValued]="selectedUserList && selectedUserList.length"
            [tooltipModalMessage]="whoTooltipModal" [title]="'peerFeedback.createUpdate.WHO_REQUEST' | translate">
            <!-- ricerca -->
            <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
              (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
              placeholder="{{'360Feedback.SEARCH_USERS_PLACEHOLDER' | translate}}"></alloy-input>

            <!-- Card degli utenti -->
            <div class="user-card-collection-container">
              <div *ngIf="!isFetchingUserList && userCardData && userCardData.length" class="row-boxes"
                fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                <user-card *ngFor="let user of userCardData | slice:0:numRecords | paginate: { id: 'smartFeedback',
                    itemsPerPage: numRecords,
                    currentPage: currentPage,
                    totalItems: counter }" [user]="user"
                    [selectedUserList]="selectedUserList"
                    [forceDisabled]="user.disabled || false" [disabled]="checkUserSelectDisabled()"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                </user-card>
              </div>

              <!-- Nessun risultato -->
              <ng-container *ngIf="!isFetchingUserList && (!userCardData || !userCardData.length)">
                <p translate="generic.NO_RESULTS"></p>
              </ng-container>

              <!-- Loader -->
              <div *ngIf="isFetchingUserList" class="full-width min-height500" fxLayout="row wrap"
                fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                <div class="loader spinner-medium"></div>
              </div>

              <!-- Limite utenti e counter -->
              <div class="limit-container">
                <h4 translate="360Feedback.LIMITATION"></h4>
                <p [innerHTML]="getCountLimit()"></p>
                <!-- counter selezionati -->
                <div class="selected-user-counter-container">
                  <h5>
                    {{ ((selectedUserList.length + excludedUsers.length)) + ("generic.OF" | translate) + maxUserCount }}
                  </h5>
                  <p class="selected-label">
                    {{ (selectedUserList.length == 1 ? '360Feedback.CONTRIBUTOR' : '360Feedback.CONTRIBUTORS') | translate }}
                  </p>
                </div>
              </div>


              <!-- impaginazione -->
              <div class="full-width" *ngIf="!isFetchingUserList && userCardData && userCardData.length" fxLayout="row"
                fxLayoutAlign="center center">
                <table-pagination id="smartFeedback" (pageChanged)="proposedUserPageChanged($event)">
                </table-pagination>
              </div>
            </div>
          </input-container>

          <!-- Pulsante per l'invio dello smart feedback -->
          <div class="button-container">
            <alloy-button (onButtonClicked)="openSendFeedbackModal()" [disabled]="!checkObbligatoryData()"
              type="primary fill" size="large" [label]="'360Feedback.people.REQUEST_FEEDBACK' | translate">
            </alloy-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/risposta di un peerfeedback -->
<modal id="360ReceiveFeedback"
  [cancelLabel]="'feedbackSection.sendClapPage.modals.CANCEL_LABEL' | translate | uppercase"
  [confirmLabel]="'feedback.SEND_FEEDBACK_REQ' | translate | uppercase" (onClose)="closeSendFeedbackModal()"
  (onCancel)="closeSendFeedbackModal()" (onConfirm)="closeSendFeedbackModal(true)">
  <modal-text-content [title]="'360Feedback.people.REQUEST_FEEDBACK' | translate">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <!-- Subtitle -->
    <p class="subtitle-text" translate="360Feedback.modal.MANAGER_SUBTITLE"></p>

    <!-- Per chi -->
    <div class="custom-container">
      <p class="title-modal-custom" [translate]="'360Feedback.modal.FOR_WHOM' | translate"></p>
      <div class="more-info-container">
        <div class="user-container" *ngIf="personDetails">
          <avatar-img [user]="personDetails" size="small"></avatar-img>
          <p *ngIf="personDetails.forename && personDetails.surname">{{ personDetails.surname + ' ' +
            personDetails.forename |
            truncate: [22, '...']}}
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let feedback of feedbackRecap">
      <div class="custom-container">
        <!-- Titolo del recap -->
        <p class="title-modal-custom">{{feedback.title}}</p>

        <!-- Avatar degli utenti -->
        <ng-container *ngIf="feedback.avatar">
          <div class="more-info-container">
            <ng-container *ngFor="let user of this.selectedUserList">
              <div class="user-container" *ngIf="!user.disabled">
                <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
                <p *ngIf="user.name && user.surname">{{ user.surname + ' ' + user.name | truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Testo del recap -->
        <p *ngIf="feedback.text" class="text-modal-custom">{{feedback.text}}</p>
      </div>
    </ng-container>

  </modal-text-content>
</modal>


<ng-template #pageLoader>
  <div class="loader spinner-big"></div>
</ng-template>