import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { KrukOpenAPI } from 'atfcore-commonclasses';
import * as moment from 'moment';

@Component({
  selector: 'app-request360-feedback',
  templateUrl: './request360-feedback.component.html',
  styleUrls: ['./request360-feedback.component.scss']
})
export class Request360FeedbackComponent implements OnInit {
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  isImpersonate: boolean = false;
  isLoadingGoalsRatingScaleModal: boolean = false;
  getGoalsRatingScaleModal$: Subscription = new Subscription();
  goalsRatingScaleModalData: Array<{
    score: number,
    text: string,
    range: string
  }> = [];
  competenceDictionary: Array<{ scale: number, scaleTagId: string, text: string }> | any = [];

  strengthTooltip: ApplicationModalMessage = {
    modalId: "360seSTR",
    text: "TEST",
    title: "Indica i punti di forza",
  }

  improveTooltip: ApplicationModalMessage = {
    modalId: "360seIMP",
    text: "TEST",
    title: "Indica i punti di forza",
  }

  strengthsComment: string = "";
  improvementComment: string = "";

  competenceArray: any;
  competenceArrayCopy: any;
  competenceForLegendModal?: {
    title: string, text: string, isManagerial?: boolean, scale: {
      scale: number,
      scaleTagId: string,
      text: string
    }[]
  } | null;
  tempCompetenceUpdated: { competenceEvaluation?: { competenceId: string, evaluationRating: number }[] } = { competenceEvaluation: [] };
  isLoadingFeedbackData: boolean = false;
  getFeedbackData$: Subscription = new Subscription();
  softSkillsFinalAvg: any;
  parentBehaviourIds: any;
  recipientUser: KrukOpenAPI.KrukUser | undefined = undefined;
  intermediateUser: KrukOpenAPI.KrukUser | undefined = undefined;
  commentDate: string = '';
  senderUser: KrukOpenAPI.KrukUser | undefined = undefined;
  feedbackId: string = '';
  sendUserFeedback$: Subscription = new Subscription();
  readOnly: boolean = false;
  competenceCount: number = 0;
  confirmModalText: string = "";
  isSharedFeedback: boolean = false;

  constructor(public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router) {
    // se sono nella pagina di dettaglio oppure post condivisione del manager sono in readonly
    this.readOnly = window.location.href.indexOf("detail") >= 0 || window.location.href.indexOf('shared') >= 0;
    this.isSharedFeedback = window.location.href.indexOf('shared') >= 0;

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
        this.route.params
          .subscribe((params: any) => {
            this.feedbackId = params.feedbackId;

            if (!this.feedbackId) {
              this.goBackBrowser();
            } else {
              this.getGoalsRatingScaleModal();
              this.getFeedbackData();
            }
          });
      })
  }


  ngOnInit(): void {
  }

  getGoalsRatingScaleModal() {
    this.isLoadingGoalsRatingScaleModal = true;
    if (this.getGoalsRatingScaleModal$) {
      this.getGoalsRatingScaleModal$.unsubscribe();
    }

    this.getGoalsRatingScaleModal$ = this.selfAssessmentService.getPerformanceAssessmentGoalsRatingScaleForSubordinate()
      .subscribe((data: SenecaResponse<Array<{
        score: number,
        text: string,
        range: string
      }>>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360se003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalsRatingScaleModal = false;
        } else {
          this.goalsRatingScaleModalData = data.response;
          this.getFeedbackData();
          this.isLoadingGoalsRatingScaleModal = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360se004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsRatingScaleModal = false;
      });
  }

  closeCompetenceDictionaryModal() {
    this.modalService.close("competence-dictionary");
  }

  getFeedbackData() {
    this.isLoadingFeedbackData = true;

    if (this.getFeedbackData$) {
      this.getFeedbackData$.unsubscribe();
    }

    let serviceToCall;
    if (this.isSharedFeedback) {
      serviceToCall = this.selfAssessmentService.getFeedback360PostShare(this.feedbackId);
    } else {
      serviceToCall = this.selfAssessmentService.getFeedback360Details(this.feedbackId);
    }

    this.getFeedbackData$ = serviceToCall
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackData = false;
        } else {
          this.recipientUser = data.response.recipientUser;
          this.intermediateUser = data.response.intermediateUser;
          this.senderUser = data.response.senderUser;
          this.commentDate = data.response.commentDate ? moment(data.response.commentDate).format('DD/MM/YYYY') : '';

          if (this.senderUser && this.recipientUser) {
            this.confirmModalText =
              this.translate.instant("360Feedback.request.SEND_FEEDBACK_MODAL_1") + this.recipientUser.forename + ' ' + this.recipientUser.surname +
              this.translate.instant("360Feedback.request.SEND_FEEDBACK_MODAL_2") + this.senderUser.forename + ' ' + this.senderUser.surname +
              this.translate.instant("360Feedback.request.SEND_FEEDBACK_MODAL_3");
          }


          let ratingCounter = 0;
          let ratingSum = 0;
          let ratingDoneCounter = 0;

          if (data.response && data.response.competences) {
            let parentBehaviours = data.response.competences.map((x: any) => {
              return {
                competenceId: x.parentCompetence.competenceId,
                title: x.parentCompetence.text
              }
            })
            this.parentBehaviourIds = parentBehaviours.map((item: any) => item.competenceId)
              .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
            let tempBehaviour: any = {};
            for (let i = 0; i < this.parentBehaviourIds.length; i++) {
              for (let j = 0; j < data.response.competences.length; j++) {
                let behavior = data.response.competences[j];
                if ((behavior.parentCompetence as any).competenceId == this.parentBehaviourIds[i]) {
                  if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
                    tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
                  } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
                    tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
                  }
                }
              }
            }
            this.competenceArray = [];
            // Assegno la scala da inserire nella modale
            this.competenceDictionary = data.response.competences && data.response.competences[0]?.evaluationScale || null;

            const mappedCompetences = this.groupBy<KrukOpenAPI.PerfAlloyCompetence>(
              data.response.competences,
              a => (a.parentCompetence as any).text
            )

            for (const [key, val] of mappedCompetences.entries()) {
              const sumPartialCompetences = val.reduce((acc, comp) => {
                if (comp.evaluationRating !== -1) {
                  acc += comp.evaluationRating!;
                }
                return acc
              }, 0);
              const countLenght = val.filter(el => el.evaluationRating !== -1);
              const partiaCompetenceslAvg = sumPartialCompetences >= 0 ? sumPartialCompetences / countLenght.length : undefined;

              this.competenceArray.push({
                category: key,
                competences: val,
                ratingScale: 5,
                partiaCompetenceslAvg: partiaCompetenceslAvg,
                partiaCompetenceslAvgRound: partiaCompetenceslAvg
              })
            }

            const sumFinalAvg = this.competenceArray.reduce((acc: any, curr: any) => {
              const competencesSum: number = curr.competences.map((comp: any) => comp.evaluationRating != null && comp.evaluationRating != undefined && comp.evaluationRating >= 0 ? comp.evaluationRating : undefined)
                ?.filter(Boolean)
                ?.reduce((a: number, b: number) => a + b, 0);

              acc += competencesSum;
              return acc
            }, 0);

            const divider = this.competenceArray.reduce((acc: any, curr: any) => {
              curr.competences.forEach((comp: any) => comp.evaluationRating != null && comp.evaluationRating != undefined && comp.evaluationRating >= 0 ? acc += 1 : acc += 0)

              return acc;
            }, 0)

            const final = sumFinalAvg / divider


            // Media finale
            let ratingAvgNum;
            let ratingAvgString;

            ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;

            const ratingEvalScale = this.competenceArray && this.competenceArray[0] && this.competenceArray[0].evaluationScale && this.competenceArray[0].evaluationScale.length || 5;

            ratingAvgString = ratingAvgNum.toString();
            if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
              ratingAvgString = ratingAvgString.replace(".", ",");
            }
            // Indica se tutti i comportamenti hanno avuto almeno una valutazione
            let allBehavioursValuated = this.allCompetencesEval();
            // Media competenze
            this.softSkillsFinalAvg = {
              allBehavioursValuated: allBehavioursValuated,
              title: this.translate.instant('generic.FINAL_AVG'),
              subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
              ratingAvgNum: final,
              ratingStars: final,
              ratingStarsRounded: Math.round(final),
              ratingAvgString: final.toString(),
              ratingScale: ratingEvalScale
            }
          }

          if (data.response.overallComment) {
            this.strengthsComment = (data.response.overallComment as any)?.areaOfStrength || '';
            this.improvementComment = (data.response.overallComment as any)?.areaOfImprovement || '';
          }

          this.competenceCount = 0;
          if (this.competenceArray) {
            for (let i = 0; i < this.competenceArray.length; i++) {
              for (let j = 0; j < this.competenceArray[i].competences.length; j++) {
                if (this.competenceArray[i].competences[j] && this.competenceArray[i].competences[j].competenceId) {
                  this.competenceCount += 1;
                }
              }
            }
            this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
          } else {
            this.competenceArrayCopy = undefined;
          }

          this.isLoadingFeedbackData = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackData = false;
      });
  }

  public groupBy<V>(list: V[], keyGetter: (input: V) => string): Map<string, V[]> {
    const map = new Map<string, V[]>();

    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);

      !collection ? map.set(key, [item]) : collection.push(item);
    });

    return map;
  }

  onStrengthChanged(text: string) {
    this.strengthsComment = text;
  }

  onImprovementChanged(text: string) {
    this.improvementComment = text;
  }

  tempCompetenceUpdatedv2: any = {}
  onCompetenceRatingChanged(competence: any) {
    const a = this.competenceArray.find((cat: any) => cat?.category == competence.parentCompetence.text);
    const b = a.competences.find((comp: any) => comp.competenceId == competence.competenceId);
    b["evaluationRating"] = competence["evaluationScore"];

    this.tempCompetenceUpdatedv2[competence.competenceId] = {
      competenceId: competence.competenceId,
      evaluationRating: competence["evaluationScore"]
    }
  }

  saveUpdateCompetenceEvaluation(competence: any) {
    this.tempCompetenceUpdated.competenceEvaluation = this.tempCompetenceUpdated.competenceEvaluation?.filter(c => c.competenceId !== competence.competenceId);
    this.tempCompetenceUpdated.competenceEvaluation?.push({ competenceId: competence.competenceId, evaluationRating: competence.evaluationScore == 5 ? -1 : competence.evaluationScore });
  }

  // Aggiorna in real time la media parziale delle soft skill
  updateSoftSkillsPartialAvg(softSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (currentCompetence.isPartialAverage) {
            currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
            currentCompetence.selfRatingAvgNum = currentCompetenceSelfRatingAvgNum;
            currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            currentCompetence.selfRatingAvgString = currentCompetenceSelfRatingAvgString;
            break;
          }
        }

        break;
      }
    }
  }

  openSendFeedbackModal() {
    this.modalService.open('confirm-send-modal');
  }

  closeSendFeedbackModal(confirm?: boolean) {
    this.modalService.close('confirm-send-modal');
    if (confirm) {
      this.sendUserFeedback();
    }
  }

  sendUserFeedback() {
    this.isLoadingFeedbackData = true;
    if (this.sendUserFeedback$) {
      this.sendUserFeedback$.unsubscribe();
    }

    this.sendUserFeedback$ = this.selfAssessmentService.answerFeedback360RequestForUser(
      this.feedbackId,
      Object.values(this.tempCompetenceUpdatedv2), {
      areaOfStrength: this.strengthsComment,
      areaOfImprovement: this.improvementComment
    }
    )
      .subscribe((data: SenecaResponse<KrukOpenAPI.PerfAlloyFeedback>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360se001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackData = false;
        } else {
          this.isLoadingFeedbackData = false;
          this.goBackBrowser();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360se002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackData = false;
      });
  }


  openCompetenceLegendModal(competence?: any) {
    if (competence) {
      this.competenceForLegendModal = { title: competence?.title, text: competence?.text, isManagerial: competence?.parentCompetence?.isManagerial, scale: competence?.evaluationScale };
    }
    this.modalService.open("common-competence");
  }

  closeCompetenceLegendModal() {
    this.competenceForLegendModal = null;
    this.modalService.close("common-competence");
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  getSidenavDescription() {
    if (this.senderUser && this.recipientUser) {
      if (this.readOnly) {
        return this.translate.instant("360Feedback.SIDEBAR_USER_DETAIL_DESC1") + this.recipientUser.forename + ' ' + this.recipientUser.surname +
          this.translate.instant("360Feedback.SIDEBAR_USER_DETAIL_DESC2") + this.senderUser.forename + ' ' + this.senderUser.surname +
          this.translate.instant("360Feedback.SIDEBAR_USER_DETAIL_DESC3") + this.commentDate;
      } else {
        return this.translate.instant("360Feedback.SIDEBAR_USER_DESCR_1") + this.senderUser.forename + ' ' + this.senderUser.surname +
          this.translate.instant("360Feedback.SIDEBAR_USER_DESCR_2") + this.recipientUser.forename + ' ' + this.recipientUser.surname +
          this.translate.instant("360Feedback.SIDEBAR_USER_DESCR_3");
      }
    }
    return "";
  }

  allCompetencesEval() {
    let allEval: boolean[] = [];
    this.competenceArray.forEach((cat: any) => {
      const a = cat?.competences.every((c: any) => c["evaluationRating"] >= -1);
      allEval.push(a)
    })

    return allEval.every(v => v);
  }

  ngOnDestroy() {
    if (this.getGoalsRatingScaleModal$) {
      this.getGoalsRatingScaleModal$.unsubscribe();
    }
    if (this.getFeedbackData$) {
      this.getFeedbackData$.unsubscribe();
    }
  }
}
