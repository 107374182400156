import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { catchError, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'admin-roles-and-competences',
  templateUrl: './rolesAndCompetences.component.html',
  styleUrls: ['./rolesAndCompetences.component.scss']
})
export class RolesAndCompetencesComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;

  competenceTypeList: { id: string; title: string; isDisabled: boolean; }[] = [];

  selectedFilters: any = {};
  selectedFiltersNumber: number = 0;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    }
  ]
  selectedRows: { id: number, title: number };
  translations: any;
  menuOptions: { id: string; title: any; icon: string; disabled?: boolean }[] = [];

  isLoadingData: boolean = false;
  dataObject: {
    fromRecord: number,
    numRecords: number,
    page: number,
    list: any[],
    counter: number
  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      list: [],
      counter: 0
    };
  selectedData: any[] = [];
  modalDataToDelete: any = [];
  isBehaviorList: boolean = true;
  searchedText: string = '';
  allChecked: boolean = false;
  processYear: any;
  processYearCode: any;
  getData$: Subscription = new Subscription();
  importFile: any;
  resetIncludeUpload: boolean = true;
  isLoadingImport: boolean = false;
  importNotice: string = '';

  isDownloadingReport: boolean = false;
  downloadFile$: Subscription = new Subscription;

  isLoadignRolesReport: boolean = false;
  rolesFile$: Subscription = new Subscription;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {

    this.isLoadingData = true;

    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
          this.route.params
            .subscribe((params: Params) => {
              if (params.processYear) {
                this.processYear = params.processYear;
              }
              if (params.processYearCode) {
                this.processYearCode = params.processYearCode;
              }
            });
        });
  }

  ngOnInit() {
    this.translate.get([
      'setupCompetences.roles.IMPORT_SOFT_ROLE',
      'setupCompetences.roles.SOFT_ROLES',
      'setupCompetences.roles.TECH_ROLES',
      'setupCompetences.roles.MANAGE_BEHAVIOR',
      'setupCompetences.roles.MANAGE_COMPETENCE',
      'generic.EDIT',
      'generic.DELETE',
      "setupCompetences.BEHAVIOR",
      'setupCompetences.TECH_SKILL',
      'setupCompetences.modals.FILE_CONTAINS',
      'setupCompetences.modals.ROLES'
    ]).subscribe(translations => {
      this.translations = translations;
      this.competenceTypeList = [
        {
          id: 'soft',
          title: '',
          isDisabled: false
        },
        {
          id: 'tech',
          title: '',
          isDisabled: true
        }
      ]
      if (window.location.href.includes('soft')) {
        this.isBehaviorList = true;
        this.menuOptions = [
          {
            id: 'manage',
            title: this.translations['setupCompetences.roles.MANAGE_COMPETENCE'],
            icon: '/assets/img/icons/edit.svg',
          },
          {
            id: 'delete',
            title: translations['generic.DELETE'],
            icon: '/assets/img/icons/trash-2.svg',
          }
        ]
        this.competenceTypeList[0].isDisabled = false;
        this.competenceTypeList[1].isDisabled = true;
      } else {
        this.isBehaviorList = false;
        this.menuOptions = [
          {
            id: 'manage',
            title: this.translations['setupCompetences.roles.MANAGE_COMPETENCE'],
            icon: '/assets/img/icons/edit.svg',
          },
          {
            id: 'delete',
            title: translations['generic.DELETE'],
            icon: '/assets/img/icons/trash-2.svg',
          }
        ]
        this.competenceTypeList[0].isDisabled = true;
        this.competenceTypeList[1].isDisabled = false;
      }
      this.getTabCounters();
    });
  }

  getTabCounters() {
    let promiseArray = [
      this.adminService.countRoles(this.processYear, this.processYearCode, true, this.searchedText).toPromise(),
      this.adminService.countRoles(this.processYear, this.processYearCode, false, this.searchedText).toPromise()
    ];

    Promise.all(promiseArray).then((results) => {
      this.competenceTypeList[0].title = this.translations['setupCompetences.roles.SOFT_ROLES'] + ' (' + (results[0] && results[0].response || 0) + ')';
      this.competenceTypeList[1].title = this.translations['setupCompetences.roles.TECH_ROLES'] + ' (' + (results[1] && results[1].response || 0) + ')';
    }).then(() => {
      this.getData();
    });
  }

  saveFile(file: any) {
    this.importFile = file;
  }
  changeCurrentCompetenceType(tab: any) {
    this.isBehaviorList = tab.id == 'soft';
    let url = window.location.href.split('/#/')[1];
    url = url.split('/rolesAndCompetences/')[0];
    if (this.isBehaviorList) {
      url += '/rolesAndCompetences/softSkills'
    } else {
      url += '/rolesAndCompetences/techSkills'
    }
    this.router.navigate([url]);
  }

  // ricerca tutte le competenze
  searchedTextAllCompetenceChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.dataObject.list = [];
    this.dataObject.counter = 0;
    this.dataObject.fromRecord = 0;
    this.dataObject.page = 1;
    this.getData();
  }

  rolePageChanged(page: number) {
    this.dataObject.page = page;
    this.dataObject.list = [];
    this.dataObject.counter = 0;
    this.getData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.dataObject.numRecords = item.id;
    this.dataObject.list = [];
    this.dataObject.fromRecord = 0;
    this.getData();
  }


  openCloseMenu(data: any) {
    let currentPresent = this.selectedData.find((x: any) => x.id == data.id);
    if (!currentPresent) {
      data.isChecked = true;
      this.addRemoveData(data);
    }
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], data)
    }
    data.isMenuOpen = !data.isMenuOpen;
  }

  isMenuOptionValid(option: any, user: any) {
    if (option.id == 'manage') {
      if (this.selectedData && this.selectedData.length > 1) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // Associato alla direttiva clickOutside
  closeMenu(data: any) {
    data.isMenuOpen = false;
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    this.selectedData = [];
    if (this.dataObject.list && this.dataObject.list.length > 0) {
      for (let i = 0; i < this.dataObject.list.length; i++) {
        this.dataObject.list[i].isChecked = this.allChecked;
        this.addRemoveData(this.dataObject.list[i]);
      }
    }
  }

  addRemoveData(newObject: any) {
    if (this.selectedData && this.selectedData.length) {
      if (!newObject.isChecked) {
        this.selectedData = this.selectedData.filter((tmp: any) => tmp.id != newObject.id);
      } else {
        this.selectedData.push(newObject);
      }
    } else {
      this.selectedData = [newObject];
    }
  }


  getData() {
    this.isLoadingData = true;

    if (this.getData$) {
      this.getData$.unsubscribe();
    }

    this.getData$ = this.adminService.countRoles(this.processYear, this.processYearCode, this.isBehaviorList, this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.dataObject.counter = counter.response;

              // Calcolo la paginazione
              this.dataObject.fromRecord = (this.dataObject.page - 1) * this.dataObject.numRecords;

              if (this.dataObject.counter) {
                return this.adminService.listRoles(this.processYear, this.processYearCode, this.dataObject.fromRecord, this.dataObject.numRecords, this.isBehaviorList, this.searchedText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                data.response[i].id = data.response[i].tagId;
                // data.response[i].hasDescription = data.response[i].text && data.response[i].text.length;
              }
              this.dataObject.list = data.response;
            } else {

              this.dataObject.list = [];
            }
          }
          this.isLoadingData = false;
        }
        , (err: any) => {
          this.isLoadingData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }



  onMenuOptionsClicked(option: any, role: any) {
    if (option.id == 'manage') {
      this.router.navigate([`admin/manageRoles/${this.isBehaviorList ? 'softSkills' : 'techSkills'}/`, this.processYear, this.processYearCode, role.id]);
    } else {
      this.modalDataToDelete = this.selectedData.map((x: any) => x.id);
      if (!this.modalDataToDelete.includes(role.id)) {
        this.modalDataToDelete.push(role.id);
      }
      this.modalService.open('confirm-cancel');
    }
  }

  // Check singolo comportamento / skill
  selectData(data: any) {
    data.isChecked = !data.isChecked;
    this.addRemoveData(data);
  }



  goToNewRole() {
    this.router.navigate([`admin/manageRoles/${this.isBehaviorList ? 'softSkills' : 'techSkills'}/`, this.processYear, this.processYearCode]);
  }

  closeCancelModal(confirm?: boolean) {
    this.modalService.close('confirm-cancel');

    if (confirm) {
      this.isLoadingData = true;

      this.adminService.deleteRole(this.processYear, this.processYearCode, this.modalDataToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          } else {
            let x: any = document.getElementById("snackbar-delete");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.modalDataToDelete = null;
            this.dataObject.list = [];
            this.dataObject.counter = 0;
            this.dataObject.page = 1;
            this.dataObject.fromRecord = 0;
            this.getData();
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          });
    }
  }

  openImportModal(type: string) {
    this.resetIncludeUpload = true;
    this.importFile = null;
    this.modalService.open('import-modal');
  }


  closeImportModal(confirm?: boolean) {
    this.modalService.close('import-modal');
    if (confirm) {
      this.isLoadingData = true;
      this.adminService.importRolesInProcessByYear(this.processYear, this.processYearCode, this.importFile)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          } else {
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.importFile = null;
            this.getData();
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          });
    }
  }


  assignFile() {

    this.isLoadingImport = true;
    this.adminService.importRoles(this.processYear, this.processYearCode, this.importFile)
      .subscribe((data: any) => {
        if (data && data.body && data.body.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        } else {
          this.importNotice = this.translations['setupCompetences.modals.FILE_CONTAINS'] + ' <b>' + data.body?.response?.roleTagsToCreateCount || 0 + '</b> ';
          this.importNotice += this.translations['setupCompetences.modals.ROLES'];
          this.isLoadingImport = false;
          // toaster 
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        });
  }

  getRolesFile() {
    if (this.rolesFile$) {
      this.rolesFile$.unsubscribe();
    }

    this.rolesFile$ = this.adminService.getExcportRolesReport(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadignRolesReport = false;
        } else {
          this.downloadnFile(data.response);
          this.isLoadignRolesReport = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadignRolesReport = false;
      })
  }

  downloadnFile(data: any) {
    if (this.downloadFile$) {
      this.downloadFile$.unsubscribe();
    }
    let filename = data
    this.downloadFile$ = this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
      if (data && data.response) {
        let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
        setTimeout(() => {
          window.open(downloadUrl, '_blank');
        }, 500)
        this.isDownloadingReport = false;
      } else {
        const messageObj: ApplicationModalMessage = {
          modalId: "a007",
          text: this.translate.instant("errors." + data && data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      }
    }, (err: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "a008",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isDownloadingReport = false;
    })
  }


  ngOnDestroy(): void {
    if (this.getData$) {
      this.getData$.unsubscribe();
    }
  }

}