<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <!-- Side nav -->
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="'360Feedback.SIDEBAR_RECEIVE_TITLE' | translate"
      [description]="'360Feedback.SIDEBAR_RECEIVE_DESC' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container>
        <div class="form-container" *ngIf="!isGettingSuggestedUsers && !isFetchingUserData; else pageLoader">
          <!-- user card -->
          <!-- [tooltipModalMessage]="whoTooltipModal" -->
          <input-container [isObbligatory]="true" [isValued]="selectedUserList && selectedUserList.length"
            [tooltipModalMessage]="whoTooltipModal" [title]="'360Feedback.SEARCH_USERS_TITLE' | translate">
            <!-- ricerca -->
            <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
              (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
              placeholder="{{'360Feedback.SEARCH_USERS_PLACEHOLDER' | translate}}"></alloy-input>

            <!-- Card degli utenti -->
            <div class="user-card-collection-container">
              <div *ngIf="!isFetchingUserData" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                fxLayoutGap.lt-md="16px">
                <!-- Tabella nomi utenti-->
                <ng-container *ngIf="userCardData && userCardData.length">
                  <user-card *ngFor="let user of userCardData | slice:0:numRecords | paginate: { id: 'smartFeedback',
                    itemsPerPage: numRecords,
                    currentPage: currentPage,
                    totalItems: counter }" [user]="user" [selectedUserList]="alreadyAddedList"
                    [forceDisabled]="user.disabled || false" [disabled]="checkUserSelectDisabled()"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                  </user-card>
                </ng-container>
                <!-- Nessun risultato -->
                <ng-container *ngIf="!userCardData || !userCardData.length">
                  <p translate="generic.NO_RESULTS"></p>
                </ng-container>
              </div>
              <!-- Loader -->
              <div *ngIf="isFetchingUserData" class="full-width min-height500" fxLayout="row wrap"
                fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                <div class="loader spinner-medium"></div>
              </div>
              <!-- impaginazione -->
              <div class="full-width" *ngIf="!isFetchingUserData && userCardData && userCardData.length" fxLayout="row"
                fxLayoutAlign="center center">
                <table-pagination id="smartFeedback" (pageChanged)="pageChanged($event)">
                </table-pagination>
              </div>
              <!-- Limite utenti e counter -->
              <div class="limit-container">
                <h4 translate="360Feedback.LIMITATION"></h4>
                <p [innerHTML]="getCountLimit()"></p>
                <!-- counter selezionati -->
                <div class="selected-user-counter-container">
                  <h5> {{(((selectedUserList.length + excludedUserIds.length) || 0)) + ("generic.OF" | translate) +
                    maxUserCount}}</h5>
                  <p class="selected-label"
                    [translate]="((((selectedUserList.length + excludedUserIds.length) || 0)) == 1 ? '360Feedback.COLLEAGUE' : '360Feedback.COLLEAGUES')">
                  </p>
                </div>
              </div>
            </div>
          </input-container>

          <!-- Pulsante per l'invio dello smart feedback -->
          <div class="button-container">
            <alloy-button (onButtonClicked)="openProposeModal()" [disabled]="!checkObbligatoryData()"
              type="primary fill" size="large" [label]="'360Feedback.BTN' | translate">
            </alloy-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/risposta di un peerfeedback -->
<modal id="360ReceiveFeedback"
  [cancelLabel]="'feedbackSection.sendClapPage.modals.CANCEL_LABEL' | translate | uppercase"
  [confirmLabel]="'360Feedback.modal.CONFIRM' | translate | uppercase" (onClose)="closeProposeModal()"
  (onCancel)="closeProposeModal()" (onConfirm)="closeProposeModal(true)">
  <modal-text-content [title]="'360Feedback.modal.TITLE' | translate">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <!-- Subtitle -->
    <p class="subtitle-text" translate="360Feedback.modal.SUBTITLE"></p>

    <ng-container *ngFor="let feedback of feedbackRecap">
      <div class="custom-container">
        <!-- Titolo del recap -->
        <p class="title-modal-custom">{{feedback.title}}</p>

        <!-- Avatar degli utenti -->
        <ng-container *ngIf="feedback.avatar">
          <div class="more-info-container">
            <ng-container *ngFor="let user of this.selectedUserList">
              <div class="user-container" *ngIf="!user.disabled">
                <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
                <p *ngIf="user.name && user.surname">{{ user.surname + ' ' + user.name | truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Testo del recap -->
        <p *ngIf="!feedback.avatar" class="text-modal-custom">{{feedback.text}}</p>
      </div>
    </ng-container>

  </modal-text-content>
</modal>


<ng-template #pageLoader>
  <div class="loader spinner-big"></div>
</ng-template>