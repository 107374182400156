import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as fromApp from "../../../ngrx/app.reducers";
import { LangsService } from 'src/app/core/services/langs.service';
import { JwtPayload } from '../../../../commonclasses';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../services/redirect.service';
import { Router } from '@angular/router';

@Component({
  selector: 'banner-home',
  templateUrl: 'banner-home.component.html',
  styleUrls: ['./banner-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BannerHomeComponent implements OnInit {
  @Input() phaseName: string = 'H1'; // H1, H2, APPRAISAL
  @Input() backgroundSrc: string = '/assets/img/head/bg-circle.png' // vedi /banner/
  @Input() customClass: string = 'orange-bg' // orange-bg, red-bg, green-bg
  @Input() isNarrow: boolean = false;
  @Input() endDate: Date = moment().toDate();
  @Input() hasButton: boolean = false;
  @Input() buttonLabel: string = '';
  @Input() isAdmin: boolean = false;
  @Input() selfAssessment: boolean = false;
  @Input() phaseClosed: boolean = false;
  @Input() isLeadership: boolean = false;
  // TODO USA SOLO QUESTI, BANNER PROVVISORIO PRIMA DELLA NUOVA HOME
  @Input() isSelfAssessment: boolean = false;
  @Input() isAdminBanner: boolean = false;
  @Input() hasHighlight: boolean = false;
  @Input() isNew: boolean = false;
  @Input() selfIsNew: boolean = false;
  @Input() secondLevelIsNew: boolean = false;
  @Input() self: boolean = false;
  @Input() team: boolean = false;
  @Input() pasData?: {
    perfYear: number,
    perfYearCode: string,
    isNewPas: boolean,
    endDate: string
  };

  today: string = '';
  isPeopleAppraisal: boolean = false;
  isStaff: boolean = false;
  userName: string = '';
  combinedSelectes$: any;
  bannerSubtitle: string = '';

  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSAClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPasClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelfClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSecondLevelClicked: EventEmitter<any> = new EventEmitter<any>();
  mobileToday: string = '';
  @Input() runningYear: number = 2021

  constructor(
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
    const isStaff$: Observable<boolean> = this.store.select(fromApp.getIsStaff)
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    this.combinedSelectes$ = combineLatest([loggedUser$, isPeopleAppraisal$, isStaff$])
      .subscribe(
        ([loggedUser, isPeopleAppraisal, isStaff]) => {
          if (loggedUser && loggedUser.user) {
            this.userName = loggedUser.user && loggedUser.user.forename;
            let language = this.langsService.getUserLang(loggedUser.user);
            moment.locale(language);
            this.today = moment().format('dddd D MMMM YYYY');
            this.mobileToday = moment().format('D MMMM YYYY');
            this.isPeopleAppraisal = isPeopleAppraisal;
            this.isStaff = isStaff;
            if (this.isValidDate(this.endDate)) {
              const formattedEndDate = new Date(this.endDate);

              this.translate.get(
                [
                  'banner.' + this.phaseName + '.DESCR_SELF_1',
                  'banner.' + this.phaseName + '.DESCR_SELF_2',
                  'banner.' + this.phaseName + '.DESCR_SELF_1_COLLAB',
                  'banner.' + this.phaseName + '.DESCR_SELF_2_COLLAB'
                ])
                .subscribe(translations => {
                  const endDate = moment(this.endDate).format('D MMMM YYYY');
                  if (this.isPeopleAppraisal && window.location.href.indexOf('peopleAppraisal') >= 0) {
                    this.bannerSubtitle = translations['banner.' + this.phaseName + '.DESCR_SELF_1'] + ' ' + endDate + translations['banner.' + this.phaseName + '.DESCR_SELF_2'];
                  } else {
                    this.bannerSubtitle = translations['banner.' + this.phaseName + '.DESCR_SELF_1_COLLAB'] + ' ' + endDate + translations['banner.' + this.phaseName + '.DESCR_SELF_2_COLLAB'];
                  }
                })
            }
          }
        });
  }

  isValidDate(dateObject: any) {
    return dateObject && new Date(dateObject).toString() !== 'Invalid Date';
  }

  emitOnButtonClicked(data?: any) {
    this.onButtonClicked.emit(data);
  }

  goToSupport() {
    if (window.location.href.indexOf('/selfAssessment') > -1) {
      this.router.navigate(['/selfAssessment/support']);
    } else {
      this.router.navigate(['/peopleAppraisal/support']);
    }
  }

  goToNotifications() {
    if (window.location.href.indexOf('/selfAssessment') > -1) {
      this.router.navigate(['/selfAssessment/notifications']);
    } else {
      this.router.navigate(['/peopleAppraisal/notifications']);
    }
  }

  pasClicked() {
    this.onPasClicked.emit();
  }

  onSelfAssessmentClicked() {
    this.onSAClicked.emit();
  }

  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }
}