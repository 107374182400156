<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <!-- Side nav -->
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="userId ? (('feedbackSection.sendClapPage.SIDENAV_TITLE_WITH_USER' | translate) + (personDetails && personDetails.forename) + ' ' + (personDetails && personDetails.surname)) : ('feedbackSection.sendClapPage.SIDENAV_TITLE_WITHOUT_USER' | translate)"
      [description]="userId ? ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITH_USER' | translate) : ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITHOUT_USER' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container>
        <div class="form-container">
          <ng-container *ngIf="!userId">
            <!-- user card -->
            <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
              [isValued]="selectedUserList && selectedUserList.length"
              [title]="'feedbackSection.sendClapPage.WHO_TO_CLAP' | translate">
              <!-- ricerca -->
              <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

              <!-- Card degli utenti -->
              <div class="user-card-collection-container">
                <div *ngIf="!isFetchingSmartFeedbackUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                  fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                  fxLayoutGap.lt-md="16px">
                  <user-card *ngFor="let user of userCardData | slice:0:smartFeedbackNumRecords | paginate: { id: 'smartFeedback',
                  itemsPerPage: smartFeedbackNumRecords,
                  currentPage: smartFeedbackCurrentPage,
                  totalItems: smartFeedbackCounter }" [user]="user" [selectedUserList]="selectedUserList"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)"></user-card>
                </div>
                <!-- Loader -->
                <div *ngIf="isFetchingSmartFeedbackUsers" class="full-width min-height500" fxLayout="row wrap"
                  fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                  fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                  <div class="loader spinner-medium"></div>
                </div>
                <!-- impaginazione -->
                <div class="full-width" *ngIf="!isFetchingSmartFeedbackUsers && userCardData && userCardData.length"
                  fxLayout="row" fxLayoutAlign="center center">
                  <table-pagination id="smartFeedback" (pageChanged)="smartFeedbackUsersPageChanged($event)">
                  </table-pagination>
                </div>
              </div>
            </input-container>
          </ng-container>
          <ng-container>
            <!-- Obiettivo su cui è richiesto il feedback -->
            <input-container [isObbligatory]="false" [isLoading]="false" [tooltipModalMessage]="competenceModal"
              *ngIf="!competenceList.length" [isValued]="true"
              title="{{'feedbackSection.sendClapPage.COMPETENCE_REQUEST' | translate}}">
              <alloy-select [items]="competenceList" (onSelectedItem)="changeAssociatedCompetence($event)"
                [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate" [searchable]="true"
                bindLabel="id" placeholder="{{'feedbackSection.sendClapPage.SELECT_COMPETENCE' | translate}}">
              </alloy-select>
            </input-container>
            <input-container [isObbligatory]="false" [isLoading]="false" [tooltipModalMessage]="competenceModal" *ngIf="competenceList.length"
              [isValued]="true" title="{{'feedbackSection.sendClapPage.COMPETENCE_REQUEST' | translate}}">
              <alloy-select [selectedItem]="FREE_FEEDBACK" [items]="competenceList" (onSelectedItem)="changeAssociatedCompetence($event)"
                [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate" [searchable]="true"
                bindLabel="id" placeholder="{{'feedbackSection.sendClapPage.SELECT_COMPETENCE' | translate}}">
              </alloy-select>
            </input-container>
          </ng-container>
          <!-- Valuta la competenza -->
          <!-- <pre>{{ selectedCompetence?.id != FREE_FEEDBACK?.id }}</pre> -->
          <ng-container *ngIf="selectedCompetence && selectedCompetence?.id != FREE_FEEDBACK?.id">
            <input-container [isObbligatory]="countEvaluation? false: true" [isLoading]="false" [isValued]="false"
              title="{{'feedbackSection.sendClapPage.EVALUATES_COMPETENCE' | translate}}" (infoEvent)="onInfo()"
              [disabled]="!selectedCompetence">
              <evaluation-system
                direction="row"
                size="big"
                kind="default"
                aspect="manager"
                [competenceScale]="true"
                [items]="5"
                [showDescr]="true"
                [level]="countEvaluation"
                [disabledFirstStar]="true"
                (onButtonClicked)="changeEvaluation($event)">
              </evaluation-system>
            </input-container>
          </ng-container>
          <!-- Area in cui si inserisce il commento per lo smart feedback -->
          <input-container [isLoading]="isFetchingCreateSmartFeedback" [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="smartFeedbackComment && smartFeedbackComment.trim().length" [isObbligatory]="true"
            title="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP' | translate}}">
            <alloy-text-area [bindValue]="smartFeedbackComment"
              (onModelChanged)="smartFeedbackDescriptionChanged($event)"
              placeholder="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>

          <!-- Pulsante per l'invio dello smart feedback -->
          <div class="button-container">
            <alloy-button (onButtonClicked)="openSmartFeedbackModal()" [disabled]="!checkObbligatoryData()"
              type="primary fill" size="large" [label]="'feedbackSection.sendClapPage.SEND_CLAP' | translate">
            </alloy-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/risposta di un peerfeedback -->
<modal id="smartFeedback" [cancelLabel]="'feedbackSection.sendClapPage.modals.CANCEL_LABEL' | translate | uppercase"
  [confirmLabel]="'feedbackSection.sendClapPage.modals.CONFIRM_LABEL' | translate | uppercase"
  (onClose)="closeSmartFeedbackModal()" (onCancel)="closeSmartFeedbackModal()" (onConfirm)="sendSmartFeedback(true)">
  <modal-text-content [title]="'peerFeedback.modals.TITLE' | translate">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <!-- Subtitle -->
    <!-- <p class="subtitle-text" translate="peerFeedback.modals.REQUEST_FEEDBACK_SUBTITLE"></p> -->

    <ng-container *ngFor="let feedback of feedbackRecap">
      <div class="custom-container">
        <!-- Titolo del recap -->
        <p class="title-modal-custom">{{feedback.title}}</p>

        <!-- Avatar degli utenti -->
        <ng-container *ngIf="feedback.avatar">
          <div class="more-info-container">
            <ng-container *ngFor="let user of this.selectedUserList">
              <div class="user-container">
                <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
                <p *ngIf="user.name && user.surname">{{ user.surname + ' ' + user.name | truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Valutazione -->
        <evaluation-system
          *ngIf="feedback.isFeedback && selectedCompetence?.id !== FREE_FEEDBACK?.id"
          direction="row"
          size="big"
          kind="default"
          [competenceScale]="true"
          [items]="5"
          [readOnly]="true"
          [showDescr]="true"
          [level]="countEvaluation"
          [disabledFirstStar]="true">
        </evaluation-system>

        <!-- Testo del recap -->
        <p *ngIf="!feedback.avatar" class="text-modal-custom">{{feedback.text}}</p>
      </div>
    </ng-container>

  </modal-text-content>
</modal>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" confirmLabel="{{ 'generic.CLOSE' | translate}}"
  (onConfirm)="closeCompetenceDictionary()" (onClose)="closeCompetenceDictionary()">
  <div class="wrap-container">
    <p class="modal-title black center" translate="dictionary.TITLE"></p>
    <div class="divisor-line"></div>
    <div class="subtitle-container">
      <p class="title">{{ getTitleCompetence(selectedCompetence?.id) }}</p>
      <p class="subtitle" [innerHTML]="getSubtitleCompetence(selectedCompetence?.id)"></p>
    </div>
    <div class="divisor-line"></div>
    <div class="container">
      <div *ngFor="let _ of [0,1,2,3,4]; let i = index">
        <evaluation-system
          direction="column"
          size="big"
          kind="default"
          [competenceScale]="true"
          [items]="5"
          [readOnly]="true"
          [showTitles]="true"
          [showDescr]="true"
          [level]="i"
          [disabledFirstStar]="true">
        </evaluation-system>

        <p class="percentage">{{ getPercentageRange(i) }}</p>
      </div>
    </div>
  </div>
</modal>