<div class="page-content-wrapper">

  <div id="snackbarPersonDetails">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingFeedback360RoundList; else bigStepLoader">
    <ng-container *ngIf="!isLoadingPersonDetails && personDetails">
      <header-dossier [user]="personDetails" [roundList]="feedbackRoundList" [selectedRound]="selectedRound"
        (onSelectedRound)="changeSelectedRound($event)" [hasTabs]="false" [hideAction]="isImpersonating"
        (onNewInterview)="createPrivateNote()" [isFeedback360]="true">
      </header-dossier>

      <div class="main-content-wrapper">
        <div class="feedback-container counter"
          *ngIf="!isLoadingManagerFeedback && requestedList && requestedList.length">
          <h6 [innerHTML]="
        ('360Feedback.people.REQUESTED_COUNTER_1' | translate) + requestedList.length +
        ('360Feedback.people.REQUESTED_COUNTER_2' | translate) + '8' +
        ('360Feedback.people.REQUESTED_COUNTER_3' | translate)
        ">
          </h6>
          <ng-container *ngIf="canAddRequest() && isRunningRound()">
            <alloy-button type="primary fill" size="medium" (onButtonClicked)="goToRequestFeedback()"
              [label]="'360Feedback.people.REQUEST_A_FEEDBACK' | translate">
            </alloy-button>
          </ng-container>

        </div>

        <!-- Richiedi feedback -->
        <div class="feedback-container">
          <!-- Placeholder nessuna richiesta inviata -->
          <ng-container *ngIf="!isLoadingManagerFeedback; else stepLoader">
            <ng-template #noRequestPlaceholder>
              <svg-icon src="/assets/img/icons/peopleApprisalFeedback360placeholder.svg"></svg-icon>
              <h4 translate="360Feedback.people.REQUEST_A_FEEDBACK"></h4>
              <p
                [innerHTML]="('360Feedback.people.REQUEST_FEEDBACK_DESCR_1' | translate) + personDetails.forename + ' ' + personDetails.surname + ('360Feedback.people.REQUEST_FEEDBACK_DESCR_2' | translate)">
              </p>
              <alloy-button [disabled]="!canAddRequest() || !isRunningRound()" type="primary fill" size="medium"
                [label]="'360Feedback.people.REQUEST_A_FEEDBACK' | translate" (onButtonClicked)="goToRequestFeedback()">
              </alloy-button>
            </ng-template>

            <!-- Richieste inviate -->
            <ng-container *ngIf="requestedList && requestedList.length; else noRequestPlaceholder">
              <h6 [translate]="'360Feedback.people.PEOPLE_REQUESTED_FEEDBACK' | translate"></h6>
              <div class="admin-table-container">
                <div class="table-content" *ngIf="true">
                  <table>
                    <thead>
                      <tr>
                        <th translate="{{ ('360Feedback.table.NAMES' | translate)}}" style="width:50%"></th>
                        <th translate="{{ ('360Feedback.table.STATE' | translate)}}"></th>
                        <th translate="{{ ('360Feedback.table.ACTIONS' | translate)}}">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let feedback of requestedList">
                        <td>
                          <div class="more-info-container">
                            <div class="user-container" *ngIf="feedback.intermediateUser">
                              <avatar-img [user]="feedback.intermediateUser" size="small">
                              </avatar-img>
                              <p *ngIf="feedback.intermediateUser.forename && feedback.intermediateUser.surname">
                                {{feedback.intermediateUser.surname + ' ' + feedback.intermediateUser.forename |
                                truncate:
                                [22,'...']}}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ng-container *ngIf="feedback.feedbackStatus == 'SENT'; else received">
                            <item-list-team-status type="inProgress"
                              [message]="'360Feedback.statuses.REQUESTED' | translate" styleItem="fill">
                            </item-list-team-status>
                          </ng-container>
                          <ng-template #received>
                            <item-list-team-status type="completed"
                              [message]="'360Feedback.statuses.RECEIVED' | translate" styleItem="fill">
                            </item-list-team-status>
                          </ng-template>
                        </td>
                        <td class="detail"
                          [ngClass]="{'disabled': feedback.feedbackStatus == 'SENT' || !isRunningRound()}"
                          (click)="goToFeedbackDetails(feedback)">
                          {{ '360Feedback.table.FEEDBACK_DETAILS' | translate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>


        </div>


        <!-- Lista persone suggerite -->
        <div class="feedback-container">

          <ng-container *ngIf="!isLoadingSuggestedList; else stepLoader">
            <ng-template #noProposalPlaceholder>
              <svg-icon src="/assets/img/icons/peopleApprisalFeedback360placeholder.svg"></svg-icon>
              <h4 translate="360Feedback.people.NO_SUGGESTION_RECEIVED"></h4>
            </ng-template>
            <ng-container *ngIf="proposedList && proposedList.length; else noProposalPlaceholder">
              <h6>
                {{ ('360Feedback.people.PEOPLE_SUGGESTED_BY' | translate) + personDetails.forename + ' ' +
                personDetails.surname}}
              </h6>
              <div class="admin-table-container">
                <div class="table-content" *ngIf="true">
                  <table>
                    <thead>
                      <tr>
                        <th translate="{{ ('360Feedback.table.NAMES' | translate)}}" style="width:50%"></th>
                        <th translate="{{ ('360Feedback.table.STATE' | translate)}}"></th>
                        <th translate="{{ ('360Feedback.table.ACTIONS' | translate)}}">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of proposedList">
                        <td>
                          <div class="more-info-container">
                            <div class="user-container">
                              <avatar-img [user]="user" size="small">
                              </avatar-img>
                              <p *ngIf="user.forename && user.surname">
                                {{user.surname + ' ' + user.forename |truncate: [22,
                                '...']}}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <item-list-team-status type="disabled"
                            [message]="'360Feedback.statuses.TO_REQUEST' | translate" styleItem="fill">
                          </item-list-team-status>
                        </td>
                        <td class="detail" [ngClass]="{'disabled': !canAddRequest() || !isRunningRound()}"
                          (click)="requestFeedbackToUser(user)">
                          {{ '360Feedback.people.REQUEST_FEEDBACK' | translate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>


      </div>

    </ng-container>
  </ng-container>
</div>


<!-- Loader obiettivi step -->
<ng-template #stepLoader>
  <div class="loader spinner-medium"></div>
</ng-template>
<!-- Loader pagina -->
<ng-template #bigStepLoader>
  <div class="loader spinner-big"></div>
</ng-template>

<!-- Conferma richiesta di feedback -->
<modal id="confirm-request-to-user" (onClose)="closeRequestToUser()" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeRequestToUser()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeRequestToUser(true)">
  <modal-text-content *ngIf="userForRequest" modalId="360fe001"
    [title]="'360Feedback.people.SHARE_FEEDBACK_TO_COLLABORATOR' | translate"
    [text]="('360Feedback.people.CONFIRM_SEND_REQUEST_DESCR' | translate) + '<b>' + userForRequest.forename + ' ' + userForRequest.surname + '</b>?'">
  </modal-text-content>
</modal>