
import { createReducer, on, Action } from '@ngrx/store';
import * as CoreActions from './core.actions';
import { PerfCareNotification } from 'src/commonclasses';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { KrukOpenAPI } from 'atfcore-commonclasses';

export interface ApplicationModalMessage {
  modalId: string,
  text: string,
  title: string,
  subtitle?: string
  badge?: any,
  image?: string,
  noBackground?: boolean,
  withScrolledBody?: boolean,
  applicationModalButtonCloseText?: string,
  modalWithKalturaScript?: string;
  kalturaCustomId?: string;
  noConfirmButton?: boolean;
  confirmLabel?: string;
}

export interface CoreState {
  globalApplicationData: any,
  isFetchingLangs: boolean,
  showApplicationLoader: boolean,
  isMainMenuSidebarOpened: boolean,
  redirectUrl: string,
  applicationLang: string,
  defaultLang: string,
  notificationsCounter: number,
  notificationsInitials: PerfCareNotification[],
  applicationModalMessage: any,
  runningYear: any,
  runningPhase: any,
  applicationModalBadge: BadgeObject[],
  impersonateBanner: boolean,
  feedbackRoundList: KrukOpenAPI.PerfAlloyFeedback360Round[],
  feedbackRunningRound: KrukOpenAPI.PerfAlloyFeedback360Round | undefined
}

const initialState: CoreState = {
  globalApplicationData: null,
  isFetchingLangs: false,
  showApplicationLoader: false,
  isMainMenuSidebarOpened: false,
  redirectUrl: '',
  applicationLang: '',
  defaultLang: 'it',
  notificationsCounter: 0,
  notificationsInitials: [],
  applicationModalMessage: { modalId: '', text: '', title: '' },
  runningYear: {
    year: 0,
    yearCode: ''
  },
  runningPhase: {},
  applicationModalBadge: [], //{ badgeId: '', badgeType: '', title: '', image: '', isActive: false, starNumberArray: [], date: '' }
  impersonateBanner: false,
  feedbackRoundList: [],
  feedbackRunningRound: undefined,
};

export const createCoreReducer = createReducer(
  initialState,
  on(CoreActions.SetApplicationModalMessage, (state, action) => {
    return {
      ...state,
      applicationModalMessage: action.payload
    };
  }),
  on(CoreActions.SetRunningYear, (state, action) => {
    return {
      ...state,
      runningYear: action.payload
    };
  }),
  on(CoreActions.DeleteApplicationModalMessage, (state, action) => {
    return {
      ...state,
      applicationModalMessage: { modalId: '', title: '', text: '' }
    };
  }),
  on(CoreActions.SaveRedirectUrl, (state, action) => {
    return {
      ...state,
      redirectUrl: action.payload
    };
  }),
  on(CoreActions.RemoveRedirectUrl, (state, action) => {
    return {
      ...state,
      redirectUrl: ''
    };
  }),
  on(CoreActions.SetCoreApplicationData, (state, action) => {
    return {
      ...state,
      globalApplicationData: action.payload
    };
  }),
  on(CoreActions.SetApplicationLang, (state, action) => {
    return {
      ...state,
      applicationLang: action.payload
    };
  }),
  on(CoreActions.RemoveCoreApplicationData, (state, action) => {
    return {
      ...state,
      globalApplicationData: null
    };
  }),
  on(CoreActions.RemoveApplicationLang, (state, action) => {
    return {
      ...state,
      applicationLang: ''
    };
  }),
  on(CoreActions.GetAvailableLangs, (state, action) => {
    return {
      ...state,
      isFetchingLangs: true
    };
  }),
  on(CoreActions.GetAvailableLangsFinished, (state, action) => {
    return {
      ...state,
      isFetchingLangs: false
    };
  }),
  on(CoreActions.SetDefaultLang, (state, action) => {
    return {
      ...state,
      defaultLang: action.payload
    };
  }),
  on(CoreActions.OpenMainMenuSidebar, (state, action) => {
    return {
      ...state,
      isMainMenuSidebarOpened: true
    };
  }),
  on(CoreActions.CloseMainMenuSidebar, (state, action) => {
    return {
      ...state,
      isMainMenuSidebarOpened: false
    };
  }),
  on(CoreActions.SetNotificationsCounter, (state, action) => {
    return {
      ...state,
      notificationsCounter: action.payload
    };
  }),
  on(CoreActions.SetNotificationsInitials, (state, action) => {
    return {
      ...state,
      notificationsInitials: action.payload
    };
  }),
  on(CoreActions.SetRunningPhase, (state, action) => {
    return {
      ...state,
      runningPhase: action.payload
    };
  }),
  on(CoreActions.SetApplicationModalBadge, (state, action) => {
    return {
      ...state,
      applicationModalBadge: action.payload
    };
  }),
  on(CoreActions.DeleteApplicationModalBadge, (state, action) => {
    return {
      ...state,
      applicationModalBadge: state.applicationModalBadge.filter((badge: BadgeObject) => badge.badgeId != action.payload) //{ badgeId: '', badgeType: '', title: '', image: '', isActive: false, starNumberArray: [], date: '' }
    };
  }),
  on(CoreActions.SetImpersonificationBanner, (state, action) => {
    sessionStorage.setItem('isImpersonification', (action.payload && action.payload.toString() || 'false'))
    return {
      ...state,
      impersonateBanner: action.payload
    }
  }),
  on(CoreActions.SetFeedbackRoundList, (state, action) => {
    return {
      ...state,
      feedbackRoundList: action.payload
    };
  }),
  on(CoreActions.SetFeedbackRunningRound, (state, action) => {
    return {
      ...state,
      feedbackRunningRound: action.payload
    };
  }),
)

export function coreReducer(state: CoreState | undefined, action: Action) {
  return createCoreReducer(state, action);
}

export const getGlobalApplicationData = (state: CoreState) => state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) => state.showApplicationLoader;
export const isMainMenuSidebarOpened = (state: CoreState) => state.isMainMenuSidebarOpened;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getApplicationContext = (state: CoreState) => state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getNotificationsCounter = (state: CoreState) => state.notificationsCounter;
export const getNotificationsInitials = (state: CoreState) => state.notificationsInitials;
export const getApplicationModalMessage = (state: CoreState) => state.applicationModalMessage;
export const getRunningYear = (state: CoreState) => state.runningYear;
export const getRunningPhase = (state: CoreState) => state.runningPhase;
export const getApplicationModalBadge = (state: CoreState) => state.applicationModalBadge;
export const getShowImpesonificaitonBanner = (state: CoreState) => state.impersonateBanner || (sessionStorage.getItem("isImpersonification") && sessionStorage.getItem("isImpersonification") === "true") || false;
export const getFeedbackRunningRound = (state: CoreState) => state.feedbackRunningRound;
export const getFeedbackRoundList = (state: CoreState) => state.feedbackRoundList;