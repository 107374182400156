<div class="page-content">
    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>
    <div class="sti-modal-container">
        <p class="title" [translate]="'setupCompetences.modals.IMPORT_ROLES'">
        </p>
        <div class="divisor-line"></div>

        <div class="modal-action-container">
            <alloy-button [disabled]="isDownloadingReport" [label]="'setupCompetences.DOWNLOAD_TRACK' | translate"
                type="black" size="medium" (onButtonClicked)="getRolesFile()">
            </alloy-button>
        </div>

        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
            [title]="'setupCompetences.modals.IMPORT_ROLES_DESC' | translate">
            <alloy-upload (onButtonClicked)="assignFile()" [buttonLabel]="'setupCompetences.modals.IMPORT_ROLES' | translate"  [isToReset]="resetIncludeUpload" (onUpload)="saveFile($event)"
                [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></alloy-upload>
            <!-- <p class="upload-footer-text" translate="uploadFile.AVAILABLE_FORMATS"></p> -->
        </input-container>
        <!-- <div class="modal-action-container">
            <alloy-button [label]="'setupCompetences.modals.IMPORT_ROLES' | translate" type="primary fill" size="medium"
                [disabled]="!importFile" (onButtonClicked)="assignFile()">
            </alloy-button>
        </div> -->
    </div>
</div>
<!-- <div class="page-content">

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div id="snackbar-delete">
        <p translate="generic.DELETE_SUCCESSFUL"></p>
    </div>

    <ng-container *ngIf="!isLoadingData">
        <div class="competence-type-container">
            <ng-container *ngFor="let tab of competenceTypeList">
                <tab-light [tabTitle]="tab.title" [isDisabled]="tab.isDisabled"
                    (onClick)="changeCurrentCompetenceType(tab)" [blackHighlight]="true"></tab-light>
            </ng-container>
        </div> -->

<!-- Barra di ricerca -->
<!-- <div class="search-filters-container">
            <div class="admin-search-container bottom-filters">
                <div class="search-wrapper">
                    <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                        (onModelChanged)="searchedTextAllCompetenceChange($event)"
                        iconRight="assets/img/icons/search.svg" [placeholder]="'generic.SEARCH_BY_ROLE' | translate"
                        [isSearchBar]="true">
                    </alloy-input>
                </div>
            </div>
        </div>
        <div class="action-row">
            <div class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.DOWNLOAD_TRACK" (click)="downloadTrack()"></p>
            </div>
            <div *ngIf="isBehaviorList" class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.roles.IMPORT_SOFT_ROLE" (click)="openImportModal('soft')"></p>
            </div>
            <div *ngIf="!isBehaviorList" class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.roles.IMPORT_TECH_ROLE" (click)="openImportModal('tech')"></p>
            </div>
            <alloy-button [label]="'setupCompetences.ADD_ROLE' | translate" type="black-border"
                (onButtonClicked)="goToNewRole()" [disabled]="isLoadingData" size="auto-width">
            </alloy-button>
        </div> -->

<!-- Tabella tutte le competenze - Behavior -->
<!-- <div class="admin-table-container">
            <ng-container *ngIf="!isLoadingData">
                <ng-template #noData>
                    <div class="no-result-container">
                        <p translate="generic.NO_RESULTS"></p>
                    </div>
                </ng-template>
                <ng-container *ngIf="dataObject.list && dataObject.list.length; else noData">
                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <alloy-check (onCheck)="selectAll()"></alloy-check>
                                    </th>
                                    <th translate="{{ ('adminTable.ROLE' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.PEOPLE_NUM' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.COMPETENCE_NUM' | translate)}}"  style="width:17%;">
                                    </th>
                                    <th translate="{{ ('adminTable.ACTIONS' | translate)}}" style="width:1%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of dataObject.list | slice:0:dataObject.numRecords | paginate: { id: 'tableData',
                            itemsPerPage: dataObject.numRecords,
                            currentPage: dataObject.page,
                            totalItems: dataObject.counter }">
                                    <td>
                                        <alloy-check id="data.id" [checked]="data.isChecked" (click)="selectData(data)">
                                        </alloy-check>
                                    </td>
                                    <td>{{ data.title }}</td>
                                    <td>{{ data.userCount || 0 }}</td>
                                    <td>{{ data.behaviorOrcompetenceCount || 0 }}</td>

                                    <td class="action">
                                        <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(data)"
                                            (clickOutside)="closeMenu(data)"
                                            src="/assets/img/icons/more-horizontal.svg">
                                        </svg-icon>
                                        <ng-container *ngIf="data.isMenuOpen">
                                            <div class="dropdown-menu larger">
                                                <ng-container *ngFor="let option of menuOptions">
                                                    <alloy-dropdown-item (click)="onMenuOptionsClicked(option, data)"
                                                        class="full-width" [item]="option">
                                                    </alloy-dropdown-item>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bottom-actions">
                        <table-pagination id="tableData" (pageChanged)="rolePageChanged($event)" [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </alloy-select>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isLoadingData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->

<!-- loader contenuto tab all -->
<!-- <ng-container *ngIf="isLoadingData">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div> -->


<modal id="confirm-cancel" (onClose)="closeCancelModal()" (onConfirm)="closeCancelModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeCancelModal()">
    <ng-container *ngIf="modalDataToDelete">
        <modal-text-content modalId="der001"
            [title]="(modalDataToDelete.length > 1 ? 'setupCompetences.modals.DELETE_TITLE_MANY' : 'setupCompetences.modals.DELETE_TITLE') | translate"
            [text]="(modalDataToDelete.length > 1 ? 'setupCompetences.modals.DELETE_DESCR_MANY' : 'setupCompetences.modals.DELETE_DESCR') | translate">
        </modal-text-content>
    </ng-container>
</modal>

<!-- Modale inclusione ruoli -->
<modal id="import-modal" (onClose)="closeImportModal()" (onConfirm)="closeImportModal(true)"
    [confirmLabel]="( (isBehaviorList ? 'setupCompetences.modals.IMPORT_SOFT_ROLE_TITLE' : 'setupCompetences.modals.IMPORT_TECH_ROLE_TITLE') | translate)"
    [cancelLabel]="'generic.CANCEL' | translate" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeImportModal()">
    <div class="sti-modal-container">
        <p class="title"
            [translate]="isBehaviorList ? 'setupCompetences.modals.IMPORT_ROLE_SOFT_TITLE' : 'setupCompetences.modals.IMPORT_ROLE_TECH_TITLE'">
        </p>
        <div class="divisor-line"></div>

        <div class="modal-action-container">
            <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium">
            </alloy-button>
        </div>

        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
            [title]="'setupCompetences.modals.IMPORT_ROLE_DESCR' | translate">
            <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="saveFile($event)"
                [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></alloy-upload>
        </input-container>

        <ng-container *ngIf="importFile">
            <ng-container *ngIf="isLoadingImport">
                <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingImport">
                <p class="text" [innerHTML]="importNotice"></p>
            </ng-container>
        </ng-container>
    </div>
</modal>