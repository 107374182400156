import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'evaluation-rating',
  templateUrl: 'evaluation-rating.component.html',
  styleUrls: ['./evaluation-rating.component.scss']
  // tolto il change on push per problemi con l'attivazione delle tab
})
export class EvaluationRatingComponent implements OnInit {
  @Input() user?: any;
  @Input() title: string = '';
  @Input() customAvatarImg: string = '';
  @Input() evaluationTitle: string = '';
  @Input() evaluationSecondTitle: string = '';
  @Input() evaluationText: string | undefined = '';
  @Input() evaluationSecondText: string | undefined = '';
  @Input() isCompetenceScale: boolean = false;
  protected _level: number = 0;
  protected _rating: number = 0;
  @Input() set rating(rating: number) {
      this._level = Math.round(rating);
      this._rating = rating;
  }
  @Input() ratingValue: string = '';
  @Input() isSelf: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() isEditDisabled: boolean = false;
  @Input() smallAvatar: boolean = false;
  @Input() isHr: boolean = false;
  @Input() level: number = 0;
  @Input() isLeadershipTeam: boolean = false;
  // Cambia la visualizzazione delle informazioni da orizontale a verticale
  @Input() verticalStructure: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.rating) {
      this.rating = Math.round(this.rating * 100) / 100;
    }
  }


  emitOnEdit() {
    this.onEdit.emit();
  }
}