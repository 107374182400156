import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { SenecaResponse, GetPerformanceCareTagsResponse, PerfCareUserPhaseGoal, CreateUserGoalForManagerResponse, UpdateUserGoalForManagerResponse, GetGoalByIdForManagerResponse, CreateUserGoalForUserResponse, GetUserFeedbackDialogueForManagerResponse, PerfCareFeedbackDialogue, UpdateUserFeedbackDialogueForManagerResponse, CreateUserFeedbackDialogueForManagerResponse, DeleteSubordinateGoalForManagerResponse, GetUserGoalWeightSumForManagerResponse, GetBehaviorCareTagsResponse, PerformanceCareRent, ApproveGoalProposedBySubordinateUserResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, CancelUserFeedbackDialogueForManagerResponse, SetCareAttributeGoalsAsSeenForManagerResponse, ListDevelopmentActionForManagerResponse, CreateDevelopmentActionForManagerResponse, PerfCareDevelopmentAction, UpdateDevelopmentActionForManagerResponse, CanCreateDevelopmentActionForManagerResponse, CanShareDevelopmentActionToUserResponse, PerfCareApplauseFeedback, ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse, CreateEvidenceFeedbackForManagerResponse, UpdateEvidenceFeedbackForManagerResponse, ListEvidenceFeedbackForManagerResponse, DeleteFileForEvidenceFeedbackForManagerResponse, MapById, CountFeedbacksForManagerResponse, ListPrivateNoteFeedbackForManagerResponse, PerfCarePrivateNoteFeedback, CreatePrivateNoteFeedbackForManagerResponse, GetPrivateNoteFeedbackForManagerResponse, DeletePrivateNoteFeedbackForManagerResponse, EnrichedSenecaResponseForBadges } from "../../../commonclasses";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import * as CommonClasses from "../../../commonclasses";
import { KrukOpenAPI, PeerFeedbackServiceResponses, PerfAlloy360Feedback, PerfAlloyBehavior, PerfAlloyFeedbackSorting, PerfAlloyFeedbackStatus, PerfAlloyPeerFeedback, PerfAlloyPeerFeedbackParams, PerfAlloyPrivateNoteFeedback, PerfAlloySmartFeedback, PerfAlloyUserGoal, privateNoteAndSmartFeedback, User } from "atfcore-commonclasses";
import { AlloyPerformanceCppServiceResponses, AlloyPerformanceUpwardFeedbackServiceResponses, AlloyPerformanceUpwardFeedbackParams, GetPerformanceAssessmentInfoForManager } from "atfcore-commonclasses";
import { map } from "rxjs/operators";
import { CompetenceGraph } from "src/app/admin/admin-dashboard/admin-dashboard.component";

@Injectable()
export class PeopleAppraisalService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;
  urlService: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData ;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }


  // VECCHI SERVIZI INIZIO

  // Recupera i goals del manager
  listUserGoalForPeopleAppraisal(year: number, userId: string, goalTab?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', year.toString() || '');
    httpParams = httpParams.append('userId', userId || '');
    if (goalTab && goalTab.length && Array.isArray(goalTab)) {
      goalTab && goalTab.forEach(type => {
        httpParams = httpParams.append('goalTab', type);
      });
    } else if (goalTab && goalTab.length && !Array.isArray(goalTab)) {
      httpParams = httpParams.append('goalTab', goalTab);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-user-goals-for-manager/', {
      params: httpParams
    });
  }

  getObjectiveWeightsByProfile(userId: string, perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-max-goal-weights-for-manager-by-profile/', {
      params: httpParams
    });
  }

  // Recupera le Care options per gli obiettivi
  getCareOptions(fromRecord: string, numRecords: string, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-care-tags/', {
      params: httpParams
    });
  }

  // Recupera le Behaviour options per gli obiettivi
  getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    if (parentTagId && parentTagId.length) {
      httpParams = httpParams.append('parentTagId', parentTagId);
    }
    return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behavior-care-tags/', {
      params: httpParams
    });
  }

  // Crea un obiettivo
  createGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-goal-for-manager', {
      perfYear: perfYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo
  updateGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-goal-for-manager', {
      perfYear: perfYear,
      perfCareUserPhaseGoal: goal
    });
  }

  // Recupera l'obiettivo per il manager
  getGoalByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, goalId: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<GetGoalByIdForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-goal-by-id-for-manager/', {
      params: httpParams
    });
  }

  // Crea un obietttivo
  canShareGoal(perfYear: number, userId: string) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-propose-goals-to-subordinate', {
      perfYear: perfYear,
      subordinateUserId: userId
    });
  }

  shareGoalToSubordinate(perfYear: number, userId: string) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/propose-goals-to-subordinate', {
      perfYear: perfYear,
      subordinateUserId: userId
    });

  }

  // Restituisce le giornate disponibili e non
  getSlotCards(attendees: string, startDate: string, endDate: string, availabilityViewInterval: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendees', attendees);
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    httpParams = httpParams.append('availabilityViewInterval', availabilityViewInterval);

    return this.http.get<SenecaResponse<CheckExchangeAgendaForFreeSlotsResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-schedules-slots-availability', {
      params: httpParams
    });
  }

  // Recupera il colloquio da suo interviewId
  getInterviewByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackDialogueId', feedbackDialogueId);

    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  // Crea il colloquio
  createInterviewForPeopleAppraisal(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<CreateUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  // Aggiorna il colloquio
  updateInterviewForPeopleAppraisal(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<UpdateUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  deleteSubordinateGoal(perfYear: number, userId: string, goalIds: string[]) {
    return this.http.post<SenecaResponse<DeleteSubordinateGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-subordinate-goal-for-manager', {
      perfYear: perfYear,
      subordinateUserId: userId,
      goalIdsToDelete: goalIds
    });
  }

  getInterviewsByUserId(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  getClosestInterviewByUserId(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-closest-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  getUserGoalWeightSumForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string, macroGoalType?: string, consuntivationOrder?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('macroGoalType', macroGoalType || '');
    if (consuntivationOrder || consuntivationOrder == 0) {
      httpParams = httpParams.append('consuntivationOrder', consuntivationOrder.toString());
    }

    return this.http.get<SenecaResponse<GetUserGoalWeightSumForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-goal-weight-sum-for-manager/', {
      params: httpParams
    });
  }

  // Servizio approva obiettivo
  approveGoalProposedByUser(perfYear: number, subordinateUserId: string, comment: string) {
    return this.http.post<SenecaResponse<ApproveGoalProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/approve-goal-proposed-by-subordinate-user', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment
    });
  }

  // Servizio riapprova obiettivo
  riApproveGoalProposedByUser(perfYear: number, subordinateUserId: string, comment: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-approve-goal-comment-proposed-by-subordinate-user', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment
    });
  }

  // Annulla il colloquio
  deleteInterview(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cancel-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogueId: feedbackDialogueId
    });
  }

  // Feedback competenza CARE utente
  setUserCareCompetenceFeedback(perfYear: number, perfYearCode: string, subordinateUserId: string, comment: string, goalId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-user-care-competence-feedback', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment,
      goalId: goalId
    });
  }

  // Aggiunge link ai goal operation
  createLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, nameLink: string, link: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      nameLink: nameLink,
      link: link
    });
  }

  deleteLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId
    });
  }

  updateLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string, linkTitle: string, url: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId,
      nameLink: linkTitle,
      link: url
    });
  }


  uploadFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('"fileName"', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-goal-for-manager', formData, options);
    return this.http.request(req);
  }

  updateFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any, uploadId: string) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());
    formData.append('uploadId', uploadId)

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-file-for-goal', formData, options);
    return this.http.request(req);
  }

  deleteFileForGoal(perfYear: number, perfYearCode: string, userId: string, uploadId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-goal-for-manager', {
      perfYear: perfYear,
      userId: userId,
      uploadId: uploadId
    });
  }

  // Se l'utente ha già scaricato il STI
  getUserWatchedSTIRule(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/has-user-watched-sti-rule/', {
      params: httpParams
    });
  }
  // Recupero le azioni di sviluppo
  getDevelopmentActions(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('forUserId', userId);
    return this.http.get<SenecaResponse<ListDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-development-action-for-manager/', {
      params: httpParams
    });
  }

  // Recupero il booleano per sapere se si possono creare azioni di sviluppo
  canCreateDevelopmentActions(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<CanCreateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-create-development-action-for-manager/', {
      params: httpParams
    });
  }

  // Crea un azione di sviluppo
  createDevelopAction(perfYear: number, developmentAction: any, forUserId: string) {
    return this.http.post<SenecaResponse<CreateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-for-manager', {
      perfYear: perfYear,
      developmentAction: developmentAction,
      forUserId: forUserId
    });
  }

  // Aggiorna un azione di sviluppo
  updateDevelopAction(perfYear: number, forUserId: string, developmentAction: PerfCareDevelopmentAction[]) {
    return this.http.post<SenecaResponse<UpdateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-for-manager', {
      perfYear: perfYear,
      developmentAction: developmentAction,
      forUserId: forUserId
    });
  }

  // Recupera l'obiettivo per il manager
  getDevelopActionByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('developmentActionId', developmentActionId);
    httpParams = httpParams.append('forUserId', userId);

    return this.http.get<SenecaResponse<GetGoalByIdForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-development-action-by-id-for-manager/', {
      params: httpParams
    });
  }

  // Aggiunge il commento ad un azione di sviluppo
  addCommentToDevelopPlan(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-comment-for-manager', {
      perfYear: perfYear,
      userId: userId,
      developmentActionId: developmentActionId,
      comment: comment
    });
  }

  // Aggiorna il commento ad un azione di sviluppo
  updateCommentForDevelopPlan(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string, commentId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-comment-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      userId: userId,
      comment: comment,
      commentId: commentId
    });
  }

  deleteCommentToDevelopPlan(perfYear: number, perfYearCode: string, developmentActionId: string, commentId: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-comment-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      commentId: commentId,
      userId: userId,
    });
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-development-actions-to-user', {
      perfYear: perfYear,
      userId: userId
    });
  }
  // Recupera l'url per fare il download del file
  retrieveNewMaskedUrlForUploadForPeopleAppraisal(uploadId: string, goalId: string, perfYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-masked-url-for-upload-for-manager/', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per lo smart feedback
  countMyCollegueUsersForManager(searchedText?: string, userIdsToIgnore?: string | string[]) {
    let httpParams = new HttpParams();

    if (searchedText) {
      httpParams = httpParams.append("searchedText", searchedText)
    }

    if (userIdsToIgnore) {
      if (userIdsToIgnore && userIdsToIgnore.length && Array.isArray(userIdsToIgnore)) {
        userIdsToIgnore.forEach(userIdToIgnore => {
          httpParams = httpParams.append('userIdsToIgnore', userIdToIgnore);
        });
      }
    }

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera la lista degli utenti per lo smart feedback
  listMyColleagueUsers(fromRecord: number, numRecords: number, searchedText?: string, userIdsToIgnore?: Array<string>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '10');

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    if (userIdsToIgnore) {
      if (userIdsToIgnore && userIdsToIgnore.length && Array.isArray(userIdsToIgnore)) {
        userIdsToIgnore.forEach(userIdToIgnore => {
          httpParams = httpParams.append('userIdsToIgnore', userIdToIgnore);
        });
      }
    }

    return this.http.get<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per lo smart feedback
  countMyCollegueUsersForPeerFeedback(perfYear: string, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('searchedText', searchedText);

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera la lista degli utenti per il peer feedback
  listMyColleagueUsersForPeerFeedback(fromRecord: number, numRecords: number, searchedText?: string, userIdsToIgnore?: Array<string>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '10');

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    if (userIdsToIgnore) {
      if (userIdsToIgnore && userIdsToIgnore.length && Array.isArray(userIdsToIgnore)) {
        userIdsToIgnore && userIdsToIgnore.forEach(userIdToIgnore => {
          httpParams = httpParams.append('userIdsToIgnore', userIdToIgnore);
        });
      }
    }

    return this.http.get<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  // Crea lo smart feedback lato manager
  createSmartFeedbackForManager(perfYear: number, perfYearCode: string, smartFeedback: PerfAlloySmartFeedback[], userIdsForCreateFeedback: string[]) {
    return this.http.post<SenecaResponse<PerfAlloySmartFeedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-smart-feedback-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      smartFeedback: smartFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }

  // Recupera le note e gli smart feedback lato manager
  getPrivateNoteAndSmartFeedbackForManager(perfYear: number, perfYearCode: string, userId: string, privateNoteFeedbackIds?: string[], privateSmartFeedbackIds?: string[], onlySmartFeedback?: Boolean, onlyPrivateNote?: Boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId.toString());
    if (privateNoteFeedbackIds) {
      httpParams = httpParams.append('privateNoteFeedbackIds', privateNoteFeedbackIds.toString());
    }
    if (privateSmartFeedbackIds) {
      httpParams = httpParams.append('privateNoteFeedbackIds', privateSmartFeedbackIds.toString());
    }
    if (onlySmartFeedback) {
      httpParams = httpParams.append('onlySmartFeedback', onlySmartFeedback.toString());
    }
    if (onlyPrivateNote) {
      httpParams = httpParams.append('onlyPrivateNote', onlyPrivateNote.toString());
    }

    return this.http.get<SenecaResponse<privateNoteAndSmartFeedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-and-smart-feedback-for-manager/', {
      params: httpParams
    })
  }

  // Recupera le note private
  getPrivateNoteForManager() {
    let httpParams = new HttpParams;

    return this.http.get<SenecaResponse<privateNoteAndSmartFeedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-and-smart-feedback-for-manager/', {
      params: httpParams
    })
  }

  // Recupera le note private
  getFeedbackForManager() {
    let httpParams = new HttpParams;

    return this.http.get<SenecaResponse<privateNoteAndSmartFeedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-and-smart-feedback-for-manager/', {
      params: httpParams
    })
  }

  deletePrivateNoteForManager(perfYear: number, perfYearCode: string, userId: string, smartFeedbackId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-smart-feedback-for-manager/', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      smartFeedbackId: smartFeedbackId
    })
  }

  deletePrivateNoteForSubordinate(perfYear: number, perfYearCode: string, userId: string, smartFeedbackId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-smart-feedback-for-subordinate/', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      smartFeedbackId: smartFeedbackId
    })

  }

  // Pulisce la novita degli attributi CARE
  setCareAttributeGoalsAsSeenForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<SetCareAttributeGoalsAsSeenForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-care-attribute-goals-as-seen-for-manager', {
      perfYear: perfYear,
      userId: userId
    });
  }

  // Elimina l'azione di sviluppo
  deleteDevelopmentActionForPeopleAppraisal(perfYear: number, perfYearCode: string, developmentActionId: string, subordinateUserId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      subordinateUserId: subordinateUserId
    });
  }

  // Se può condividere le azioni di sviluppo
  canShareDevelopmentActions(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<CanShareDevelopmentActionToUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-share-development-action-to-user/', {
      params: httpParams
    });
  }

  listMyBadges(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  listTeamMembers(perfYear: number, filterByPhaseStatuses: MapById<string | string[]>, searchedText?: string) {
    return this.http.post<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/monitoring-subordinates-for-manager/', {
      perfYear: perfYear,
      filterByPhaseStatuses: filterByPhaseStatuses,
      searchedText: searchedText
    });
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociateWithEvidence(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-valid-goals-to-associate-for-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Crea l'evidenza
  createEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<CreateEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-evidence-feedback-for-manager', {
      evidenceFeedback: feedbackObject
    });
  }

  // Aggiorna l'evidenza
  updateEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<UpdateEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-evidence-feedback-for-manager', {
      evidenceFeedback: feedbackObject
    });
  }

  // Lista delle evidenze
  getEvidences(userId: string, perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Recupera l'evidenza
  getEvidenceFeedback(userId: string, perfYear: number, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Upload file in una evidenza
  uploadFileForEvidence(userId: string, perfYear: string, evidenceId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('evidenceId', evidenceId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-evidence-feedback-for-manager', formData, options);
    return this.http.request(req);
  }

  // Download file nell'evidenza
  retrieveNewMaskedUrlForUploadForEvidence(userId: string, uploadId: string, evidenceId: string, perfYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('evidenceId', evidenceId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-evicende-feedback-masked-url-upload-for-manager/', {
      params: httpParams
    });
  }

  // Cancella file nell'evidenza
  deleteFileForEvidence(userId: string, perfYear: string, evidenceId: string, uploadId: string) {
    return this.http.post<SenecaResponse<DeleteFileForEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-evidence-feedback-for-manager', {
      perfYear: perfYear,
      evidenceId: evidenceId,
      uploadId: uploadId,
      userId: userId
    });
  }

  // Recupera i counter delle tab del feedback
  getCounterFeedbackTabs(perfYear: number, perfYearCode: string, subordinateId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('subordinateId', subordinateId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    return this.http.get<SenecaResponse<PeerFeedbackServiceResponses.CountNotesAndFeedbacksForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-notes-and-feedbacks-for-manager', {
      params: httpParams
    });
  }

  getPrivateNotesList(perfYear: number, perfYearCode: string, userId: string, privateNoteFeedbackId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (privateNoteFeedbackId) {
      httpParams = httpParams.append('privateNoteFeedbackId', privateNoteFeedbackId);
    }
    return this.http.get<SenecaResponse<ListPrivateNoteFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-feedback-for-manager/', {
      params: httpParams
    });
  }

  createPrivateNote(privateFeedback: any[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-private-note-feedback-for-manager', {
      privateFeedback: privateFeedback
    });
  }

  deletePrivateNote(perfYear: number, perfYearCode: string, privateFeedbackNoteId: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-private-note-feedback-for-manager', {
      privateFeedbackNoteId: privateFeedbackNoteId,
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
    });
  }

  // Lista usenti paginata e ricerca per appunti privati
  countTeamUsers(perfYear: number, perfYearCode: string, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchUserText);
    httpParams = httpParams.append('onlySubordinatesInProcess', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-subordinate-users/', {
      params: httpParams
    });
  }

  listTeamUsers(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('searchedText', searchUserText);
    httpParams = httpParams.append('onlySubordinatesInProcess', 'true');
    return this.http.get<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-subordinate-users/', {
      params: httpParams
    });
  }


  canShareEditedObjectives(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-propose-goals-to-subordinate-after-sharing/', {
      params: httpParams
    });
  }

  afterSharingGoalToSubordinate(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/propose-goals-to-subordinate-after-sharing/', {
      params: httpParams
    });
  }

  cancelGoalAfterSharing(perfYear: number, subordinateUserId: string, goalId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    if (goalId) {
      httpParams = httpParams.append('goalId', goalId);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-draft-goals-for-manager-after-sharing/', {
      params: httpParams
    });
  }

  listPeerFeedback(perfYear: number, userId: string, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-peer-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Dati box home page mid term review
  getMidTermReviewBoxData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-box-data-for-manager/', {
      params: httpParams
    });
  }

  getPeerFeedbackById(perfYear: number, userId: string, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-peer-feedback-for-manager', {
      params: httpParams
    });
  }

  manageMidTermReviewData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/manage-mid-term-review-data-for-manager/', {
      params: httpParams
    });
  }

  updatePeerFeedback(perfYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-peer-feedback-for-manager', {
      perfYear: perfYear,
      peerFeedback: peerFeedback
    });
  }

  deletePeerFeedback(perfYear: number, userId: string, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-peer-feedback-for-manager', {
      params: httpParams
    });
  }

  // funzioni feedback per manager senza profilo self assessment
  listExternalPeerFeedback(perfYear: number, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-peer-feedback-for-user/', {
      params: httpParams
    });
  }

  updateExternalPeerFeedback(perfYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      perfYear: perfYear
    });
  }

  getExternalPeerFeedbackById(perfYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-peer-feedback-for-user', {
      params: httpParams
    });
  }

  getMidTermGoals(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-revision-data-for-manager/', {
      params: httpParams
    });
  }

  setMidTermReviewRevision(perfYear: number, userId: string, midTermReviewManagerObservation: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-mid-term-review-revision-data-for-manager', {
      perfYear: perfYear,
      userId: userId,
      midTermReviewManagerObservation: midTermReviewManagerObservation,
    });
  }

  setObservationForSubordinateGoal(perfYear: number, userId: string, goalId: string, observation: string, observationScore: string) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-observation-for-subordinate-goal', {
      perfYear: perfYear,
      userId: userId,
      goalId: goalId,
      observation: observation,
      observationScore: observationScore,
    });
  }

  getUserFinalEvaluation(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-final-evaluation-for-manager', {
      params: httpParams
    });
  }

  setUserFinalEvaluation(perfYear: number, userFinalEvaluation: any) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-final-evaluation-observation-for-manager', {
      perfYear: perfYear,
      userFinalEvaluation: userFinalEvaluation
    });
  }

  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-payout-range-by-final-evaluation', {});
  }

  getSuggestedFinalEvaluation(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-suggested-final-evaluation-for-manager', {
      params: httpParams
    })
  }

  getFinalEvaluationDate(perfYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-final-evaluation-dates', {
      perfYear: perfYear
    });
  }

  getPerformanceAssessmentYears(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-years', {
      params: httpParams
    });
  }

  getCalibrationDataForPeopleAppraisal(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-calibration-data-for-manager', {
      params: httpParams
    })
  }


  confirmPostCalibration(perfYear: number, userId: string, peopleAppraisalObservation: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/confirm-evaluation-observation-post-calibration-for-manager', {
      perfYear: perfYear,
      userId: userId,
      managerObservation: peopleAppraisalObservation
    });
  }

  isProcessClosed(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/is-process-closed-by-year', {
      params: httpParams
    })
  }

  // VECCHI SERVIZI FINE

  getUserPhaseDataForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-phase-completion-data-for-manager', {
      params: httpParams
    })
  }

  getUserGoalListToEvaluate(perfYear: number, perfYearCode: string, targetUserIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (targetUserIds && targetUserIds.length) {
      for (let i = 0; i < targetUserIds.length; i++) {
        httpParams = httpParams.append('targetUserIds', targetUserIds[i]);
      }
    }
    // if (goalUpdates) {
    //   httpParams = httpParams.append('goalUpdates', goalUpdates ? 'true' : 'false')
    // }
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-to-evaluate-for-manager', {
      params: httpParams
    })
  }

  addOrUpdateGoalsForManager(
    targetUserId: string,
    updates: Array<{
      goalId: string;
      description: string;
    }>,
    perfYear: number,
    perfYearCode: string

  ) {
    return this.http.post<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/add-update-to-perf-assessment-goals-for-manager',
      <CommonClasses.PerformanceAlloyItem.AddUpdateToPerfAssessmentGoalsForManager>{
        targetUserId: targetUserId,
        updates: updates,
        perfYear: perfYear,
        perfYearCode: perfYearCode
      })
  }

  getUserGoalList(perfYear: number, perfYearCode: string, targetUserIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (targetUserIds && targetUserIds.length) {
      for (let i = 0; i < targetUserIds.length; i++) {
        httpParams = httpParams.append('targetUserIds', targetUserIds[i]);
      }
    }
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-for-manager', {
      params: httpParams
    })
  }

  clonePerformanceAssessmentGoalsByGoalIds(userId: string, goalIds: any, perfYear: any, perfYearCode: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/clone-performance-assessment-goals-by-goal-ids', {
      userId: userId,
      goalIds: goalIds,
      perfYear: perfYear,
      perfYearCode: perfYearCode
    });
  }

  deletePerformanceAssessmentGoalsBySubordinateForManager(userId: string, goalIdsToDelete: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-proposed-goals-by-subordinate-for-manager', {
      userId: userId,
      goalIdsToDelete: goalIdsToDelete
    });
  }

  createOrUpdatePerformanceAssessmentGalEvaluationForManager(userId: string, evaluation: Array<{ goalId: string, evaluation: string | undefined, evaluationVote: number | undefined }>) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goal-evaluation-for-manager', {
      userId: userId,
      evaluation: evaluation
    });
  }

  getPerformanceAssessmentGoalsRatingScaleForManager() {
    return this.http.get<SenecaResponse<Array<{
      score: number,
      text: string,
      range: string
    }>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-rating-scale-for-manager', {
    });
  }

  // get-performance-assessment-intermediate-evaluation-data-for-manager
  getPerformanceAssessmentIntermediateEvaluationDataForManager(userId: string, perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-intermediate-evaluation-data-for-manager', {
      params: httpParams
    })
  }

  getBehavioursToEvaluateForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behaviors-to-evaluate-for-manager', {
      params: httpParams
    })
  }


  createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(userId: string, behaviorEvaluation?: { behaviorId: string, evaluationScore: string } | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-behavior-evaluation-for-manager', {
      userId: userId,
      behaviorEvaluation: behaviorEvaluation,
      overallComment: overallComment
    });
  }


  getCompetenceToEvaluateForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-competences-to-evaluate-for-manager', {
      params: httpParams
    })
  }

  getCalculatedStarsCountByAvg(avg: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('avg', avg);
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-calculated-stars-count-by-avg', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(userId: string, competenceEvaluation: { competenceId: string, evaluationScore: number }[] | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-competence-evaluation-for-manager', {
      userId: userId,
      competenceEvaluation: competenceEvaluation,
      overallComment: overallComment
    });
  }

  getPerformanceAssessmentFinalEvaluationDataForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', `${perfYear}`);
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('userId', userId);

    // if (targetUserIds && targetUserIds.length) {
    //   for (let i = 0; i < targetUserIds.length; i++) {
    //     httpParams = httpParams.append('targetUserId', targetUserIds[i]);
    //   }
    // }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-final-evaluation-data-for-manager', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(userId: string, finalEvaluationComment: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-final-evaluation-comment-for-manager', {
      userId: userId,
      finalEvaluationComment: finalEvaluationComment
    });
  }


  sendFinalEvaluationFormForManager(perfYear: number, perfYearCode: string, userId: string, forcedPasProfile: string, hasDoneFeedbackInterview: boolean) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-final-evaluation-form-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      forcedPasProfile: forcedPasProfile,
      userId: userId,
      hasDoneFeedbackInterview: hasDoneFeedbackInterview
    });
  }

  sharePostCalibrationForManager(userId: string, isFeedbackInterviewDone?: boolean) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-post-calibration-form-for-manager', {
      targetUserIds: userId,
      isFeedbackInterviewDone: isFeedbackInterviewDone
    });
  }

  // count-notes-and-feedbacks-for-manager

  // Recupera il count degli utenti per lo smart feedback
  // countNotesAndFeedbackForManager() {
  //   let httpParams = new HttpParams();
  //   return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
  //     params: httpParams
  //   });
  // }

  // get-private-note-feedback-for-manager
  getPrivateNoteFeedbackForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<Array<PerfAlloyPrivateNoteFeedback>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-feedback-for-manager', {
      params: httpParams
    });
  }

  getFeedbacksForManager(perfYear: number, perfYearCode: string, subordinateId: string, feedbackTypes?: Array<string>, feedbackStatus?: PerfAlloyFeedbackStatus, sorting?: PerfAlloyFeedbackSorting, minCreationDate?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('subordinateId', subordinateId);

    if (feedbackTypes) {
      if (feedbackTypes && feedbackTypes.length && Array.isArray(feedbackTypes)) {
        feedbackTypes && feedbackTypes.forEach(feedbackType => {
          httpParams = httpParams.append('feedbackTypes', feedbackType);
        });
      }
    }
    if (feedbackStatus) {
      httpParams = httpParams.append('feedbackStatus', feedbackStatus);
    }
    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }
    if (minCreationDate) {
      httpParams = httpParams.append('minCreationDate', minCreationDate);
    }

    return this.http.get<SenecaResponse<Array<PerfAlloyPeerFeedback>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-feedbacks-for-manager', {
      params: httpParams
    });
  }

  // Creazione di un peerfeedback
  createPeerFeedback(perfYear: string, perfYearCode: string, motivation: string, intermediateUserIds: Array<string>, recipientUserId: string, goalId?: string) {
    return this.http.post<SenecaResponse<PerfAlloyPeerFeedback>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-peer-feedback-for-manager/', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      motivation: motivation,
      intermediateUserIds: intermediateUserIds,
      recipientUserId: recipientUserId,
      goalId: goalId
    });
  }

  // lista goal selezionabili su cui ricevere feedback
  getGoalsToAssociateForManager(perfYear: string, perfYearCode: string, subordinateId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('subordinateId', subordinateId);

    return this.http.get<SenecaResponse<Array<PerfAlloyUserGoal>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-valid-goals-to-associate-for-peer-feedback-for-manager/', {
      params: httpParams
    });
  }

  getDashboardSelfAssessment(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-self-assessment-for-manager/', {
      params: httpParams
    });
  }

  getDashboardPeopleAppraisal(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<{ competences: Array<CompetenceGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-people-appraisal-for-manager/', {
      params: httpParams
    });
  }

  getDashboardCompetencesForManager(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<{ competences: Array<CompetenceGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-competences-for-manager/', {
      params: httpParams
    });
  }
  getDashboardProgress(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<{ competences: Array<CompetenceGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-progress-for-manager/', {
      params: httpParams
    });
  }

  getFeedback(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<{ competences: Array<CompetenceGraph> }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-feedback-for-manager/', {
      params: httpParams
    });

  }

  getDashboardCalibration(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-calibration-for-manager/', {
      params: httpParams
    });
  }

  getDashboardPas(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-restituzione-pas-for-manager/', {
      params: httpParams
    });
  }

  getDashboardBehaviorCompetence(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-behaviour-and-competences-for-manager/', {
      params: httpParams
    });
  }

  getDashboardOverview(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-final-overview-for-manager/', {
      params: httpParams
    });
  }

  downloadDashboardReport(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());

    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (entireStructure) {
      httpParams = httpParams.append('entireStructure', 'true');
    }

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-report-for-manager', {
      params: httpParams
    })
  }

  // Recupero dati cpp lato manager
  getCppInfoForManager() {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('getCollaboratorsData', 'true');
    return this.http.get<SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-info-for-manager', {
      params: httpParams
    })
  }

  // Scarico report cpp lato manager
  downloadCppReport(roundId: string, userIds: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    if (userIds && userIds.length && Array.isArray(userIds)) {
      userIds && userIds.forEach(type => {
        httpParams = httpParams.append('userIds', type);
      });
    } else if (userIds && userIds.length && !Array.isArray(userIds)) {
      httpParams = httpParams.append('userIds', userIds);
    }

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-cpp-survey-for-manager', {
      params: httpParams
    })
  }

  // Informazioni Upward Feedback per la home
  getUpwardFeedbackInfo() {
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-info-for-manager')
  }

  // Recupera i risultati personali del manager
  getUpwardFeedbackResults(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-manager', {
      params: httpParams
    })
  }

  // Recupera i dati del manager per i manager di secondo livello
  getUpwardFeedbackResultForChief(roundId: string, subordinateId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("subordinateId", subordinateId);
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForChief>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-chief', {
      params: httpParams
    })
  }

  // Invia il commento del chief al manager di cui è responsabile
  sendUpwardFeedbackComment(params: AlloyPerformanceUpwardFeedbackParams.SendUpwardFeedbackCommentForChief) {
    return this.http.post<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.SendUpwardFeedbackCommentForChief>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-upwardfeedback-comment-for-chief', {
      roundId: params.roundId,
      subordinateId: params.subordinateId,
      comment: params.comment,
    });
  }

  getPerformanceAsessmentInfoForUser() {
    return this.http.get<SenecaResponse<GetPerformanceAssessmentInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-asessment-info-for-manager/', {
    })
  }

  getCompetenceToEvaluate(perfYear: number, perfYearCode: string, userId: string): Observable<any> {
    let httpParams = new HttpParams()
    httpParams = httpParams.append("perfYear", perfYear);
    httpParams = httpParams.append("perfYearCode", perfYearCode);
    httpParams = httpParams.append("userId", userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-competences-to-evaluate-for-manager', {
      params: httpParams
    })
  }

  createComepetenceCommentForManager(competenceId: string, userId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-competence-comment-for-manager', {
      competenceId, userId, comment
    })
  }

  deleteComepetenceCommentForManager(userId: string, commentId: string) {
    return this.http.delete<SenecaResponse<void>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-competence-comment-for-manager', {
      body: { userId, commentId }
    });
  }

  closeIntermediateEvaluationFormForManager(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/close-intermediate-evaluation-form-for-manager', {
      perfYear, perfYearCode, userId
    })
  }

  shareIntermediateEvaluationFormForManager(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-intermediate-evaluation-form-for-manager', {
      perfYear, perfYearCode, userId
    })
  }

  createRetriveTokenAfterLogin() {
    return this.http.post<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-retrieve-token-after-login', {})
  }

  downloadTempFile(filename: string, ssortkqp: string) {
    let httpParams = new HttpParams()
    httpParams = httpParams.append("filename", filename);
    httpParams = httpParams.append("ssortkqp", ssortkqp);

    window.open(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-temp-file' + `?filename=${filename}&ssortkqp=${ssortkqp}`);
  }

  exportSkillMatrixForManager(userId: string): Observable<any> {
    let httpParams = new HttpParams()
    httpParams = httpParams.append("userId", userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-skill-matrix-for-manager', {
      params: httpParams
    })
  }

  createUpdateCompetenceEvaluationForManager(params: { competenceEvaluation?: { competenceId?: string, evaluationScore?: number }[], userId: string, overallComment?: { areaOfStrength?: string, areaOfImprovement?: string } }) {
    return this.http.post<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-competence-evaluation-for-manager', {
      competenceEvaluation: params.competenceEvaluation, overallComment: params.overallComment, userId: params.userId
    })
  }

  getPerformanceAssessmentProcessInfoForManager() {
    return this.http.get<SenecaResponse<GetPerformanceAssessmentInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-process-info-for-manager', {
    })
  }

  getPerformanceAssessmentProcessInfoForSubordinate() {
    return this.http.get<SenecaResponse<CommonClasses.GetPerformanceAssessmentInfoForUser>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-process-info-for-subordinate', {
    })
  }

  // servizi definizione obiettivi

  // recupera gli obiettivi definiti dal manager e proposti dal collaboratore
  getPerformanceGoalsForManager(perfYear: number, perfyearCode: string, targetUserIds: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("perfYear", perfYear);
    httpParams = httpParams.append("perfYearCode", perfyearCode);
    if (targetUserIds && targetUserIds.length && Array.isArray(targetUserIds)) {
      targetUserIds && targetUserIds.forEach(userId => {
        httpParams = httpParams.append('targetUserIds', userId);
      });
    } else if (targetUserIds && targetUserIds.length && !Array.isArray(targetUserIds)) {
      httpParams = httpParams.append('targetUserIds', targetUserIds);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-for-manager', {
      params: httpParams
    })
  }

  // Crea o aggiorna un obiettivo creato dal manager
  createOrUpdatePerformanceAssessmentGoalsForManager(userId: string, goals: { goalId: string, text: string }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goals-for-manager', {
      userId: userId,
      goals: goals
    });
  }

  // Elimina un obiettivo creato dal manager
  deleteAssessmentGoalsForManager(userId: string, goalIdsToDelete: string | string[]) {
    return this.http.delete<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-performance-assessment-goals-for-manager', {
      body: { userId, goalIdsToDelete }
    });
  }
  // Elimina un obiettivo proposto
  deleteAssessmentGoalsProposedForManager(userId: string, goalIdsToDelete: string | string[]) {
    return this.http.delete<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-proposed-goals-by-subordinate-for-manager', {
      body: { userId, goalIdsToDelete }
    });
  }

  // Modifica e approva gli obiettivi proposti dal collaboratore
  updatePerfAssessmentGoalsProposedBySubordinateForManager(userId: string, goals: { goalId: string, text: string }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-proposed-goals-by-subordinate-for-manager', {
      userId: userId,
      goals: goals
    });
  }

  // Modifica e approva gli obiettivi proposti dal collaboratore
  approvePerfAssessmentGoalsProposedBySubordinateForManager(userId: string, goals: { goalId: string, text: string }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-proposed-goals-by-subordinate-for-manager', {
      userId: userId,
      goals: goals
    });
  }


  // Ritorna la lista di utenti proposti dal collaboratore al manager per il feedback 360
  listSuggestedCollaboratorsFeedback360ForManager(roundId: string, targetUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append('targetUserId', targetUserId);
    return this.http.get<SenecaResponse<KrukOpenAPI.PerfAlloySuggestedUserForFeedback360[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-suggested-collaborators-feedback-360-for-manager', {
      params: httpParams
    })
  }

  // Richiedi feedback 360 ad x utenti (intermediateUserIds) per un membro del team (targetUserId)
  // suggestedUserId - suggerimenti dati dall'utente target per gli utenti intermediari, a cui viene richiesto il feedback 360
  //  (se presenti, devono essere nello stessa posizione dell'array degli intermediateUserId a cui si riferiscono)
  createFeedback360ForManager(intermediateUserIds: string[], targetUserId: string) {
    return this.http.post<SenecaResponse<PerfAlloy360Feedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-feedback-360-for-manager', {
      intermediateUserIds: intermediateUserIds,
      targetUserId: targetUserId
    });
  }

  // Ritorna la list di round
  listFeedback360Rounds(roundStatus?: string) {
    let httpParams = new HttpParams();

    if (roundStatus) {
      httpParams = httpParams.append('roundStatus', roundStatus);
    }

    return this.http.get<SenecaResponse<KrukOpenAPI.PerfAlloyFeedback360Round[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-feedback-360-rounds', {
      params: httpParams
    })
  }

  // Ritorna la lista di feedback 360 richiesti  per il manager
  listFeedback360ForManager(targetUserId: string, roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("targetUserId", targetUserId);
    httpParams = httpParams.append("roundId", roundId);
    return this.http.get<SenecaResponse<KrukOpenAPI.PerfAlloyFeedback[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-feedbacks-360-for-manager', {
      params: httpParams
    })
  }

  // recupera i dettagli di un feedback 360 richiesto
  getFeedback360DetailsForManager(feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("feedbackId", feedbackId);
    return this.http.get<SenecaResponse<KrukOpenAPI.PerfAlloyFeedback>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-feedback-360-detail-for-manager', {
      params: httpParams
    })
  }

  // condivide il feedback ricevuto con il collaboratore
  shareFeedback360ToUserForManager(feedbackId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-feedback-360-to-user-for-manager', {
      feedbackId: feedbackId
    });
  }

}

export interface CheckExchangeAgendaForFreeSlotsResponse {
  start?: Date;
  end?: Date;
  startLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  persons: number;
  percentage: number;
  unavailablePersonList: Array<string>;
}