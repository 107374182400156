<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

    <!-- Side nav -->
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="readOnly ? (('360Feedback.SIDEBAR_USER_TITLE_SHARED' | translate)): 
            (('360Feedback.SIDEBAR_USER_TITLE' | translate) + ( recipientUser ? recipientUser.surname + ' ' + recipientUser.forename : '')) " [description]="getSidenavDescription()">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <!-- (!isLoadingGoalsRatingScaleModal && !isLoadingFeedbackData) -->
        <div class="content" *ngIf="!isLoadingFeedbackData; else pageLoader">

            <div class="form-container">
                <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                        [ratingScale]="competence.ratingScale" (infoClicked)="openCompetenceLegendModal($event)"
                        (onRating)="onCompetenceRatingChanged($event)" [hideComments]="true" [hideFeedbacks]="true"
                        [canValueNA]="true" [disabled]="readOnly" [hideSkillMatrix]="true" [noContainer]="true">
                    </competence-evaluation>
                </ng-container>
                <!-- Media finale -->
                <competence-average
                    *ngIf="softSkillsFinalAvg && softSkillsFinalAvg.allBehavioursValuated"
                    [disabled]="true" id="soft-skill-final-avg" [competenceAvg]="softSkillsFinalAvg"
                    [ratingScale]="softSkillsFinalAvg.ratingScale">
                </competence-average>

                <!-- [disabled]="isScheduleShared || isFinalStepCompleted" [readonly]="isItemDisabled()" -->
                <!-- Indica i punti di forza -->
                <ng-container *ngIf="!readOnly; else completed">
                    <input-container [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="strengthsComment && strengthsComment.trim().length" [isObbligatory]="false"
                        title="{{'evaluations.AREA_STRANGTHS' | translate}}" id="competenceEvaluationNotes">
                        <alloy-text-area [bindValue]="strengthsComment" (onModelChanged)="onStrengthChanged($event)"
                            placeholder="{{'360Feedback.YOUR_ANSWER' | translate}}">
                        </alloy-text-area>
                    </input-container>
                    <!-- Indica le aree di miglioramento -->
                    <input-container [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="improvementComment && improvementComment.trim().length" [isObbligatory]="false"
                        [title]="'evaluations.AREA_IMPROVEMENT' | translate" id="competenceEvaluationNotes">
                        <alloy-text-area [bindValue]="improvementComment"
                            (onModelChanged)="onImprovementChanged($event)"
                            placeholder="{{'360Feedback.YOUR_ANSWER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <div class="button-container">
                        <!-- (onButtonClicked)="openSmartFeedbackModal()" [disabled]="!checkObbligatoryData()" -->
                        <alloy-button type="primary fill" size="large" [label]="'360Feedback.SEND_FEEDBACK' | translate"
                            [disabled]="!allCompetencesEval()" (onButtonClicked)="openSendFeedbackModal()">
                        </alloy-button>
                    </div>
                </ng-container>
                <!-- Se sono in readonly -->
                <ng-template #completed>
                    <!-- Indica i punti di forza -->
                    <div class="indication">
                        <p class="title" translate="evaluations.AREA_STRANGTHS"></p>
                        <p class="text">
                            {{ strengthsComment || ('360Feedback.people.NO_OBSERVATION_ADDED' | translate) }}
                        </p>
                    </div>
                    <!-- Indica le aree di miglioramento -->
                    <div class="indication">
                        <p class="title" translate="evaluations.AREA_IMPROVEMENT"></p>
                        <p class="text">
                            {{ improvementComment || ('360Feedback.people.NO_OBSERVATION_ADDED' | translate) }}
                        </p>
                    </div>
                    <div class="button-container">
                        <!-- (onButtonClicked)="openSmartFeedbackModal()" [disabled]="!checkObbligatoryData()" -->
                        <alloy-button type="primary fill" size="large" [label]="'generic.CLOSE' | translate"
                            (onButtonClicked)="goBackBrowser()">
                        </alloy-button>
                    </div>
                </ng-template>
            </div>

        </div>
    </div>
</page-container>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
    (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
    <p class="modal-title black center" translate="dictionary.TITLE"></p>
    <div class="container">
        <div *ngFor="let evaluation of goalsRatingScaleModalData; let i = index">
            <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="5" [readOnly]="true"
                [level]="(i)" [disabledFirstStar]="true" [valueFirstStar]="goalsRatingScaleModalData[0].text"
                [valueSecondStar]="goalsRatingScaleModalData[1].text"
                [valueThirdStar]="goalsRatingScaleModalData[2].text"
                [valueFourthStar]="goalsRatingScaleModalData[3].text"
                [valueFifthStar]="goalsRatingScaleModalData[4].text">
            </evaluation-system>
        </div>
    </div>
</modal>

<!-- Modale scala di valutazione competenze -->
<modal id="common-competence" (onClose)="closeCompetenceLegendModal()" (onConfirm)="closeCompetenceLegendModal()"
    confirmLabel="{{ 'generic.CLOSE' | translate}}">
    <div class="wrap-container" *ngIf="competenceForLegendModal">
        <p class="modal-title black center" translate="dictionary.TITLE"></p>
        <div class="divisor-line"></div>
        <div class="subtitle-container">
            <p class="type">{{ (competenceForLegendModal.isManagerial ? "dictionary.MANAGERIAL" : "dictionary.GENERAL")
                | translate }}</p>
            <p class="title">{{competenceForLegendModal.title}}</p>
            <p class="subtitle" [innerHTML]="competenceForLegendModal.text"></p>
        </div>
        <div class="divisor-line"></div>
        <div class="modal-evaluation-system-container">
            <ng-container *ngIf="competenceForLegendModal.scale && competenceForLegendModal.scale.length">
                <div *ngFor="let evaluation of competenceForLegendModal.scale; let i = index">
                    <evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="6"
                        [readOnly]="true" [level]="i-1" [disabledFirstStar]="true" [competenceScale]="true"
                        [valueNASub]="competenceForLegendModal.scale[0].text"
                        [valueFirstStarSub]="competenceForLegendModal.scale[1].text"
                        [valueSecondStarSub]="competenceForLegendModal.scale[2].text"
                        [valueThirdStarSub]="competenceForLegendModal.scale[3].text"
                        [valueFourthStarSub]="competenceForLegendModal.scale[4].text"
                        [valueFifthStarSub]="competenceForLegendModal.scale[5].text" [showTitles]="true">
                    </evaluation-system>
                </div>
            </ng-container>
        </div>
    </div>
</modal>

<modal id="confirm-send-modal" (onClose)="closeSendFeedbackModal()" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeSendFeedbackModal()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeSendFeedbackModal(true)">
    <modal-text-content modalId="360fe003" [title]="'360Feedback.SEND_FEEDBACK' | translate" [text]="confirmModalText">
    </modal-text-content>
</modal>

<!-- Loader pagina -->
<ng-template #pageLoader>
    <div class="loader spinner-big"></div>
</ng-template>