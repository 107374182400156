import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import moment = require('moment');

@Component({
  selector: 'app-admin-edit-phase-date',
  templateUrl: './editPhaseDate.component.html',
  styleUrls: ['./editPhaseDate.component.scss']
})
export class EditPhaseDateComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  processId: string = '';
  isLoadingPhaseData: boolean = true;


  notes: string = '';
  endDate: Date | undefined = undefined;
  startDate: Date | undefined = undefined;
  deadlineDate: Date | undefined = undefined;

  today: Date = new Date();
  phaseStatus: 'SELF_ASSESSMENT' |'PEOPLE_APPRAISAL_EVALUATION' |'PEOPLE_APPRAISAL_GOAL_SETTINGS' | undefined = undefined;
  processYear: any;
  processYearCode: any;
  processTitle: any;
  processNotes: any;
  processStatus: any;
  yesterday = new Date();

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.processId = params.processId;
              this.processYear = params.processYear;
              this.processYearCode = params.processYearCode;

              if (window.location.href.includes('people')) {
                this.phaseStatus = 'PEOPLE_APPRAISAL_EVALUATION';
              } else if(window.location.href.includes('self')){
                this.phaseStatus = 'SELF_ASSESSMENT';
              } else {
                this.phaseStatus = 'PEOPLE_APPRAISAL_GOAL_SETTINGS';
              }
              if (!this.processId) {
                this.isLoadingPhaseData = false;
              } else {
                this.getPhaseData();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  noteChanged(text: string) {
    this.notes = text;
  }

  getPhaseData() {
    this.isLoadingPhaseData = true;

    this.adminService.getProcess(this.processId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "epd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          this.processTitle = data.response.processTitle;
          this.processNotes = data.response.processNotes;
          this.processStatus = data.response.processStatus;
          if (window.location.href.includes('/people/') && data.response.peopleAppraisalEvaluationStartDate) {
            this.startDate = data.response.peopleAppraisalEvaluationStartDate;
            this.endDate = data.response.peopleAppraisalEvaluationEndDate;
            this.deadlineDate = data.response.peopleAppraisalEvaluationDeadlineDate;
          } else if (window.location.href.includes('/self/') && data.response.selfAssessmentStartDate) {
            this.startDate = data.response.selfAssessmentStartDate;
            this.endDate = data.response.selfAssessmentEndDate;
            this.deadlineDate = data.response.selfAssessmentDeadlineDate;
          } else if(window.location.href.includes('/goal/') && data.response.peopleAppraisalGoalSettingsStartDate) {
            this.startDate = data.response.peopleAppraisalGoalSettingsStartDate;
            this.endDate = data.response.peopleAppraisalGoalSettingsEndDate;
            this.deadlineDate = data.response.peopleAppraisalGoalSettingsDeadlineDate;
          }
        }
        this.isLoadingPhaseData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "epd002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPhaseData = false;
      });
  }


  startDateChanged(newDate: Date) {
    this.startDate = newDate;
  }

  endDateChanged(newDate: Date) {
    this.endDate = newDate;
  }
  deadlineDateChanged(newDate: Date) {
    this.deadlineDate = newDate;
  }


  onConfirm() {
    if (!this.startDate || !this.endDate || !this.deadlineDate) {
      const messageObj: ApplicationModalMessage = {
        modalId: "mrd001",
        title: this.translate.instant("generic.WARNING"),
        text: this.translate.instant("process.phases.MISSING_REQ_DATA"),
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
    } else {
      this.modalService.open('confirm-edit');
    }
  }

  closeConfirm(confirm?: boolean) {
    this.modalService.close('confirm-edit');
    if (confirm) {
      let serviceToCall;
      serviceToCall = this.adminService.updateProcessPhase(this.processYear, this.processYearCode, this.phaseStatus, this.startDate, this.endDate,this.deadlineDate);

      serviceToCall.subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "epd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
          this.redirectService.goBackBrowser();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "epd004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPhaseData = false;
      });
    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}