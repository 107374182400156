import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KrukOpenAPI } from 'atfcore-commonclasses';

export type MonitoringFilter = {
  performanceManagerName: unknown[];
  position: unknown[];
  department: unknown[];
  evaluationStatus: unknown[];
  selfEvaluationStatus: unknown[];
  goalSettingStatus: unknown[];
}

@Component({
  selector: 'pce-monitoring-filters',
  templateUrl: './pce-monitoring-filters.component.html',
  styleUrls: ['./pce-monitoring-filters.component.scss']
})

export class PceMonitoringFiltersComponent {
  @Input() isLoadingFilters: boolean = false;
  @Input() filters: MonitoringFilter = {
    performanceManagerName: [],
    position: [],
    department: [],
    evaluationStatus: [],
    selfEvaluationStatus: [],
    goalSettingStatus: []
  };
  @Output() onApply = new EventEmitter<MonitoringFilter>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<MonitoringFilter>();

  protected showFilters?: boolean;
  protected selectedFiltersCount?:number;
  protected selectedFilter: MonitoringFilter = {
    performanceManagerName: [],
    position: [],
    department: [],
    evaluationStatus: [],
    selfEvaluationStatus: [],
    goalSettingStatus: []
  }

  protected onFilterSelection() {
    this.selectedFiltersCount = Object.values(this.selectedFilter).reduce((acc, cur) => acc + (cur?.length || 0), 0);
  }

  protected deleteSelectedFilters() {
    let selectedFiltersCopy = { ...this.selectedFilter };
    for(let filter in selectedFiltersCopy) {
      selectedFiltersCopy[filter as keyof MonitoringFilter] = [];
    }
    
    this.selectedFiltersCount = 0;
    this.selectedFilter = selectedFiltersCopy;
    this.onDelete.emit(this.selectedFilter);
  }

  protected onApplyFilters() {
    this.onApply.emit(this.selectedFilter);
    this.showFilters = false;
  }
}
