import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareTag } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';

export class UserCardDisabled extends UserCard {
  disabled?: boolean = false;
}

@Component({
  selector: 'app-receive360-feedback',
  templateUrl: './receive360-feedback.component.html',
  styleUrls: ['./receive360-feedback.component.scss']
})
export class Receive360FeedbackComponent implements OnInit {
  isImpersonate: boolean = false;
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  userId: string = '';
  personDetails: any;

  isFetchingUserData: boolean = false;
  fromRecord: number = 0;
  numRecords: number = 0;
  currentPage: number = 0;
  counter: number = 0;

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, towards?: boolean }> = [];
  recipientUser!: UserCard;

  userCardData: UserCardDisabled[] = [];
  selectedUserList: any[] = [];

  // Ricerca utente
  searchUserText: string = '';
  loggedUser: any;
  forceBack: boolean = false;

  maxUserCount: number = 8;
  sendFeedbackProposal$: Subscription = new Subscription();
  getMyCollegueUsers$: Subscription = new Subscription();
  isGettingSuggestedUsers: boolean = true;
  getAlreadySuggestedUsers$: Subscription = new Subscription();
  excludedUserIds: any[] = [];
  whoTooltipModal?: ApplicationModalMessage;
  alreadyAddedList: any = [];

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.loggedUser = loggedUser.user;
                    if (!this.userId) {
                      this.resetFeedbackUserData();
                      this.getAlreadyAddedSuggested();
                    }
                  })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP',
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR',
        'feedbackSection.sendClapPage.modals.WHICH_CARE',
        'feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR',
        'feedbackSection.360_HOW_TO_RECIVED',
        'feedbackSection.360_HOW_TO_RECIVED_DESCR'
      ])
      .subscribe(translations => {
        this.whoTooltipModal = {
          modalId: "c014",
          title: translations['feedbackSection.360_HOW_TO_RECIVED'],
          text: translations['feedbackSection.360_HOW_TO_RECIVED_DESCR'],
        }
      })
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetFeedbackUserData(notDeleteSelected?: boolean) {
    this.fromRecord = 0;
    this.numRecords = 10;
    this.currentPage = 1;
    this.counter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  // Esegue una nuova ricerca
  searchUsers() {
    this.resetFeedbackUserData(true);
    this.getMyCollegueUsers();
  }

  // Salva il testo che si cerca
  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Recupero la lista di utenti già suggeriti
  getAlreadyAddedSuggested() {
    this.isGettingSuggestedUsers = true;

    if (this.getAlreadySuggestedUsers$) {
      this.getAlreadySuggestedUsers$.unsubscribe();
    }

    this.getAlreadySuggestedUsers$ = this.selfAssessmentService.listSuggestedCollaboratorsFeedback360ForUser()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe014",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.excludedUserIds = [];
          data.response.forEach((feedback: any) => {
            if (feedback && feedback.userId) {
              this.excludedUserIds.push(feedback);
            }
          })
        }
        this.getMyCollegueUsers();
        this.isGettingSuggestedUsers = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe015",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isGettingSuggestedUsers = false;
      });
  }

  // Recupera una lista di utenti per gli inviare gli smart feedback
  getMyCollegueUsers() {
    this.isFetchingUserData = true;

    if (this.getMyCollegueUsers$) {
      this.getMyCollegueUsers$.unsubscribe();
    }

    this.getMyCollegueUsers$ = this.selfAssessmentService.countMyCollegueUsersforFeedback360(this.searchUserText, [this.loggedUser.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingUserData = false;
        } else {
          this.counter = data.response;
          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.currentPage && this.numRecords) {
            fromRecord = (this.currentPage - 1) * this.numRecords;
          } else {
            fromRecord = 0;
          }

          if (this.counter) {
            this.selfAssessmentService.listMyColleagueForFeedback360(fromRecord, this.numRecords, this.searchUserText, [this.loggedUser.userId]).subscribe
              ((data: SenecaResponse<any>) => {
                if (data && data.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "360fe005",
                    text: this.translate.instant("errors." + data.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isFetchingUserData = false;
                } else {
                  this.userCardData = UserDataUtils.getUserCardData(data.response) || [];
                  // Se è tra gli utenti a cui ho già fatto la richiesta è disabilitato e checkato
                  this.userCardData.forEach((user: UserCardDisabled) => {
                    let excluded = this.excludedUserIds.some(usr => usr.userId === user.id);
                    user.isChecked = excluded;
                    user.disabled = excluded;
                    
                    const isPresent = this.alreadyAddedList.some((usr: any) => usr.id == user.id);
                    if (user.isChecked && !isPresent) this.alreadyAddedList.push(user);
                  })
                }
                this.isFetchingUserData = false
              }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "360fe006",
                  text: this.translate.instant("errors." + (err.message || err)),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isFetchingUserData = false;
              })
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingUserData = false;
      });
  }


  // Cambia la paginazione alla lista degli utenti
  pageChanged(newPage: any) {
    this.currentPage = newPage;
    // Avvio una nuova ricerca
    this.getMyCollegueUsers();
  }

  // Invia lo smart feedback ad uno o più utenti
  sendFeedbackProposal() {
    this.isFetchingUserData = true;
    if (this.sendFeedbackProposal$) {
      this.sendFeedbackProposal$.unsubscribe();
    }

    let userIds = this.selectedUserList.filter((user: any) => !user.disabled)?.map(user => user.id);

    this.sendFeedbackProposal$ = this.selfAssessmentService.suggestCollaboratorsFeedback360ForUser(userIds)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "360fe001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.router.navigate(['homepage']);
          this.isFetchingUserData = false;
        } else {
          this.router.navigate(['homepage']);
        }
        this.isFetchingUserData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "360fe002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.router.navigate(['homepage']);
        this.isFetchingUserData = false;
      });
  }

  // apri modale conferma proposta
  openProposeModal() {
    this.feedbackRecap = [
      {
        title: this.translate.instant('360Feedback.modal.TO_WHOM'),
        avatar: true,
        towards: true
      },
      {
        title: this.translate.instant('360Feedback.modal.MOTIVATION_FOR_REQUEST'),
        text: this.translate.instant('360Feedback.modal.MOTIVATION_RESPONSE'),
      }];
    this.modalService.open('360ReceiveFeedback');
  }

  // chiudi modale conferma proposta utenti
  closeProposeModal(confirm?: boolean) {
    this.modalService.close('360ReceiveFeedback');
    if (confirm) {
      this.sendFeedbackProposal();
    }
  }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateModal() {
    // this.modalUpdateId = this.getModalSaveId();
    // this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
    this.openModalForUpdate();
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdate() {
    this.modalService.open('modalUpdateForUser');
  }

  checkObbligatoryData() {
    const disabledUser = this.selectedUserList.every(user => user.disabled);

    if (this.selectedUserList.length >= 1 && this.selectedUserList.length <= 8 && !disabledUser) {
      return true;
    }
    return false;
  }

  selectUser(user: any) {
    const isPresent = this.selectedUserList.some((usr) => usr.id == user.id && !user.disabled);

    if (isPresent) {
      this.selectedUserList = this.selectedUserList.filter((usr) => usr.id != user.id && !user.disabled);
    } else {
      this.selectedUserList.push(user);
    }
  }


  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  getCountLimit() {
    return this.translate.instant('360Feedback.MAX_PROPOSED_1') + this.maxUserCount + this.translate.instant('360Feedback.MAX_PROPOSED_2');
  }

  checkUserSelectDisabled() {
    let count = 0;
    count += this.selectedUserList?.length || 0;
    // count += this.excludedUserIds?.length || 0;
    if (count > (this.maxUserCount - 1)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }

    if (this.getMyCollegueUsers$) {
      this.getMyCollegueUsers$.unsubscribe();
    }

    if (this.sendFeedbackProposal$) {
      this.sendFeedbackProposal$.unsubscribe();
    }
  }
}
