<div class="general-competence-container">
    <p class="title" translate="dashboard.accordion.PERCENTAGE_COMPETENCE"></p>

    <div class="summary-ratings-dashboard" *ngFor="let value of valueList">
        <p class="text-desc">{{value.title | translate}}</p>

        <div class="main-container">
            <div class="graph-container" fxLayout="column">
                <div fxLayout="row">
                    <div class="first-star-row" [ngStyle]="{'width': (screenWidth*value.first/5) + 'px'}"></div>
                    <div class="first-star-row gray"
                        [ngStyle]="{'width': (screenWidth-(screenWidth*value.first/5)) + 'px'}"></div>
                </div>
                <div fxLayout="row">
                    <div class="second-star-row" [ngStyle]="{'width': (screenWidth*value.second/5) + 'px'}"></div>
                    <div class="second-star-row gray"
                        [ngStyle]="{'width': (screenWidth-(screenWidth*value.second/5)) + 'px'}"></div>
                </div>
                <div fxLayout="row">
                    <div class="third-star-row" [ngStyle]="{'width': (screenWidth*value.third/5) + 'px'}"></div>
                    <div class="third-star-row gray"
                        [ngStyle]="{'width': (screenWidth-(screenWidth*value.third/5)) + 'px'}"></div>
                </div>

            </div>

            <div class="text-desc" fxLayout="column" *ngIf="isMobile">
                <div fxLayout="row">
                    <p class="star-value-text bold-text">{{value.first}}</p>
                </div>
                <div fxLayout="row">
                    <p class="star-value-text bold-text">{{value.second}}</p>
                </div>
                <div fxLayout="row">
                    <p class="star-value-text bold-text">{{value.third}}</p>
                </div>
            </div>
        </div>
        <div class="text-desc" fxLayout="column" *ngIf="!isMobile">
            <div fxLayout="row">
                <p class="star-value-text bold-text">{{value.first}}</p>
            </div>
            <div fxLayout="row">
                <p class="star-value-text bold-text">{{value.second}}</p>
            </div>
            <div fxLayout="row">
                <p class="star-value-text bold-text">{{value.third}}</p>
            </div>
        </div>
    </div>
    <div class="box-container">
        <div class="box">
            <span class="first-box"></span>
            <p translate="dashboard.pas.SELF_ASSESSMENT"></p>
        </div>
        <div class="box">
            <span class="second-box"></span>
            <p translate="dashboard.pas.EVALUATION"></p>
        </div>
        <div class="box">
            <span class="third-box"></span>
            <p translate="dashboard.pas.FEEDBACK"></p>
        </div>
    </div>
</div>