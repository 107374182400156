<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBack()" [isBackButton]="true"
            [title]="('upwardFeedback.selfAssessment.SIDENAV_TITLE' | translate) + (!isLoadingSurvey && managerData ? managerData.surname + ' ' + managerData.forename : '')"
            [description]="('upwardFeedback.selfAssessment.SIDENAV_DESC' | translate)">
        </sidenav-informations>
    </div>



    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingSurvey && currentModule">
                <div class="form-container">
                    <p class="text-title">Upward Feedback</p>
                    <p class="text-module"
                        [innerHTML]="('technicalAssessment.selfAssessment.MODULE' | translate) + ' <b>' + (currentModule.index + 1) + ' di ' + (currentModule.totalModules)">
                    </p>
                    <p class="text-advancement" translate="upwardFeedback.selfAssessment.ADVANCEMENT"></p>
                    <div fxLayout="row" fxLayoutAlign="center center" class="full-width">
                        <div class="progress-module-percentage">
                            <div class="bar" [ngStyle]="{'width': currentModule.totalPercentage + '%'}"></div>
                        </div>
                        <p>{{currentModule.totalPercentage + '%'}}</p>
                    </div>
                    <!-- Linear rating -->
                    <ng-container *ngIf="lastModuleView">
                        <div class="linear-rating-container">
                            <linear-rating [ratings]="ratingData"
                                [average]="currentModule.aggregatedData?.modulesAverage?.globalAverage || 0">
                            </linear-rating>
                        </div>
                    </ng-container>
                    <!-- Titolo e info a destra -->
                    <div class="title-container" [ngClass]="{'last-module': lastModuleView}">
                        <p *ngIf="!lastModuleView" class="module-title">{{currentModule.title}}</p>
                        <div class="more-details" (click)="toggleAccordion()">
                            <svg-icon class="more-info-icon" src="./assets/img/icons/info-link.svg" [applyClass]="true">
                            </svg-icon>
                            <p class="more-details" translate="upwardFeedback.selfAssessment.GUIDE_TITLE"
                                (click)="openGuideInfoModal()"></p>
                        </div>
                        <!-- Mostra / nascondi dettagli ultimo modulo -->
                        <div *ngIf="lastModuleView" class="more-details" (click)="toggleAccordion()">
                            <p [translate]="showAllAccordion ? 'generic.HIDE_DETAILS' : 'generic.SHOW_DETAILS'"></p>
                            <svg-icon class="more-details-icon"
                                [src]="showAllAccordion ? './assets/img/icons/chevron-up.svg' : './assets/img/icons/chevron-down.svg'"
                                [applyClass]="true">
                            </svg-icon>
                        </div>
                    </div>

                    <!-- Sezione acordion recap ultimo modulo -->
                    <ng-container *ngIf="lastModuleView && currentModule && showAllAccordion">
                        <alloy-accordion *ngFor="let answers of currentModule.aggregatedData?.closedAnswers"
                            class="accordion-container" [infoIcon]="false"
                            [title]="answers.answerText + ' (' + (answers.questions.length || 0) + ')'" [black]="true"
                            [isAccordionOpened]="false">
                            <div *ngFor="let response of answers.questions" class="accordion-opened-container">
                                <div class="divisor-horizontal"></div>
                                <p class="title">{{response.moduleTitle}}</p>
                                <p class="subtitle">{{ response.questionTitle }}</p>
                            </div>
                        </alloy-accordion>
                    </ng-container>

                    <!-- Container delle domande -->
                    <ng-container *ngIf="currentModule.questions">
                        <ng-container *ngFor="let question of currentModule.questions; let i = index">
                            <!-- Domande a risposta chiusa -->
                            <div class="question-container" *ngIf="question.isClosedAnswer && question.closedAnswer">
                                <div fxLayout="row" fxLayoutAlign="start end">
                                    <p class="position-relative">
                                        <span class="text-question"
                                            [innerHTML]="question.closedAnswer.selectedAnswerIndex != null &&  question.closedAnswer.selectedAnswerIndex >= 0 ? question.text : question.text + '*'"></span>
                                    </p>
                                </div>
                                <alloy-radio (onItemChange)="onRadioSelect($event, question)" fxLayout="column"
                                    fxLayoutAlign="start start" [plainItems]="question.closedAnswer.answers"
                                    [bindValueIndex]="question.closedAnswer.selectedAnswerIndex" [disabled]="isComplete"
                                    radioName="question-cpp-radio-{{i}}"></alloy-radio>
                            </div>
                            <!-- Domande a risposta aperta -->
                            <ng-container *ngIf="!question.isClosedAnswer">
                                <!-- Non ho dati per l'obbligatorietà quindi confronto il testo della domanda -->
                                <input-container *ngIf="question.text == 'Note e commenti'" class="input-container" [numberCharsTextArea]="300"
                                    [isFromTextArea]="true" [isUpwardFeedback]="true" [isWithoutInfoIcon]="true"
                                    [isValued]="question.openAnswer && question.openAnswer.text.length"
                                    [title]="question.text"
                                    [disabled]="isComplete">
                                    <alloy-text-area [bindValue]="question.openAnswer && question.openAnswer.text"
                                        (onModelChanged)="openAnswerUpdate($event, question)"
                                        placeholder="{{'generic.YOUR_ANSWERS' | translate}}">
                                    </alloy-text-area>
                                </input-container>
                                <input-container *ngIf="question.text != 'Note e commenti'" class="input-container" [numberCharsTextArea]="300"
                                    [isFromTextArea]="true" [isUpwardFeedback]="true" [isWithoutInfoIcon]="true"
                                    [isValued]="question.openAnswer && question.openAnswer.text.length && question.openAnswer.text.length > 9"
                                    [isObbligatory]="true" [title]="question.text" [errorText]="(question.openAnswer && question.openAnswer.text.length && question.openAnswer.text.length < 10) ? ('upwardFeedback.selfAssessment.TEXTAREA_ERROR' | translate)  : ''"
                                    [disabled]="isComplete">
                                    <alloy-text-area [bindValue]="question.openAnswer && question.openAnswer.text"
                                        (onModelChanged)="openAnswerUpdate($event, question)"
                                        placeholder="{{'generic.YOUR_ANSWERS' | translate}}">
                                    </alloy-text-area>
                                </input-container>
                            </ng-container>

                        </ng-container>
                    </ng-container>


                    <!-- Actions -->
                    <div class="action-container">
                        <alloy-button (onButtonClicked)="goPrecModule()"
                            [disabled]="currentModule.prevModuleId ? false : true" type="black-border" size="large"
                            iconLeft="assets/img/icons/arrow-left.svg" [label]="'generic.BACK' | translate">
                        </alloy-button>
                        <alloy-button class="margin-bottom0 large"
                            (onButtonClicked)="lastModuleView ? openConfirmSurveyModal() : onSaveCurrentModule()"
                            type="success default" size="large"
                            [disabled]="!isModuleComplete() || (lastModuleView && isComplete)"
                            [label]="(lastModuleView ? 'generic.SEND' : 'technicalAssessment.selfAssessment.SAVE_AND_GO') | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento cpp -->
            <ng-container *ngIf="isLoadingSurvey">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<!-- Modale conferma -->
<modal id="confirm-survey" (onClose)="closeConfirmSurveyModal()"
    [confirmLabel]="'upwardFeedback.selfAssessment.modals.CONFRIM_AND_SEND_TITLE' | translate"
    (onConfirm)="closeConfirmSurveyModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
    (onCancel)="closeConfirmSurveyModal()">
    <modal-text-content [title]="'upwardFeedback.selfAssessment.modals.CONFRIM_AND_SEND_TITLE' | translate"
        [subtitle]="'upwardFeedback.selfAssessment.modals.CONFRIM_AND_SEND_SUBTITLE' | translate"
        [text]="'upwardFeedback.selfAssessment.modals.CONFRIM_AND_SEND_DESC' | translate"></modal-text-content>
</modal>

<!-- Modale salva modifche -->
<modal id="save-edit" (onClose)="closeSaveEditModal()"
    [confirmLabel]="'upwardFeedback.selfAssessment.modals.SAVE_EDIT_BTN' | translate"
    (onConfirm)="closeSaveEditModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
    (onCancel)="closeSaveEditModal()">
    <modal-text-content [title]="'upwardFeedback.selfAssessment.modals.SAVE_EDIT_TITLE' | translate"
        [subtitle]="'upwardFeedback.selfAssessment.modals.SAVE_EDIT_SUBTITLE' | translate"
        [text]="'upwardFeedback.selfAssessment.modals.SAVE_EDIT_DESC' | translate"></modal-text-content>
</modal>

<!-- Modale guida -->
<modal id="guide-info" (onClose)="closeGuideInfoModal()"
    [confirmLabel]="'upwardFeedback.selfAssessment.modals.GUIDE_INFO_BTN' | translate"
    (onConfirm)="closeGuideInfoModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
    (onCancel)="closeGuideInfoModal()">
    <modal-text-content [title]="'upwardFeedback.selfAssessment.modals.GUIDE_INFO_TITLE' | translate"
        [text]="'upwardFeedback.selfAssessment.modals.GUIDE_INFO_DESC' | translate"></modal-text-content>
</modal>