<div class="main-container">
    <!-- Peerfeedback -->
    <ng-container *ngIf="object">
        <div class="rectangle-container">
            <!-- Card-->
            <div class="card-container">
                <!-- Header -->
                <card-feedback-header
                    icon="assets/img/icons/iteration-ccw.svg"
                    [creationDate]="creationDateText"
                    [title]="isPosted?
                    ('feedbackSection.smartFeedback.SENDED_SMART_FEEDBACK' | translate) + ' ' + object.recipientUser?.surname + ' ' + object.recipientUser?.forename:
                    ('feedbackSection.smartFeedback.RECEIVED_SMART_FEEDBACK' | translate) + ' ' + object.senderUser?.surname + ' ' + object.senderUser?.forename"
                    [user]="object.senderUser" [hasAvatar]="true" [avatarRight]="isPosted">
                </card-feedback-header>
                <div class="divisor-line"></div>

                <!-- Card profile -->
                <ng-container>
                    <div class="information-container">
                        <!-- Competenza -->
                        <div class="information-text-container">
                            <p class="information-title" translate="feedbackSection.REFERRED_TO"></p>
                            <p class="information-desc">{{object.competence?.title}}</p>
                            <!-- Text -->
                        </div>
                        <!-- Valutazione -->
                        <div class="information-text-container" *ngIf="!object.competence?.isFreeFeedback">
                            <p class="information-title" translate="feedbackSection.EVALUATION"></p>
                            <evaluation-system 
                              [direction]="'row'"
                              [size]="'small'"
                              [kind]="'default'"
                              [competenceScale]="true"
                              [items]="5"
                              [readOnly]="true"
                              [level]="competenceEvaluation"
                              [disabledFirstStar]="true">
                            </evaluation-system>
                        </div>
                        <!-- Commento -->
                        <div class="information-text-container">
                            <p class="information-title" translate="feedbackSection.MOTIVATION"></p>
                            <p class="information-desc">{{object.smartComment}}</p>
                            <!-- Text -->
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>