import { PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, PerfCareUserPhaseGoal, PerfCareGoalTargetStatutes, PerfCareGoalRatingStatutes, PerfCareGoalDivisions } from "../../commonclasses";
import * as moment from 'moment';

const DOC_TYPE = ['doc', 'docx', 'odt']
const PP_TYPE = ['ppt', 'pptx']
const EX_TYPE = ['xls', 'xlsx', 'ods'];
const IMG_TYPE = ['jpeg']

export const NON_VALUABLE_TYPES = [
  PerfCareMacroGoalTypes.COMPANY,
  PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT,
  PerfCareMacroGoalTypes.STRUCTURE,
  PerfCareMacroGoalTypes.OPERATIONS_KPI,
  PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES,
]

export const SHARED_STATUS = [
  PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED
]

export const FINAL_STATUSES = [
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED
]

// Classe per la formattazione dei dati riguardanti gli obiettivi della fase di goal setting
export class GoalUtils {


  // Funzione da chiamare passando la response del servizio e le autorizzazioni del peopleAppraisal
  static formatGoalCardList(goals: PerfCareUserPhaseGoal[], isPeopleAppraisal: boolean, userId: string) {
    let tmp: any[] = [];
    if (goals && goals?.length) {
      for (let i = 0; i < goals?.length; i++) {
        tmp.push(this.formatGoalCardData(goals[i], isPeopleAppraisal, userId));
      }
    }
    return tmp;
  }

  // Calcola testo trimestre, semestre ecc per la sidenav
  static calculateMesterAndMonths(translate: any, goal: any, giveMonth?: boolean) {
    let objectTexts =
    {
      mester: '',
      month: ''
    };
    switch (goal && goal.consuntivation && goal.consuntivation.division) {
      case PerfCareGoalDivisions.BIMESTRALE:
        objectTexts.mester = translate.instant('goalSetting.updatePMGoal.BIMESTER');
        switch (goal && goal.consuntivation && goal.consuntivation.order) {
          case 0:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.JANUARY') + ' - ' + translate.instant('goalSetting.updatePMGoal.FEBRUARY');
            break;
          case 1:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.MARCH') + ' - ' + translate.instant('goalSetting.updatePMGoal.AVRIL');
            break;
          case 2:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.MAY') + ' - ' + translate.instant('goalSetting.updatePMGoal.JUNE');
            break;
          case 3:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.JULY') + ' - ' + translate.instant('goalSetting.updatePMGoal.AUGUST');
            break;
          case 4:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.SEPTEMBER') + ' - ' + translate.instant('goalSetting.updatePMGoal.OCTOBER');
            break;
          case 5:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.NOVEMBER') + ' - ' + translate.instant('goalSetting.updatePMGoal.DECEMBER');
            break;
        }
        if (giveMonth) {
          return objectTexts.month;
        } else {
          return objectTexts.mester;
        }
      case PerfCareGoalDivisions.QUADRIMESTRALE:
        objectTexts.mester = translate.instant('goalSetting.updatePMGoal.QUADRIMESTER');
        switch (goal && goal.consuntivation && goal.consuntivation.order) {
          case 0:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.JANUARY') + ' - ' + translate.instant('goalSetting.updatePMGoal.AVRIL');
            break;
          case 1:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.MAY') + ' - ' + translate.instant('goalSetting.updatePMGoal.AUGUST');
            break;
          case 2:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.SEPTEMBER') + ' - ' + translate.instant('goalSetting.updatePMGoal.DECEMBER');
            break;
        }
        if (giveMonth) {
          return objectTexts.month;
        } else {
          return objectTexts.mester;
        }
      case PerfCareGoalDivisions.SEMESTRALE:
        objectTexts.mester = translate.instant('goalSetting.updatePMGoal.SEMESTER');
        switch (goal && goal.consuntivation && goal.consuntivation.order) {
          case 0:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.JANUARY') + ' - ' + translate.instant('goalSetting.updatePMGoal.JUNE');
            break;
          case 1:
            objectTexts.month = translate.instant('goalSetting.updatePMGoal.JULY') + ' - ' + translate.instant('goalSetting.updatePMGoal.DECEMBER');
            break;
        }
        if (giveMonth) {
          return objectTexts.month;
        } else {
          return objectTexts.mester;
        }
      default:
        return '';
    }
  }

  // Funzione di supporto, formatta il singolo goal
  static formatGoalCardData(goal: any, isPeopleAppraisal: boolean, userId: string) {
    const localtz = moment.tz.guess()
    let statusTmp = GoalUtils.getStatus(goal, isPeopleAppraisal);
    let miniCards = GoalUtils.getMiniCards(goal, isPeopleAppraisal, userId);
    let isOperation = false;

    let targetTmp = {
      status: '',
      statusMessage: '',
    };
    let resultStatusGoalTmp = {
      status: '',
      statusMessage: '',
    };
    if (goal.targetStatus) {
      targetTmp = GoalUtils.getTargetStatus(goal.targetStatus);
    }
    if (goal.ratingStatus) {
      resultStatusGoalTmp = GoalUtils.getResultStatus(goal.ratingStatus);
    }
    if (goal.macroGoalType == PerfCareMacroGoalTypes.OPERATIONS_KPI || goal.macroGoalType == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES) {
      isOperation = true;
    }
    if (goal.macroGoalType == PerfCareMacroGoalTypes.MY_CARE_ATTRIBUTES) {
      return GoalUtils.formatCompetenceTag(goal, isPeopleAppraisal);
    } else {
      let fileNumber = 0;
      if (goal.link && goal.link.length) {
        fileNumber += goal.link.length
      }
      let files: any[] = [];
      if (goal.upload && goal.upload.length) {
        fileNumber += goal.upload.length
        files = GoalUtils.formatFiles(goal.upload);
      }
      return {
        id: goal.goalId,
        title: goal.title || 'Titolo non inserito',
        status: statusTmp.status,
        statusMessage: statusTmp.statusMessage,
        goalStatus: goal.goalStatus,
        targetStatus: goal.targetStatus,
        targetMessage: targetTmp.statusMessage,
        targetStatusForComponent: targetTmp.status,
        resultMessage: goal.ratingObservations || resultStatusGoalTmp.statusMessage,
        resultStatusForComponent: resultStatusGoalTmp.status,
        // rating obiettivi rolling
        ratingStatus: goal.ratingStatus,
        rating: goal.rating,
        ratingMessage: goal.rating ? 'goalSettings.upda' : '',
        target: goal.target,
        result: goal.result,
        consuntivation: goal.consuntivation,
        miniCards: miniCards,
        weight: goal.weight || 0,
        type: goal.macroGoalType,
        deadline: goal.deadlineDate ? moment(goal.deadlineDate).tz(localtz).format('DD/MM/YYYY') : null,
        deadlineDate: goal.deadlineDate,
        description: !isOperation || (isOperation && goal.macroGoalType === PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES) ? (goal.description || '-') : null,
        files: files,
        fileNumber: fileNumber,
        isOperation: isOperation,
        careTitle: GoalUtils.getCareTitle(goal),
        careDescription: goal.howToUseCareTag,
        describeWhatYouIntendToDoForWinChallenge: goal.describeWhatYouIntendToDoForWinChallenge,
        feedbackCounter: goal.peerFeedbacks && goal.peerFeedbacks.length || 0,
        evidencesCounter: goal.evidenceFeedbacks?.length || 0,
        creationUserId: goal.creationUserId,
        objectiveDescription: goal.objectiveDescription,
        peopleAppraisalComment: goal.peopleAppraisalComment,
        peopleAppraisalCommentDate: goal.peopleAppraisalCommentDate,
        links: goal.link,
        isChallenge: ((goal.macroGoalType == 'DEVELOPMENT' || goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) && goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) ? true : false,
        isNew: (typeof goal.hasGoalBeenSaw === 'boolean' && goal.hasGoalBeenSaw === false),
        sharedObjective: isPeopleAppraisal && (goal.goalStatus === PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT),
        originalGoalId: goal.originalGoalId || null,
        creationDate: moment(goal.creationDate).format('DD.MM.YYYY'),
        goalEvaluation: goal.goalEvaluation
      }
    }
  }


  static formatFiles(files: any) {
    let tmpList = [];
    for (let i = 0; i < files.length; i++) {
      let tmpTitle = files[i].fileName.split('.');
      let fileName = tmpTitle[0];
      tmpList.push({
        creationDate: files[i].creationDate,
        creationUserId: files[i].creationUserId,
        fileName: fileName,
        originalFileName: files[i].fileName,
        goalId: files[i].goalId,
        uploadId: files[i].uploadId,
        url: files[i].url
      })
    }
    return tmpList;
  }

  // Ritorna i "tag" presenti negli obiettivi Es: di ruolo, proposta, di sviluppo ecc...
  static getMiniCards(goal: any, isPeopleAppraisal: boolean, userId: string) {
    let tmpList = [];
    if (goal.macroGoalType != 'DEVELOPMENT' && goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && goal.macroGoalType != PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
      tmpList.push({
        title: 'goal.miniCards.' + goal.macroGoalType,
        color: 'default'
      });
    }
    else if ((goal.macroGoalType == 'DEVELOPMENT' || goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) && goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
      tmpList.push({
        title: 'goal.miniCards.CHALLENGE',
        color: 'accent'
      });
    } else if (goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
      tmpList.push({
        title: 'goal.miniCards.EMPOWERMENT',
        color: 'blue'
      });
    }
    return tmpList;
  }

  static getCareTitle(goal: any) {
    let title = "";
    if (goal.behaviorTag && goal.behaviorTag.title) {
      title += goal.behaviorTag.title + ' - '
    }
    if (goal.careTag && goal.careTag.title) {
      title += goal.careTag.title
    }
    return title;
  }

  // Ritorna lo stato del target goal per la tabella degli obiettivi di Team
  static getTargetStatus(goalTarget: string) {
    if (goalTarget == PerfCareGoalTargetStatutes.EXCLUDED) {
      return {
        status: 'disabled',
        statusMessage: 'goalSetting.updatePMGoal.targets.' + goalTarget
      }
    } else if (goalTarget == PerfCareGoalTargetStatutes.TO_BE_DEFINED) {
      return {
        status: 'toStart',
        statusMessage: 'goalSetting.updatePMGoal.targets.' + goalTarget
      }
    } else {
      return {
        status: '',
        statusMessage: ''
      }
    }
  }

  // Ritorna lo stato della valutazione goal per la tabella degli obiettivi di Team
  static getResultStatus(goalResult: string) {
    if (goalResult == PerfCareGoalRatingStatutes.DISABLED) {
      return {
        status: 'disabled',
        statusMessage: 'goalSetting.updatePMGoal.results.' + goalResult
      }
    } else if (goalResult == PerfCareGoalRatingStatutes.EXCLUDED) {
      return {
        status: 'disabled',
        statusMessage: 'goalSetting.updatePMGoal.results.' + goalResult
      }
    } else if (goalResult == PerfCareGoalRatingStatutes.TO_BE_EVALUATED) {
      return {
        status: 'toStart',
        statusMessage: 'goalSetting.updatePMGoal.results.' + goalResult
      }
    } else {
      return {
        status: '',
        statusMessage: ''
      }
    }
  }

  // Ritorna lo stato del goal (label in alto a sx) che popola item-list-item
  static getStatus(goal: any, isPeopleAppraisal: boolean) {
    let today = moment().format();
    if (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE) {
      if (isPeopleAppraisal) {
        return {
          status: 'inProgress',
          statusMessage: 'goal.status.COMPILING'
        }
      } else {
        return {
          status: 'toStart',
          statusMessage: 'goal.status.TO_COMPILE'
        }
      }
    } else if (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE) {
      if (isPeopleAppraisal) {
        return {
          status: 'toStart',
          statusMessage: 'goal.status.TO_EVALUATE'
        }
      } else {
        return {
          status: 'inProgress',
          statusMessage: 'goal.status.EVALUATING'
        }
      }
    } else if (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED) {
      return {
        status: 'completed',
        statusMessage: 'goal.status.COMPLETED'
      }
    } else if (goal.macroGoalType === PerfCareMacroGoalTypes.OPERATIONS_KPI || goal.macroGoalType === PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES) {
      // da aggiornare con gli altri stati
      return {
        status: 'inProgress',
        statusMessage: 'goal.status.IN_PROGRESS'
      }
    } else {
      if (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE_DRAFT || goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER_DRAFT) {
        return {
          status: 'disabled',
          statusMessage: 'goal.status.DRAFT'
        }
      }
      if (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE ||
        goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER) {
        return {
          status: 'toStart',
          statusMessage: 'goal.status.TO_SHARE'
        }
      } else if (isPeopleAppraisal &&
        (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT)) {
        // obiettivo condiviso visto dal peopleAppraisal (da approvare)
        return {
          status: 'toStart',
          statusMessage: 'goal.status.TO_APPROVE'
        }
      } else if (!isPeopleAppraisal &&
        (goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT)) {
        // obiettivo condiviso visto dal peopleAppraisal (da approvare)
        return {
          status: 'completed',
          statusMessage: 'goal.status.SHARED'
        }
      } else if (isPeopleAppraisal && goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
        return {
          status: 'inProgress',
          statusMessage: 'goal.status.IN_PROGRESS'
        }
      } else if (!isPeopleAppraisal && goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
        return {
          status: 'inProgress',
          statusMessage: 'goal.status.IN_PROGRESS'
        }
      } else {
        return {
          status: 'disabled',
          statusMessage: 'goal.status.DISABLED'
        }
      }
    }
  }

  static formatCompetenceTag(goal: any, isPeopleAppraisal: boolean) {
    let title = goal.behaviorTag && goal.behaviorTag.title;
    let descr = goal.behaviorTag && goal.behaviorTag.description;
    let statusTmp = GoalUtils.getStatus(goal, isPeopleAppraisal);
    return {
      id: goal.goalId,
      tagId: goal.behaviorTagId,
      evaluation: goal.careAttributeAreaType,
      title: title,
      ratingStatus: goal.ratingStatus,
      consuntivation: goal.consuntivation,
      weight: goal.weight || 0,
      type: goal.macroGoalType,
      deadline: moment(goal.deadlineDate).utc().format('DD/MM/YYYY'),
      goalStatus: goal.goalStatus,
      description: descr,
      creationUserId: goal.creationUserId,
      peopleAppraisalComment: goal.peopleAppraisalComment,
      peopleAppraisalCommentDate: goal.peopleAppraisalCommentDate,
      isEdit: isPeopleAppraisal ? false : true,
      isComplete: isPeopleAppraisal ? (!!goal.peopleAppraisalCommentDate) : (!!goal.peopleAppraisalCommentDate && (typeof goal.hasGoalBeenSaw === 'boolean' && goal.hasGoalBeenSaw === false)),
      isCompleteWithIconComment: !!goal.peopleAppraisalCommentDate,
      hasBeenSaw: (typeof goal.hasGoalBeenSaw === 'boolean' && goal.hasGoalBeenSaw === false)
    }
  }
}