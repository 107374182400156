import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreActions from "../../core/ngrx/core.actions";
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { PerformanceAssessmentAlloyStatuses } from 'atfcore-commonclasses';

@Component({
  selector: 'admin-cpp',
  templateUrl: './cpp.component.html',
  styleUrls: ['./cpp.component.scss']
})
export class CppComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;

  isLoadingCppRoundsData: boolean = false;
  getCppRoundsData$: Subscription = new Subscription();
  cppRoundData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: number = 0;
  processYearCode: string = '';

  isLoadingProcessDetails: boolean = false;
  getProcessDetails$: Subscription = new Subscription();
  processDetail: any[] = [];
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;
  tabList: { id: string; name: any; }[] = [];
  processYearSelected: boolean = false;
  translations: any;
  currentContentTab: any
  isLoadingAllCompetencesData: boolean = false;
  isLoadingYear: boolean = false;
  menuOptions: { id: string; title: any; icon: string; disabled?: boolean }[] = [];
  menuOptionsWithStatusClosed: { id: string; title: any; icon: string; disabled?: boolean }[] = [];
  selectedData: any[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    this.processYearSelected = false;

    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.translate.get([
              'process.status.ACTIVE',
              'process.status.STANDBY',
              'process.status.CLOSED',
              'generic.EDIT',
              'generic.DETAILS'
            ]).subscribe((translations) => {
              this.translations = translations;

              this.menuOptions = [
                {
                  id: 'manage',
                  title: this.translations['generic.EDIT'],
                  icon: '/assets/img/icons/edit.svg'
                },
                {
                  id: 'details',
                  title: translations['generic.DETAILS'],
                  icon: '/assets/img/icons/clipboard-list.svg'
                }
              ]

              this.menuOptionsWithStatusClosed = [
                {
                  id: 'manage',
                  title: this.translations['generic.EDIT'],
                  icon: '/assets/img/icons/edit.svg',
                  disabled: true
                },
                {
                  id: 'details',
                  title: translations['generic.DETAILS'],
                  icon: '/assets/img/icons/clipboard-list.svg'
                }
              ]
              this.getCppRoundsData();
            })
          }
        });
  }

  ngOnInit() { }


  getCppRoundsData() {
    this.isLoadingCppRoundsData = true;
    if (this.getCppRoundsData$) {
      this.getCppRoundsData$.unsubscribe();
    }
    this.getCppRoundsData$ = this.adminService.countCppRounds()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.cppRoundData.counter = counter.response;

              // Calcolo la paginazione
              this.cppRoundData.fromRecord = (this.cppRoundData.page - 1) * this.cppRoundData.numRecords;

              if (this.cppRoundData.counter) {
                return this.adminService.getCppRounds(this.cppRoundData.fromRecord?.toString(), this.cppRoundData.numRecords?.toString());
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingCppRoundsData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            for (let i = 0; i < data.response.length; i++) {
              if (data.response[i].roundStatus == PerformanceAssessmentAlloyStatuses.STANDBY) {
                data.response[i].statusType = 'inProgress';
                data.response[i].statusMessage = this.translations['process.status.STANDBY'];
              } else if (data.response[i].roundStatus == PerformanceAssessmentAlloyStatuses.RUNNING) {
                data.response[i].statusType = 'completed';
                data.response[i].statusMessage = this.translations['process.status.ACTIVE'];
              } else {
                data.response[i].statusType = 'disabled';
                data.response[i].statusMessage = this.translations['process.status.CLOSED'];
              }
            }
            this.cppRoundData.list = data.response || [];
          }
          this.isLoadingCppRoundsData = false;
        }
        , (err: any) => {
          this.isLoadingCppRoundsData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  isThereActiveRound() {
    let activeRounds = this.cppRoundData?.list?.filter((x: any) => {
      return x.roundStatus == PerformanceAssessmentAlloyStatuses.RUNNING;
    })

    return activeRounds?.length;
  }

  roundPageChanged(page: number) {
    this.cppRoundData.page = page;
    this.cppRoundData.list = [];
    this.getCppRoundsData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.cppRoundData.numRecords = item.id;
    this.cppRoundData.list = [];
    this.cppRoundData.fromRecord = 0;
    this.getCppRoundsData();
  }


  goToCppRoundDetails(round: any) {
    this.router.navigate(['admin/cpp/' + round.roundId]);
  }

  goToCreateCppRound() {
    if (!this.isThereStandByRound()) {
      if (this.isThereActiveRound()) {
        this.router.navigate(['admin/createUpdateCppWithActiveRound']);
      } else {
        this.router.navigate(['admin/createUpdateCpp']);
      }
    } else {
      this.openCantCreateRound();
    }
  }

  isThereStandByRound() {
    let standbyRounds = this.cppRoundData?.list?.filter((x: any) => {
      return x.roundStatus == PerformanceAssessmentAlloyStatuses.STANDBY;
    })

    return standbyRounds?.length;
  }

  openCloseMenu(data: any) {
    let currentPresent = this.selectedData.find((x: any) => x.id == data.id);
    if (!currentPresent) {
      data.isChecked = true;
      this.addRemoveData(data);
    }
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], data)
    }
    data.isMenuOpen = !data.isMenuOpen;
  }

  isMenuOptionValid(option: any, user: any) {
    if (option.id == 'manage') {
      if (this.selectedData && this.selectedData.length > 1) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  closeMenu(data: any) {
    data.isMenuOpen = false;
  }

  addRemoveData(newObject: any) {
    if (this.selectedData && this.selectedData.length) {
      if (!newObject.isChecked) {
        this.selectedData = this.selectedData.filter((tmp: any) => tmp.id != newObject.id);
      } else {
        this.selectedData.push(newObject);
      }
    } else {
      this.selectedData = [newObject];
    }
  }

  onMenuOptionsClicked(option: any, round: any) {
    if (round.roundStatus == 'CLOSED') {
      if (option.id == 'details') {
        this.router.navigate(['admin/cpp/' + round.roundId]);
      }
    } else {
      if (option.id == 'manage') {
        this.router.navigate(['admin/createUpdateCpp/' + round.roundId]);
      } else {
        this.router.navigate(['admin/cpp/' + round.roundId]);
      }
    }
  }

  closeCantCreateRound() {
    this.modalService.close("cant-create-cpp-round");
  }

  openCantCreateRound() {
    this.modalService.open("cant-create-cpp-round");
  }

  ngOnDestroy() {
    if (this.getCppRoundsData$) {
      this.getCppRoundsData$.unsubscribe();
    }
  }
}


